export const customForm = editor => {
  editor.BlockManager.add('custom-form', {
    category: 'Experience Utils',
    label: 'Analytics Form',
    media:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 8H3V6h18v2zM22 10.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 13H3v-2h18v2z"/><rect width="10" height="3" x="2" y="15" rx=".5"/></svg>',
    content: {
      type: 'custom-form',
      classes: ['custom-form'],
      attributes: { name: 'analytics-form' },
      components: [
        {
          components: [
            { type: 'text', components: 'Age range' },
            { type: 'age-range-select', classes: ['age-range-select'] }
          ]
        },
        {
          components: [
            { type: 'text', components: 'Gender' },
            { type: 'gender-select', classes: ['gender-select'] }
          ]
        },
        {
          components: [
            {
              type: 'page-reference',
              attributes: { 'data-target': '' },
              components: [{ type: 'text', components: 'Continue' }]
            }
          ]
        }
      ]
    }
  });

  editor.DomComponents.addType('custom-form', {
    isComponent: el => el.tagName === 'FORM' && el.className === 'custom-form',

    model: {
      defaults: {
        tagName: 'form',
        droppable: ':not(form)',
        draggable: ':not(form)'
      }
    },
    traits: [],
    view: {
      events: {
        // The submit of the form might redirect the user from the editor so
        // we should always prevent the default here.
        submit: e => e.preventDefault()
      }
    }
  });
};
