import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useMediaFilesStyles = makeStyles(theme => ({
  headerWrapper: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingTop: 0
  },
  footerWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiTablePagination-toolbar': {
      alignItems: 'center !important',
      minHeight: 'unset'
    },
    '& .MuiToolbar-root': {
      padding: 0
    },
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiTablePagination-root': {
      padding: '5px 15px !important'
    }
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
