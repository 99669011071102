import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { NAME_SPACE } from 'i18n';
import { PATH } from 'app.routes.const';
import { ViewTable, skeletonFactory, useViewTableData, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { useListUsersStyles } from './listUsers.styles';
import { users as usersService } from 'services';
import { useUsersBuildTable } from './useUsersBuildTable';

const usersDummy = skeletonFactory.users();

export function ListUsers() {
  const classes = useListUsersStyles();
  const {
    data: users,
    get: getUsers,
    deleteElements: deleteUsers,
    errorInfo
  } = useViewTableData({
    service: usersService,
    paginatedSkeleton: usersDummy
  });
  const { t } = useTranslation(NAME_SPACE.USERS);
  const history = useHistory();

  const { rows, headCells } = useUsersBuildTable(users, t);

  function handleEdit([userId]) {
    history.push(PATH.USERS.EDIT.replace(':id', userId));
  }

  function handleAdd() {
    history.push(PATH.USERS.CREATE);
  }

  async function handleDelete(usersToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          usersToDelete.length > 1
            ? t('removeConfirm.message', { length: usersToDelete.length })
            : t('removeConfirm.singularMessage', {
                userName: usersToDelete[0].userName
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });
      await deleteUsers(usersToDelete);
    } catch (err) {}
  }

  return (
    <div className={classes.users}>
      <ViewTable
        elevation
        spaced
        errors={errorInfo}
        rows={rows}
        emptyText={t('empty')}
        cells={headCells}
        mainKey={'name'}
        onFetchData={getUsers}
        totalRows={users.total}
        selectRowWhen={element => element.canBeDeleted}
        serverSidePaging
        allowRowFilter={false}
        actions={[
          {
            title: t('actions.edit'),
            name: 'edit',
            properties: ['id'],
            label: 'edit this row',
            cb: handleEdit,
            icon: <EditIcon />
          }
        ]}
        toolbar={{
          title: t('name'),
          actions: [
            {
              condition: isAnySelected => !isAnySelected,
              cb: handleAdd,
              component: ButtonAdd
            },
            {
              condition: isAnySelected => isAnySelected,
              resetOnCB: true,
              cb: handleDelete,
              component: ButtonDelete
            }
          ]
        }}
      />
    </div>
  );
}
