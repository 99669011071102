import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { settings, KEY_SETTINGS } from './settings';
import { http } from './services/http';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import globalStyles from './styles';
import './i18n';
import { APIS } from 'app.apis.const';
import 'poppins-font';
import 'nprogress/nprogress.css';
// GrapesJS
import 'grapesjs/dist/css/grapes.min.css';
import './assets/css/grapesjs.css?v=0.1.1';
import './assets/css/gjs-asset-manager.css?v=0.1.0';

async function startUp() {
  /**
   * Here we are fetching settings
   * set BaseUrl for the Static HTTP
   */
  process.env.REACT_APP_DOCKER ? await settings.loadDockerSettings() : await settings.loadSettings();

  const hgtagApiURL = `${settings.getSetting(KEY_SETTINGS.API_URL)}/api/v1.0`;
  const identityApiURL = `${settings.getSetting(KEY_SETTINGS.IDENTITY_URL)}/api/v1.0`;

  const azureStorageURL = `${settings.getSetting(KEY_SETTINGS.STORAGE_URL)}`;

  const azureCommonStorageURL = `${settings.getSetting(KEY_SETTINGS.COMMON_STORAGE_URL)}`;

  http.setBaseUrls([
    { key: APIS.HGTAG, url: hgtagApiURL },
    { key: APIS.IDENTITY, url: identityApiURL },
    { key: APIS.AZURE_STORAGE, url: azureStorageURL },
    { key: APIS.AZURE_COMMON_STORAGE, url: azureCommonStorageURL }
  ]);
}

(async () => {
  try {
    await startUp();
    ReactDOM.render(
      <BrowserRouter>
        <ThemeProvider theme={globalStyles}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>,
      document.getElementById('root')
    );
  } catch (error) {
    ReactDOM.render(
      <React.StrictMode>
        <dir>{error}</dir>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
