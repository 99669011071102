import { useMemo } from 'react';
import { settings, KEY_SETTINGS } from 'settings';
import { dateHelper } from 'shared';
import { isSkeletonLoading } from 'shared';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';

export function useTagsBuildTable(inputs, t) {
  const tamperElements = useMemo(() => {
    const TAMPER = {
      true: 'common:actions.yes',
      false: 'common:actions.no'
    };

    var tamperMapped = Object.keys(TAMPER).map(key => {
      return {
        value: key,
        name: t(TAMPER[key])
      };
    });

    return [{ value: -1, name: t('common:selectFirstOptionComboFilter') }, ...tamperMapped];
  }, [t]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'uuid',
        numeric: false,
        disablePadding: true,
        label: t('properties.uuid')
      },
      {
        id: 'product',
        numeric: false,
        disablePadding: true,
        label: t('properties.product')
      },
      {
        id: 'serialNumber',
        numeric: false,
        disablePadding: true,
        ellipsis: true,
        label: t('properties.serialNumber')
      },
      {
        id: 'requestDate',
        numeric: false,
        disablePadding: false,
        label: t('properties.requestDate'),
        filterType: FILTER_TYPE.NONE
      },
      {
        id: 'adquisitionDate',
        numeric: false,
        disablePadding: false,
        label: t('properties.adquisitionDate'),
        filterType: FILTER_TYPE.NONE
      },
      {
        id: 'tamper',
        numeric: false,
        disablePadding: false,
        label: t('properties.tamper'),
        filterType: FILTER_TYPE.COMBO,
        filterValues: tamperElements
      },
      {
        id: 'customUrl',
        numeric: false,
        disablePadding: false,
        label: t('properties.customUrl'),
        isSortable: false,
        filterType: FILTER_TYPE.NONE
      },
      {
        id: 'scans',
        numeric: false,
        disablePadding: false,
        label: t('properties.scans'),
        isSortable: false,
        filterType: FILTER_TYPE.NONE
      }
    ];
  }, [t, tamperElements]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }

    const experienceAppUrl = settings.getSetting(KEY_SETTINGS.EXPERIENCE_APP_URL);

    return inputs.items.map(tag => ({
      uuid: tag.uuid,
      product: tag.product,
      serialNumber: tag.serialNumber,
      requestDate: isSkeletonLoading(tag.requestDate)
        ? tag.requestDate
        : tag.requestDate
        ? dateHelper.toShortDate(tag.requestDate)
        : '',
      adquisitionDate: isSkeletonLoading(tag.adquisitionDate)
        ? tag.adquisitionDate
        : tag.adquisitionDate
        ? dateHelper.toShortDate(tag.adquisitionDate)
        : '',
      tamper: tag.tamper,
      customUrl: isSkeletonLoading(tag.id) ? tag.id : `${experienceAppUrl || ''}?productId=${tag.id}`,
      scans: tag.scans,
      id: tag.id,
      canBeDeleted: tag.scans === 0
    }));
  }, [inputs]);

  return { headCells, rows };
}
