import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ViewTemplatePage } from './ViewTemplatePage';
import { useViewTemplatePagesStyles } from './viewTemplatePages.styles';

export const ViewTemplatePages = ({ experience, pages, onChange, onSwitchChange, templateUsesMediaFiles }) => {
  const classes = useViewTemplatePagesStyles();
  const pageColumns = pages.length === 3 ? 3 : 2;

  return (
    <Grid container spacing={5} className={classes.root}>
      {pages.map((page, index) => (
        <ViewTemplatePage
          key={index}
          page={page}
          experience={experience}
          onChange={onChange}
          onSwitchChange={onSwitchChange}
          templateUsesMediaFiles={templateUsesMediaFiles}
          pageColumns={pageColumns}
        />
      ))}
    </Grid>
  );
};

ViewTemplatePages.propTypes = {
  experience: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  pages: PropTypes.array,
  templateUsesMediaFiles: PropTypes.bool
};
