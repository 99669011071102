import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { Grid } from '@material-ui/core';
import { useFormChangePasswordStyles } from './FormChangePassword.styles';

export function FormChangePassword({ formRef, onSubmit, errorInfo }) {
  const [inputs, setInputs] = useState({});
  const { t } = useTranslation(NAME_SPACE.USERS);
  const classes = useFormChangePasswordStyles();

  function handleSubmit() {
    onSubmit(inputs);
  }

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));
    };

  return (
    <Form
      className={`${classes.form} ${classes.container}`}
      elementRef={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      errors={errorInfo}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Input
            required
            type="password"
            name={t('properties.currentPassword')}
            label={t('properties.currentPassword')}
            variant="outlined"
            value={inputs.currentPassword || ''}
            onChange={handleChange('currentPassword')}
            inputProps={{ 'aria-label': t('properties.currentPassword') }}
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            type="password"
            name={t('properties.newPassword')}
            label={t('properties.newPassword')}
            variant="outlined"
            value={inputs.newPassword || ''}
            onChange={handleChange('newPassword')}
            inputProps={{ 'aria-label': t('properties.newPassword') }}
            validators={['required']}
            errorMessages={[
              t('validations.required'),
              inputs.newPassword !== inputs.repeatNewPassword && 'Son distintos'
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            type="password"
            name={t('properties.repeatNewPassword')}
            label={t('properties.repeatNewPassword')}
            variant="outlined"
            value={inputs.repeatNewPassword || ''}
            onChange={handleChange('repeatNewPassword')}
            inputProps={{ 'aria-label': t('properties.repeatNewPassword') }}
            validators={['required', `isPasswordMatch:${inputs.newPassword}`]}
            errorMessages={[t('validations.required'), t('validations.passwordMissmatch')]}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

FormChangePassword.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errorInfo: PropTypes.object
};
