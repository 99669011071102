import { useCallback, useEffect, useState } from 'react';
import { authHelper, useHandleRequest } from 'shared';
import { auth, users } from 'services';
import { authStorage } from 'auth';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { PATH } from 'app.routes.const';
import { ROLES } from './roles.const';
import { ACCESS } from './access.const';
import { PLAN_TYPE } from './planType.const';
import { PLAN_ACCESS } from './planAccess.const';

const CLAIM_USERNAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const CLAIM_ROLE = 'http://schema.hfbarcelona.com/role';

export const UserContext = React.createContext({});

function getUserAccesses(role) {
  switch (role) {
    case ROLES.ADMIN:
      return (
        ACCESS.ANALYTICS |
        ACCESS.CONFIGURATION |
        ACCESS.EXPERIENCES |
        ACCESS.MULTILANGUAGE_EXPERIENCES |
        ACCESS.HTML_TEMPLATES |
        ACCESS.MARKERS |
        ACCESS.MEDIA_FILES |
        ACCESS.QR_CODES |
        ACCESS.STICKERS |
        ACCESS.TAGS |
        ACCESS.USERS
      );
    case ROLES.IDENTITY_MANAGER:
      return ACCESS.USERS;
    case ROLES.CONTENT_EDITOR:
      return (
        ACCESS.EXPERIENCES |
        ACCESS.MULTILANGUAGE_EXPERIENCES |
        ACCESS.HTML_TEMPLATES |
        ACCESS.MEDIA_FILES |
        ACCESS.STICKERS
      );
    case ROLES.ANALYTICS:
      return ACCESS.ANALYTICS;
    case ROLES.TAG_MANAGER:
      return ACCESS.TAGS;
    default:
      return undefined;
  }
}

function getPlanAccess(planType) {
  switch (planType) {
    case PLAN_TYPE.BASIC:
      return PLAN_ACCESS.BASIC;
    case PLAN_TYPE.STANDARD:
      return PLAN_ACCESS.BASIC | PLAN_ACCESS.STANDARD;
    case PLAN_TYPE.ADVANCED:
      return PLAN_ACCESS.BASIC | PLAN_ACCESS.STANDARD | PLAN_ACCESS.ADVANCED;
    case PLAN_TYPE.PREMIUM:
      return PLAN_ACCESS.BASIC | PLAN_ACCESS.STANDARD | PLAN_ACCESS.ADVANCED | PLAN_ACCESS.PREMIUM;
    default:
      return undefined;
  }
}

function userInfoMapper(user) {
  const accesses = getUserAccesses(user[CLAIM_ROLE]);
  const planAccess = getPlanAccess(user.plan);
  return {
    alias: user[CLAIM_USERNAME],
    avatar: user.avatar,
    exp: user.exp,
    role: user[CLAIM_ROLE],
    name: user[CLAIM_USERNAME],
    access: accesses,
    id: user.sub,
    planType: user.plan,
    planAccess: planAccess
  };
}

// TODO: tests
export function useUserContext() {
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const [user, setUser] = useState({});
  const [authToken, setAuthToken] = useState('');
  const [isLogged, setIsLogged] = useState(!!authStorage.getToken());
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const clear = useCallback(() => {
    authStorage.setToken('');
    authStorage.setUserName('');
    authStorage.setPassword('');
    setAuthToken('');
    setUser('');
    setIsLogged(false);
    history.push(PATH.AUTHENTICATION.LOGIN);
  }, [history]);

  useEffect(() => {
    async function getData() {
      await request(async () => {
        try {
          const token = authStorage.getToken();

          if (token) {
            const userInfo = authHelper.claimDeserializer(token);
            const user = userInfoMapper(userInfo);
            const response = await users.getMy();
            setUser({ ...user, ...(response?.data || {}) });
            setAuthToken(token);
            setIsLogged(!!token);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          clear();
        }
      }, false);
    }
    getData();
  }, [clear, request]);

  const setUserLogged = useCallback(
    async _userTokenData => {
      authStorage.setToken(_userTokenData.token);
      setAuthToken(_userTokenData.token);
      const userInfo = authHelper.claimDeserializer(_userTokenData.token);
      const user = userInfoMapper(userInfo);

      const responseUser = await users.getMy();
      setUser({ ...user, ...(responseUser?.data || {}) });

      setIsLogged(true);
      setErrorInfo(null);
    },
    [setErrorInfo]
  );

  const login = useCallback(
    async _user => {
      await request(async () => {
        const response = await auth.login(_user);

        authStorage.setUserName(_user.user);
        authStorage.setPassword(_user.password);

        await setUserLogged(response.data);
      });
    },
    [request, setUserLogged]
  );

  const logout = useCallback(
    async user => {
      await request(async () => {
        clear();
      });
    },
    [request, clear]
  );

  const hasMinimumPlan = minimumPlan => {
    if (!minimumPlan) return true;
    return Boolean(user.planAccess & minimumPlan);
  };

  return {
    user,
    authToken,
    isLogged,
    isLoading,
    setUser,
    setAuthToken,
    setIsLogged,
    login,
    logout,
    errorInfo,
    setErrorInfo,
    hasMinimumPlan
  };
}
