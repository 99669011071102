import { useCallback, useState } from 'react';

export function useSelected({ data = [], keyName = 'id', initialSelected = [], selectWhenCondition = null }) {
  const [selectedData, setSelectedData] = useState(initialSelected);

  const setSelectToggleAll = useCallback(() => {
    setSelectedData(prevSelected => {
      if (data.length === prevSelected.length) {
        return [];
      }

      let newSelectedData = data;

      if (selectWhenCondition) {
        newSelectedData = newSelectedData.filter(element => selectWhenCondition(element));
      }

      return newSelectedData.map(n => n[keyName]);
    });
  }, [data, keyName, selectWhenCondition]);

  const setSelected = useCallback(key => {
    setSelectedData(prevSelected => {
      const selectedIndex = prevSelected.indexOf(key);
      let newSelected = [];

      if (selectedIndex === -1) {
        return newSelected.concat(prevSelected, key);
      } else if (selectedIndex === 0) {
        return newSelected.concat(prevSelected.slice(1));
      } else if (selectedIndex === prevSelected.length - 1) {
        return newSelected.concat(prevSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        return newSelected.concat(prevSelected.slice(0, selectedIndex), prevSelected.slice(selectedIndex + 1));
      }
    });
  }, []);

  const cleanAllSelected = useCallback(() => {
    setSelectedData([]);
  }, []);

  const getFullObjectSelected = useCallback(() => {
    return selectedData.map(s => data.find(d => d[keyName] === s));
  }, [data, keyName, selectedData]);

  return { selected: selectedData, setSelected, setSelectToggleAll, getFullObjectSelected, cleanAllSelected };
}
