import { useCallback } from 'react';
import { useViewTableData, useHandleRequest, skeletonFactory } from 'shared';
import { stickers } from 'services';

const stickersDummy = skeletonFactory.stickers();

export function useStickersPaginated() {
  const {
    data: stickersData,
    get: getStickers,
    deleteElements: deleteStickers,
    errorInfo: getDeleteErrorKey,
    setErrorInfo,
    setData,
    paging,
    sorting,
    undoData
  } = useViewTableData({
    service: stickers,
    paginatedSkeleton: stickersDummy
  });
  const { request, errorInfo } = useHandleRequest();

  const copySticker = useCallback(
    async stickerId => {
      try {
        await request(async () => {
          setData({ ...stickersDummy });
          await stickers.clone(stickerId);
          await getStickers(paging, sorting);
        });
      } catch (error) {
        undoData();
      }
    },
    [getStickers, request, setData, paging, sorting, undoData]
  );

  return {
    stickers: stickersData,
    errorInfo: errorInfo || getDeleteErrorKey,
    setErrorInfo,
    getStickers,
    deleteStickers,
    copySticker
  };
}
