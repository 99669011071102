import { http } from './http';
import { apiHelper } from 'shared';

class QRCodes {
  get(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, ...advancedFilters });
    return http.get(`/qr-codes${queryString}`);
  }

  async getByExperience(productId) {
    const response = await http.get(`/qr-codes?productId=${productId}`);
    const items = response.data && response.data.items;

    return items;
  }

  delete(id) {
    return http.delete(`/qr-codes/${id}`);
  }

  create(qrCode) {
    return http.post('/qr-codes', qrCode);
  }
}

export const qrCodes = new QRCodes();
