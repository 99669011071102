import { makeStyles } from '@material-ui/core/styles';

export const useViewExperienceStyles = makeStyles(theme => ({
  experience: {
    flexGrow: 1,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(4)}px`,
    marginRight: '4px',
    marginLeft: '4px'
  }
}));
