import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { APIS } from 'app.apis.const';
import { http } from './http';

class AzureCommonStorage {
  async uploadFile({ content, mimeType, containerIdentifier, sasToken, prefix }) {
    const date = moment().utc().format('ddd, MMM D YYYY hh:mm A');
    const uuid = uuidv4();

    const headers = {
      'x-ms-content-type': mimeType,
      'Content-Type': mimeType,
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-version': '2018-03-28',
      'x-ms-date': date,
      Accept: '*/*'
    };

    await http.put(
      `/${containerIdentifier}/${prefix}/${uuid}?${sasToken}&api-version=2018-03-28`,
      content,
      { headers },
      APIS.AZURE_COMMON_STORAGE,
      false
    );

    return `${http.getBaseUrl(APIS.AZURE_COMMON_STORAGE)}/${containerIdentifier}/${prefix}/${uuid}`;
  }

  deleteFile = async ({ blobUrl, sasToken }) => {
    const url = `${blobUrl}?${sasToken}&api-version=2018-03-28`;
    await http.delete(url, {}, APIS.AZURE_COMMON_STORAGE, false, false);
  };

  getFiles = async ({ containerIdentifier, prefix }) => {
    let queryString = '';
    if (prefix) {
      queryString = `&prefix=${prefix}`;
    }

    const response = await http.get(
      `/${containerIdentifier}?restype=container&comp=list&api-version=2018-03-28${queryString}`,
      {},
      APIS.AZURE_COMMON_STORAGE,
      false
    );

    const parser = new DOMParser();
    const xml = parser.parseFromString(response.data, 'application/xml');
    let blobList = Array.from(xml.querySelectorAll('Url'));
    const blobs = blobList.map(blobUrl => {
      return {
        blobUrl: blobUrl.textContent
      };
    });

    return blobs;
  };

  getSasToken(containerId) {
    return http.post(`/blob-containers/${containerId}/write-access-token`);
  }
}

export const azureCommonStorage = new AzureCommonStorage();
