import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useViewBlockPagesStyles } from './viewBlockPages.styles';
import { ContentCard } from 'pages/shared';

export function ViewBlockPages({ configuration, onSwitchChange }) {
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const classes = useViewBlockPagesStyles();

  return (
    <div className={classes.blockPages}>
      <ContentCard title={t('legalBlockSections')}>
        <>
          <Grid item xs={6}>
            <Switch
              className={classes.switch}
              label={t('properties.ageCheckAllowed')}
              checked={configuration.ageCheckAllowed || false}
              isLoading={isSkeletonLoading(configuration.ageCheckAllowed)}
              onChange={onSwitchChange('ageCheckAllowed')}
            />
          </Grid>
          <Grid item xs={6}>
            <Switch
              className={classes.switch}
              label={t('properties.authenticationAllowed')}
              checked={configuration.authenticationAllowed || false}
              isLoading={isSkeletonLoading(configuration.authenticationAllowed)}
              onChange={onSwitchChange('authenticationAllowed')}
            />
          </Grid>
          <Grid item xs={12}>
            <hr className={classes.switchSeparator} />
          </Grid>
          <Grid item xs={6}>
            <Switch
              className={classes.switch}
              label={t('properties.promotionalVideoAllowed')}
              checked={configuration.promotionalVideoAllowed || false}
              isLoading={isSkeletonLoading(configuration.promotionalVideoAllowed)}
              onChange={onSwitchChange('promotionalVideoAllowed')}
            />
          </Grid>
          <Grid item xs={6}>
            <Switch
              className={classes.switch}
              label={t('properties.experienceInfoAllowed')}
              checked={configuration.experienceInfoAllowed || false}
              isLoading={isSkeletonLoading(configuration.experienceInfoAllowed)}
              onChange={onSwitchChange('experienceInfoAllowed')}
            />
          </Grid>
        </>
      </ContentCard>
    </div>
  );
}

ViewBlockPages.propTypes = {
  configuration: PropTypes.shape({
    ageCheckAllowed: PropTypes.bool,
    authenticationAllowed: PropTypes.bool,
    promotionalVideoAllowed: PropTypes.bool,
    experienceInfoAllowed: PropTypes.bool
  }),
  onSwitchChange: PropTypes.func
};
