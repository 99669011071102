import { SKELETON_KEY, SKELETON_KEY_DATE } from './skeletonFactory';

export function skeletonInstallation() {
  return {
    clientCode: SKELETON_KEY,
    plan: SKELETON_KEY,
    startDate: SKELETON_KEY_DATE,
    finishDate: SKELETON_KEY_DATE
  };
}
