import { http } from './http';
import { apiHelper } from 'shared';

class Languages {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/languages${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }
}

export const languages = new Languages();
