import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { Form, Tabs, AsyncInputAutocomplete, Input } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';
import { NAME_SPACE } from 'i18n';
import { useFormMultilanguageExperienceStyles } from './formMultilanguageExperience.styles';
import { useMultilanguageExperienceForm } from './useMultilanguageExperienceForm';
import { languages } from 'services';
import { ViewRelatedExperiences } from '../ViewRelatedExperiences';

export function FormMultilanguageExperience({ formRef, onSubmit, experience, createMode, errors, onChangeInput }) {
  const { t } = useTranslation(NAME_SPACE.MULTILANGUAGE_EXPERIENCES);
  const classes = useFormMultilanguageExperienceStyles();
  const { experienceInputs, handleChange, handleLanguageAutocomplete, handleDeleteExperiences, handleAddExperiences } =
    useMultilanguageExperienceForm(experience, onChangeInput);

  function handleSubmit() {
    onSubmit({
      ...experienceInputs,
      relatedProductIds: [...experienceInputs.relatedProducts.map(exp => exp.id)]
    });
  }

  const renderTabs = useMemo(() => {
    let tabs = [
      {
        name: t('relatedExperiences.name'),
        icon: <ViewQuiltOutlinedIcon />,
        children: (
          <ViewRelatedExperiences
            relatedExperiences={experienceInputs?.relatedProducts}
            onDeleteExperiences={handleDeleteExperiences}
            onAddExperiences={handleAddExperiences}
          />
        )
      }
    ];

    return tabs;
  }, [t, experienceInputs, handleDeleteExperiences, handleAddExperiences]);

  return (
    <>
      <Form
        className={classes.formExperience}
        errors={errors}
        elementRef={formRef}
        onSubmit={handleSubmit}
        autoComplete="off"
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <Grid container className={`${classes.header} ${classes.background} ${classes.containers}`}>
          <Grid item container spacing={3}>
            <Grid item xs={6}>
              <Input
                isLoading={isSkeletonLoading(experienceInputs.name)}
                required
                name={t('properties.name')}
                label={t('properties.name')}
                variant="outlined"
                value={experienceInputs.name || ''}
                onChange={handleChange('name')}
                inputProps={{ 'aria-label': t('properties.name') }}
                validators={['required', 'maxStringLength:255']}
                errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
              />
            </Grid>
            <Grid item xs={6}>
              <AsyncInputAutocomplete
                isLoading={isSkeletonLoading({
                  name: experienceInputs.defaultLanguage,
                  id: experienceInputs.defaultLanguageId
                })}
                onChange={(e, element) => handleLanguageAutocomplete(element)}
                label={t('properties.defaultLanguage')}
                required
                variant="outlined"
                icon={<SearchIcon />}
                value={
                  createMode
                    ? null
                    : { name: experienceInputs?.defaultLanguage?.name, id: experienceInputs.defaultLanguageId }
                }
                defaultInputValue={experienceInputs.defaultLanguage?.name}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name}
                requestAction={languages.getUsingQueryFilter}
                validators={['required']}
                errorMessages={[t('validations.required')]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={`${classes.containers} ${classes.secondContainer}`}>
          <Grid item xs={12}>
            <Tabs classNameContent={classes.tabsMinHeight} tabs={renderTabs} />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormMultilanguageExperience.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  experience: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultLanguageId: PropTypes.string,
    defaultLanguage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string
      })
    ])
  }),
  createMode: PropTypes.bool.isRequired,
  onChangeInput: PropTypes.func
};
