import { makeStyles } from '@material-ui/core/styles';

export const useHeaderStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    background: theme.palette.secondary.light,
    filter: 'drop-shadow(0px 1px 10px rgba(74, 86, 109, 0.05))'
  },
  toolbar: {
    padding: '0.5rem',
    fontSize: '13px',

    '& .MuiButtonBase-root': {
      padding: 0,
      marginLeft: '15px',
      fontSize: '13px',
      '& p': {
        margin: '0'
      }
    },
    '& .MuiAvatar-root': {
      width: '29px',
      height: '29px',
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      fontSize: '0.7rem',
      alignItems: 'center',
      flexShrink: '0',
      fontFamily: 'Mulish, sans-serif',
      justifyItems: 'center',
      borderRadius: '25px',
      marginRight: '10px',
      justifyContent: 'center'
    },
    '& p': {
      color: theme.palette.secondary.light
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  iconMenu: {
    color: theme.palette.primary.main
  },
  title: {
    fontWeight: 900,
    fontSize: '37px',
    color: theme.palette.primary.dark,
    display: 'block'
  }
}));
