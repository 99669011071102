import { makeStyles } from '@material-ui/core/styles';

export const useEditMultilanguageExperienceStyles = makeStyles(theme => ({
  formOverride: {
    '& form': {
      padding: 'unset',
      background: 'unset',
      boxShadow: 'unset',
      borderRadius: 'unset'
    }
  }
}));
