import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { useContentCardStyles } from './contentCard.styles';

export function ContentCard({ title, headerComponent, children }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useContentCardStyles();

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {headerComponent}
      </Grid>
      <Grid item container xs={12} spacing={3} className={classes.contentContainer}>
        {children}
      </Grid>
    </Grid>
  );
}

ContentCard.propTypes = {};
