import { useEffect } from 'react';
import { useFormState } from 'shared';

export function useHtmlTemplateForm(htmlTemplate, onChangeInput) {
  const [htmlTemplateInputs, setHtmlTemplateInputs] = useFormState(htmlTemplate, onChangeInput);

  useEffect(() => {
    setHtmlTemplateInputs({ ...htmlTemplate }, false);
  }, [htmlTemplate]);

  const handleChange =
    fieldName =>
    ({ target }) => {
      setHtmlTemplateInputs(inputs => ({ ...inputs, [fieldName]: target.value }));
    };

  return {
    htmlTemplateInputs,
    handleChange
  };
}
