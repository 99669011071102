import 'grapesjs-blocks-basic';
import parserPostCSS from 'grapesjs-parser-postcss';
import gjsForms from 'grapesjs-plugin-forms';
import {
  basicPlugin,
  devicesPlugin,
  flexBoxPlugin,
  customCodePlugin,
  templatesPlugin
} from 'shared/CustomHtmlEditor/plugins';

export const viewCustomPagesConfig = {
  forceClass: false,
  height: '100%',
  width: '100%',
  fromElement: true,
  protectedCss: '',
  wrapper: 'div',
  clearStyles: false,
  storageManager: { type: 0 },
  avoidInlineStyle: true,
  noticeOnUnload: 0,
  plugins: [
    gjsForms,
    'experiences-plugin',
    flexBoxPlugin,
    'gjs-blocks-basic',
    parserPostCSS,
    customCodePlugin,
    basicPlugin,
    devicesPlugin,
    templatesPlugin
  ],
  pluginsOpts: {
    'gjs-blocks-basic': {
      blocks: ['text', 'link', 'image', 'video', 'map']
    },
    [basicPlugin]: {
      modalImportLabel:
        '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
      modalImportContent: function (editor) {
        const cssContent = editor.getCss();
        return editor.getHtml() + (cssContent ? `<style>${cssContent}</style>` : '');
      }
    },
    [customCodePlugin]: {
      blockLabel: 'Custom Code',
      blockCustomCode: {
        category: 'Basic'
      }
    },
    'experiences-plugin': {
      blocks: ['mainContainer', 'pageReference', 'cookiesButton']
    },
    [gjsForms]: { blocks: [] }
  },
  devices: [
    {
      name: 'Mobile',
      width: '320px',
      widthMedia: '480px'
    },
    {
      name: 'Desktop',
      width: ''
    }
  ],
  canvasStyles: [
    'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800;900&family=Abril+Fatface&family=Cinzel:wght@400;500;600;700;800;900&display=swap'
  ]
};
