import { SKELETON_KEY, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonTag() {
  return {
    id: SKELETON_KEY,
    uuid: SKELETON_KEY,
    serialNumber: SKELETON_KEY,
    product: SKELETON_KEY,
    customUrl: SKELETON_KEY,
    scans: SKELETON_KEY_NUMBER,
    adquisitionDate: SKELETON_KEY_DATE,
    requestDate: SKELETON_KEY_DATE,
    tamper: SKELETON_KEY
  };
}
