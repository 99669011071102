import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';
import { FILTER_TYPE } from '../AdvancedFilters';

export function ViewTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    disableOrderBy,
    numSelected,
    rowCount,
    onRequestSort,
    cells,
    allowRowSelection
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow hover={!disableOrderBy}>
        {allowRowSelection ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        ) : (
          ''
        )}
        {cells.map(headCell => (
          <TableCell
            className={headCell.filterType === FILTER_TYPE.DATE ? classes.cellDate : ''}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={
              disableOrderBy || headCell.isSortable === false ? false : orderBy === headCell.id ? order : false
            }
          >
            {headCell.label &&
              (disableOrderBy || headCell.isSortable === false ? (
                <span className={classes.headCell}>{headCell.label}</span>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <span className={classes.headCell}>{headCell.label}</span>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ))}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ViewTableHead.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  rowCount: PropTypes.number.isRequired,
  allowRowSelection: PropTypes.bool,
  disableOrderBy: PropTypes.bool
};

ViewTableHead.defaultProps = {
  allowRowSelection: true
};
