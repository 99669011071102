import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useRequestLoading } from 'shared';
import { FormFileUpload } from 'pages/shared';
import { useViewAppearanceStyles } from './viewAppearance.styles';
import { useBrandFile } from './useBrandFile';
import { BrandImage } from '../../BrandImage';

export function ViewAppearance({ configuration, onChange }) {
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const classes = useViewAppearanceStyles();
  const [selectedImage, setSelectedImage] = useState();
  const { createFiles, errorInfoFiles } = useBrandFile(configuration.id);
  const { callbackRequest } = useRequestLoading();

  const $formFileUploadRef = useRef(null);
  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);

  function handleSelectFile(targetImage) {
    setSelectedImage(targetImage);
    handleOpenAddFile();
  }

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  async function handleMediaFileUploadSubmit(selectedFiles) {
    if (selectedFiles) {
      await callbackRequest(async () => {
        await createFiles(selectedFiles[0]);
        const newEvent = {
          target: {
            name: selectedImage,
            value: selectedFiles[0].blobUrl
          }
        };
        onChange(selectedImage)(newEvent);
        handleCloseAddFile();
      });
    }
  }

  function handleDeleteFile(targetName) {
    const newEvent = {
      target: {
        name: targetName,
        value: null
      }
    };
    onChange(targetName)(newEvent);
  }

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            {t('appearanceImages')}
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={3} className={classes.contentContainer}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <BrandImage
              src={configuration.loginBackground}
              readToken={configuration.readToken}
              title={t('properties.loginBackground')}
              onDelete={() => handleDeleteFile('loginBackground')}
              onClick={() => handleSelectFile('loginBackground')}
              showTitleBottom
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <BrandImage
              src={configuration.mainLogo}
              readToken={configuration.readToken}
              title={t('properties.mainLogo')}
              onDelete={() => handleDeleteFile('mainLogo')}
              onClick={() => handleSelectFile('mainLogo')}
              showTitleBottom
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <BrandImage
              src={configuration.navigationLogo}
              readToken={configuration.readToken}
              title={t('properties.navigationLogo')}
              onDelete={() => handleDeleteFile('navigationLogo')}
              onClick={() => handleSelectFile('navigationLogo')}
              showTitleBottom
            />
          </Grid>
        </Grid>
      </Grid>
      <DialogModal
        title={t('common:upload.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpenAddFile}
        onClose={handleCloseAddFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseAddFile
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptUpload
          }
        ]}
      >
        <FormFileUpload
          formRef={$formFileUploadRef}
          onSubmit={handleMediaFileUploadSubmit}
          errors={errorInfoFiles}
          accept="image/*"
          maxFiles={1}
        />
      </DialogModal>
    </>
  );
}

ViewAppearance.propTypes = {
  configuration: PropTypes.shape({
    id: PropTypes.string,
    mainLogo: PropTypes.string,
    navigationLogo: PropTypes.string,
    loginBackground: PropTypes.string,
    readToken: PropTypes.string
  }),
  onChange: PropTypes.func
};
