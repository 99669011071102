import jwt from 'jsonwebtoken';

function checkIfTokenIsExpired(token) {
  let isExpired = false;

  if (!token) {
    return isExpired;
  }
  const decodedToken = jwt.decode(token, { complete: true });
  const dateNow = new Date();
  if (decodedToken.payload.exp * 1000 < dateNow.getTime()) {
    isExpired = true;
  }

  return isExpired;
}

function claimDeserializer(token) {
  if (!token) {
    return;
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const authHelper = {
  claimDeserializer,
  checkIfTokenIsExpired
};
