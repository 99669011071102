import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import {
  Typography,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { isSkeletonLoading } from 'shared';
import { Button } from '@engloba-tech/englobity';
import { useFormPageStyles } from './formPage.styles';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';

export function FormPage({
  children,
  disabled,
  title,
  identifier,
  breadcrumbsEntries,
  buttons,
  className,
  isChildForm = false,
  onSubmit
}) {
  const classes = useFormPageStyles();
  const [buttonsMapped, setButtonsMapped] = useState(buttons);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();
  const [unsaved, setUnsaved] = useState(false);
  const validateUnsaved = onSubmit !== undefined;

  useEffect(() => {
    if (buttons) {
      setButtonsMapped(
        buttons.map((button, index) => {
          return { ...button, id: index, popperOpened: false };
        })
      );
    }
  }, [buttons]);

  function handleUnsavedInput() {
    setUnsaved(true);
  }

  function handleClick(event, action) {
    event.preventDefault();
    action();
  }

  function togglePopper(id, isOpen) {
    setButtonsMapped(prevButtons =>
      prevButtons.map(button => {
        return button.id === id ? { ...button, popperOpened: isOpen } : { ...button, popperOpened: !isOpen };
      })
    );
  }

  function genericHandleSubmit(element) {
    if (!unsaved) return;
    setUnsaved(false);
    onSubmit(element);
  }

  function renderModifiedChildren() {
    const childrenWithProps = React.Children.map(children, child => {
      if (validateUnsaved && React.isValidElement(child))
        return React.cloneElement(child, {
          ...child.props,
          onChangeInput: handleUnsavedInput,
          onSubmit: genericHandleSubmit
        });
      else return child;
    });
    return childrenWithProps;
  }

  return (
    <div className={`${classes.root} ${className}`}>
      <Prompt when={validateUnsaved && unsaved} message={t('unsaved')} />
      {breadcrumbsEntries && breadcrumbsEntries.length > 0 && (
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbsEntries.map(entry => {
            return entry.action ? (
              <Link
                key={shortid.generate()}
                color="inherit"
                className={classes.breadcrum}
                href={entry.href}
                onClick={event => handleClick(event, entry.action)}
              >
                {isSkeletonLoading(entry.text) ? <Skeleton height={24} width={100} /> : entry.text}
              </Link>
            ) : (
              <Typography color="textPrimary" key={shortid.generate()} className={classes.breadcrum}>
                {isSkeletonLoading(entry.text) ? <Skeleton height={24} width={100} /> : entry.text}
              </Typography>
            );
          })}
        </Breadcrumbs>
      )}

      <div className={classes.header}>
        <Typography className={classes.title} variant="h6">
          {isSkeletonLoading(identifier) ? <Skeleton height={32} /> : `${title}${identifier ? ' | ' + identifier : ''}`}
        </Typography>
        <div className={classes.actions}>
          {buttons &&
            buttonsMapped.map((button, i) =>
              button.actions ? (
                <>
                  <ButtonGroup ref={anchorRef}>
                    <Button
                      size="small"
                      key={shortid.generate()}
                      type={button.type || 'secondary'}
                      className={button.popperOpened ? classes.buttonToggled : classes.button}
                      // aria-controls={open ? 'split-button-menu' : undefined}
                      // aria-expanded={open ? 'true' : undefined}
                      // aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={() => togglePopper(button.id, true)}
                    >
                      <div className={classes.wrapperButton}>
                        {button.icon}
                        <p className={classes.textButton}>{button.text}</p>
                      </div>
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    open={button.popperOpened}
                    anchorEl={anchorRef.current}
                    transition
                    disablePortal
                    className={classes.multiActionButtonPopper}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                      >
                        <ClickAwayListener onClickAway={() => togglePopper(button.id, false)}>
                          <MenuList id="split-button-menu" style={{ width: anchorRef.current.clientWidth - 16 }}>
                            {button.actions.map((action, index) => (
                              <MenuItem
                                key={action.text}
                                onClick={action.action}
                                className={button.type ? classes.primary : classes.secondary}
                              >
                                {action.text}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Grow>
                    )}
                  </Popper>
                </>
              ) : (
                button &&
                (button.component ? (
                  <button.component
                    tooltip={{ title: button.text, placement: 'top' }}
                    disabled={disabled || (validateUnsaved && !unsaved && button.enableOnDirty)}
                    onClick={button.onClick}
                    text={button.text}
                    icon={button.icon}
                    key={shortid.generate()}
                  />
                ) : (
                  <Button
                    tooltip={{ title: button.text, placement: 'top' }}
                    disabled={disabled || (validateUnsaved && !unsaved && button.enableOnDirty)}
                    className={`${classes.button} ${button.className}`}
                    id={button.id || `form_button_${i}`}
                    key={shortid.generate()}
                    type={button.type || 'secondary'}
                    onClick={button.onClick}
                  >
                    <div className={classes.wrapperButton}>
                      {button.icon}
                      <p className={classes.textButton}>{button.text}</p>
                    </div>
                  </Button>
                ))
              )
            )}
        </div>
      </div>
      <Card className={`${classes.container} ${isChildForm ? classes.containerChildForm : ''}`}>
        <CardContent className={`${classes.content} ${isChildForm ? classes.contentChildForm : ''}`}>
          <>{renderModifiedChildren()}</>
        </CardContent>
      </Card>
    </div>
  );
}

FormPage.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  identifier: PropTypes.string,
  disabled: PropTypes.bool,
  breadcrumbsEntries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      paths: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string.isRequired, action: PropTypes.string }))
    })
  ),
  buttons: PropTypes.arrayOf(PropTypes.object),
  isChildForm: PropTypes.bool,
  className: PropTypes.string,
  onSubmit: PropTypes.func
};

FormPage.defaultProps = {
  title: '',
  breadcrumbsEntries: []
};
