import { makeStyles } from '@material-ui/core/styles';

export const useViewPageBlockingsStyles = makeStyles(theme => ({
  titleContainer: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  switch: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontWeight: theme.font.weight.bold,
      color: theme.palette.primary.main,
      lineHeight: 2.2,
      fontSize: '1.1rem'
    }
  }
}));
