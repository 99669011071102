export const cookiesButton = editor => {
  editor.BlockManager.add('show-cookies-button', {
    category: 'Experience Utils',
    label: 'Cookie policy',
    attributes: { class: 'fa fa-certificate' },
    content:
      '<a class="cookies-button" data-cookies-button onclick="event.preventDefault();" style="display:inline-block; min-height:50px;min-width:50px"></a>',
    type: 'cmp-show-cookies-button'
  });

  editor.DomComponents.addType('show-cookies-button', {
    isComponent: el =>
      typeof el.hasAttribute === 'function' && el.tagName === 'A' && el.hasAttribute('data-cookies-button'),
    model: {
      defaults: {
        editable: true,
        droppable: true
      }
    }
  });
};
