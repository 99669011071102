export const pageReference = editor => {
  editor.BlockManager.add('navigation-page-reference', {
    category: 'Experience Utils',
    label: 'Navigate to screen',
    attributes: { class: 'fa fa-link' },
    content:
      '<a data-target="" onclick="event.preventDefault();" style="display:inline-block; min-height:50px;min-width:50px"></a>',
    type: 'cmp-page-reference'
  });

  editor.DomComponents.addType('page-reference', {
    isComponent: el => typeof el.hasAttribute === 'function' && el.tagName === 'A' && el.hasAttribute('data-target'),
    model: {
      defaults: {
        tagName: 'a',
        attributes: { onclick: 'event.preventDefault();' },
        editable: false,
        droppable: true,
        traits: [
          {
            type: 'select',
            name: 'data-target',
            label: 'Page',
            options: []
          },
          {
            label: 'Type',
            name: 'data-type',
            type: 'select',
            options: [
              { label: 'Default', value: 'link' },
              { label: 'Submit', value: 'submit' },
              { label: 'Next page', value: 'next' }
            ]
          }
        ]
      }
    }
  });
};
