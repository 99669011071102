import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormState, sortHelper } from 'shared';
import { ARTICLE_RATING_TYPE, experienceHelper } from '../../shared';

export function useArticleForm(article) {
  const [articleInputs, setArticleInputs] = useFormState(article);

  const handleChangeRatingItem = (fieldName, ratingItemId, target) => {
    let forcedFields = {};
    if (fieldName === 'type') {
      forcedFields.options = [];
    }

    setArticleInputs(prevInputs => ({
      ...prevInputs,
      ratingItems: (prevInputs.ratingItems || []).map(p => {
        return p.id === ratingItemId
          ? { ...p, ...forcedFields, [fieldName]: target.type === 'checkbox' ? !p[fieldName] : target.value }
          : p;
      })
    }));
  };

  const handleChange =
    (fieldName, modelTarget) =>
    ({ target }) => {
      if (!modelTarget) {
        setArticleInputs(inputs => ({ ...inputs, [fieldName]: target.value }));
      } else if (modelTarget['ratingItemId']) {
        handleChangeRatingItem(fieldName, modelTarget['ratingItemId'], target);
      }
    };

  const handleDeleteRating = useCallback(
    ratingToDelete => {
      setArticleInputs(prev => {
        return { ...prev, ratingItems: (prev.ratingItems || []).filter(rating => rating.id !== ratingToDelete.id) };
      });
    },
    [setArticleInputs]
  );

  const handleAddRating = useCallback(async () => {
    setArticleInputs(prevInputs => ({
      ...prevInputs,
      ratingItems: [
        ...(prevInputs.ratingItems || []),
        {
          id: uuidv4(),
          type: ARTICLE_RATING_TYPE.SCORE,
          order: experienceHelper.getNextOrder(prevInputs.ratingItems || [])
        }
      ]
    }));
  }, [setArticleInputs]);

  const handleAddOption = useCallback(
    async (ratingItemId, optionName) => {
      const currentRating = articleInputs.ratingItems.filter(r => r.id === ratingItemId)[0];
      if (
        currentRating &&
        currentRating.options &&
        currentRating.options.findIndex(o => o.name?.trim().toLowerCase() === optionName?.trim().toLowerCase()) < 0
      ) {
        setArticleInputs(prevInputs => ({
          ...prevInputs,
          ratingItems: (prevInputs.ratingItems || []).map(r => {
            return r.id === ratingItemId
              ? {
                  ...r,
                  options: [
                    ...(r.options || []),
                    {
                      id: uuidv4(),
                      name: optionName,
                      reference: optionName.toLowerCase(),
                      order: experienceHelper.getNextOrder(currentRating.options || [])
                    }
                  ]
                }
              : r;
          })
        }));
      }
    },
    [setArticleInputs, articleInputs]
  );

  const handleDeleteOption = useCallback(
    async (ratingItemId, optionToDeleteId) => {
      setArticleInputs(prevInputs => ({
        ...prevInputs,
        ratingItems: (prevInputs.ratingItems || []).map(r => {
          return r.id === ratingItemId
            ? {
                ...r,
                options: [...(r.options || []).filter(option => option.id !== optionToDeleteId)]
              }
            : r;
        })
      }));
    },
    [setArticleInputs]
  );

  const handleEditOption = useCallback(
    async (ratingItemId, optionToEdit) => {
      setArticleInputs(prevInputs => ({
        ...prevInputs,
        ratingItems: (prevInputs.ratingItems || []).map(r => {
          return r.id === ratingItemId
            ? {
                ...r,
                options: (r.options || [])
                  .map(o => {
                    return o.id === optionToEdit.id
                      ? {
                          ...optionToEdit
                        }
                      : o;
                  })
                  .sort((a, b) => -sortHelper.comparators(a, b, 'order'))
              }
            : r;
        })
      }));
    },
    [setArticleInputs]
  );

  return {
    articleInputs,
    setArticleInputs,
    handleChange,
    handleAddRating,
    handleDeleteRating,
    handleAddOption,
    handleDeleteOption,
    handleEditOption
  };
}
