import { makeStyles } from '@material-ui/core';
import accountBackground from '../assets/images/account_background.jpg';

export const usContainerStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    '& .MuiPaper-root': {
      '& .MuiToolbar-root': {
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        alignItems: 'start'
      }
    }
  },
  appBar: {
    background: theme.palette.secondary.light,
    filter: 'drop-shadow(0px 1px 10px rgba(74, 86, 109, 0.05))'
  },
  pageContainer: {
    flexGrow: 1,
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      padding: theme.spacing(3),
      paddingTop: 0,
      flexGrow: 1
    }
  },
  topBar: {
    margin: theme.spacing(3),
    marginBottom: 0,
    backgroundImage: `url(${accountBackground})`,
    backgroundSize: 'cover'
  }
}));
