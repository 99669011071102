import { useState, useEffect, useCallback } from 'react';
import { stickers } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';
import { useAzureStorage } from 'shared';
import { CONTAINERS } from 'pages/shared';

const stickerDummy = skeletonFactory.sticker();

const stickerEmpty = { mediaFiles: [], readToken: '' };

export function useSticker(id) {
  const [stickerData, setStickerData] = useState(id ? stickerDummy : { ...stickerEmpty });
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { getReadSasToken, readSasToken } = useAzureStorage(CONTAINERS.MEDIA);

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await stickers.getById(id);
        if (response) {
          setStickerData(response.data || {});
        }
      }, false);
    }
    id && getData();
    !id && getReadSasToken();
  }, [request, id]);

  useEffect(() => {
    setStickerData(prevSticker => ({ ...prevSticker, readToken: readSasToken }));
  }, [readSasToken]);

  const updateSticker = useCallback(
    async sticker => {
      await request(async () => {
        setStickerData(sticker);
        await stickers.update(id, sticker);
      });
    },
    [request, id]
  );

  const createSticker = useCallback(
    async sticker => {
      await request(async () => {
        setStickerData(sticker);
        await stickers.create(sticker);
      });
    },
    [request]
  );

  return {
    sticker: stickerData,
    errorInfo,
    setErrorInfo,
    setStickerData,
    createSticker,
    updateSticker
  };
}
