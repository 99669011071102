import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useViewMediaFilesStyles = makeStyles(theme => ({
  mediaFiles: {
    flexGrow: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`
  },
  galleryModal: {
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      maxHeight: '70vh'
    }
  },
  toolbar: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold !important',
    fontSize: '1.1rem',
    alignSelf: 'end',
    color: theme.palette.primary.main,
    lineHeight: 2.2
  },
  button: {
    marginLeft: '1rem'
  },
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem',
    whiteSpace: 'nowrap'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  contentWrapper: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2)
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
