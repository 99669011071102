import { makeStyles } from '@material-ui/core/styles';

export const useViewBlockPagesStyles = makeStyles(theme => ({
  blockPages: { marginBottom: theme.spacing(4) },
  container: {
    backgroundColor: theme.palette.primary.light,
    alignContent: 'flex-start',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  titleContainer: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    lineHeight: 2.2,
    fontSize: '1.1rem'
  },
  contentContainer: {
    margin: 0
  },
  switch: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontWeight: theme.font.weight.bold,
      fontSize: 'inherit',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  switchSeparator: {
    background: theme.palette.primary.main,
    border: 0,
    height: 1
  }
}));
