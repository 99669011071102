import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Summary } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Typography
} from '@material-ui/core';
import { ViewTableToolbar } from './Toolbar';
import { ViewTableHead } from './Head';
import { useSelected } from '../useSelected';
import { getComparator, stableSort } from './viewTable.helper';
import { useViewTableStyles } from './viewTable.styles';
import { Skeleton } from '@material-ui/lab';
import { isSkeletonLoading } from '../skeletonFactory';
import { usePagination } from './usePagination';
import { ViewTableFooter } from './Footer';
import { AdvancedFilters } from './AdvancedFilters';
import { ViewTableSummary } from './FooterSummary';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

export function _viewTable({
  errors,
  rows,
  viewTableRef,
  cells,
  onFetchData,
  serverSidePaging = false,
  totalRows,
  elevation = false,
  spaced = false,
  allowRowSelection,
  allowPaging = true,
  defaultOrderBy,
  emptyText,
  disableOrderBy,
  toolbar,
  selectRowWhen,
  actions,
  allowRowFilter,
  summaryCells,
  defaultPageSize,
  rowHeight = 53,
  classes: tableClasses
}) {
  const { selected, setSelected, setSelectToggleAll, getFullObjectSelected, cleanAllSelected } = useSelected({
    data: rows,
    selectWhenCondition: selectRowWhen
  });

  if (viewTableRef) {
    viewTableRef.current = { selected: getFullObjectSelected() };
  }

  const isPaginatedTable = serverSidePaging && !!onFetchData;

  const mapCellWithActions = useMemo(() => {
    return actions
      ? [...cells, ...actions.map(ac => ({ id: ac.name, numeric: false, disablePadding: false, label: '' }))]
      : cells;

    // WARNING: dont add actions as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells]);

  const { order, orderBy, page, rowsPerPage, changeSort, changePage, changeRows, changeFilter } = usePagination(
    cells,
    onFetchData,
    isPaginatedTable,
    defaultOrderBy || cells[0].id,
    defaultPageSize
  );

  const classes = { ...useViewTableStyles(), ...tableClasses };
  const { t } = useTranslation();

  function getVisibleRows(rows) {
    if (allowPaging && serverSidePaging) return rows;

    let sortedRows = stableSort(rows, getComparator(order, orderBy));
    if (allowPaging) {
      sortedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return sortedRows;
  }

  function getEmptyRows() {
    const rowsWithValueCount = serverSidePaging ? rows.length : rows.length - page * rowsPerPage;
    return rowsPerPage - Math.min(rowsPerPage, rowsWithValueCount);
  }

  const isSelected = name => selected.indexOf(name) !== -1;
  const isLoading = rows && !rows.length;
  const emptyRows = getEmptyRows();
  const totalRowCount = serverSidePaging ? totalRows : rows.length;
  const isAnySelected = selected.length > 0;

  const ableSelectRow = element => {
    if (selectRowWhen) {
      return selectRowWhen(element);
    }

    return allowRowSelection;
  };

  const mapToolbarActions = useMemo(() => {
    return toolbar && toolbar.actions
      ? toolbar.actions.map(action => {
          if (action.resetOnCB) {
            action.mappedCb = async selected => {
              await action.cb(selected);
              cleanAllSelected();
            };
          } else {
            action.mappedCb = action.cb;
          }
          return action;
        })
      : [];
  }, [toolbar, cleanAllSelected]);

  useEffect(() => {
    if (page > 0 && !rows.length) changePage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length]);

  return (
    <div className={`${classes.root} viewtable`}>
      {errors && errors.message && (
        <Summary
          className={classes.summary}
          severity={errors.severity || 'error'}
          text={errors.message}
          detail={errors.detail}
        />
      )}

      {toolbar && (
        <ViewTableToolbar
          advancedFilters={toolbar.advancedFilters}
          actions={mapToolbarActions}
          entityName={toolbar.title}
          numSelected={selected.length}
          selected={getFullObjectSelected()}
          toolbarClasses={toolbar.classes}
        />
      )}

      <Paper elevation={elevation ? 1 : 0} className={`${classes.paper} ${spaced ? classes.spaced : ''}`}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby={toolbar?.title || ''}
            size="medium"
            aria-label="enhanced table"
          >
            <ViewTableHead
              classes={classes}
              cells={mapCellWithActions}
              numSelected={selected.length}
              order={order}
              disableOrderBy={disableOrderBy}
              orderBy={orderBy}
              onSelectAllClick={setSelectToggleAll}
              onRequestSort={changeSort}
              rowCount={totalRowCount}
              allowRowSelection={allowRowSelection}
            />
            {allowRowFilter && (
              <TableRow className={classes.filterRow} hover={false}>
                {allowRowSelection && (
                  <TableCell className={classes.filterCell}>
                    <FilterListIcon />
                  </TableCell>
                )}

                <AdvancedFilters cells={mapCellWithActions} onFilterCellChange={changeFilter} />
              </TableRow>
            )}
            {!rows.length && emptyText ? (
              <TableRow hover={false}>
                <TableCell style={{ borderBottom: 'none' }} colSpan="100%">
                  <Summary className={classes.summary} severity="info" text={emptyText} />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {getVisibleRows(rows).map((row, index) => {
                  const isItemSelected = isSelected(row['id']);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={shortid.generate()}
                      selected={isItemSelected}
                      style={{ height: rowHeight }}
                    >
                      {allowRowSelection && (
                        <TableCell padding="checkbox">
                          {ableSelectRow(row) ? (
                            <Checkbox
                              onClick={() => setSelected(row['id'])}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          ) : (
                            ''
                          )}
                        </TableCell>
                      )}
                      {cells.map(cell => {
                        return (
                          <TableCell
                            key={shortid.generate()}
                            align={cell.numeric ? 'right' : 'left'}
                            className={cell.ellipsis && classes.ellipsis}
                          >
                            {isSkeletonLoading(row[cell.id]) ? (
                              <Skeleton />
                            ) : cell.ellipsis && row[cell.id]?.length > 20 ? (
                              <Tooltip classes={{ tooltip: classes.tooltip }} title={row[cell.id]} placement="top">
                                <span>{row[cell.id]}</span>
                              </Tooltip>
                            ) : typeof row[cell.id] === 'boolean' && !cell.warning ? (
                              row[cell.id] ? (
                                <CheckIcon style={{ color: '4bf49', fontSize: 30 }} />
                              ) : (
                                <CloseIcon style={{ color: 'ff4c4c', fontSize: 30 }} />
                              )
                            ) : cell.warning && row[cell.id] ? (
                              <Tooltip classes={{ tooltip: classes.tooltip }} title={cell.warning} placement="top">
                                <WarningRoundedIcon style={{ color: 'F9A825', fontSize: 30 }} />
                              </Tooltip>
                            ) : (
                              row[cell.id]
                            )}
                          </TableCell>
                        );
                      })}

                      {actions &&
                        actions.map(action => {
                          let actionToPrint;

                          if (action.condition && action.condition(row)) {
                            actionToPrint = action.whenConditionTrue;
                          } else {
                            actionToPrint = action;
                          }

                          if (actionToPrint) {
                            const params =
                              (actionToPrint.properties && actionToPrint.properties.map(property => row[property])) ||
                              [];

                            return (
                              <TableCell key={shortid.generate()} padding="checkbox">
                                <Tooltip className={classes.tooltip} title={actionToPrint.title}>
                                  {actionToPrint.children ? (
                                    actionToPrint.children({
                                      checked: row[actionToPrint.selectedProperty],
                                      onChange: e => {
                                        params.push(e.target.checked);
                                        !isSkeletonLoading(params[0]) && actionToPrint.cb(params);
                                      }
                                    })
                                  ) : (
                                    <IconButton
                                      disabled={!params[0]}
                                      onClick={() => !isSkeletonLoading(params[0]) && actionToPrint.cb(params)}
                                      aria-label={actionToPrint.label}
                                    >
                                      {actionToPrint.icon}
                                    </IconButton>
                                  )}
                                </Tooltip>
                              </TableCell>
                            );
                          } else return <TableCell key={shortid.generate()} padding="checkbox"></TableCell>;
                        })}
                    </TableRow>
                  );
                })}
                {summaryCells && cells && (
                  <ViewTableSummary
                    classes={classes}
                    allowRowSelection={allowRowSelection}
                    headCells={cells}
                    summaryCells={summaryCells}
                    actions={actions}
                  />
                )}
                {!isLoading && emptyRows > 0 && (
                  <TableRow className={classes.empty} style={{ height: rowHeight * emptyRows }}>
                    <TableCell colSpan="100%" />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div className={classes.footerWrapper}>
          {isAnySelected && (
            <Toolbar
              disableGutters
              className={clsx(classes.root, classes.selected, {
                [classes.highlight]: isAnySelected
              })}
            >
              <Typography
                className={`${classes.titleSelected} ${classes.title}`}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} {t('common:actions.selected')}
              </Typography>
            </Toolbar>
          )}
          {allowPaging && rows.length > 0 ? (
            <TablePagination
              style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}
              rowsPerPageOptions={[5, 10, 25]}
              count={totalRowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRows}
              ActionsComponent={ViewTableFooter}
              labelRowsPerPage={t('viewTable.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} ${t('viewTable.of')} ${count}`
              }
            />
          ) : (
            ''
          )}
        </div>
      </Paper>
    </div>
  );
}

export const ViewTable = React.memo(_viewTable);

_viewTable.propTypes = {
  mainKey: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string,
    severity: PropTypes.string
  }),
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  serverSidePaging: PropTypes.bool,
  onFetchData: PropTypes.func,
  totalRows: PropTypes.number,
  showId: PropTypes.bool,
  allowRowSelection: PropTypes.bool,
  elevation: PropTypes.bool,
  allowPaging: PropTypes.bool,
  defaultOrderBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ property: PropTypes.string, direction: PropTypes.string })
  ]),
  toolbar: PropTypes.object,
  spaced: PropTypes.bool,
  selectRowWhen: PropTypes.func,
  emptyText: PropTypes.string,
  disableOrderBy: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      properties: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.string,
      cb: PropTypes.func,
      icon: PropTypes.node
    }).isRequired
  ),
  allowRowFilter: PropTypes.bool,
  summaryCells: PropTypes.arrayOf(PropTypes.object),
  booleanIcons: PropTypes.arrayOf(PropTypes.element),
  viewTableRef: PropTypes.shape({ current: PropTypes.any }),
  defaultPageSize: PropTypes.number,
  rowHeight: PropTypes.number,
  classes: PropTypes.object
};

_viewTable.defaultProps = {
  serverSidePaging: false,
  totalRows: -1,
  allowRowSelection: true,
  allowPaging: true,
  elevation: false,
  disableOrderBy: false,
  spaced: false,
  allowRowFilter: true
};
