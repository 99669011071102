import { paperBlocks } from './paperBlocks';
import { paperCommands } from './paperCommands';
import { paperComponents } from './paperComponents';
import { paperPanels } from './paperPanels';

export const paperPlugin = (editor) => {
	paperBlocks(editor);
	paperCommands(editor);
	paperComponents(editor);
	paperPanels(editor);
};
