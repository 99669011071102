import { http } from './http';
import { apiHelper } from 'shared';

class MultilanguageExperiences {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/multilanguage-products${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/multilanguage-products/${queryString}`);
  }

  getById(id) {
    return http.get(`/multilanguage-products/${id}`);
  }

  create(experience) {
    return http.post('/multilanguage-products', experience);
  }

  delete(id) {
    return http.delete(`/multilanguage-products/${id}`);
  }

  update(id, experience) {
    return http.put(`/multilanguage-products/${id}`, experience);
  }
}

export const multilanguageExperiences = new MultilanguageExperiences();
