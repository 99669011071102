export const deviceCommands = (editor) => {
	editor.Commands.add('set-device-desktop', {
		run: (editor) => editor.setDevice('Desktop'),
		stop() {},
	});

	editor.Commands.add('set-device-mobile', {
		run: (editor) => editor.setDevice('Mobile'),
		stop() {},
	});
};
