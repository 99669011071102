import React from 'react';
import { ImageListItem, ImageListItemBar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared/skeletonFactory';
import { CustomImage } from 'shared';
import { AddImageTile } from 'pages/shared';
import { useImageTileStyles } from 'pages/shared/ImageGallery/imageTile.styles';

export const TemplateImageTile = ({
  image,
  templateImage,
  onClick,
  readToken,
  isValid,
  tileMinHeight,
  tileMinWidth,
  cols = 1,
  rows = 1,
  style,
  extraActions
}) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useImageTileStyles();

  return (
    <>
      {image && (
        <ImageListItem
          className={`${classes.tile} ${!isValid && classes.error}`}
          onClick={() => onClick(image)}
          cols={cols}
          rows={rows}
          style={{ ...style, minHeight: tileMinHeight, minWidth: tileMinWidth }}
        >
          {isSkeletonLoading(image.blobUrl) ? (
            <Skeleton height={tileMinHeight || style?.height} />
          ) : (
            <>
              {image.blobUrl ? (
                <div className={classes.imageContainer}>
                  <CustomImage src={`${image.blobUrl}?${readToken}`} alt="Image" className={classes.image} />
                </div>
              ) : (
                <AddImageTile
                  title={t('common:actions.editFile')}
                  cols={1}
                  rows={1}
                  component="div"
                  className={classes.fullTile}
                />
              )}
              <ImageListItemBar
                position="top"
                title={image.tag}
                className={`${classes.imageTitle} ${classes.imageTitleTop}`}
                actionIcon={<div className={classes.actionIconContainer}>{extraActions && extraActions(image)}</div>}
              />
              <ImageListItemBar
                title={templateImage?.referenceCode || ''}
                subtitle={`${t('mediaFiles.recommendedDimensions')} ${templateImage?.recommendedWidth || ''}x${
                  templateImage?.recommendedHeight || ''
                }`}
                className={classes.imageTitle}
              />
            </>
          )}
        </ImageListItem>
      )}
    </>
  );
};

TemplateImageTile.propTypes = {
  image: PropTypes.shape({
    blobUrl: PropTypes.string,
    tag: PropTypes.string,
    targetLink: PropTypes.string
  }),
  templateImage: PropTypes.shape({
    referenceCode: PropTypes.string,
    recommendedWidth: PropTypes.number,
    recommendedHeight: PropTypes.number
  }),
  onClick: PropTypes.func,
  readToken: PropTypes.string,
  isValid: PropTypes.bool,
  tileMinHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tileMinWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cols: PropTypes.number,
  rows: PropTypes.number,
  style: PropTypes.object,
  extraActions: PropTypes.func
};
