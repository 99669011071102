import { http } from './http';
// const url = `${api_url}/api/v1.0/authentication/token`;
class Auth {
  login(user) {
    // dont works in dev
    // return http.post('/authentication/token', user, { withCredentials: true }, null, false);
    return http.post('/authentication/token', user, {}, null, false);
  }

  changePassword(request) {
    return http.post('/authentication/change-password', request, {});
  }
}

export const auth = new Auth();
