import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { DialogModal, TextArea, useHandleOpen } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useViewDefaultSectionStyles } from './viewDefaultSection.styles';
import { ColorInput } from 'pages/shared';
import { useRequestLoading } from 'shared';
import { FormFileUpload, FontSelect } from 'pages/shared';
import { settings } from 'settings';
import { KEY_SETTINGS } from 'settings';
import { BrandImage } from '../../BrandImage';
import { useBrandFile } from '../../ViewSetup/ViewAppearance/useBrandFile';

export function ViewDefaultSection({ configuration, onChange }) {
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const { createFiles, errorInfoFiles } = useBrandFile(configuration.id);
  const { callbackRequest } = useRequestLoading();
  const classes = useViewDefaultSectionStyles();

  const maxFileSize = settings.getSetting(KEY_SETTINGS.MAX_IMAGE_SIZE);

  const $formFileUploadRef = useRef(null);
  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  async function handleMediaFileUploadSubmit(selectedFiles) {
    if (selectedFiles) {
      await callbackRequest(async () => {
        await createFiles(selectedFiles[0]);
        const newEvent = {
          target: {
            name: 'experienceDefaultImage',
            value: selectedFiles[0].blobUrl
          }
        };
        onChange('experienceDefaultImage')(newEvent);
        handleCloseAddFile();
      });
    }
  }

  function handleDeleteFile(targetName) {
    const newEvent = {
      target: {
        name: targetName,
        value: null
      }
    };
    onChange(targetName)(newEvent);
  }
  return (
    <>
      <div>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography variant="h6" className={classes.title}>
              {t('defaultSection')}
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={3} className={classes.contentContainer}>
            <Grid item container spacing={3}>
              <Grid item xs={12} lg={6}>
                <ColorInput
                  label={t('properties.experienceDefaultBackgroundColor')}
                  name="experienceDefaultBackgroundColor"
                  value={configuration.experienceDefaultBackgroundColor}
                  onChange={onChange('experienceDefaultBackgroundColor')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ColorInput
                  label={t('properties.experienceDefaultFontColor')}
                  name="experienceDefaultFontColor"
                  value={configuration.experienceDefaultFontColor}
                  onChange={onChange('experienceDefaultFontColor')}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={3} style={{ paddingBottom: 3 }}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FontSelect
                      className={classes.select}
                      value={configuration.experienceDefaultFontFamily}
                      title={t('properties.experienceDefaultFontFamily')}
                      name={t('properties.experienceDefaultFontFamily')}
                      onChange={onChange('experienceDefaultFontFamily')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea
                      isLoading={isSkeletonLoading(configuration.experienceDefaultMessage)}
                      label={t('properties.experienceDefaultMessage')}
                      value={configuration.experienceDefaultMessage || ''}
                      rows={5}
                      required
                      onChange={onChange('experienceDefaultMessage')}
                      validators={['required']}
                      errorMessages={[t('validations.required')]}
                      skeletonHeight={'80'}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BrandImage
                      src={configuration.experienceDefaultImage}
                      height={198}
                      title={t('properties.experienceDefaultImage')}
                      onDelete={() => handleDeleteFile('experienceDefaultImage')}
                      onClick={handleOpenAddFile}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <DialogModal
        title={t('common:upload.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpenAddFile}
        onClose={handleCloseAddFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseAddFile
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptUpload
          }
        ]}
      >
        <FormFileUpload
          formRef={$formFileUploadRef}
          onSubmit={handleMediaFileUploadSubmit}
          errors={errorInfoFiles}
          accept="image/*"
          maxFiles={1}
          maxFileSize={maxFileSize}
        />
      </DialogModal>
    </>
  );
}

ViewDefaultSection.propTypes = {
  configuration: PropTypes.shape({
    id: PropTypes.string,
    experienceDefaultBackgroundColor: PropTypes.string,
    experienceDefaultFontColor: PropTypes.string,
    experienceDefaultFontFamily: PropTypes.string,
    experienceDefaultImage: PropTypes.string,
    experienceDefaultMessage: PropTypes.string
  }),
  onChange: PropTypes.func
};
