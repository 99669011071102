import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Form, AsyncInputAutocomplete } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useFormSelectHtmlTemplateStyles } from './formSelectHtmlTemplate.styles';
import { htmlTemplates } from 'services';
import { useHandleRequest } from 'shared';

export function FormSelectHtmlTemplate({ formRef, onSubmit }) {
  const { request, errorInfo } = useHandleRequest();
  const [inputs, setInputs] = useState();
  const { t } = useTranslation(NAME_SPACE.HTML_TEMPLATES);
  const classes = useFormSelectHtmlTemplateStyles();

  async function handleSubmit() {
    if (inputs?.id) {
      await request(async () => {
        const response = await htmlTemplates.getById(inputs.id);
        if (response && response.data) {
          onSubmit({ ...response.data });
        }
      }, false);
    }
  }

  function handleHtmlTemplateAutocomplete(element) {
    setInputs({ ...element });
  }

  return (
    <Form
      className={classes.formField}
      errors={errorInfo}
      elementRef={formRef}
      onSubmit={handleSubmit}
      onKeyPress={e => {
        if (e.which === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AsyncInputAutocomplete
            onChange={(e, element) => handleHtmlTemplateAutocomplete(element)}
            label={t('templateConfirm.properties.template')}
            variant="outlined"
            icon={<SearchIcon />}
            value={inputs && { name: inputs.name, id: inputs.id }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            requestAction={htmlTemplates.getUsingQueryFilter}
            required
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

FormSelectHtmlTemplate.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired
};
