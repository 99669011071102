import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NAME_SPACE } from 'i18n';
import { TablePagination, Toolbar, Typography } from '@material-ui/core';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { FormFileUpload, ImageGallery } from 'pages/shared';
import { useRequestLoading, execConfirmModal } from 'shared';
import { settings, KEY_SETTINGS } from 'settings';
import { usePagination } from './usePagination';
import { FormMediaFile } from './FormMediaFile';
import { useMediaFilesStyles } from './mediaFiles.styles';
import { MediaFileFilters } from './MediaFileFilters';

export function MediaFiles({
  mediaFiles,
  getMediaFiles,
  deleteMediaFiles,
  createMediaFiles,
  updateMediaFile,
  errorInfo,
  paginationProps,
  extraActions,
  cols = 5,
  rowHeight = 100,
  gap = 3,
  tileMinWidth,
  footerText,
  showAddImageTile
}) {
  const { t } = useTranslation(NAME_SPACE.MEDIA_FILES);
  const { callbackRequest } = useRequestLoading();
  const classes = useMediaFilesStyles();

  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);
  const {
    isOpen: isOpenEditFile,
    handleClose: handleCloseEditFile,
    handleOpen: handleOpenEditFile
  } = useHandleOpen(false);

  const [mediaFileEdit, setMediaFileEdit] = useState();

  const maxFileSize = settings.getSetting(KEY_SETTINGS.MAX_IMAGE_SIZE);

  const $formFileUploadRef = useRef(null);
  const $formFileEditRef = useRef(null);

  const { page, rowsPerPage, changePage, changeRows, changeSort, changeFilter } = usePagination(
    getMediaFiles,
    paginationProps?.defaultPageSize,
    { property: 'uploadDate', direction: 'desc' }
  );

  function handleMediaFileUploadOpen() {
    handleOpenAddFile();
  }

  async function handleMediaFileUploadSubmit(selectedFiles, event) {
    if (selectedFiles) {
      await callbackRequest(async () => {
        await createMediaFiles(selectedFiles);
        handleCloseAddFile();
      });
    }
  }

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  function handleEditFile(file) {
    setMediaFileEdit(file);
    handleOpenEditFile();
  }

  function handleModalAcceptEdit() {
    $formFileEditRef.current.submit();
  }

  async function handleMediaFileEditSubmit(inputs, event) {
    await callbackRequest(async () => {
      var success = await updateMediaFile(inputs);
      if (success) {
        handleCloseEditFile();
      }
    });
  }

  async function handleDelete(mediaFileToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description: t('removeConfirm.singularMessage', {
          tag: mediaFileToDelete.tag
        }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });
      await deleteMediaFiles([mediaFileToDelete]);
    } catch (err) {}
  }

  const onChangeFilter =
    fieldName =>
    ({ target }) =>
      changeFilter(fieldName, target.value);

  const onChangeSort =
    fieldName =>
    ({ target }) =>
      changeSort(null, target.value);

  return (
    <>
      <div className={classes.headerWrapper}>
        <Toolbar disableGutters>
          <MediaFileFilters onChangeSort={onChangeSort('sortBy')} onChangeFilter={onChangeFilter} />
        </Toolbar>
      </div>
      <ImageGallery
        images={mediaFiles?.items || []}
        cols={cols}
        rowHeight={rowHeight}
        gap={gap}
        tileMinWidth={tileMinWidth}
        extraActions={extraActions}
        onAddImageClick={handleMediaFileUploadOpen}
        onImageClick={handleEditFile}
        onDeleteImageClick={handleDelete}
        showAddImageTile={showAddImageTile}
      />

      <div className={classes.footerWrapper}>
        <Toolbar disableGutters>
          <Typography color="inherit" variant="body2" component="div">
            {footerText}
          </Typography>
        </Toolbar>
        <TablePagination
          component="div"
          nextIconButtonProps={{ color: 'default' }}
          backIconButtonProps={{ color: 'default' }}
          style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}
          count={mediaFiles?.total}
          page={page}
          onPageChange={changePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationProps?.rowsPerPageOptions}
          onRowsPerPageChange={changeRows}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true
          }}
          labelRowsPerPage={t('viewTable.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} ${t('viewTable.of')} ${count}`
          }
        />
      </div>
      <DialogModal
        title={t('common:upload.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpenAddFile}
        onClose={handleCloseAddFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseAddFile,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptUpload,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormFileUpload
          formRef={$formFileUploadRef}
          onSubmit={handleMediaFileUploadSubmit}
          accept="image/*"
          maxFileSize={maxFileSize}
        />
      </DialogModal>

      <DialogModal
        title={t('common:upload.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpenEditFile}
        onClose={handleCloseEditFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseEditFile,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.edit'),
            type: 'primary',
            onClick: handleModalAcceptEdit,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormMediaFile
          formRef={$formFileEditRef}
          mediaFile={mediaFileEdit}
          onSubmit={handleMediaFileEditSubmit}
          errors={errorInfo}
        />
      </DialogModal>
    </>
  );
}

MediaFiles.propTypes = {
  mediaFiles: PropTypes.object,
  getMediaFiles: PropTypes.func.isRequired,
  deleteMediaFiles: PropTypes.func,
  createMediaFiles: PropTypes.func,
  updateMediaFile: PropTypes.func,
  errorInfo: PropTypes.object,
  paginationProps: PropTypes.shape({ defaultPageSize: PropTypes.number, rowsPerPageOptions: PropTypes.array }),
  extraActions: PropTypes.func,
  cols: PropTypes.number,
  rowHeight: PropTypes.number,
  gap: PropTypes.number,
  tileMinWidth: PropTypes.number,
  footerText: PropTypes.string,
  showAddImageTile: PropTypes.bool
};
