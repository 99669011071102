import { ButtonAdd } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { useButtonStyles } from '../button.styles';

export function PackagingButtonAdd({ disabled, tooltip, text, onClick, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useButtonStyles();
  const textButton = text || t('actions.add');

  const renderButton = () => {
    return (
      <ButtonAdd
        disabled={disabled}
        className={classes.buttonAdd}
        text={textButton}
        tooltip={
          tooltip
            ? { ...tooltip, title: tooltip.title || textButton }
            : {
                title: textButton,
                placement: 'top'
              }
        }
        onClick={onClick}
      />
    );
  };

  return renderButton();
}

PackagingButtonAdd.propTypes = {
  disabled: PropTypes.bool,
  tooltip: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  text: PropTypes.string
};
