import { useViewTableData, skeletonFactory } from 'shared';
import { htmlTemplates } from 'services';
import { useHandleRequest } from 'shared';
import { useCallback } from 'react';

const htmlTemplatesDummy = skeletonFactory.htmlTemplates();

export function useHtmlTemplatesPaginated() {
  const {
    data: htmlTemplatesData,
    get: getHtmlTemplates,
    deleteElements: deleteHtmlTemplates,
    errorInfo: getDeleteErrorKey,
    setData,
    undoData,
    paging,
    sorting
  } = useViewTableData({
    service: htmlTemplates,
    paginatedSkeleton: htmlTemplatesDummy
  });

  const { request, errorInfo } = useHandleRequest();

  const copyHtmlTemplate = useCallback(
    async htmlTemplateId => {
      try {
        await request(async () => {
          setData({ ...htmlTemplatesDummy });
          await htmlTemplates.clone(htmlTemplateId);
          await getHtmlTemplates(paging, sorting);
        });
      } catch (error) {
        undoData();
      }
    },
    [getHtmlTemplates, request, setData, paging, sorting, undoData]
  );

  return {
    htmlTemplates: htmlTemplatesData,
    errorInfo: errorInfo || getDeleteErrorKey,
    getHtmlTemplates,
    deleteHtmlTemplates,
    copyHtmlTemplate
  };
}
