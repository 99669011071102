import { makeStyles } from '@material-ui/core/styles';
import { buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useNavigationListStyles = makeStyles(theme => ({
  navigation: {
    width: 370,
    zIndex: 0
  },
  root: {
    width: '100%',
    padding: 0
  },
  content: {
    flex: 1,
    padding: 0
  },
  noIcon: {
    paddingLeft: '3.3rem'
  },
  elementContentClose: {
    width: '4rem !important',

    '&:before': {
      left: '18% !important'
    }
  },
  wrapperIconText: {
    display: 'flex',
    marginLeft: '3rem',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main
    }
  },
  tooltip: {
    zIndex: '9999'
  },
  elementContent: {
    width: '70%',
    marginLeft: '-13px',
    '&:before': {
      transition: theme.time.fast,
      left: '2.5rem',
      right: 0,
      height: '100%',
      width: '2px',
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& .MuiTypography-body1': {
        fontWeight: theme.font.weight.semibold
      },
      '&:before': {
        backgroundColor: theme.palette.primary.main,
        width: '4px'
      }
    }
  },
  elementText: {
    paddingLeft: '1rem',
    '& span': {
      fontSize: '0.85rem !important'
    }
  },
  wrapperIconTextClose: {
    marginLeft: '0.5rem'
  },
  summary: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '0px 12px 12px 0px',
    minHeight: 'auto !important',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main
    },
    '& .Mui-expanded': {
      margin: 'inherit !important',
      '& p': {
        fontWeight: 'bold'
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '&:focus': {
      // color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.dark,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiIconButton-edgeStart': {
      transform: 'rotate(-90deg) !important',
      right: '-10px',
      margin: '0'
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg) !important'
    }
  },
  highlighted: {
    color: 'white !important',
    backgroundColor: `${theme.palette.primary.dark} !important`,
    '& .MuiTypography-body1': {
      fontWeight: theme.font.weight.semibold
    },
    '&:before': {
      backgroundColor: theme.palette.primary.main,
      width: '4px'
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main
    }
  },
  navContentOpen: {
    overflowX: 'hidden',
    width: '190px',
    overflow: 'hidden',
    flexGrow: '0 !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  navContentClose: {
    width: '24px',
    overflow: 'hidden',
    flexGrow: '0 !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  expandIcon: {
    color: theme.palette.basic.darker
  },
  heading: {
    fontSize: '0.90rem',
    alignSelf: 'center',
    marginLeft: '0.5rem'
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
