import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useParams, useHistory } from 'react-router-dom';
import { FormPage, useRequestLoading, ButtonSave, ButtonBack } from 'shared';
import { PATH } from 'app.routes.const';
import { useHtmlTemplate } from './useHtmlTemplate';
import { FormHtmlTemplate } from './FormHtmlTemplate';

export function EditHtmlTemplate() {
  const { id } = useParams();
  const { htmlTemplate, createHtmlTemplate, updateHtmlTemplate, errorInfo, setErrorInfo } = useHtmlTemplate(id);
  const { t } = useTranslation(NAME_SPACE.HTML_TEMPLATES);
  const $formRef = useRef(null);
  const history = useHistory();
  const { requestLoading, callbackRequest } = useRequestLoading();

  async function handleSubmit(htmlTemplate) {
    await callbackRequest(async () => {
      setErrorInfo({ message: '', detail: '' });
      id ? await updateHtmlTemplate(htmlTemplate) : await createHtmlTemplate(htmlTemplate);
      history.push(PATH.HTML_TEMPLATES.LIST);
    });
  }

  function handleSaveClick() {
    $formRef.current.submit();
  }

  function handleGoBack() {
    history.push(PATH.HTML_TEMPLATES.LIST);
  }

  function composeActionButtons() {
    const actionButtons = [
      { onClick: handleGoBack, component: ButtonBack },
      {
        onClick: handleSaveClick,
        enableOnDirty: true,
        component: ButtonSave
      }
    ];

    return actionButtons;
  }

  return (
    <FormPage
      title={t('singularName')}
      disabled={requestLoading}
      buttons={composeActionButtons()}
      onSubmit={handleSubmit}
    >
      <FormHtmlTemplate errors={errorInfo} formRef={$formRef} htmlTemplate={{ ...htmlTemplate }} />
    </FormPage>
  );
}
