import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { Grid } from '@material-ui/core';

export const ViewPageExperienceInfo = ({ classes, showTitle, onSwitchChange }) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  return (
    <Grid item container xs={6} style={{ order: 4 }}>
      <Grid item xs={12}>
        <Switch
          className={classes.switch}
          label={t('properties.showTitle')}
          checked={showTitle || false}
          onChange={onSwitchChange('showTitle')}
        />
      </Grid>
    </Grid>
  );
};

ViewPageExperienceInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  onSwitchChange: PropTypes.func
};
