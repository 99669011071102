import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { DialogModal } from '@engloba-tech/englobity';
import globalStyles from 'styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

export function execConfirmFormModal({
  title,
  description,
  confirmText,
  declineText,
  formComponent,
  declineClassName,
  confirmClassName
}) {
  return new Promise((resolve, reject) => {
    const idGenerated = shortid.generate();
    const entryPointToasterDom = document.createElement('div');
    entryPointToasterDom.setAttribute('id', idGenerated);

    const global = document.getElementById('global');
    global.appendChild(entryPointToasterDom);

    function CustomModal({ resolve, reject, ...rest }) {
      const $formSelectTemplateRef = useRef(null);

      async function handleModalAcceptUpload() {
        $formSelectTemplateRef.current.submit();
        const valid = await $formSelectTemplateRef.current.isFormValid();
        return valid;
      }

      async function handleSubmit(inputs) {
        resolve(inputs);
      }

      return (
        <DialogModal
          fullWidth
          title={title}
          description={description}
          isOpen
          buttons={[
            {
              children: <CloseIcon />,
              type: 'secondary',
              text: declineText,
              className: declineClassName,
              onClick: () => {
                ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                global.removeChild(entryPointToasterDom);
                reject('User clicked No');
              }
            },
            {
              children: <DoneIcon />,
              type: 'primary',
              text: confirmText,
              className: confirmClassName,
              onClick: async () => {
                if (await handleModalAcceptUpload()) {
                  ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                  global.removeChild(entryPointToasterDom);
                }
              }
            }
          ]}
          onClose={() => {
            ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
            global.removeChild(entryPointToasterDom);
          }}
        >
          {React.cloneElement(formComponent, { formRef: $formSelectTemplateRef, onSubmit: handleSubmit })}
        </DialogModal>
      );
    }

    ReactDOM.render(
      <ThemeProvider theme={globalStyles}>
        <CssBaseline />
        <CustomModal resolve={resolve} reject={reject} />
      </ThemeProvider>,
      document.getElementById(idGenerated)
    );
  });
}
