import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AsyncInputAutocomplete, Form, Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { experiences } from 'services';
import { objectHelper, Dropzone } from 'shared';
import { useFormCsvFileUploadStyles } from './formCsvFileUpload.styles';
import { multilanguageExperiences } from 'services';

export function FormCsvFileUpload({ formRef, onSubmit, accept, maxFiles, errors }) {
  const { t } = useTranslation(NAME_SPACE.TAGS);
  const classes = useFormCsvFileUploadStyles();
  const [inputs, setInputs] = useState({});
  const [multilanguageExperience, setMultilanguageExperience] = useState(false);

  function handleSubmit() {
    if (!objectHelper.isEmpty(inputs.selectedFile) && inputs.productId) {
      onSubmit(inputs);
      setInputs({});
    }
  }

  async function handleSelectFile(acceptedFile) {
    const file = acceptedFile[0];
    if (file) {
      file.tag = file.name;
      setInputs(prevInputs => ({
        ...prevInputs,
        selectedFile: file
      }));
    }
  }

  function handleDeleteFile() {
    setInputs(prevInputs => ({
      ...prevInputs,
      selectedFile: null
    }));
  }

  function handleExperienceAutocomplete(element) {
    setInputs(prevFilters => ({
      ...prevFilters,
      productId: element?.id,
      product: element?.name
    }));
  }

  function handleMultiLanguageSwitchChange() {
    setMultilanguageExperience(prev => !prev);
    setInputs(prevFilters => ({
      ...prevFilters,
      productId: '',
      product: ''
    }));
  }

  return (
    <Form
      className={`${classes.form} ${classes.container}`}
      elementRef={formRef}
      onSubmit={handleSubmit}
      onKeyPress={e => {
        if (e.which === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      autoComplete="off"
      errors={errors}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Tooltip title={t('checkMultilanguageTooltip')} placement={'bottom'}>
            <div className={classes.tooltipWrapper}>
              <Switch
                label={multilanguageExperience ? t('checkMultilanguage') : t('checkSingle')}
                checked={multilanguageExperience || false}
                onChange={handleMultiLanguageSwitchChange}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <AsyncInputAutocomplete
            onChange={(e, element) => handleExperienceAutocomplete(element)}
            label={t('properties.product')}
            required
            variant="outlined"
            icon={<SearchIcon />}
            value={inputs.productId ? { name: inputs.product, id: inputs.productId } : null}
            defaultInputValue={inputs.product || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            requestAction={
              multilanguageExperience ? multilanguageExperiences.getUsingQueryFilter : experiences.getUsingQueryFilter
            }
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid container alignItems="center" item xs={12}>
          <Dropzone
            accept={accept}
            maxFiles={maxFiles}
            files={inputs.selectedFile ? [inputs.selectedFile] : []}
            onDrop={handleSelectFile}
            onDeleteFile={handleDeleteFile}
            labelDrop={t('common:upload.drop')}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

FormCsvFileUpload.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  errors: PropTypes.object
};
