import { http } from './http';
import { apiHelper } from 'shared';

class Countries {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/countries${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }
}

export const countries = new Countries();
