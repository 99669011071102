import qrious from 'qrious';

const COMPONENT_TYPE = 'show-qr-code';
const TRAIT_TYPE = 'qrcode-value';
const SELECT_URL_CLASSNAME = 'qrcode__select_url';

export const qrCodeComponent = editor => {
  editor.BlockManager.add('show-qr-code-button', {
    category: 'Basic',
    label: 'QR Code',
    attributes: { class: 'fa fa-qrcode' },
    content: '<img class="qrcode" data-qrcode style="min-height:50px;min-width:50px" />',
    type: 'cmp-qr-code'
  });

  editor.DomComponents.addType(COMPONENT_TYPE, {
    isComponent: el => typeof el.hasAttribute === 'function' && el.tagName === 'IMG' && el.hasAttribute('data-qrcode'),
    model: {
      defaults: {
        editable: true,
        droppable: false,
        resizable: true,
        traits: [
          {
            type: TRAIT_TYPE,
            name: 'data-qrcode',
            label: 'Content'
          }
        ]
      }
    }
  });

  editor.TraitManager.addType(TRAIT_TYPE, {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.innerHTML = `
				<select class="${SELECT_URL_CLASSNAME}" style="appearance: auto">
        <option value="">Select...</option>
        ${
          trait &&
          trait.attributes &&
          trait.attributes.options.map(opt => `<option value="${opt.value}">${opt.name}</option>`).join('')
        }
				</select>
			`;
      return el;
    },
    onEvent({ elInput, component, event }) {
      const selectedUrl = elInput.querySelector(`.${SELECT_URL_CLASSNAME}`).value;
      var qr = new qrious({
        value: selectedUrl,
        level: 'H',
        size: 500
      });

      component.addAttributes({ src: qr.toDataURL() });
    },
    eventCapture: ['input']
  });
};
