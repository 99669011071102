import { makeStyles } from '@material-ui/core/styles';

export const useAcademyLinkStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.light,
    marginRight: theme.spacing(2),
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  icon: {
    fontSize: 28,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 100
  },
  text: {
    color: 'inherit'
  }
}));
