import { Button } from '@engloba-tech/englobity';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';

import { useButtonStyles } from '../button.styles';

export function ButtonPackaging({ disabled, tooltip, type, className, onClick, icon, text, ...props }) {
  const classes = useButtonStyles();

  const renderButton = () => {
    return (
      <Button
        tooltip={
          tooltip || {
            title: text,
            placement: 'top'
          }
        }
        disabled={disabled}
        className={clsx(classes.buttonPrimary, className)}
        key={shortid.generate()}
        type={type || 'primary'}
        onClick={onClick}
      >
        {props.children || (
          <div className={classes.wrapperButton}>
            {icon}
            <p className={classes.textButton}>{text}</p>
          </div>
        )}
      </Button>
    );
  };

  return renderButton();
}

ButtonPackaging.propTypes = {
  disabled: PropTypes.bool,
  tooltip: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  text: PropTypes.string
};
