import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { Grid } from '@material-ui/core';
import { isSkeletonLoading } from 'shared';

export const ViewPageAgeCheck = ({
  classes,
  visible,
  legalAge,
  cookiesEnabled,
  onChange,
  onSwitchChange,
  itemSizeLg
}) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  return (
    <>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 4 }}>
        <Input
          isLoading={isSkeletonLoading(legalAge)}
          required={visible}
          name={t('properties.legalAge')}
          label={t('properties.legalAge')}
          type="number"
          variant="outlined"
          value={`${legalAge || ''}`}
          onChange={onChange('legalAge')}
          inputProps={{ 'aria-label': t('properties.legalAge') }}
          validators={visible ? ['required', 'minNumber:1', 'maxNumber:99'] : []}
          errorMessages={[
            t('validations.required'),
            t('validations.minNumber', { minimum: 1 }),
            t('validations.maxNumber', { maximum: 99 })
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 4 }}>
        <Switch
          label={t('properties.cookiesEnabled')}
          checked={cookiesEnabled || false}
          onChange={onSwitchChange('cookiesEnabled')}
          disabled={!visible}
          className={classes.switch}
        />
      </Grid>
    </>
  );
};

ViewPageAgeCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  legalAge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cookiesEnabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  itemSizeLg: PropTypes.number
};
