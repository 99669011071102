import { useState, useMemo, useCallback, useEffect } from 'react';
import { rowFilterHelper } from 'shared';

export const useRelatedExperiencesBuildTable = (inputs, t) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: t('relatedExperiences.properties.name')
      },
      {
        id: 'language',
        numeric: false,
        disablePadding: true,
        label: t('relatedExperiences.properties.language')
      }
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs?.length
      ? []
      : inputs
      ? inputs.map(experience => {
          return {
            id: experience.id,
            name: experience.name,
            language: experience.language
          };
        })
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped);
  }, [inputs, inputs?.length]);

  const getRelatedExperiences = useCallback(
    (paging, sorting, filters) => {
      setRows(rowFilterHelper.getFilteredRows(initialRows, filters));
    },
    [initialRows]
  );

  return { headCells, rows, getRelatedExperiences };
};
