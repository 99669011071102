import { useEffect, useCallback } from 'react';
import { useFormState } from 'shared';

export function useConfigurationForm(configuration, formRef, onChangeInput, t) {
  const [configurationInputs, setConfigurationInputs] = useFormState(configuration, onChangeInput);

  useEffect(() => {
    setConfigurationInputs({ ...configuration }, false);
  }, [configuration]);

  const handleChangeSwitch =
    fieldName =>
    ({ target }) =>
      setConfigurationInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.checked }));

  const handleChange =
    fieldName =>
    ({ target }) =>
      setConfigurationInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  const handleAddCountry = useCallback(
    countries => {
      setConfigurationInputs(prevInputs => ({
        ...prevInputs,
        legalBlockCountries: [
          ...prevInputs.legalBlockCountries,
          ...countries.filter(c => prevInputs.legalBlockCountries.findIndex(block => block.id === c.id) < 0)
        ]
      }));
    },
    [setConfigurationInputs]
  );

  const handleDeleteCountry = useCallback(
    countriesToDelete => {
      setConfigurationInputs(prev => {
        let countries = prev.legalBlockCountries;

        countriesToDelete.forEach(countryToDelete => {
          countries = countries.filter(a => a.id !== countryToDelete.id);
        });

        return { ...prev, legalBlockCountries: countries };
      });
    },
    [setConfigurationInputs]
  );

  return {
    configurationInputs,
    handleChangeSwitch,
    handleChange,
    handleAddCountry,
    handleDeleteCountry
  };
}
