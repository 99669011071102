import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { TableCell, TableRow } from '@material-ui/core';

export function ViewTableSummary(props) {
  const { classes, headCells, summaryCells, allowRowSelection, actions } = props;
  return (
    <TableRow className={classes.summaryRow}>
      {allowRowSelection && <TableCell key={shortid.generate()}></TableCell>}
      {headCells.map(headCell => {
        return (
          <TableCell key={shortid.generate()} align={headCell.numeric ? 'right' : 'left'}>
            <span className={classes.summaryCell}>{summaryCells[headCell.id] || ''}</span>
          </TableCell>
        );
      })}
      {actions && actions.map(action => <TableCell key={shortid.generate()}></TableCell>)}
    </TableRow>
  );
}

ViewTableSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  summaryCells: PropTypes.object.isRequired,
  headCells: PropTypes.array.isRequired,
  allowRowSelection: PropTypes.bool,
  actions: PropTypes.array
};
