import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { useButtonStyles } from '../button.styles';

export function PackagingButtonBack({ onClick, tooltip }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useButtonStyles();

  return (
    <Button
      text={t('actions.back')}
      className={classes.buttonPrimary}
      onClick={onClick}
      tooltip={tooltip && { ...tooltip, title: tooltip.title || t('actions.back') }}
      icon={<KeyboardBackspaceIcon />}
    />
  );
}

PackagingButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.object
};
