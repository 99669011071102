import React, { useContext } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useAccountContainerStyles } from './accountContainer.styles';
import { BrandContext } from 'brand';

export function AccountContainer(pageComponent) {
  return function ContainerWrapper() {
    const classes = useAccountContainerStyles();
    const { brand } = useContext(BrandContext);

    return (
      <Grid container spacing={0} className={classes.white}>
        <Grid item md={4} xs={12} className={[classes.contentCentered]}>
          {pageComponent}
        </Grid>
        <Box
          component={Grid}
          className={[classes.contentCentered, classes.white, classes.background]}
          item
          md={8}
          display={{ xs: 'none !important', md: 'flex !important' }}
          style={brand?.welcomeImage && { backgroundImage: `url(${brand.welcomeImage})` }}
        ></Box>
      </Grid>
    );
  };
}
