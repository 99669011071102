import { NAME_SPACE } from 'i18n';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { execConfirmModal } from 'shared';
import { useFormState } from 'shared';

export function useMultilanguageExperienceForm(experience, onChangeInput) {
  const [experienceInputs, setExperienceInputs] = useFormState(experience, onChangeInput);
  const { t } = useTranslation(NAME_SPACE.MULTILANGUAGE_EXPERIENCES);

  useEffect(() => {
    setExperienceInputs(
      {
        ...experience
      },
      false
    );
  }, [experience]);

  const handleChange =
    fieldName =>
    ({ target }) => {
      setExperienceInputs(inputs => ({ ...inputs, [fieldName]: target.value }));
    };

  function handleLanguageAutocomplete(element) {
    if (element?.id) {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        defaultLanguageId: element?.id,
        defaultLanguage: { ...element }
      }));
    } else {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        defaultLanguage: {},
        defaultLanguageId: ''
      }));
    }
  }

  const handleDeleteExperiences = useCallback(
    experiencesToDelete => {
      setExperienceInputs(prev => {
        let relatedProducts = prev.relatedProducts;

        experiencesToDelete.forEach(experienceToDelete => {
          relatedProducts = relatedProducts.filter(a => a.id !== experienceToDelete.id);
        });

        return { ...prev, relatedProducts: relatedProducts };
      });
    },
    [setExperienceInputs]
  );

  const handleAddExperiences = useCallback(
    async experiencesToAdd => {
      try {
        if (
          experience.hasTagsWithTamper !== null &&
          !experience.hasTagsWithTamper &&
          experiencesToAdd.some(exp => exp.isTamperType)
        ) {
          await execConfirmModal({
            title: `⚠️ ${t('tamperMismatchWarningModal.title')}`,
            description: t('tamperMismatchWarningModal.description'),
            confirmText: t('common:actions.accept'),
            declineText: t('common:actions.cancel')
          });
        }

        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          relatedProducts: [
            ...prevInputs.relatedProducts,
            ...experiencesToAdd.filter(c => prevInputs.relatedProducts?.findIndex(prod => prod.id === c.id) < 0)
          ]
        }));
      } catch {}
    },
    [setExperienceInputs, t, experience]
  );

  return {
    experienceInputs,
    handleChange,
    handleLanguageAutocomplete,
    handleDeleteExperiences,
    handleAddExperiences
  };
}
