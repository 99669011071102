import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useViewHistoryStatStyles } from './viewHistoryStat.styles';
import { Grid } from '@material-ui/core';
import { Input } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';
import { ContentCard } from 'pages/shared';
import { dateHelper } from 'shared';

export const ViewHistoryStat = ({ title, user, date }) => {
  const classes = useViewHistoryStatStyles();
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const formattedDate = date && dateHelper.toLongDate(date, true);

  return (
    <div className={classes.historyStat}>
      <ContentCard title={title}>
        <>
          <Grid item xs={12}>
            <Input
              isLoading={isSkeletonLoading(user)}
              name={t('history.properties.user')}
              label={t('history.properties.user')}
              variant="outlined"
              value={user || ''}
              inputProps={{ 'aria-label': t('history.properties.user') }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              isLoading={isSkeletonLoading(date)}
              name={t('history.properties.date')}
              label={t('history.properties.date')}
              value={formattedDate || ''}
              inputProps={{ 'aria-label': t('history.properties.user') }}
            />
          </Grid>
        </>
      </ContentCard>
    </div>
  );
};

ViewHistoryStat.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.string,
  date: PropTypes.string
};
