import { makeStyles } from '@material-ui/core/styles';

export const useViewPageStyles = makeStyles(theme => ({
  page: {
    padding: '12px 24px 6px 24px !important',
    minWidth: 250
  },
  container: {
    backgroundColor: theme.palette.primary.light,
    alignContent: 'flex-start',
    paddingBottom: theme.spacing(3),
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  titleContainer: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    lineHeight: 2.2,
    fontSize: '1.1rem'
  },
  fieldsContainer: {
    height: 'unset',
    padding: theme.spacing(1)
  },
  pageSwitch: {
    marginLeft: 0,
    marginRight: 0
  },
  switch: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontWeight: theme.font.weight.bold,
      fontSize: 'inherit'
    }
  },
  select: {
    minWidth: '8rem',
    fontSize: 'inherit'
  },
  colorSelector: {
    display: 'flex',
    '& span': {
      marginLeft: 15
    }
  },
  checkInput: {
    paddingBottom: '4px !important'
  }
}));
