import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Summary, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useFormArticleStyles } from './formArticle.styles';
import { ViewArticleRatingItems } from './ViewArticleRatingItems';
import { useArticleForm } from './useArticleForm';
import { ImageTagSelect } from '../../shared';

export function FormArticle({
  article,
  mediaFiles,
  formRef,
  onSubmit,
  successMessage,
  errorMessage,
  resetErrorAndSuccessMessages,
  onClose
}) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormArticleStyles();
  const {
    articleInputs,
    setArticleInputs,
    handleChange,
    handleAddRating,
    handleDeleteRating,
    handleAddOption,
    handleDeleteOption,
    handleEditOption
  } = useArticleForm(article);

  useEffect(() => {
    if (successMessage && !article.id) {
      setArticleInputs({});
    } else if (successMessage) {
      onClose();
    }
  }, [successMessage, article.id]);

  function handleSubmit() {
    resetErrorAndSuccessMessages();
    onSubmit(articleInputs);
  }

  return (
    <>
      {(successMessage || errorMessage) && (
        <Grid item xs={12}>
          <Summary
            text={successMessage ? t(successMessage) : t(errorMessage)}
            severity={successMessage ? 'success' : 'error'}
          />
        </Grid>
      )}
      <Form
        className={classes.formArticle}
        errors={errorMessage}
        elementRef={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item md={6} sm={6} xs={12}>
            <Input
              isLoading={isSkeletonLoading(articleInputs.name)}
              required
              name={t('articles.properties.name')}
              label={t('articles.properties.name')}
              variant="outlined"
              value={articleInputs.name || ''}
              onChange={handleChange('name')}
              inputProps={{ 'aria-label': t('articles.properties.name') }}
              validators={['required', 'maxStringLength:100']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 100 })]}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ImageTagSelect
              variant="outlined"
              withAnyLabel
              name={t('articles.properties.imageTag')}
              title={t('articles.properties.imageTag')}
              value={articleInputs.imageTag}
              onChange={handleChange('imageTag')}
              options={mediaFiles}
              className={classes.select}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Input
              isLoading={isSkeletonLoading(articleInputs.description)}
              multiline
              rows={2}
              name={t('articles.properties.description')}
              label={t('articles.properties.description')}
              variant="outlined"
              value={articleInputs.description || ''}
              onChange={handleChange('description')}
              inputProps={{ 'aria-label': t('articles.properties.description') }}
              validators={['maxStringLength:2000']}
              errorMessages={[t('common:validations.maxStringLength', { length: 2000 })]}
              onKeyPress={e => {
                if (e.which === 13) {
                  e.stopPropagation();
                }
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} className={classes.ratingsContainer}>
            <ViewArticleRatingItems
              ratingItems={articleInputs.ratingItems}
              onChange={handleChange}
              onAdd={handleAddRating}
              onDelete={handleDeleteRating}
              onAddOption={handleAddOption}
              onDeleteOption={handleDeleteOption}
              onEditOption={handleEditOption}
              mediaFiles={mediaFiles}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormArticle.propTypes = {
  article: PropTypes.object.isRequired,
  mediaFiles: PropTypes.array,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func,
  onClose: PropTypes.func
};
