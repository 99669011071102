import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useParams, useHistory } from 'react-router-dom';
import { FormPage, useRequestLoading, ButtonSave, ButtonBack } from 'shared';
import { PATH } from 'app.routes.const';
import { useMultilanguageExperience } from './useMultilanguageExperience';
import { FormMultilanguageExperience } from './FormMultilanguageExperience';
import { useEditMultilanguageExperienceStyles } from './editMultilanguageExperience.styles';

export function EditMultilanguageExperience() {
  const { id } = useParams();
  const tab = new URLSearchParams(window.location.search).get('tab');
  const { experience, createExperience, updateExperience, errorInfo, setErrorInfo } = useMultilanguageExperience(id);
  const { t } = useTranslation(NAME_SPACE.MULTILANGUAGE_EXPERIENCES);
  const $formRef = useRef(null);
  const history = useHistory();
  const { requestLoading, callbackRequest } = useRequestLoading();
  const classes = useEditMultilanguageExperienceStyles();

  async function handleSubmit(experience) {
    await callbackRequest(async () => {
      setErrorInfo({ message: '', detail: '' });
      id ? await updateExperience(experience) : await createExperience(experience);
      history.push(PATH.MULTILANGUAGE_EXPERIENCES.LIST);
    });
  }

  function handleSaveClick() {
    $formRef.current.submit();
  }

  function handleGoBack() {
    history.push(PATH.MULTILANGUAGE_EXPERIENCES.LIST);
  }

  function composeActionButtons() {
    const actionButtons = [
      { onClick: handleGoBack, component: ButtonBack },
      {
        onClick: handleSaveClick,
        enableOnDirty: true,
        component: ButtonSave
      }
    ];

    return actionButtons;
  }

  const isCreating = !id;

  return (
    <FormPage
      title={t('singularName')}
      disabled={requestLoading}
      buttons={composeActionButtons()}
      onSubmit={handleSubmit}
      className={classes.formOverride}
    >
      <FormMultilanguageExperience
        errors={errorInfo}
        formRef={$formRef}
        experience={{ ...experience }}
        createMode={isCreating}
        selectedTab={tab ? parseInt(tab, 10) : 0}
      />
    </FormPage>
  );
}
