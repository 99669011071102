import { makeStyles } from '@material-ui/core/styles';

export const useViewIconsStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'end'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
    lineHeight: 2.2
  },
  subtitle: {
    display: 'flex',
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    padding: '0.7rem 24px !important',
    '& .MuiTypography-body1': {
      flex: 1
    }
  },
  button: {
    marginLeft: '1rem',
    padding: '0rem 2rem'
  },
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem',
    whiteSpace: 'nowrap'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    padding: '0px !important'
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px  !important`
  }
}));
