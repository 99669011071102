import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import LockIcon from '@material-ui/icons/Lock';
import { NAME_SPACE } from 'i18n';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { FormCustomPage } from './FormCustomPage';
import { useListCustomPagesStyles } from './listCustomPages.styles';
import { objectHelper } from 'shared';
import { TEMPLATE_PAGE_REFERENCE_TYPE } from '../../shared';

export const ListCustomPages = ({
  customPages,
  selectedPageId,
  onSelectPage,
  onDeletePage,
  onAddPage,
  successMessage,
  errorMessage,
  resetErrorAndSuccessMessages
}) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useListCustomPagesStyles();
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const [selectedCustomPage, setSelectedCustomPage] = useState(null);
  const $formPageRef = useRef(null);

  function handlePageSubmit(inputs) {
    onAddPage(inputs);
  }

  function handleModalAccept() {
    $formPageRef.current.submit();
  }

  function handleModalOpen(event, pageId) {
    event.preventDefault();
    event.stopPropagation();
    const page = pageId ? customPages.find(customPage => customPage.id === pageId) : {};
    setSelectedCustomPage(objectHelper.deepClone(page));
    handleOpen();
  }

  function handleModalClose() {
    handleClose();
    resetErrorAndSuccessMessages();
  }

  useEffect(() => {
    if (isOpen && successMessage && selectedCustomPage?.id) {
      handleModalClose();
    }
  }, [isOpen, successMessage]);

  return (
    <>
      <Typography variant="subtitle1" component="div" className={classes.title}>
        {t('pages.customPages')}
      </Typography>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
        {customPages &&
          customPages.map((page, index) => {
            return (
              <ListItem
                key={`page-${index}`}
                button
                selected={selectedPageId === page.id}
                onClick={() => onSelectPage(page.id)}
                className={page.isContentHomePage ? classes.itemHomePage : classes.itemPage}
              >
                <ListItemText
                  primary={page.contentPageReference || page.reference}
                  classes={{ root: classes.itemText, primary: classes.itemPrimaryText }}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
                {!page.isContentHomePage && page.reference !== TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION ? (
                  <ListItemSecondaryAction>
                    <Tooltip title={t('common:actions.edit')}>
                      <IconButton onClick={evt => handleModalOpen(evt, page.id)} aria-label={t('common:actions.edit')}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('common:actions.remove')}>
                      <IconButton
                        onClick={evt => {
                          evt.stopPropagation();
                          onDeletePage(page);
                        }}
                        aria-label={t('common:actions.remove')}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                ) : page.reference !== TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION ? (
                  <ListItemSecondaryAction>
                    <Tooltip title={t('common:actions.add')}>
                      <IconButton onClick={handleModalOpen} aria-label={t('common:actions.add')}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                ) : (
                  <ListItemSecondaryAction className={classes.locked}>
                    <Tooltip title={t('pages.lockedPageInfo')}>
                      <LockIcon />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
      </List>

      <DialogModal
        maxWidth="xs"
        fullWidth
        title={selectedCustomPage?.id ? t('pages.dialogTitleUpdate') : t('pages.dialogTitleAdd')}
        description=""
        isOpen={isOpen}
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormCustomPage
          formRef={$formPageRef}
          customPage={selectedCustomPage}
          onSubmit={handlePageSubmit}
          successMessage={successMessage}
          errorMessage={errorMessage}
          resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
        />
      </DialogModal>
    </>
  );
};

ListCustomPages.propTypes = {
  customPages: PropTypes.array,
  selectedPageId: PropTypes.string,
  onSelectPage: PropTypes.func,
  onDeletePage: PropTypes.func,
  onAddPage: PropTypes.func,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
