const actionButtons = [
  {
    id: 'show-templates',
    className: 'fa fa-html5',
    attributes: {
      title: 'Select template'
    },
    command: 'show-templates'
  }
];

export const templatePanels = editor => {
  const panelManager = editor.Panels;

  var actionsPanel = panelManager.getPanel('basic-actions');
  if (!actionsPanel) {
    panelManager.addPanel({
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: actionButtons
    });
  } else {
    actionsPanel.get('buttons').add(actionButtons);
  }
};
