import { useCallback, useState } from 'react';
import { useFormState } from 'shared';

export function useFormOption(option, ratingItem, onSubmit) {
  const [optionInputs, setOptionInputs] = useFormState(option);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange =
    fieldName =>
    ({ target }) => {
      setOptionInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));
    };

  const handleSubmit = useCallback(async () => {
    const options = ratingItem?.options;
    if (options) {
      if (
        options.some(
          o => o.name?.trim().toLowerCase() === optionInputs.name?.trim().toLowerCase() && o.id !== optionInputs.id
        )
      ) {
        setErrorMessage('articleRatings.validations.optionNameAlreadyExists');
        return;
      }
      if (
        options.some(
          o =>
            o.reference?.trim().toLowerCase() === optionInputs.reference?.trim().toLowerCase() &&
            o.id !== optionInputs.id
        )
      ) {
        setErrorMessage('articleRatings.validations.optionReferenceAlreadyExists');
        return;
      }
    }

    onSubmit(optionInputs);
  }, [optionInputs, ratingItem]);

  function resetErrorAndSuccessMessages() {
    setErrorMessage(null);
    setSuccessMessage(null);
  }

  return {
    optionInputs,
    setOptionInputs,
    handleChange,
    handleSubmit,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    resetErrorAndSuccessMessages
  };
}
