import { makeStyles } from '@material-ui/core/styles';

export const useFormPageStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  breadcrumbs: {
    margin: '2rem 0 1.5rem 0'
  },
  container: {
    background: theme.palette.secondary.main,
    width: '100%',
    '& form': {
      background: 'white',
      padding: theme.spacing(3),
      borderRadius: '4px'
    }
  },
  containerChildForm: {
    marginBottom: '0 !important',
    boxShadow: 'none'
  },
  contentChildForm: {
    paddingBottom: '6px !important'
  },
  breadcrum: {
    minWidth: '3rem'
  },
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(3)
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    whiteSpace: 'nowrap',
    alignSelf: 'end',
    '& svg': {
      marginLeft: '1rem'
    }
  },
  actions: {
    flex: 11,
    alignSelf: 'end',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  multiActionButtonPopper: {
    zIndex: '5 !important',
    marginLeft: theme.spacing(2),
    '& .MuiList-padding': {
      padding: '0 !important',
      border: `1px solid ${theme.palette.primary.main}`,
      background: '#f5f8fd',
      borderTop: 'none',
      '& .MuiMenuItem-root': {
        fontSize: '14px',
        color: theme.palette.primary.main
      }
    }
  },
  buttonToggled: {
    borderBottom: 'none !important',
    marginLeft: theme.spacing(2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  '& .primary': {
    background: 'red'
  },

  '& .secondary': {
    background: 'yellow'
  },
  '.multiActionButtonPopper': {
    zIndex: '5 !important',
    '& .MuiList-padding': {
      padding: '0 !important'
    }
  }
}));
