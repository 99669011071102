export const basicExtendedBlocks = editor => {
  editor.BlockManager.add('button', {
    category: 'Basic',
    label: 'Button',
    attributes: { class: 'gjs-fonts gjs-f-button' },
    content: '<div class="gjs-button">Button</div>'
  });

  const css = editor.Css;
  css.addRules(
    '.gjs-button{padding:10px;width:190px;min-height:30px;text-align:center;letter-spacing:3px;line-height:30px;background-color:#d983a6;border-radius:5px;transition:all 0.5s ease;cursor:pointer;user-select: none;}'
  );
  css.addRules('.gjs-button:hover{background-color:#ffffff;color:#4c114e;}');
  css.addRules('.gjs-button:active{background-color:#4d114f;color:#fff;}');

  editor.BlockManager.add('link-block', {
    category: 'Basic',
    label: 'Link Block',
    attributes: { class: 'fa fa-link' },
    content: {
      type: 'link',
      editable: false,
      droppable: true,
      style: {
        display: 'inline-block',
        padding: '5px',
        'min-height': '50px',
        'min-width': '50px'
      }
    }
  });

  editor.BlockManager.add('quote', {
    label: 'Quote',
    category: 'Basic',
    attributes: { class: 'fa fa-quote-right' },
    content: `<blockquote class="quote">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit
      </blockquote>`
  });

  editor.BlockManager.add('text-basic', {
    category: 'Basic',
    label: 'Text section',
    attributes: { class: 'gjs-fonts gjs-f-h1p' },
    content: `<section class="bdg-sect">
      <h1 class="heading">Insert title here</h1>
      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </section>`
  });
};
