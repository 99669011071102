import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Summary } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useFormFileUploadStyles } from './formFileUpload.styles';
import { fileHelper, Dropzone } from 'shared';

export function FormFileUpload({ formRef, onSubmit, accept, maxFiles, maxFileSize, errors }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useFormFileUploadStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);

  function handleSubmit() {
    if (selectedFiles && selectedFiles.length > 0) {
      onSubmit(selectedFiles);
      setSelectedFiles([]);
    }
  }

  async function handleSelectFiles(acceptedFiles) {
    const files = acceptedFiles;
    if (files) {
      let readedFiles = [];
      for (let i = 0; i < files.length; i++) {
        const readedFile = await fileHelper.asyncFileReader(files[i]);
        readedFiles.push({ ...readedFile });
      }
      setSelectedFiles(prevFiles => prevFiles.concat(readedFiles));
    }
  }

  function handleDeleteFile(file) {
    setSelectedFiles(prevFiles => prevFiles.filter(prevFile => prevFile !== file));
  }

  return (
    <>
      {maxFileSize && !isNaN(maxFileSize) && (
        <Grid item xs={12}>
          <Summary
            text={t('validations.maxFileSize', { maxFileSize: Math.trunc(maxFileSize / 1024) })}
            severity={'info'}
          />
        </Grid>
      )}
      <Form
        className={`${classes.form} ${classes.container}`}
        elementRef={formRef}
        onSubmit={handleSubmit}
        errors={errors}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid container alignItems="center" item xs={12}>
            <Dropzone
              accept={accept}
              maxFiles={maxFiles}
              maxFileSize={maxFileSize}
              files={selectedFiles}
              onDrop={handleSelectFiles}
              onDeleteFile={handleDeleteFile}
              labelDrop={t('common:upload.drop')}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormFileUpload.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  })
};
