import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSave } from '@engloba-tech/englobity';

import { NAME_SPACE } from 'i18n';
import { useButtonStyles } from '../button.styles';

export function PackagingButtonSave({ disabled, tooltip, text, onClick, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useButtonStyles();
  const textButton = text || t('actions.save');

  const renderButton = () => (
    <>
      <ButtonSave
        disabled={disabled}
        text={text || t('actions.save')}
        tooltip={
          tooltip
            ? { ...tooltip, title: tooltip.title || textButton }
            : {
                title: textButton,
                placement: 'top'
              }
        }
        className={classes.buttonAdd}
        onClick={onClick}
      />
    </>
  );

  return renderButton();
}

PackagingButtonSave.propTypes = {
  disabled: PropTypes.bool,
  tooltip: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  text: PropTypes.string
};
