import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import CollectionsIcon from '@material-ui/icons/CollectionsOutlined';
import { Form, Tabs } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useFormStickerStyles } from './formSticker.styles';
import { settings, KEY_SETTINGS } from 'settings';
import { useStickerForm } from './useStickerForm';
import { ViewMediaFiles } from './ViewMediaFiles';
import { ViewSticker } from './ViewSticker/ViewSticker';

export function FormSticker({ formRef, onSubmit, sticker, createMode, errors, onChangeInput }) {
  const { t } = useTranslation(NAME_SPACE.STICKERS);
  const classes = useFormStickerStyles();
  const { stickerInputs, handleChange, handleExperienceAutocomplete, handleAddMediaFiles, handleDeleteMediaFile } =
    useStickerForm(sticker, createMode, onChangeInput);

  function handleSubmit() {
    const storageUrl = settings.getSetting(KEY_SETTINGS.STORAGE_URL);
    const regex = new RegExp(`(?!${storageUrl}.*?)(\\?sv.*?(?=[\\"']))`, 'gim');
    onSubmit({
      ...stickerInputs,
      content: stickerInputs.content ? stickerInputs.content.replaceAll(regex, '#TOKEN#') : stickerInputs.content
    });
  }

  const renderTabs = useMemo(() => {
    return [
      {
        name: t('stickerData'),
        icon: <ViewQuiltOutlinedIcon />,
        children: (
          <ViewSticker
            createMode={createMode}
            sticker={stickerInputs}
            onChange={handleChange}
            onExperienceAutocomplete={handleExperienceAutocomplete}
          />
        )
      },
      {
        name: t('mediaFiles.name'),
        icon: <CollectionsIcon />,
        children: (
          <ViewMediaFiles
            mediaFiles={stickerInputs.mediaFiles}
            readToken={stickerInputs.readToken}
            onAddMediaFile={handleAddMediaFiles}
            onDeleteMediaFile={handleDeleteMediaFile}
          />
        )
      }
    ];
  }, [
    t,
    createMode,
    stickerInputs,
    handleChange,
    handleExperienceAutocomplete,
    handleAddMediaFiles,
    handleDeleteMediaFile
  ]);

  return (
    <>
      <Form
        className={classes.formSticker}
        errors={errors}
        elementRef={formRef}
        onSubmit={handleSubmit}
        autoComplete="off"
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <Grid container className={classes.tabsContainer}>
          <Grid item xs={12}>
            <Tabs classNameContent={classes.tabsMinHeight} tabs={renderTabs} /* defaultPosition={selectedTab} */ />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormSticker.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  sticker: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    blobContainerId: PropTypes.string,
    productId: PropTypes.string,
    product: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ]),
    content: PropTypes.string
  }),
  createMode: PropTypes.bool.isRequired,
  onChangeInput: PropTypes.func,
  selectedTab: PropTypes.number
};
