export const resources = {
  en: {
    common: require('./en/common.json'),
    analytics: require('./en/analytics.json'),
    arMarkers: require('./en/arMarkers.json'),
    authentication: require('./en/authentication.json'),
    configuration: require('./en/configuration.json'),
    experiences: require('./en/experiences.json'),
    htmlTemplates: require('./en/htmlTemplates.json'),
    mediaFiles: require('./en/mediaFiles.json'),
    multilanguageExperiences: require('./en/multilanguageExperiences.json'),
    qrCodes: require('./en/qrCodes.json'),
    stickers: require('./en/stickers.json'),
    tags: require('./en/tags.json'),
    users: require('./en/users.json')
  }
};
