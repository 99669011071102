export const ICON_TARGET_TYPE = {
  LINK: 0,
  IMAGE: 1
};

export const TEMPLATE_PAGE_REFERENCE_TYPE = {
  PROMOTIONAL_VIDEO: 'PromotionalVideo',
  LEGAL_TERMS: 'LegalTerms',
  AUTHENTICATION: 'AuthResult',
  DEFAULT_PAGE: 'Default Page',
  CIGAR_SHOP_ANDORRA: 'CigarShopAndorra',
  VERTICAL: 'Vertical',
  HORIZONTAL: 'Horizontal',
  IMAGES: 'Images',
  RATINGS: 'Ratings'
};

export const TEMPLATE_NAME = {
  CIGAR_SHOP_ANDORRA: 'Cigar Shop Andorra',
  CUSTOM_PAGE: 'Custom Page',
  VERTICAL: 'Vertical',
  HORIZONTAL: 'Horizontal',
  IMAGES: 'Images',
  REDIRECT: 'Redirect'
};

export const ARTICLE_RATING_TYPE = {
  SCORE: 0,
  SELECTOR: 1
};

export const RATING_COMPLETED_RESULT_TYPE = {
  GRATITUDE_MESSAGE: 0,
  AVERAGE_RATING: 1
};
