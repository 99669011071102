import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { ViewTable, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { NAME_SPACE } from 'i18n';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useViewArticlesStyles } from './viewArticles.styles';
import { useArticlesBuildTable } from './useArticlesBuildTable';
import { FormArticle } from './FormArticle';

const articleEmpty = { ratingItems: [] };

export const ViewArticles = ({
  articles,
  mediaFiles,
  onEditArticle,
  onDeleteArticle,
  canBeDeleted,
  errorMessage,
  successMessage,
  resetErrorAndSuccessMessages
}) => {
  const classes = useViewArticlesStyles();
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const { rows, headCells, getExperienceArticles } = useArticlesBuildTable(articles, t);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formArticleRef = useRef(null);

  function handleDetail([articleId]) {
    setSelectedArticle(articleId ? articles.find(article => article.id === articleId) : { ...articleEmpty });
    handleOpen();
  }

  function handleModalAccept() {
    $formArticleRef.current.submit();
  }

  function handleModalClose() {
    resetErrorAndSuccessMessages();
    handleClose();
  }

  function handleArticleSubmit(article) {
    if (article) {
      onEditArticle(article);
    }
  }

  async function handleDelete(articlesToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          articlesToDelete.length > 1
            ? t('articles.removeConfirm.message', { length: articlesToDelete.length })
            : t('articles.removeConfirm.singularMessage', {
                name: articlesToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });

      onDeleteArticle(articlesToDelete);
    } catch (err) {}
  }

  return (
    <>
      <ViewTable
        rows={rows}
        cells={headCells}
        mainKey={'name'}
        defaultOrderBy={{ property: 'order', direction: 'asc' }}
        onFetchData={getExperienceArticles}
        selectRowWhen={() => canBeDeleted}
        emptyText={t('articles.empty')}
        actions={[
          {
            title: t('actions.view'),
            name: 'view',
            properties: ['id'],
            label: t('common:edit'),
            cb: handleDetail,
            icon: <EditIcon />
          }
        ]}
        toolbar={{
          title: t('articles.name'),
          actions: [
            {
              condition: isAnySelected => !isAnySelected,
              cb: handleDetail,
              component: ButtonAdd
            },
            {
              condition: isAnySelected => isAnySelected,
              resetOnCB: true,
              cb: handleDelete,
              component: ButtonDelete
            }
          ]
        }}
      />

      <DialogModal
        className={classes.modal}
        maxWidth="md"
        fullWidth
        title={t('articles.dialogTitle')}
        description=""
        isOpen={isOpen}
        disableBackdropClick
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormArticle
          formRef={$formArticleRef}
          onSubmit={handleArticleSubmit}
          article={{ ...selectedArticle }}
          mediaFiles={mediaFiles}
          successMessage={successMessage}
          errorMessage={errorMessage}
          resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
          onClose={handleModalClose}
        />
      </DialogModal>
    </>
  );
};

ViewArticles.propTypes = {
  articles: PropTypes.array.isRequired,
  mediaFiles: PropTypes.array,
  onEditArticle: PropTypes.func,
  onDeleteArticle: PropTypes.func,
  setErrorInfo: PropTypes.func,
  canBeDeleted: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
