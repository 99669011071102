import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Avatar, Typography } from '@material-ui/core';
import { Form, Input, useHandleOpen, DialogModal } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { NAME_SPACE } from 'i18n';
import { useFormUserStyles } from './formUser.styles';
import { isSkeletonLoading, useFormState } from 'shared';
import { RoleSelect } from './RoleSelect';
import { FormAvatar } from './FormAvatar';
import { ROLES } from 'auth';
import { Skeleton } from '@material-ui/lab';

export function FormUser({ formRef, onSubmit, user, createMode, errors, profile, onChangeInput }) {
  const [inputs, setInputs] = useFormState({ ...user } || {}, onChangeInput);
  const { t } = useTranslation(NAME_SPACE.USERS);
  const classes = useFormUserStyles();
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formAvatarRef = useRef(null);

  function handleSubmit() {
    onSubmit({ ...inputs });
  }

  useEffect(() => {
    setInputs({ ...user }, false);
  }, [user]);

  const handleUser =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  function handleSelectChange(fieldName, value) {
    setInputs(prevInputs => ({
      ...prevInputs,
      [fieldName]: value
    }));
  }

  function handleModalAccept() {
    $formAvatarRef.current.submit();
  }

  const handelAccept = file => {
    setInputs(prevInputs => ({ ...prevInputs, avatar: file }));
    handleClose();
  };

  return (
    <>
      <Form errors={errors} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
        <Grid container className={`${classes.headerUser} ${classes.background} ${classes.containers}`}>
          <Grid item container className={`${classes.end}`}>
            <Avatar
              src={`data:image/jpeg;base64,${inputs.avatar || ''}`}
              onClick={profile ? handleOpen : null}
              className={`${classes.picture} ${profile && classes.pointer}`}
            />
            <Grid xs={6} container item className={`${classes.marginLeft} ${classes.flexDirectionColumn}`}>
              <Grid container item className={classes.inputsContainer}>
                {!isSkeletonLoading(inputs.userName) ? (
                  <Grid container item className={`${classes.hover}`}>
                    <Typography variant="h6">{inputs.userName || ''}</Typography>
                  </Grid>
                ) : (
                  <Skeleton width={100} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={`${classes.containers} ${classes.secondContainer}`}>
          <Grid xs={6} item>
            <Input
              isLoading={isSkeletonLoading(inputs.userName)}
              required
              disabled={profile || inputs.role === ROLES.TAG_MANAGER}
              name={t('properties.userName')}
              label={t('properties.userName')}
              variant="outlined"
              value={inputs.userName || ''}
              onChange={handleUser('userName')}
              inputProps={{ 'aria-label': t('properties.userName') }}
              validators={['required', 'isEmail', 'maxStringLength:255']}
              errorMessages={[
                t('validations.required'),
                t('validations.invalidEmail'),
                t('common:validations.maxStringLength', { length: 255 })
              ]}
            />
          </Grid>

          <Grid item xs={6}>
            {profile || inputs.role === ROLES.TAG_MANAGER ? (
              <Input
                isLoading={isSkeletonLoading(inputs.role)}
                required={createMode}
                className={classes.fullInput}
                name={t('properties.role')}
                label={t('properties.role')}
                variant="outlined"
                value={inputs.role || ''}
                inputProps={{ 'aria-label': t('properties.role') }}
                disabled
              />
            ) : (
              <RoleSelect
                value={inputs.role || ''}
                title={t('properties.role')}
                onChange={({ target }) => handleSelectChange('role', target.value)}
              />
            )}
          </Grid>
          {!profile && (
            <Grid item xs={6}>
              <Input
                type="password"
                isLoading={isSkeletonLoading(inputs.userName)}
                required={createMode}
                disabled={profile || inputs.role === ROLES.TAG_MANAGER}
                className={classes.fullInput}
                name={t('properties.password')}
                label={t('properties.password')}
                variant="outlined"
                value={inputs.password || ''}
                onChange={handleUser('password')}
                inputProps={{ 'aria-label': t('properties.password') }}
                validators={createMode ? ['required'] : []}
                errorMessages={[t('validations.required')]}
              />
            </Grid>
          )}
        </Grid>
      </Form>
      <DialogModal
        title={t('common:import.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpen}
        onClose={handleClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormAvatar avatar={inputs.avatar} formRef={$formAvatarRef} onSubmit={handelAccept} />
      </DialogModal>
    </>
  );
}

FormUser.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    role: PropTypes.string
  }),
  createMode: PropTypes.bool,
  onChangeInput: PropTypes.func,
  profile: PropTypes.bool
};
