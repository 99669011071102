import { http } from './http';
import { apiHelper } from 'shared';

class Users {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/users/${queryString}`);
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/users${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  getById(id) {
    return http.get(`/users/${id}`);
  }

  create(user) {
    return http.post('/users', user);
  }

  update(id, user) {
    return http.put(`/users/${id}`, user);
  }

  getMy() {
    return http.get('/users/my');
  }

  updateMy(user) {
    return http.put('/users/my', user);
  }
}

export const users = new Users();
