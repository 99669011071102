import { useMemo } from 'react';

export function useMultilanguageExperiencesBuildTable(inputs, t) {
  const headCells = useMemo(() => {
    return [{ id: 'name', numeric: false, disablePadding: false, label: t('properties.name') }];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }
    return inputs.items.map(experience => ({
      name: experience.name,
      id: experience.id,
      canBeDeleted: !experience.hasTags && !experience.hasRatedScans
    }));
  }, [inputs]);

  return { headCells, rows };
}
