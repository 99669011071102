import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NAME_SPACE } from 'i18n';
import { Tooltip, IconButton } from '@material-ui/core';
import { Form } from '@engloba-tech/englobity';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { MediaFiles } from '../MediaFiles';
import { useMediaFilesPaginated } from '../useMediaFilesPaginated';

export function FormSelectMediaFiles({ formRef, onSubmit, maxSelectedFiles }) {
  const { t } = useTranslation(NAME_SPACE.MEDIA_FILES);
  const { mediaFiles, getMediaFiles, deleteMediaFiles, createMediaFiles, updateMediaFile, errorInfo } =
    useMediaFilesPaginated();

  const [selectedMediaFiles, setSelectedMediaFiles] = useState([]);

  const selectedText = selectedMediaFiles.length
    ? `${selectedMediaFiles.length}${maxSelectedFiles ? ` of ${maxSelectedFiles}` : ''} ${t('common:actions.selected')}`
    : '';

  function handleSelectItem(event, item) {
    event.stopPropagation();

    setSelectedMediaFiles(prevItems =>
      prevItems.findIndex(f => f.id === item.id) >= 0
        ? [...prevItems.filter(f => f.id !== item.id)]
        : !maxSelectedFiles || prevItems.length < maxSelectedFiles
        ? [...prevItems, item]
        : [...prevItems]
    );
  }

  function handleSubmit() {
    if (selectedMediaFiles && selectedMediaFiles.length > 0) {
      onSubmit(selectedMediaFiles);
      setSelectedMediaFiles([]);
    }
  }

  function handleDeleteMediaFile(mediaFilesToDelete) {
    deleteMediaFiles(mediaFilesToDelete);
    setSelectedMediaFiles(prev => prev.filter(f => f.id !== mediaFilesToDelete[0]?.id));
  }

  return (
    <Form
      elementRef={formRef}
      onSubmit={handleSubmit}
      errors={errorInfo}
      onKeyPress={e => {
        if (e.which === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      autoComplete="off"
    >
      <div>
        <MediaFiles
          mediaFiles={mediaFiles}
          getMediaFiles={getMediaFiles}
          deleteMediaFiles={handleDeleteMediaFile}
          createMediaFiles={createMediaFiles}
          updateMediaFile={updateMediaFile}
          cols={8}
          rowHeight={120}
          tileMinWidth={100}
          gap={3}
          extraActions={image => (
            <Tooltip title={t('actions.select')}>
              <IconButton onClick={evt => handleSelectItem(evt, image)} aria-label={`select ${image.tag}`}>
                {selectedMediaFiles.findIndex(f => f.id === image.id) >= 0 ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
          paginationProps={{ defaultPageSize: 25, rowsPerPageOptions: [10, 25, 50] }}
          footerText={selectedText}
        />
      </div>
    </Form>
  );
}
FormSelectMediaFiles.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  maxSelectedFiles: PropTypes.number
};
