import { makeStyles } from '@material-ui/core/styles';

export const useViewTableStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    '& .MuiTableRow-root': {
      '&:last-child': {
        '& .MuiTableCell-root': {
          borderBottom: 'none'
        }
      }
    }
  },
  paper: {
    width: '100%',
    // height: '100%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingTop: `${theme.spacing(3)}px !important`,
    '& .MuiFormControl-root': {
      minWidth: 'auto'
    }
  },
  cellDate: { width: '18rem' },
  spaced: {},
  table: {
    minWidth: 750
  },
  ellipsis: {
    maxWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  tooltip: {
    fontSize: theme.font.size.m
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  summary: {
    marginBottom: '1rem',
    alignItems: 'center'
  },
  empty: {
    backgroundColor: 'white',
    '&.MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: 'white'
    },
    '& td': {
      borderBottom: 0
    }
  },
  highlight: {
    color: theme.palette.primary.main
  },
  selected: {
    flex: 1,
    marginLeft: '1rem',
    backgroundColor: '#fff'
  },
  footerWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    borderTop: '1px solid #e8e8e8',
    '& .MuiTablePagination-root': {
      borderBottom: 0,
      flex: '3 1 0% !important'
    },
    '& .MuiTablePagination-toolbar': {
      alignItems: 'center !important'
    },
    '& .MuiToolbar-root': {
      padding: 0,
      flexWrap: 'wrap'
    }
  },
  headCell: {
    fontWeight: theme.font.weight.black,
    fontSize: '14px'
  },
  filterRow: {
    '& .MuiTableCell-root': {
      paddingTop: '15px !important',
      paddingBottom: '15px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    }
  },
  filterCell: {
    padding: '15px 0px',
    textAlign: 'center',
    '&:first-child': {
      paddingRight: 0
    }
  },
  summaryRow: {
    backgroundColor: theme.palette.table.summary
  },
  summaryCell: {
    fontWeight: theme.font.weight.semibold
  }
}));
