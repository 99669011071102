export const paperComponents = editor => {
  editor.DomComponents.addType('a6-container', {
    isComponent: el => el.tagName === 'MAIN' && el.hasAttribute('data-a6-paper'),
    model: {
      defaults: {
        editable: false,
        droppable: true,
        style: {
          display: 'inline-block',
          height: '100%',
          width: '100%',
          'min-height': '148mm',
          'max-height': '148mm',
          'min-width': '105mm',
          'max-width': '105mm',
          overflow: 'hidden',
          'box-sizing': 'border-box'
        }
      }
    }
  });

  editor.DomComponents.addType('a5-container', {
    isComponent: el => el.tagName === 'MAIN' && el.hasAttribute('data-a5-paper'),
    model: {
      defaults: {
        editable: false,
        droppable: true,
        style: {
          display: 'inline-block',
          height: '100%',
          width: '100%',
          'min-height': '209mm',
          'max-height': '210mm',
          'min-width': '148mm',
          'max-width': '148mm',
          overflow: 'hidden',
          'box-sizing': 'border-box'
        }
      }
    }
  });

  editor.DomComponents.addType('a4-container', {
    isComponent: el => el.tagName === 'MAIN' && el.hasAttribute('data-a4-paper'),
    model: {
      defaults: {
        editable: false,
        droppable: true,
        style: {
          display: 'inline-block',
          height: '100%',
          width: '100%',
          'min-height': '297mm',
          'max-height': '297mm',
          'min-width': '210mm',
          'max-width': '210mm',
          overflow: 'hidden',
          'box-sizing': 'border-box'
        }
      }
    }
  });
};
