import { makeStyles } from '@material-ui/core/styles';

export const useViewTableStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: '100%'
  },
  paper: {
    margin: 8,
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiTableContainer-root': {
      borderRadius: 4
    }
  },
  table: {
    minWidth: 'unset'
  },
  selected: {
    flex: 1,
    marginLeft: '1rem',
    backgroundColor: '#fff'
  },
  footerWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    borderTop: '1px solid #e8e8e8',
    '& .MuiTablePagination-root': {
      borderBottom: 0,
      flex: '3 1 0% !important'
    },
    '& .MuiTablePagination-toolbar': {
      alignItems: 'center !important',
      minHeight: 'unset'
    },
    '& .MuiToolbar-root': {
      padding: 0,
      flexWrap: 'wrap'
    }
  }
}));
