import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, ImageListItem, ImageListItemBar, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomImage } from 'shared';
import { useBrandImage } from './brandImage.styles';
import { Skeleton } from '@material-ui/lab';
import { isSkeletonLoading } from 'shared';

export function BrandImage({ title, src, readToken, onClick, onDelete, height = 254, showTitleBottom }) {
  const { t } = useTranslation('common');
  const classes = useBrandImage();
  const isLoading = isSkeletonLoading(src);
  return (
    <ImageListItem
      className={`${classes.tile}`}
      onClick={!isLoading ? onClick : undefined}
      component="div"
      style={{ height: height }}
    >
      {isLoading ? (
        <Skeleton height={'100%'} />
      ) : (
        <>
          <div className={classes.imageContainer}>
            {src ? (
              <CustomImage src={`${src}${readToken ? `?${readToken}` : ''}`} alt="Image" className={classes.image} />
            ) : (
              <div className={classes.imagePlaceholder}></div>
            )}
          </div>
          <ImageListItemBar
            position="top"
            title={title}
            className={`${classes.imageTitle} ${classes.imageTitleTop}`}
            actionIcon={
              <div className={classes.actionIconContainer}>
                {onDelete && (
                  <Tooltip title={t('actions.remove')}>
                    <span>
                      <IconButton
                        onClick={evt => {
                          evt.stopPropagation();
                          onDelete();
                        }}
                        aria-label={t('actions.remove')}
                        disabled={!src}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </div>
            }
          />
          {showTitleBottom && <ImageListItemBar className={classes.imageTitleBottom} />}
        </>
      )}
    </ImageListItem>
  );
}

BrandImage.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  readToken: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  height: PropTypes.number,
  showTitleBottom: PropTypes.bool
};
