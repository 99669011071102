import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, Chip, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
import CachedIcon from '@material-ui/icons/Cached';
import { useViewTableToolbarStyles } from './viewTableToolbar.styles';
import { useTranslation } from 'react-i18next';
import { DialogModal, useHandleOpen, Button } from '@engloba-tech/englobity';
import { objectHelper } from 'shared';

export function ViewTableToolbar({ numSelected, entityName, selected, actions, advancedFilters, toolbarClasses }) {
  const classes = { ...useViewTableToolbarStyles(), ...toolbarClasses };
  const { t } = useTranslation();
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $refFilter = useRef();

  const isAnySelected = numSelected > 0;
  const showFilterButton = !!advancedFilters?.handleAdd || false;
  const activeFilters = advancedFilters?.getActiveFilters ? advancedFilters?.getActiveFilters() : null;
  const showClearButton = advancedFilters?.showClearButton !== false;

  function handleFilterModalAccept() {
    $refFilter.current.submit();
  }

  function handleFilterModal(filters) {
    handleClose();
    advancedFilters.handleAdd(filters);
  }

  function handleResetFilterModal() {
    handleClose();
    advancedFilters.handleAdd({});
  }

  const renderFilterChip = (filter, objPropertyName, index = null) => {
    return (
      <Chip
        onDelete={advancedFilters.handleDelete && (() => advancedFilters.handleDelete(objPropertyName, index))}
        className={classes.advancedFiltersChip}
        key={`filter${objPropertyName}${index}`}
        label={`${t(`${advancedFilters.translationKey}.${objPropertyName}`) + ': ' + filter.chipLabel}`}
        style={{ marginRight: '1rem', marginBottom: '1rem' }}
      />
    );
  };

  return (
    <Toolbar className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={`${classes.advancedFiltersWrapper} ${classes.toolbarTitleRow}`}>
          {entityName && (
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {entityName}
            </Typography>
          )}

          {showFilterButton ? (
            <Button
              className={classes.button}
              tooltip={{ title: t('actions.filter'), placement: 'top' }}
              type={'secondary'}
              onClick={handleOpen}
            >
              <div className={classes.wrapperButton}>
                <FilterListIcon />
                <p className={classes.textButton}>{t('actions.filter')}</p>
              </div>
            </Button>
          ) : (
            ''
          )}

          {actions.map((action, index) => {
            return (
              action.condition(isAnySelected) &&
              (action.component ? (
                <action.component
                  onClick={() => action.mappedCb(selected)}
                  tooltip={{ title: action.title, placement: 'top' }}
                  text={action.title}
                  key={`action${index}`}
                  icon={action.icon}
                />
              ) : (
                <Button
                  className={`${classes.button} ${action.className}`}
                  tooltip={{ title: action.title, placement: 'top' }}
                  type={action.type || 'secondary'}
                  key={`action${index}`}
                  onClick={() => action.mappedCb(selected)}
                >
                  <div className={classes.wrapperButton}>
                    {action.icon}
                    <p className={classes.textButton}>{action.title}</p>
                  </div>
                </Button>
              ))
            );
          })}
        </Grid>
        {activeFilters && !objectHelper.isEmpty(activeFilters) && (
          <Grid item xs={12} onClick={handleOpen}>
            {Object.keys(activeFilters).map((objPropertyName, i) => {
              const filter = activeFilters[objPropertyName];
              const isFilterArray = Array.isArray(filter);
              return !isFilterArray
                ? renderFilterChip(filter, objPropertyName)
                : filter.map((filter, index) => {
                    return renderFilterChip(filter, objPropertyName, index);
                  });
            })}
          </Grid>
        )}
      </Grid>

      {showFilterButton && (
        <DialogModal
          title={advancedFilters.title}
          description=""
          fullWidth
          isOpen={isOpen}
          onClose={handleClose}
          buttons={[
            {
              children: <CloseIcon />,
              text: t('common:actions.cancel'),
              type: 'secondary',
              onClick: handleClose
            },
            showClearButton && {
              children: <CachedIcon />,
              text: t('common:actions.clear'),
              type: 'secondary',
              onClick: handleResetFilterModal
            },
            {
              children: <DoneIcon />,
              text: t('common:actions.apply'),
              type: 'primary',
              onClick: handleFilterModalAccept
            }
          ]}
        >
          {advancedFilters.form({
            formRef: $refFilter,
            onSubmit: handleFilterModal,
            activeFilters: activeFilters
          })}
        </DialogModal>
      )}
    </Toolbar>
  );
}

ViewTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  entityName: PropTypes.string,
  selected: PropTypes.array,
  actions: PropTypes.array,
  advancedFilters: PropTypes.shape({
    title: PropTypes.string,
    translationKey: PropTypes.string,
    form: PropTypes.func,
    getActiveFilters: PropTypes.func,
    handleAdd: PropTypes.func,
    handleDelete: PropTypes.func,
    showClearButton: PropTypes.bool
  }),
  toolbarClasses: PropTypes.object
};
