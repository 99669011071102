import { http } from './http';
import { apiHelper } from 'shared';

class HtmlTemplates {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/html-templates${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/html-templates/${queryString}`);
  }

  getById(id) {
    return http.get(`/html-templates/${id}`);
  }

  create(htmlTemplate) {
    return http.post('/html-templates', htmlTemplate);
  }

  delete(id) {
    return http.delete(`/html-templates/${id}`);
  }

  update(id, htmlTemplate) {
    return http.put(`/html-templates/${id}`, htmlTemplate);
  }

  clone(id) {
    return http.post(`/html-templates/${id}`);
  }
}

export const htmlTemplates = new HtmlTemplates();
