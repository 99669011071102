import { useState, useCallback, useEffect } from 'react';
import { objectHelper } from './helper';
// TODO: tests

export function useStateWithBackup(initial) {
  const [state, setState] = useState(initial);
  const [stateBackup, setStateBackup] = useState(initial);

  useEffect(() => {
    setStateBackup(objectHelper.deepClone(state));
  }, [state]);

  const rollBack = useCallback(() => {
    setState(objectHelper.deepClone(stateBackup));
  }, [setState, stateBackup]);

  return [state, setState, rollBack];
}
