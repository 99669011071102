import { http } from './http';

class Configurations {
  get() {
    return http.get('/site-configurations');
  }

  getPublic() {
    return http.get('/site-configurations/public');
  }

  update(id, configuration) {
    return http.put(`/site-configurations/${id}`, configuration);
  }
}

export const configurations = new Configurations();
