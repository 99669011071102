import { SKELETON_KEY, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonQRCode() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    product: SKELETON_KEY,
    customUrl: SKELETON_KEY,
    scans: SKELETON_KEY_NUMBER
  };
}
