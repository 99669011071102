import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import { NAME_SPACE } from 'i18n';
import { AsyncInputAutocomplete, Form, Switch } from '@engloba-tech/englobity';
import { experiences } from 'services';
import { LocaleDatePicker } from 'shared';
import { useFormExportStyles } from './formExport.styles';
import { multilanguageExperiences } from 'services';

export function FormExport({ formRef, onSubmit, errors }) {
  const { t } = useTranslation(NAME_SPACE.TAGS);
  const classes = useFormExportStyles();
  const [inputs, setInputs] = useState({});
  const [multilanguageExperience, setMultilanguageExperience] = useState(false);

  function handleSubmit() {
    if (inputs.productId) {
      onSubmit(inputs);
    }
  }

  function handleExperienceAutocomplete(element) {
    setInputs(prevFilters => ({
      ...prevFilters,
      productId: element?.id,
      product: element?.name
    }));
  }

  function handleMultiLanguageSwitchChange() {
    setMultilanguageExperience(prev => !prev);
    setInputs(prevFilters => ({
      ...prevFilters,
      productId: '',
      product: ''
    }));
  }

  const handleChange =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target?.value?.format('YYYY-MM-DD') }));

  return (
    <Form className={classes.form} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off" errors={errors}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="div" variant="body2">
            {t('export.description')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Tooltip title={t('checkMultilanguageTooltip')} placement={'bottom'}>
            <div className={classes.tooltipWrapper}>
              <Switch
                label={multilanguageExperience ? t('checkMultilanguage') : t('checkSingle')}
                checked={multilanguageExperience || false}
                onChange={handleMultiLanguageSwitchChange}
              />
            </div>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <AsyncInputAutocomplete
            onChange={(e, element) => handleExperienceAutocomplete(element)}
            label={t('properties.product')}
            required
            variant="outlined"
            icon={<SearchIcon />}
            value={inputs.productId ? { name: inputs.product, id: inputs.productId } : null}
            defaultInputValue={inputs.product || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            requestAction={
              multilanguageExperience ? multilanguageExperiences.getUsingQueryFilter : experiences.getUsingQueryFilter
            }
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid item xs={12}>
          <LocaleDatePicker
            icon={<DateRangeIcon />}
            name={t('properties.requestDate')}
            label={t('properties.requestDate')}
            value={inputs.requestDate}
            onChange={handleChange('requestDate')}
            inputProps={{ 'aria-label': 'requestDate' }}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

FormExport.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object
};
