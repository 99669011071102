import { useState, useMemo, useCallback, useEffect } from 'react';
import { rowFilterHelper } from 'shared';

export const useArticlesBuildTable = (inputs, t) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: t('articles.properties.name')
      }
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs?.length
      ? []
      : inputs
      ? inputs.map(article => {
          return {
            id: article.id,
            name: article.name
          };
        })
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped);
  }, [inputs, inputs.length]);

  const getExperienceArticles = useCallback(
    (paging, sorting, filters) => {
      setRows(rowFilterHelper.getFilteredRows(initialRows, filters));
    },
    [initialRows]
  );

  return { headCells, rows, getExperienceArticles };
};
