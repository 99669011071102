import { makeStyles } from '@material-ui/core/styles';

export const useAnalyticsDashboardStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  report: {
    minHeight: 900,
    '& iframe': {
      border: 0,
      width: '100%',
      minHeight: 900
    }
  }
}));
