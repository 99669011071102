import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { MenuItem } from '@material-ui/core';
import { RATING_COMPLETED_RESULT_TYPE } from '../experienceTypes.const';
import { useRatingCompletedResultSelectStyles } from './ratingCompletedResultSelect.styles';

export function RatingCompletedResultSelect({ value = '', withAnyLabel, error, errorMessage, required, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useRatingCompletedResultSelectStyles();

  const elements = useMemo(() => {
    let el = [];
    if (withAnyLabel) el.push({ value: '', name: t('selectFirstOption') });

    for (const property in RATING_COMPLETED_RESULT_TYPE) {
      el.push({
        value: RATING_COMPLETED_RESULT_TYPE[property],
        name: t(`experiences:ratingCompletedResultTypes.${property.toLowerCase()}`)
      });
    }

    return el;
  }, [t, withAnyLabel]);

  return (
    <>
      <Input
        required={required}
        isLoading={isSkeletonLoading(value)}
        value={value?.toString()}
        select
        fullWidth
        validators={required && ['required']}
        errorMessages={required && [t('validations.required')]}
        {...props}
        className={classes.root}
      >
        {elements.map((element, index) => (
          <MenuItem key={index} value={element.value}>
            {element.name}
          </MenuItem>
        ))}
      </Input>
    </>
  );
}

RatingCompletedResultSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withAnyLabel: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool
};
