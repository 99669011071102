import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { ViewTable, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { NAME_SPACE } from 'i18n';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useViewBlockCountryStyles } from './viewBlockCountries.styles';
import { useBlockCountryBuildTable } from './useBlockCountryBuildTable';
import { FormCountry } from './FormCountry/FormCountry';
import { useViewTableToolbarStyles } from './viewTableToolbar.styles';
import { useViewTableStyles } from './viewTable.styles';
import { Grid } from '@material-ui/core';

export const ViewBlockCountries = ({ countries, onAddCountry, onDeleteCountry }) => {
  const classes = useViewBlockCountryStyles();
  const viewTableClasses = useViewTableStyles();
  const viewTableToolbarClasses = useViewTableToolbarStyles();
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const { rows, headCells, getBlockCountries } = useBlockCountryBuildTable(countries, t);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formCountryRef = useRef(null);

  function handleDetail() {
    handleOpen();
  }

  function handleModalAccept() {
    $formCountryRef.current.submit();
    handleClose();
  }

  function handleModalClose() {
    handleClose();
  }

  async function handleCountrySubmit(inputs) {
    if (inputs) {
      onAddCountry(inputs);
    }
  }

  async function handleDelete(countriesToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          countriesToDelete.length > 1
            ? t('countries.removeConfirm.message', { length: countriesToDelete.length })
            : t('countries.removeConfirm.singularMessage', {
                name: countriesToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.close'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });

      onDeleteCountry(countriesToDelete);
    } catch (err) {}
  }

  return (
    <div className={classes.legalBlockCountry}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} className={classes.contentContainer}>
          <ViewTable
            rows={rows}
            cells={headCells}
            mainKey={'name'}
            onFetchData={getBlockCountries}
            emptyText={t('countries.empty')}
            defaultPageSize={10}
            allowRowSelection={true}
            defaultOrderBy={{ property: 'name', direction: 'asc' }}
            rowHeight={40}
            classes={viewTableClasses}
            toolbar={{
              title: t('countries.legalBlockByCountry'),
              classes: viewTableToolbarClasses,
              actions: [
                {
                  condition: isAnySelected => !isAnySelected,
                  cb: handleDetail,
                  component: ButtonAdd
                },
                {
                  condition: isAnySelected => isAnySelected,
                  resetOnCB: true,
                  cb: handleDelete,
                  component: ButtonDelete
                }
              ]
            }}
          />
        </Grid>
      </Grid>

      <DialogModal
        maxWidth="sm"
        className={classes.modal}
        title={t('countries.dialogTitle')}
        description=""
        isOpen={isOpen}
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormCountry formRef={$formCountryRef} onSubmit={handleCountrySubmit} />
      </DialogModal>
    </div>
  );
};

ViewBlockCountries.propTypes = {
  countries: PropTypes.array.isRequired,
  onAddCountry: PropTypes.func.isRequired,
  onDeleteCountry: PropTypes.func.isRequired
};
