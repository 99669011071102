import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ImageList } from '@material-ui/core';
import { ImageTile } from './ImageTile';
import { useImageGalleryStyles } from './imageGallery.styles';
import { AddImageTile } from './AddImageTile';
import { isSkeletonLoading } from 'shared/skeletonFactory';

export const ImageGallery = ({
  images = [],
  readToken,
  onAddImageClick,
  onImageClick,
  onDeleteImageClick,
  tileMinHeight,
  tileMinWidth,
  rowHeight = 200,
  cols = 10,
  gap = 3,
  showAddImageTile = true,
  extraActions
}) => {
  const { t } = useTranslation('common');
  const classes = useImageGalleryStyles();
  const token = readToken || images.filter(i => i.readToken)[0]?.readToken;

  return (
    <ImageList cols={cols} rowHeight={rowHeight} gap={gap} className={classes.gallery}>
      {onAddImageClick && showAddImageTile && !isSkeletonLoading(images[0]?.blobUrl) && (
        <AddImageTile
          onClick={onAddImageClick}
          title={t('upload.dialogTitle')}
          cols={1}
          rows={1}
          tileMinHeight={tileMinHeight}
          tileMinWidth={tileMinWidth}
        />
      )}
      {images.map((image, index) => (
        <ImageTile
          image={image}
          key={index}
          readToken={token}
          onClick={onImageClick}
          cols={1}
          rows={1}
          tileMinHeight={tileMinHeight}
          tileMinWidth={tileMinWidth}
          extraActions={extraActions}
          onDeleteImageClick={onDeleteImageClick}
          canBeDeleted={image.canBeDeleted}
        />
      ))}
    </ImageList>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.array,
  readToken: PropTypes.string,
  onOpenAddFileDialog: PropTypes.func,
  onAddImageClick: PropTypes.func,
  onImageClick: PropTypes.func,
  onDeleteImageClick: PropTypes.func,
  tileMinHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tileMinWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cols: PropTypes.number,
  rows: PropTypes.number,
  rowHeight: PropTypes.number,
  gap: PropTypes.number,
  showAddImageTile: PropTypes.bool,
  extraActions: PropTypes.func
};
