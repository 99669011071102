import React from 'react';
import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NAME_SPACE } from 'i18n';
import { usContainerStyles } from 'layout/container.styles';
import { UnauthorizedSection } from './UnauthorizedSection';

export function Unauthorized({ messageKey }) {
  const classes = usContainerStyles();
  const { t } = useTranslation(NAME_SPACE.COMMON);

  return (
    <>
      <div className={classes.root}>
        <main className={classes.content}>
          <Paper elevation={1}></Paper>
          <div className={classes.pageContainer}>
            <UnauthorizedSection messageKey={messageKey} />
          </div>
        </main>
      </div>
    </>
  );
}

Unauthorized.propTypes = {
  messageKey: PropTypes.string
};
