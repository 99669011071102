import { makeStyles } from '@material-ui/core/styles';

export const useFormMultilanguageExperienceStyles = makeStyles(theme => ({
  formExperience: {
    flexGrow: 1
  },
  tabsMinHeight: {
    minHeight: '22.1875rem'
  },
  header: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  background: {
    width: '100%',
    height: '100%'
  },
  containers: {
    padding: '36px 24px 24px 24px',
    background: 'white',
    borderRadius: '4px'
  },
  secondContainer: {
    padding: '24px 16px 16px 16px',
    alignItems: 'start',
    '&.MuiGrid-spacing-xs-3': {
      width: 'unset',
      margin: 'unset'
    }
  }
}));
