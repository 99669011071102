import { useCallback } from 'react';
import { useViewTableData, useHandleRequest, skeletonFactory } from 'shared';
import { experiences } from 'services';

const experiencesDummy = skeletonFactory.experiences();

export function useExperiencesPaginated() {
  const {
    data: experiencesData,
    get: getExperiences,
    deleteElements: deleteExperiences,
    errorInfo: getDeleteErrorKey,
    setErrorInfo,
    setData,
    paging,
    sorting,
    undoData
  } = useViewTableData({
    service: experiences,
    paginatedSkeleton: experiencesDummy
  });
  const { request, errorInfo } = useHandleRequest();

  const copyExperience = useCallback(
    async experienceId => {
      try {
        await request(async () => {
          setData({ ...experiencesDummy });
          await experiences.clone(experienceId);
          await getExperiences(paging, sorting);
        });
      } catch (error) {
        undoData();
      }
    },
    [getExperiences, request, setData, paging, sorting, undoData]
  );

  return {
    experiences: experiencesData,
    errorInfo: getDeleteErrorKey,
    setErrorInfo,
    getExperiences,
    deleteExperiences,
    copyExperience
  };
}
