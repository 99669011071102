import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Select } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';
import { fontFamilyHelper } from 'pages/Configuration/Edit/ViewLegalBlock/ViewDefaultSection/fontFamily.helper';

export const FontSelect = ({ className, value, title, onChange }) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);

  const fontFamilies = useMemo(() => {
    return fontFamilyHelper.get(t, true);
  }, [t]);

  return (
    <Select
      isLoading={isSkeletonLoading(value)}
      value={value}
      elements={fontFamilies}
      name={title}
      onChange={onChange}
      title={title}
      className={className}
      fullWidth
    />
  );
};

FontSelect.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  fontFamily: PropTypes.string,
  ratingFullImageTag: PropTypes.string,
  ratingHalfImageTag: PropTypes.string,
  ratingEmptyImageTag: PropTypes.string,
  onChange: PropTypes.func,
  itemSizeLg: PropTypes.number,
  mediaFiles: PropTypes.array
};
