import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Paper, Typography, Grid, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { DialogModal, Summary, useHandleOpen } from '@engloba-tech/englobity';
import { useRequestLoading, execConfirmModal, ButtonAdd } from 'shared';
import { FormFileUpload, ImageGallery } from 'pages/shared';
import { useARMarkers } from './useARMarkers';
import { useListARMarkersStyles } from './listARMarkers.styles';

export function ListARMarkers() {
  const { t } = useTranslation(NAME_SPACE.AR_MARKERS);
  const { markers, createARMarkers, deleteARMarker, errorInfo, setErrorInfo } = useARMarkers();
  const classes = useListARMarkersStyles();
  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);
  const { callbackRequest } = useRequestLoading();
  const $formFileUploadRef = useRef(null);

  async function handleMediaFileUploadSubmit(selectedFiles) {
    if (selectedFiles && selectedFiles.length > 0) {
      await callbackRequest(async () => {
        handleModalCloseUpload();
        await createARMarkers(selectedFiles);
      });
    }
  }

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  function handleModalCloseUpload() {
    setErrorInfo({ message: '', detail: '' });
    handleCloseAddFile();
  }

  async function handleDelete(fileToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description: t('removeConfirm.singularMessage'),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });
      await deleteARMarker(fileToDelete);
    } catch (err) {}
  }

  return (
    <div className={classes.arMarkers}>
      <Summary severity={'error'} text={errorInfo.message} detail={errorInfo.detail} />
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {t('name')}
            </Typography>
            <ButtonAdd onClick={handleOpenAddFile} />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper elevation={0} className={classes.paper}>
        <ImageGallery
          images={markers}
          cols={10}
          rowHeight={180}
          gap={3}
          tileMinWidth={180}
          onDeleteImageClick={handleDelete}
        />
        <DialogModal
          title={t('common:upload.dialogTitle')}
          description=""
          fullWidth
          isOpen={isOpenAddFile}
          onClose={handleModalCloseUpload}
          buttons={[
            {
              children: <CloseIcon />,
              text: t('common:actions.cancel'),
              type: 'secondary',
              onClick: handleModalCloseUpload,
              className: classes.buttonDelete
            },
            {
              children: <DoneIcon />,
              text: t('common:actions.add'),
              type: 'primary',
              onClick: handleModalAcceptUpload,
              className: classes.buttonAdd
            }
          ]}
        >
          <FormFileUpload formRef={$formFileUploadRef} onSubmit={handleMediaFileUploadSubmit} accept="image/*" />
        </DialogModal>
      </Paper>
    </div>
  );
}
