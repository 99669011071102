import { makeStyles } from '@material-ui/core/styles';

export const usePasswordPolicyTooltipStyles = makeStyles(theme => ({
  tooltip: {
    maxWidth: '450px',
    fontSize: '0.8rem',
    backgroundColor: 'rgb(97 97 97)',
    margin: '0 36px',
    padding: '17px',
    '& ul': {
      paddingLeft: '20px'
    },
    '&.MuiTooltip-tooltipPlacementRight': {
      margin: '0 5px',
    }
  }
}));
