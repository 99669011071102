export const printCommands = editor => {
  editor.Commands.add('print-label', {
    run(editor) {
      var headContent = editor.Canvas.getDocument().head.innerHTML;
      var content = `<html style="margin: 0;padding: 0; display: block"><head>${headContent}<style>* { box-sizing: border-box; } ${editor.getCss(
        {
          avoidProtected: false
        }
      )}</style></head><body style="margin: 0;padding: 0; display: block">${editor.getHtml()}</body></html>`;

      var height = 600;
      var width = 750;
      var left = Number(window.screen.width + width / 4);
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${0}`;
      let w = window.open('', '', params);
      w.document.write(content);

      setTimeout(() => {
        w.print();
        w.close();
        return true;
      }, 500);
    }
  });
};
