import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getLanguage, LANGUAGE } from 'i18n';
import 'moment/locale/ca';
import 'moment/locale/es';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateTimePicker } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';

export function LocaleDatePicker({ onChange, name, ...props }) {
  const [locale, setLocale] = useState(LANGUAGE.ENGLISH);
  const { t } = useTranslation();
  useEffect(() => {
    const lng = getLanguage();
    moment.locale(lng);
    setLocale(lng);
  }, []);

  const handleChange = event => {
    return onChange && onChange({ target: { name: name, value: event.target.value } });
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
      <DateTimePicker
        icon={<DateRangeIcon />}
        {...props}
        showTodayButton={false}
        onChange={handleChange}
        todayLabel={t('actions.today')}
        clearLabel={t('actions.clear')}
        cancelLabel={t('actions.cancel')}
        okLabel={t('actions.accept')}
      />
    </MuiPickersUtilsProvider>
  );
}

LocaleDatePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired
};
