import { useCallback, useState } from 'react';
import { useHandleRequest } from './useHandleRequest';
import { useStateWithBackup } from './useStateWithBackup';
import { useTranslation } from 'react-i18next';
import { objectHelper } from 'shared';

export function useViewTableData({ service, paginatedSkeleton = {} }) {
  const { t } = useTranslation();
  const [data, setData, undoData] = useStateWithBackup(paginatedSkeleton); // should be paginated
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const [paging, setPaging] = useState();
  const [sorting, setSorting] = useState();
  const [filteredCells, setFilteredCells] = useState();
  const [advancedFilters, setAdvancedFilters] = useState();

  const get = useCallback(
    async (_paging, _sorting, _filteredCells, _advancedFilters) => {
      await request(async () => {
        setPaging(_paging);
        setSorting(_sorting);
        setFilteredCells(_filteredCells);
        setAdvancedFilters(_advancedFilters);

        if (!objectHelper.isEmpty(paginatedSkeleton) && _paging?.pageSize && paginatedSkeleton?.items?.length > 0) {
          setData({ ...paginatedSkeleton, items: Array(_paging?.pageSize).fill(paginatedSkeleton?.items[0]) });
        }

        const response = await service.get(_paging, _sorting, _filteredCells, _advancedFilters);
        if (response) {
          setData(response.data || []);
        }
      }, false);
    },
    [request, setData, service]
  );

  const exportElements = useCallback(
    async (headCells, fileName, advancedFilters) => {
      await request(async () => {
        const response = await service.export(sorting, filteredCells, advancedFilters);
        if (response.data) {
          generateAndDownloadCSV(headCells, response.data.items, fileName);
        }
      }, false);
    },
    [filteredCells, request, service, sorting]
  );

  const deleteElements = useCallback(
    async elementsToDelete => {
      await request(async () => {
        await Promise.allSettled(
          elementsToDelete.map(element => {
            setData(prevElement => {
              prevElement.items = prevElement.items.map(w => {
                if (!elementsToDelete.find(o => o.id === w.id)) {
                  return w;
                }
                return paginatedSkeleton.items[0];
              });
              return { ...prevElement };
            });
            return service.delete(element.id);
          })
        ).then(responses => {
          if (responses.filter(resp => resp.status === 'rejected').length === responses.length) {
            undoData();
            const errorResponse = JSON.parse(responses[0].reason.response.data);
            setErrorInfo({
              message: errorResponse.message ? t(JSON.parse(responses[0].reason.response.data).message) : errorResponse
            });
          } else if (
            responses.some(resp => resp.status === 'rejected') &&
            responses.some(resp => resp.status === 'fulfilled')
          ) {
            get(paging, sorting, filteredCells, advancedFilters);
            setErrorInfo({
              message: `${t('deletionWithErrors')} ${t(
                JSON.parse(responses.find(resp => resp.status === 'rejected').reason.response.data).message
              )}`,
              severity: 'warning'
            });
          } else {
            get(paging, sorting, filteredCells, advancedFilters);
          }
        });
      });
    },
    [
      service,
      paginatedSkeleton,
      get,
      paging,
      request,
      setData,
      sorting,
      setErrorInfo,
      t,
      undoData,
      filteredCells,
      advancedFilters
    ]
  );

  const generateAndDownloadCSV = (headers, rows, fileName) => {
    const csvHeaders = [headers.map(cell => cell.label)].map(e => e.join(';'));
    const csvRows = rows
      .map(row => headers.map(cell => row[cell.id]))
      .map(e => e.join(';'))
      .join('\n');
    let csvContent = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvHeaders + '\n' + csvRows);
    var link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${fileName}_${new Date().toLocaleDateString()}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return {
    data,
    get,
    deleteElements,
    exportElements,
    errorInfo,
    setErrorInfo,
    setData,
    undoData,
    paging,
    sorting,
    filteredCells
  };
}
