import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { ROLES } from '../../../../../auth/roles.const';

export function RoleSelect({ value, withAnyLabel, ...props }) {
  const { t } = useTranslation(NAME_SPACE.USERS);

  const elements = useMemo(() => {
    let el = [];
    if (withAnyLabel) el.push({ value: -1, name: t('common:selectFirstOption') });

    for (const property in ROLES) {
      if (ROLES[property] !== ROLES.TAG_MANAGER) {
        el.push({ value: ROLES[property], name: t(ROLES[property]) });
      }
    }

    return el;
  }, [t, withAnyLabel]);

  return (
    <Select
      isLoading={isSkeletonLoading(ROLES[0]) || isSkeletonLoading(value)}
      {...props}
      value={value}
      elements={elements}
    />
  );
}

RoleSelect.propTypes = {
  value: PropTypes.string,
  withAnyLabel: PropTypes.bool
};
