import React from 'react';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MemoryIcon from '@material-ui/icons/Memory';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import GradientIcon from '@material-ui/icons/Gradient';
import NfcIcon from '@material-ui/icons/Nfc';
import StyleIcon from '@material-ui/icons/Style';
import SettingsIcon from '@material-ui/icons/Settings';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CodeSharpIcon from '@material-ui/icons/CodeSharp';

import { PATH } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { ACCESS } from 'auth';
import { PLAN_ACCESS } from 'auth';

function generate(t) {
  return [
    {
      name: t(`${NAME_SPACE.ANALYTICS}:name`),
      icon: <NfcIcon />,
      access: ACCESS.ANALYTICS,
      planAccess: PLAN_ACCESS.BASIC,
      url: PATH.ANALYTICS.DASHBOARD
    },
    {
      name: t(`${NAME_SPACE.EXPERIENCES}:name`),
      icon: <ViewQuiltOutlinedIcon />,
      access: ACCESS.EXPERIENCES,
      paths: [
        {
          name: t(`${NAME_SPACE.EXPERIENCES}:name`),
          icon: <ViewQuiltOutlinedIcon />,
          access: ACCESS.EXPERIENCES,
          planAccess: PLAN_ACCESS.BASIC,
          url: PATH.EXPERIENCES.LIST
        },
        {
          name: t(`${NAME_SPACE.MULTILANGUAGE_EXPERIENCES}:shortName`),
          icon: <ViewQuiltOutlinedIcon />,
          access: ACCESS.EXPERIENCES,
          planAccess: PLAN_ACCESS.BASIC,
          url: PATH.MULTILANGUAGE_EXPERIENCES.LIST
        },
        {
          name: t(`${NAME_SPACE.HTML_TEMPLATES}:name`),
          icon: <CodeSharpIcon />,
          access: ACCESS.HTML_TEMPLATES,
          planAccess: PLAN_ACCESS.STANDARD,
          url: PATH.HTML_TEMPLATES.LIST
        },
        {
          name: t(`${NAME_SPACE.STICKERS}:name`),
          icon: <StyleIcon />,
          access: ACCESS.STICKERS,
          planAccess: PLAN_ACCESS.BASIC,
          url: PATH.STICKERS.LIST
        },
        {
          name: t(`${NAME_SPACE.MEDIA_FILES}:name`),
          icon: <PhotoLibraryIcon />,
          access: ACCESS.MEDIA_FILES,
          planAccess: PLAN_ACCESS.BASIC,
          url: PATH.MEDIA_FILES.LIST
        }
      ]
    },
    {
      name: t(`${NAME_SPACE.TAGS}:name`),
      icon: <MemoryIcon />,
      access: ACCESS.TAGS,
      planAccess: PLAN_ACCESS.STANDARD,
      url: PATH.TAGS.LIST
    },
    {
      name: t(`${NAME_SPACE.QR_CODES}:name`),
      icon: <GradientIcon />,
      access: ACCESS.QR_CODES,
      planAccess: PLAN_ACCESS.BASIC,
      url: PATH.QR_CODES.LIST
    },
    {
      name: t(`${NAME_SPACE.AR_MARKERS}:name`),
      icon: <FilterCenterFocusIcon />,
      access: ACCESS.MARKERS,
      planAccess: PLAN_ACCESS.ADVANCED,
      url: PATH.AR_MARKERS.LIST
    },
    {
      name: t('navigation.configuration'),
      icon: <SettingsIcon />,
      access: ACCESS.CONFIGURATION,
      planAccess: PLAN_ACCESS.BASIC,
      url: PATH.CONFIGURATION.EDIT
    },
    {
      name: t('navigation.user'),
      icon: <AccountCircle />,
      access: ACCESS.USERS,
      planAccess: PLAN_ACCESS.BASIC,
      url: PATH.USERS.LIST
    }
  ];
}

const _guidRegex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/;

function highlighted(navigations, pathname) {
  return navigations.map(navigation => {
    if (navigation.paths) {
      navigation.paths = navigation.paths
        ? navigation.paths.map(path => {
            const cleanPath = pathname.replace(_guidRegex, '').slice(0, -2);
            if (pathname !== '/' && path.url.includes(cleanPath)) {
              if (navigation.prefixUrlName) {
                if (pathname.includes(navigation.prefixUrlName)) {
                  navigation.open = true;
                } else {
                  navigation.open = false;
                }
              } else {
                navigation.open = true;
              }
              if (path.highlightedOnlyBy) {
                const pathnameWitGuidReplaced = pathname.replace(_guidRegex, ':id');
                path.highlighted = path.highlightedOnlyBy.some(path => path === pathnameWitGuidReplaced);
              } else {
                path.highlighted = true;
              }
            }
            return path;
          })
        : null;
    } else {
      const cleanPath = pathname.replace(_guidRegex, '').slice(0, -2);
      navigation.highlighted = pathname !== '/' && navigation.url && navigation.url.includes(cleanPath);
    }
    return navigation;
  });
}

function byAccess(navigations, access) {
  if (access) {
    return navigations.map(navigation => {
      navigation.show = access & navigation.access;
      navigation.paths = navigation.paths
        ? navigation.paths.map(path => {
            path.show = access & path.access;
            return path;
          })
        : null;

      return navigation;
    });
  }

  return navigations;
}

export const navigationHelper = {
  generate,
  highlighted,
  byAccess
};
