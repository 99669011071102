import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NAME_SPACE } from 'i18n';
import { useUnauthorizedSectionStyles } from './unauthorizedSection.styles';

export function UnauthorizedSection({ messageKey }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useUnauthorizedSectionStyles();
  return (
    <div className={classes.container}>
      <Typography
        gutterBottom
        component="h3"
        variant="h1"
        style={{ fontWeight: 'bold', fontFamily: 'Mulish', fontSize: '30px', textAlign: 'center' }}
      >
        {403} 🔒
      </Typography>
      <Typography
        gutterBottom
        component="h3"
        variant="h1"
        style={{ fontFamily: 'Mulish', fontSize: '30px', textAlign: 'center' }}
      >
        {messageKey ? t(messageKey) : t('permissions.forbidden')}
      </Typography>
    </div>
  );
}

UnauthorizedSection.propTypes = {
  messageKey: PropTypes.string
};
