export const PATH = {
  HOME: '/',
  AUTHENTICATION: {
    LOGIN: '/login'
  },
  ANALYTICS: {
    DASHBOARD: '/scans'
  },
  EXPERIENCES: {
    LIST: '/experiences',
    EDIT: '/experience/:id',
    CREATE: '/experience'
  },
  MULTILANGUAGE_EXPERIENCES: {
    LIST: '/multilanguage-experiences',
    EDIT: '/multilanguage-experience/:id',
    CREATE: '/multilanguage-experience'
  },
  TAGS: {
    LIST: '/tags'
  },
  QR_CODES: {
    LIST: '/qr-codes'
  },
  STICKERS: {
    LIST: '/stickers',
    EDIT: '/sticker/:id',
    CREATE: '/sticker'
  },
  HTML_TEMPLATES: {
    LIST: '/html-templates',
    EDIT: '/html-template/:id',
    CREATE: '/html-template'
  },
  MEDIA_FILES: {
    LIST: '/media'
  },
  AR_MARKERS: {
    LIST: '/ar-markers'
  },
  USERS: {
    LIST: '/users',
    EDIT: '/user/:id',
    CREATE: '/user',
    PROFILEEDIT: '/profileuser/:id'
  },
  CONFIGURATION: {
    EDIT: '/configuration'
  },
  ERROR: '/error',
  HEALTH: '/health'
};

export const ACADEMY_PATH = {
  '/scans': '/documentation/data-analytics/',
  '/experience': '/documentation/experiences/',
  '/multilanguage-experience': '/documentation/multi-languages/',
  '/tags': '/documentation/tags/',
  '/qr-codes': '/documentation/qr-codes/',
  '/sticker': '/documentation/stickers/',
  '/html-template': '/documentation/html-templates/',
  '/media': '/documentation/media-library/',
  '/ar-markers': '/documentation/ar-markers/',
  '/user': '/documentation/users/',
  '/profileuser': '/documentation/users/#user-profile',
  '/configuration': '/documentation/configuration/'
};
