import { useCallback, useState } from 'react';

const NAVIGATION_STORAGE = {
  NAV: 'NAV_OPEN'
};

export function useNavigationStorage() {
  const [isNavOpenisOpen, setIsNavOpenisOpen] = useState(
    JSON.parse(localStorage.getItem(NAVIGATION_STORAGE.NAV) || false)
  );

  const toggleNavOpen = useCallback(() => {
    setIsNavOpenisOpen(prev => {
      localStorage.setItem(NAVIGATION_STORAGE.NAV, !prev);
      return !prev;
    });
  }, []);

  return { isNavOpenisOpen, toggleNavOpen };
}
