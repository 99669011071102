import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  Error,
  Unauthorized,
  ListUsers,
  EditUser,
  Login,
  AnalyticsDashboard,
  ListExperiences,
  EditExperience,
  ListARMarkers,
  ListQRCodes,
  ListTags,
  ListStickers,
  EditSticker,
  EditConfiguration,
  ListMediaFiles,
  ListHtmlTemplates,
  EditHtmlTemplate,
  ListMultilanguageExperiences,
  EditMultilanguageExperience
} from './pages';

import { Container } from 'layout';
import { PATH } from './app.routes.const';
import { ACCESS } from 'auth';
import { AccountContainer } from 'layout';
import { PLAN_ACCESS } from 'auth';

const _routes = ({ isLogged, user }) => {
  const GuardedRoute = ({ component: Component, access, planAccess, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          isLogged === true ? (
            !access || (user && user.access & access) ? (
              !planAccess || user.planAccess & planAccess ? (
                <Component {...props} />
              ) : (
                <Unauthorized messageKey={'permissions.forbiddenByPlan'} />
              )
            ) : (
              <Unauthorized />
            )
          ) : (
            <Redirect to={`${PATH.AUTHENTICATION.LOGIN}?returnUrl=${rest.location.pathname}`} />
          )
        }
      />
    );
  };

  return (
    <Switch>
      {/* Health check page */}
      <Route exact path={PATH.HEALTH} component={() => <h3>Health page</h3>} />
      <Route path={PATH.ERROR} component={Error} />
      <Route exact path={PATH.AUTHENTICATION.LOGIN} component={AccountContainer(<Login />)} />

      {/* Analytics */}
      <GuardedRoute
        exact
        path={PATH.ANALYTICS.DASHBOARD}
        access={ACCESS.ANALYTICS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<AnalyticsDashboard />)}
      />

      {/* AR Markers */}
      <GuardedRoute
        exact
        path={PATH.AR_MARKERS.LIST}
        access={ACCESS.MARKERS}
        planAccess={PLAN_ACCESS.ADVANCED}
        component={Container(<ListARMarkers />)}
      />

      {/* Configuration */}
      <GuardedRoute
        exact
        path={PATH.CONFIGURATION.EDIT}
        access={ACCESS.CONFIGURATION}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditConfiguration />)}
      />

      {/* Experiences */}
      <GuardedRoute
        exact
        path={PATH.EXPERIENCES.LIST}
        access={ACCESS.EXPERIENCES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<ListExperiences />)}
      />
      <GuardedRoute
        exact
        path={PATH.EXPERIENCES.CREATE}
        access={ACCESS.EXPERIENCES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditExperience />)}
      />
      <GuardedRoute
        exact
        path={PATH.EXPERIENCES.EDIT}
        access={ACCESS.EXPERIENCES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditExperience />)}
      />

      {/* Html Templates */}
      <GuardedRoute
        exact
        path={PATH.HTML_TEMPLATES.LIST}
        access={ACCESS.HTML_TEMPLATES}
        planAccess={PLAN_ACCESS.STANDARD}
        component={Container(<ListHtmlTemplates />)}
      />
      <GuardedRoute
        exact
        path={PATH.HTML_TEMPLATES.CREATE}
        access={ACCESS.HTML_TEMPLATES}
        planAccess={PLAN_ACCESS.STANDARD}
        component={Container(<EditHtmlTemplate />)}
      />
      <GuardedRoute
        exact
        path={PATH.HTML_TEMPLATES.EDIT}
        access={ACCESS.HTML_TEMPLATES}
        planAccess={PLAN_ACCESS.STANDARD}
        component={Container(<EditHtmlTemplate />)}
      />

      {/* Media Files */}
      <GuardedRoute
        exact
        path={PATH.MEDIA_FILES.LIST}
        access={ACCESS.MEDIA_FILES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<ListMediaFiles />)}
      />

      {/* Multilanguage Experiences */}
      <GuardedRoute
        exact
        path={PATH.MULTILANGUAGE_EXPERIENCES.LIST}
        access={ACCESS.MULTILANGUAGE_EXPERIENCES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<ListMultilanguageExperiences />)}
      />
      <GuardedRoute
        exact
        path={PATH.MULTILANGUAGE_EXPERIENCES.CREATE}
        access={ACCESS.MULTILANGUAGE_EXPERIENCES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditMultilanguageExperience />)}
      />
      <GuardedRoute
        exact
        path={PATH.MULTILANGUAGE_EXPERIENCES.EDIT}
        access={ACCESS.MULTILANGUAGE_EXPERIENCES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditMultilanguageExperience />)}
      />

      {/* QR Codes */}
      <GuardedRoute
        exact
        path={PATH.QR_CODES.LIST}
        access={ACCESS.QR_CODES}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<ListQRCodes />)}
      />

      {/* Stickers */}
      <GuardedRoute
        exact
        path={PATH.STICKERS.LIST}
        access={ACCESS.STICKERS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<ListStickers />)}
      />
      <GuardedRoute
        exact
        path={PATH.STICKERS.CREATE}
        access={ACCESS.STICKERS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditSticker />)}
      />
      <GuardedRoute
        exact
        path={PATH.STICKERS.EDIT}
        access={ACCESS.STICKERS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditSticker />)}
      />

      {/* Tags */}
      <GuardedRoute
        exact
        path={PATH.TAGS.LIST}
        access={ACCESS.TAGS}
        planAccess={PLAN_ACCESS.STANDARD}
        component={Container(<ListTags />)}
      />

      {/* Users */}
      <GuardedRoute
        exact
        path={PATH.USERS.LIST}
        access={ACCESS.USERS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<ListUsers />)}
      />
      <GuardedRoute
        exact
        path={PATH.USERS.CREATE}
        access={ACCESS.USERS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditUser />)}
      />
      <GuardedRoute
        exact
        path={PATH.USERS.EDIT}
        access={ACCESS.USERS}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditUser />)}
      />
      <GuardedRoute
        exact
        path={PATH.USERS.PROFILEEDIT}
        planAccess={PLAN_ACCESS.BASIC}
        component={Container(<EditUser profile />)}
      />

      {user && user.access & ACCESS.ANALYTICS ? (
        <Redirect from={PATH.HOME} to={PATH.ANALYTICS.DASHBOARD} />
      ) : user && user.access & ACCESS.EXPERIENCES ? (
        <Redirect from={PATH.HOME} to={PATH.EXPERIENCES.LIST} />
      ) : user && user.access & ACCESS.TAGS ? (
        <Redirect from={PATH.HOME} to={PATH.TAGS.LIST} />
      ) : user && user.access & ACCESS.USERS ? (
        <Redirect from={PATH.HOME} to={PATH.USERS.LIST} />
      ) : (
        <Redirect from={PATH.HOME} to={PATH.AUTHENTICATION.LOGIN} />
      )}
    </Switch>
  );
};

export const Routes = React.memo(_routes);

_routes.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  user: PropTypes.object
};
