import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { ChipInput, DialogModal, Input, Select, useHandleOpen } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useViewArticleRatingItemStyles } from './viewArticleRatingItem.styles';
import { ARTICLE_RATING_TYPE } from 'pages/Experiences/Edit/shared';
import { FormOption } from './FormOption';

export function ViewArticleRatingItem({
  ratingItem,
  ratingTypes,
  mediaFiles,
  onChange,
  onDelete,
  onAddOption,
  onDeleteOption,
  onEditOption,
  disabled
}) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewArticleRatingItemStyles();
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const $formOptionRef = useRef(null);

  const handleChange = fieldName => event => {
    onChange(fieldName, { ratingItemId: ratingItem.id })(event);
  };

  const handleAddOption = optionName => {
    onAddOption(ratingItem.id, optionName);
  };

  const handleDeleteOption = option => {
    if (option) {
      onDeleteOption(ratingItem.id, option.id);
    }
  };

  const handleClickOption = option => {
    setSelectedOption({ ...option });
    handleOpen();
  };

  function handleModalAccept() {
    $formOptionRef.current.submit();
  }

  function handleModalClose() {
    handleClose();
  }

  function handleRatingItemSubmit(option) {
    onEditOption(ratingItem.id, option);
    handleClose();
  }

  const optionRenderer = ({ chip: option, className, handleDelete }, key) => (
    <Chip
      className={className}
      key={key}
      label={`${option.name} (${option.reference || '-'})`}
      onClick={() => handleClickOption(option)}
      onDelete={handleDelete}
    />
  );

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Input
            isLoading={isSkeletonLoading(ratingItem.name)}
            required
            disabled={disabled}
            name={t('articleRatings.properties.name')}
            label={t('articleRatings.properties.name')}
            variant="outlined"
            value={ratingItem.name || ''}
            onChange={handleChange('name')}
            inputProps={{ 'aria-label': t('articles.properties.name') }}
            validators={['required', 'maxStringLength:100']}
            errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 100 })]}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Input
            isLoading={isSkeletonLoading(ratingItem.reference)}
            required
            disabled={disabled}
            name={t('articleRatings.properties.reference')}
            label={t('articleRatings.properties.reference')}
            variant="outlined"
            value={ratingItem.reference || ''}
            onChange={handleChange('reference')}
            inputProps={{ 'aria-label': t('articles.properties.reference') }}
            validators={['required', 'maxStringLength:100']}
            errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 100 })]}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={6}>
          <Select
            isLoading={isSkeletonLoading(ratingItem.type)}
            disabled={disabled}
            elements={ratingTypes}
            value={ratingItem.type?.toString() || ''}
            name={t('articleRatings.properties.type')}
            required
            onChange={handleChange('type')}
            title={t('articleRatings.properties.type')}
            validators={['required']}
            errorMessages={[t('validations.required')]}
            className={classes.select}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={3}>
          <Input
            isLoading={isSkeletonLoading(ratingItem.order)}
            required
            disabled={disabled}
            type="number"
            name={t('articleRatings.properties.order')}
            label={t('articleRatings.properties.order')}
            variant="outlined"
            value={ratingItem.order || ''}
            fullWidth
            onChange={handleChange('order')}
            inputProps={{ 'aria-label': t('articleRatings.properties.order') }}
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid item xs={3} sm={2} md={1} lg={1} className={classes.actionsContainer}>
          <Tooltip title={t('actions.remove')}>
            <IconButton
              disabled={disabled}
              onClick={evt => {
                evt.stopPropagation();
                onDelete(ratingItem);
              }}
              aria-label={t('actions.remove')}
              style={{ fontSize: '0.5rem' }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
        {ratingItem.type === ARTICLE_RATING_TYPE.SELECTOR && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChipInput
              chipRenderer={optionRenderer}
              isLoading={isSkeletonLoading(ratingItem.options && ratingItem.options[0])}
              label={t(
                ratingItem.options?.length > 0
                  ? 'articleRatings.properties.optionsWithExplanation'
                  : 'articleRatings.properties.options'
              )}
              required
              value={ratingItem.options || []}
              onAdd={handleAddOption}
              onDelete={option => !disabled && handleDeleteOption(option)}
              validators={['required']}
              errorMessages={[t('validations.required')]}
              className={classes.chip}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
      <DialogModal
        className={classes.modal}
        maxWidth="md"
        title={t('articleRatingOptions.dialogTitle')}
        description=""
        isOpen={isOpen}
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          !disabled && {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormOption
          option={selectedOption}
          ratingItem={ratingItem}
          formRef={$formOptionRef}
          onSubmit={handleRatingItemSubmit}
          onChange={handleChange}
          mediaFiles={mediaFiles}
          disabled={disabled}
        />
      </DialogModal>
    </>
  );
}

ViewArticleRatingItem.propTypes = {
  ratingItem: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    reference: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array
  }).isRequired,
  ratingTypes: PropTypes.array,
  mediaFiles: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired,
  onDeleteOption: PropTypes.func.isRequired,
  onEditOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
