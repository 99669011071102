export const basicStyles = editor => {
  const styleManager = editor.StyleManager;
  styleManager.addSector('style-general', {
    name: 'Positioning',
    open: false,
    buildProps: [
      'float',
      'display',
      'box-sizing',
      'position',
      'top',
      'right',
      'left',
      'bottom',
      'overflow',
      'overflow-x',
      'overflow-y'
    ],
    properties: [
      {
        name: 'Box sizing',
        property: 'box-sizing',
        type: 'select',
        defaults: 'content-box',
        list: [
          { value: 'content-box', name: 'content box' },
          { value: 'border-box', name: 'border box' },
          { value: 'inherit', name: 'inherit' },
          { value: 'initial', name: 'initial' },
          { value: 'revert', name: 'revert' },
          { value: 'unset', name: 'unset' }
        ]
      }
    ]
  });
  styleManager.addSector('style-dimension', {
    name: 'Dimension',
    open: false,
    // buildProps: ['width', 'height', 'min-width', 'max-width', 'min-height', 'max-height', 'margin', 'padding'],
    properties: [
      {
        name: 'Width',
        type: 'integer',
        units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
        property: 'width',
        defaults: 'auto'
      },
      {
        name: 'Height',
        type: 'integer',
        units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
        property: 'height',
        defaults: 'auto'
      },
      {
        name: 'Min width',
        type: 'integer',
        units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
        property: 'min-width',
        defaults: 'auto'
      },
      {
        name: 'Max width',
        type: 'integer',
        units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
        property: 'max-width',
        defaults: 'auto'
      },
      {
        name: 'Min height',
        type: 'integer',
        units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
        property: 'min-height',
        defaults: 'auto'
      },
      {
        name: 'Max height',
        type: 'integer',
        units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
        property: 'max-height',
        defaults: 'auto'
      },
      {
        name: 'Margin',
        type: 'composite',
        property: 'margin',
        properties: [
          {
            name: 'Margin top',
            property: 'margin-top',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          },
          {
            name: 'Margin right',
            property: 'margin-right',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          },
          {
            name: 'Margin bottom',
            property: 'margin-bottom',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          },
          {
            name: 'Margin left',
            property: 'margin-left',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          }
        ]
      },
      {
        name: 'Padding',
        type: 'composite',
        property: 'padding',
        properties: [
          {
            name: 'Padding top',
            property: 'padding-top',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          },
          {
            name: 'Padding right',
            property: 'padding-right',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          },
          {
            name: 'Padding bottom',
            property: 'padding-bottom',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          },
          {
            name: 'Padding left',
            property: 'padding-left',
            type: 'integer',
            units: ['px', 'vw', 'vh', '%', 'cm', 'mm'],
            defaults: '0'
          }
        ]
      }
    ]
  });
  styleManager.addSector('style-typography', {
    name: 'Fonts',
    open: false,
    buildProps: [
      'font-family',
      'font-size',
      'font-weight',
      'letter-spacing',
      'color',
      'line-height',
      'text-align'
      // 'text-shadow'
    ],
    properties: [
      {
        property: 'text-align',
        list: [
          { value: 'left', className: 'fa fa-align-left' },
          { value: 'center', className: 'fa fa-align-center' },
          { value: 'right', className: 'fa fa-align-right' },
          { value: 'justify', className: 'fa fa-align-justify' }
        ]
      },
      {
        property: 'font-family',
        options: [
          { id: 'inherit', label: 'inherit' },
          { id: '"Abril Fatface"', label: 'Abril Fatface' },
          { id: 'Cinzel', label: 'Cinzel' },
          { id: '"Playfair Display"', label: 'Playfair Display' },
          { id: 'Roboto', label: 'Roboto' },
          { id: '"Roboto Slab"', label: 'Roboto Slab' },
          { id: 'monospace', label: 'Generic monospace' },
          { id: 'sans-serif', label: 'Generic sans-serif' },
          { id: 'serif', label: 'Generic serif' }
        ]
      }
      /* Doesn't map properly on load
      {
        property: 'text-shadow',
        properties: [
          { name: 'X position', property: 'text-shadow-h' },
          { name: 'Y position', property: 'text-shadow-v' },
          { name: 'Blur', property: 'text-shadow-blur' },
          { name: 'Color', property: 'text-shadow-color' }
        ]
      } */
    ]
  });
  styleManager.addSector('style-decorations', {
    name: 'Design Properties',
    open: false,
    buildProps: [
      'background-color',
      'background',
      'opacity',
      'border-radius-c',
      'border-radius',
      'border',
      'border-left',
      'border-right',
      'border-top',
      'border-bottom',
      'box-shadow'
    ],
    properties: [
      {
        type: 'slider',
        property: 'opacity',
        defaults: 1,
        step: 0.01,
        max: 1,
        min: 0
      },
      {
        name: 'Border left',
        property: 'border-left',
        type: 'composite',
        properties: [
          {
            name: 'Width',
            type: 'integer',
            units: ['px', 'em', 'rem'],
            property: 'border-left-width'
          },
          {
            name: 'Style',
            type: 'select',
            property: 'border-left-style',
            options: [
              { value: 'none' },
              { value: 'solid' },
              { value: 'dotted' },
              { value: 'dashed' },
              { value: 'double' },
              { value: 'groove' },
              { value: 'ridge' },
              { value: 'inset' },
              { value: 'outset' }
            ]
          },
          {
            name: 'Color',
            type: 'color',
            property: 'border-left-color'
          }
        ]
      },
      {
        name: 'Border right',
        property: 'border-right',
        type: 'composite',
        properties: [
          {
            name: 'Width',
            type: 'integer',
            units: ['px', 'em', 'rem'],
            property: 'border-right-width'
          },
          {
            name: 'Style',
            type: 'select',
            property: 'border-right-style',
            options: [
              { value: 'none' },
              { value: 'solid' },
              { value: 'dotted' },
              { value: 'dashed' },
              { value: 'double' },
              { value: 'groove' },
              { value: 'ridge' },
              { value: 'inset' },
              { value: 'outset' }
            ]
          },
          {
            name: 'Color',
            type: 'color',
            property: 'border-right-color'
          }
        ]
      },
      {
        name: 'Border top',
        property: 'border-top',
        type: 'composite',
        properties: [
          {
            name: 'Width',
            type: 'integer',
            units: ['px', 'em', 'rem'],
            property: 'border-top-width'
          },
          {
            name: 'Style',
            type: 'select',
            property: 'border-top-style',
            options: [
              { value: 'none' },
              { value: 'solid' },
              { value: 'dotted' },
              { value: 'dashed' },
              { value: 'double' },
              { value: 'groove' },
              { value: 'ridge' },
              { value: 'inset' },
              { value: 'outset' }
            ]
          },
          {
            name: 'Color',
            type: 'color',
            property: 'border-top-color'
          }
        ]
      },
      {
        name: 'Border bottom',
        property: 'border-bottom',
        type: 'composite',
        properties: [
          {
            name: 'Width',
            type: 'integer',
            units: ['px', 'em', 'rem'],
            property: 'border-bottom-width'
          },
          {
            name: 'Style',
            type: 'select',
            property: 'border-bottom-style',
            options: [
              { value: 'none' },
              { value: 'solid' },
              { value: 'dotted' },
              { value: 'dashed' },
              { value: 'double' },
              { value: 'groove' },
              { value: 'ridge' },
              { value: 'inset' },
              { value: 'outset' }
            ]
          },
          {
            name: 'Color',
            type: 'color',
            property: 'border-bottom-color'
          }
        ]
      }
    ]
  });
  styleManager.addSector('styles-flex', {
    name: 'Responsive Settings',
    open: false,
    properties: [
      {
        name: 'Flex Container',
        property: 'display',
        type: 'select',
        defaults: 'block',
        list: [
          { value: 'block', name: 'Disable' },
          { value: 'flex', name: 'Enable' }
        ]
      },
      {
        name: 'Flex Parent',
        property: 'label-parent-flex',
        type: 'integer'
      },
      {
        name: 'Flex Wrap',
        property: 'flex-wrap',
        type: 'select',
        defaults: 'nowrap',
        list: [
          { value: 'nowrap', name: 'No wrap' },
          { value: 'wrap', name: 'Wrap' },
          { value: 'wrap-reverse', name: 'Reverse' },
          { value: 'inherit', name: 'inherit' },
          { value: 'initial', name: 'initial' },
          { value: 'revert', name: 'revert' },
          { value: 'unset', name: 'unset' }
        ]
      },
      {
        name: 'Direction',
        property: 'flex-direction',
        type: 'radio',
        defaults: 'row',
        list: [
          {
            value: 'row',
            name: 'Row',
            className: 'icons-flex icon-dir-row',
            title: 'Row'
          },
          {
            value: 'row-reverse',
            name: 'Row reverse',
            className: 'icons-flex icon-dir-row-rev',
            title: 'Row reverse'
          },
          {
            value: 'column',
            name: 'Column',
            title: 'Column',
            className: 'icons-flex icon-dir-col'
          },
          {
            value: 'column-reverse',
            name: 'Column reverse',
            title: 'Column reverse',
            className: 'icons-flex icon-dir-col-rev'
          }
        ]
      },
      {
        name: 'Justify content',
        property: 'justify-content',
        type: 'radio',
        defaults: 'flex-start',
        list: [
          {
            value: 'flex-start',
            className: 'icons-flex icon-just-start',
            title: 'Start'
          },
          {
            value: 'flex-end',
            title: 'End',
            className: 'icons-flex icon-just-end'
          },
          {
            value: 'space-between',
            title: 'Space between',
            className: 'icons-flex icon-just-sp-bet'
          },
          {
            value: 'space-around',
            title: 'Space around',
            className: 'icons-flex icon-just-sp-ar'
          },
          {
            value: 'center',
            title: 'Center',
            className: 'icons-flex icon-just-sp-cent'
          }
        ]
      },
      {
        name: 'Align items',
        property: 'align-items',
        type: 'radio',
        defaults: 'center',
        list: [
          {
            value: 'flex-start',
            title: 'Start',
            className: 'icons-flex icon-al-start'
          },
          {
            value: 'flex-end',
            title: 'End',
            className: 'icons-flex icon-al-end'
          },
          {
            value: 'stretch',
            title: 'Stretch',
            className: 'icons-flex icon-al-str'
          },
          {
            value: 'center',
            title: 'Center',
            className: 'icons-flex icon-al-center'
          }
        ]
      },
      {
        name: 'Flex Children',
        property: 'label-parent-flex',
        type: 'integer'
      },
      {
        name: 'Order',
        property: 'order',
        type: 'integer',
        defaults: 0,
        min: 0
      },
      {
        name: 'Flex',
        property: 'flex',
        type: 'composite',
        properties: [
          {
            name: 'Grow',
            property: 'flex-grow',
            type: 'integer',
            defaults: 0,
            min: 0
          },
          {
            name: 'Shrink',
            property: 'flex-shrink',
            type: 'integer',
            defaults: 0,
            min: 0
          },
          {
            name: 'Basis',
            property: 'flex-basis',
            type: 'integer',
            units: ['px', '%', ''],
            unit: '',
            defaults: 'auto'
          }
        ]
      },
      {
        name: 'Align content',
        property: 'align-content',
        type: 'radio',
        defaults: 'center',
        list: [
          {
            value: 'flex-start',
            title: 'Start',
            className: 'icons-flex icon-al-start'
          },
          {
            value: 'flex-end',
            title: 'End',
            className: 'icons-flex icon-al-end'
          },
          {
            value: 'stretch',
            title: 'Stretch',
            className: 'icons-flex icon-al-str'
          },
          {
            value: 'center',
            title: 'Center',
            className: 'icons-flex icon-al-center'
          },
          {
            value: 'space-between',
            title: 'Space between',
            className: 'icons-flex icon-just-sp-bet'
          },
          {
            value: 'space-around',
            title: 'Space around',
            className: 'icons-flex icon-just-sp-ar'
          }
        ]
      },
      {
        name: 'Align self',
        property: 'align-self',
        type: 'radio',
        defaults: 'auto',
        list: [
          {
            value: 'auto',
            name: 'Auto'
          },
          {
            value: 'flex-start',
            title: 'Start',
            className: 'icons-flex icon-al-start'
          },
          {
            value: 'flex-end',
            title: 'End',
            className: 'icons-flex icon-al-end'
          },
          {
            value: 'stretch',
            title: 'Stretch',
            className: 'icons-flex icon-al-str'
          },
          {
            value: 'center',
            title: 'Center',
            className: 'icons-flex icon-al-center'
          }
        ]
      }
    ]
  });
  styleManager.addSector('style-extra', {
    name: 'Transform',
    open: false,
    buildProps: ['transition', 'perspective', 'transform'],
    properties: [
      {
        name: 'Object fit',
        property: 'object-fit',
        type: 'select',
        defaults: 'fill',
        list: [
          { value: 'none', name: 'none' },
          { value: 'contain', name: 'contain' },
          { value: 'cover', name: 'cover' },
          { value: 'fill', name: 'fill' },
          { value: 'scale-down', name: 'scale-down' },
          { value: 'inherit', name: 'inherit' },
          { value: 'initial', name: 'initial' },
          { value: 'revert', name: 'revert' },
          { value: 'unset', name: 'unset' }
        ]
      },
      {
        name: 'z-index',
        property: 'z-index',
        type: 'integer'
      }
    ]
  });
};
