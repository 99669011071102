import NProgress from 'nprogress';

let progress = 0;
NProgress.configure({ showSpinner: false });

export function moreProgress() {
  progress++;
  NProgress.start();
}

export function stopProgress() {
  progress = 0;
  NProgress.done();
}

export function lessProgress() {
  progress--;

  if (progress < 1) {
    setTimeout(stopProgress, 500);
  }
}
