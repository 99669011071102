import 'grapesjs-blocks-basic';
import parserPostCSS from 'grapesjs-parser-postcss';
import {
  qrCodePlugin,
  basicPlugin,
  printPlugin,
  paperPlugin,
  flexBoxPlugin,
  customCodePlugin,
  templatesPlugin
} from 'shared/CustomHtmlEditor/plugins';

export const viewContentConfiguration = {
  forceClass: false,
  height: '100%',
  width: '100%',
  fromElement: true,
  protectedCss: '',
  clearStyles: true,
  storageManager: { type: 0 },
  avoidInlineStyle: true,
  noticeOnUnload: 0,
  plugins: [
    paperPlugin,
    qrCodePlugin,
    flexBoxPlugin,
    'gjs-blocks-basic',
    parserPostCSS,
    customCodePlugin,
    basicPlugin,
    printPlugin,
    templatesPlugin
  ],
  pluginsOpts: {
    [flexBoxPlugin]: {
      addMediaQueries: false
    },
    'gjs-blocks-basic': {
      blocks: ['text', 'link', 'image', 'video', 'map']
    },
    [basicPlugin]: {
      modalImportLabel:
        '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
      modalImportContent: function (editor) {
        return editor.getHtml() + '<style>' + editor.getCss() + '</style>';
      }
    },
    [customCodePlugin]: {
      blockLabel: 'Custom Code',
      blockCustomCode: {
        category: 'Basic'
      }
    }
  },
  devices: [
    {
      name: 'A6',
      width: '105mm',
      widthMedia: ''
    },
    {
      name: 'A5',
      width: '148mm',
      widthMedia: ''
    },
    {
      name: 'A4',
      width: '210mm',
      widthMedia: ''
    }
  ],
  canvasStyles: [
    'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800;900&family=Abril+Fatface&family=Cinzel:wght@400;500;600;700;800;900&display=swap'
  ]
};
