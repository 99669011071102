import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAzureStorage, useHandleRequest } from 'shared';

export function useBrandFile(configurationId) {
  const { request, errorInfo: errorInfoFiles, setErrorInfo: setErrorInfoFiles } = useHandleRequest();
  const { uploadFile } = useAzureStorage(configurationId);

  const createFiles = useCallback(
    async selectedFile => {
      await request(async () => {
        if (selectedFile) {
          const fileId = uuidv4();
          const urlFile = await uploadFile({ ...selectedFile, fileId: fileId });
          selectedFile.blobUrl = urlFile;
        }
      });
    },
    [request, uploadFile]
  );

  return {
    errorInfoFiles,
    setErrorInfoFiles,
    createFiles
  };
}
