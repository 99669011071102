import { makeStyles } from '@material-ui/core/styles';
export const useFormAvatarStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1
  },
  container: {

  },
  picture: {
    width: '3rem',
    height: '3rem',
    fontSize: theme.font.size.xxl,
    marginRight: '10px'
  },
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  removeButton: {
    marginLeft: '10px'
  }
}));
