import qrious from 'qrious';
import { useState } from 'react';

export function useQRious() {
  const [dataUrl, setDataUrl] = useState();

  const getQRCode = ({ value, level = 'H', size = 1372, padding = 0, ...rest }) => {
    var qr = new qrious({
      value: value,
      level: level,
      size: size,
      padding: padding,
      ...rest
    });

    setDataUrl(qr.toDataURL());
  };

  return { dataUrl, getQRCode };
}
