import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useViewMediaFilesStyles = makeStyles(theme => ({
  mediaFiles: {
    flexGrow: 1,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  toolbar: {
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold !important',
    alignSelf: 'end'
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
