import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { NAME_SPACE } from 'i18n';
import { AsyncInputAutocomplete, Form, Input, Switch } from '@engloba-tech/englobity';
import { experiences } from 'services';
import { useFormTagRangeStyles } from './formTagRange.styles';
import { multilanguageExperiences } from 'services';
import { execConfirmModal } from 'shared';

export function FormTagRange({ formRef, onSubmit, errors }) {
  const { t } = useTranslation(NAME_SPACE.TAGS);
  const classes = useFormTagRangeStyles();
  const [inputs, setInputs] = useState({});
  const [multilanguageExperience, setMultilanguageExperience] = useState(false);

  async function confirmTamperMismatch() {
    try {
      await execConfirmModal({
        title: `⚠️ ${t('createRange.tamperMismatchWarningModal.title')}`,
        description: t('createRange.tamperMismatchWarningModal.description'),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel')
      });

      return true;
    } catch {
      return false;
    }
  }

  async function handleSubmit() {
    if (!inputs.productId) return;

    if (!inputs.tamper && inputs.productIsTamperType && !(await confirmTamperMismatch())) {
      return;
    }

    onSubmit(inputs);
  }

  async function handleExperienceAutocomplete(element) {
    try {
      setInputs(prevFilters => ({
        ...prevFilters,
        productId: element?.id,
        product: element?.name,
        productIsTamperType: element?.isTamperType
      }));
    } catch {}
  }

  function handleMultiLanguageSwitchChange() {
    setMultilanguageExperience(prev => !prev);
    setInputs(prevFilters => ({
      ...prevFilters,
      productId: '',
      product: '',
      productIsTamperType: false
    }));
  }

  const handleChange =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  const handleSwitchChange =
    fieldName =>
    ({ target }) => {
      setInputs(inputs => ({ ...inputs, [fieldName]: !inputs[fieldName] }));
    };

  return (
    <Form className={classes.form} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off" errors={errors}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="div" variant="body2">
            {t('createRange.description')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Tooltip title={t('checkMultilanguageTooltip')} placement={'bottom'}>
            <div>
              <Switch
                label={multilanguageExperience ? t('checkMultilanguage') : t('checkSingle')}
                checked={multilanguageExperience || false}
                onChange={handleMultiLanguageSwitchChange}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title={t('createRange.checkTamperTooltip')} placement={'bottom'}>
            <div>
              <Switch
                label={t('createRange.properties.tamper')}
                checked={inputs.tamper || false}
                onChange={handleSwitchChange('tamper')}
              />
            </div>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <AsyncInputAutocomplete
            onChange={(e, element) => handleExperienceAutocomplete(element)}
            label={t('createRange.properties.product')}
            required
            variant="outlined"
            icon={<SearchIcon />}
            value={inputs.productId ? { name: inputs.product, id: inputs.productId } : null}
            defaultInputValue={inputs.product || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            requestAction={
              multilanguageExperience ? multilanguageExperiences.getUsingQueryFilter : experiences.getUsingQueryFilter
            }
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            name={t('createRange.properties.amount')}
            label={t('createRange.properties.amount')}
            variant="outlined"
            type="number"
            value={inputs.amount || ''}
            onChange={handleChange('amount')}
            inputProps={{ 'aria-label': t('createRange.properties.amount') }}
            validators={['required', 'minNumber:1']}
            errorMessages={[
              t('validations.required'),
              t('validations.minNumber', { minimum: 1 })
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Switch
            label={t('createRange.properties.exportCSV')}
            checked={inputs.exportCSV || false}
            onChange={handleSwitchChange('exportCSV')}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

FormTagRange.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object
};
