import { SKELETON_KEY } from './skeletonFactory';

export function skeletonCountry() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY
  };
}

export function skeletonConfiguration(countriesNum = 10) {
  return {
    id: SKELETON_KEY,
    loginBackground: SKELETON_KEY,
    mainLogo: SKELETON_KEY,
    navigationLogo: SKELETON_KEY,
    ageCheckAllowed: SKELETON_KEY,
    authenticationAllowed: SKELETON_KEY,
    promotionalVideoAllowed: SKELETON_KEY,
    experienceInfoAllowed: SKELETON_KEY,
    experienceDefaultBackgroundColor: SKELETON_KEY,
    experienceDefaultFontColor: SKELETON_KEY,
    experienceDefaultFontFamily: SKELETON_KEY,
    experienceDefaultImage: SKELETON_KEY,
    experienceDefaultMessage: SKELETON_KEY,
    legalBlockCountries: Array(countriesNum).fill(skeletonCountry())
  };
}
