import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { ColorInput, FontSelect } from 'pages/shared';
import { ImageTagSelect, experienceHelper } from '../../shared';
import { useViewDefaultsStyles } from './viewDefaults.styles';

export function ViewDefaults({ experience, mediaFiles, onChange }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewDefaultsStyles();
  const templateSectionsUsed = experienceHelper.templateSectionsUsed(experience?.template);

  return (
    <Grid container spacing={3} item xs={12} className={classes.container}>
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          {t('legalBlock.defaultSection')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={4} className={classes.contentContainer}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ColorInput
                label={t('properties.experienceDefaultBackgroundColor')}
                name="experienceDefaultBackgroundColor"
                value={experience.experienceDefaultBackgroundColor}
                onChange={onChange('experienceDefaultBackgroundColor')}
                canBeCleared
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ColorInput
                label={t('properties.experienceDefaultFontColor')}
                name="experienceDefaultFontColor"
                value={experience.experienceDefaultFontColor}
                onChange={onChange('experienceDefaultFontColor')}
                canBeCleared
              />
            </Grid>
            {templateSectionsUsed.mediaFiles && (
              <Grid item xs={12} lg={6}>
                <ImageTagSelect
                  variant="outlined"
                  withAnyLabel
                  name={t('properties.experienceDefaultBackgroundImage')}
                  title={t('properties.experienceDefaultBackgroundImage')}
                  value={experience.experienceDefaultBackgroundImage}
                  onChange={onChange('experienceDefaultBackgroundImage')}
                  options={mediaFiles}
                  className={classes.select}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <FontSelect
                className={classes.select}
                value={experience.experienceDefaultFontFamily}
                title={t('properties.experienceDefaultFontFamily')}
                name={t('properties.experienceDefaultFontFamily')}
                onChange={onChange('experienceDefaultFontFamily')}
              />
            </Grid>
            {templateSectionsUsed.mediaFiles && (
              <Grid item xs={12} lg={6}>
                <ImageTagSelect
                  variant="outlined"
                  withAnyLabel
                  name={t('properties.experienceDefaultImage')}
                  title={t('properties.experienceDefaultImage')}
                  value={experience.experienceDefaultImage}
                  onChange={onChange('experienceDefaultImage')}
                  options={mediaFiles}
                  className={classes.select}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} style={{ height: '100%' }}>
            <Input
              isLoading={isSkeletonLoading(experience.experienceDefaultMessage)}
              name={t('properties.experienceDefaultMessage')}
              label={t('properties.experienceDefaultMessage')}
              value={experience.experienceDefaultMessage || ''}
              variant="outlined"
              multiline
              fullWidth
              className={classes.textarea}
              rows={templateSectionsUsed.mediaFiles ? 3 : 3}
              onChange={onChange('experienceDefaultMessage')}
              inputProps={{ 'aria-label': t('properties.experienceDefaultMessage') }}
              onKeyPress={e => {
                if (e.which === 13) {
                  e.stopPropagation();
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ViewDefaults.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string,
    experienceDefaultBackgroundColor: PropTypes.string,
    experienceDefaultFontColor: PropTypes.string,
    experienceDefaultFontFamily: PropTypes.string,
    experienceDefaultImage: PropTypes.string,
    experienceDefaultMessage: PropTypes.string,
    experienceDefaultBackgroundImage: PropTypes.string,
    template: PropTypes.object
  }),
  mediaFiles: PropTypes.array,
  onChange: PropTypes.func
};
