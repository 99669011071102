import axios from 'axios';

export const KEY_SETTINGS = {
  API_URL: 'API_URL',
  ENVIRONMENT: 'ENVIRONMENT',
  SITE_ID: 'SITE_ID', // TODO
  STORAGE_URL: 'STORAGE_URL',
  COMMON_STORAGE_URL: 'COMMON_STORAGE_URL',
  EXPERIENCE_APP_URL: 'EXPERIENCE_APP_URL',
  IDENTITY_URL: 'IDENTITY_URL',
  BRAND: 'BRAND',
  DASHBOARD_URL: 'DASHBOARD_URL',
  MAX_IMAGE_SIZE: 'MAX_IMAGE_SIZE',
  ACADEMY_URL: 'ACADEMY_URL'
};

class Settings {
  async loadSettings() {
    const isLocalHost = window.location.hostname.includes('localhost');
    const $SETTINGS_PATH = '/config/settings.json';
    const $HOST = isLocalHost ? window.location.host : window.location.hostname;
    const $URL_CONFIG = `${isLocalHost ? 'http' : 'https'}://${$HOST}${$SETTINGS_PATH}`;

    try {
      const { data } = await axios.get($URL_CONFIG);
      Object.keys(data).forEach(settingName => localStorage.setItem(settingName, data[settingName]));
    } catch (error) {
      throw error || 'Api error';
    }
  }

  async loadDockerSettings() {
    // TODO: New apis Docker
    const { REACT_APP_API_URL, REACT_APP_SITE_ID } = process.env;
    const data = { API_URL: REACT_APP_API_URL, SITE_ID: REACT_APP_SITE_ID };
    Object.keys(data).forEach(settingName => localStorage.setItem(settingName, data[settingName]));
  }

  getSetting(settingName) {
    return localStorage.getItem(settingName);
  }
}

export const settings = new Settings();
