import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useImageTileStyles = makeStyles(theme => ({
  tile: {
    padding: '5px !important',
    margin: '0px 2px',
    '& > div': {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  },
  fullTile: {
    height: '100%',
    width: '100%',
    border: 0,
    padding: '0px !important',
    '& > div': {
      border: 'none'
    }
  },
  imageContainer: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
    backgroundColor: '#fdfbf6',
    borderRadius: 5
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  imageTitle: {
    background: '#fdfbf6',
    flex: 1,
    height: 32,
    '& > div': {
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '5px'
    },
    '& > div > div:first-child': {
      color: theme.palette.basic.dark,
      fontSize: '10px',
      fontWeight: '500'
    },
    '& > div > div': {
      color: theme.palette.primary.main,
      fontSize: '12px'
    }
  },
  error: {
    '& > div': {
      border: '2px solid ' + color.action.error
    }
  },
  imageTitleTop: {
    '& > div': {
      marginLeft: '10px',
      marginRight: '5px',
      marginBottom: '0px'
    }
  },
  actionIconContainer: {
    display: 'flex',
    height: '1.5rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: theme.palette.primary.main
    },
    '& .MuiIconButton-root': {
      padding: 4
    },
    '& a': {
      alignSelf: 'center'
    }
  },
  addFileIcon: {
    marginRight: 5
  }
}));
