import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useFormUserStyles = makeStyles(theme => ({
  containers: {
    padding: '24px',
    background: 'white',
    borderRadius: '4px'
  },
  secondContainer: {
    alignItems: 'start',
    '&.MuiGrid-spacing-xs-3': {
      width: 'unset',
      margin: 'unset'
    }
  },
  picture: {
    width: '7rem',
    height: 'auto',
    fontSize: theme.font.size.xxl,
    '&.MuiAvatar-root': {
      height: '7rem'
    }
  },
  background: {
    width: '100%',
    height: '100%'
  },
  headerUser: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  pointer: {
    cursor: 'pointer'
  },
  marginLeft: {
    marginLeft: '1rem'
  },
  flexDirectionColumn: {
    flexDirection: 'column'
  },
  end: {
    alignItems: 'end'
  },
  icon: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    alignSelf: 'center'
  },
  hideIcon: {
    display: 'none'
  },
  hover: {
    '&:hover': {
      '& .MuiSvgIcon-root': {
        display: 'flex !important'
      }
    }
  },
  inputsContainer: {
    margin: '0 0 0.6rem 0'
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
