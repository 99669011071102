import { useEffect, useCallback, useState } from 'react';
import { skeletonFactory } from 'shared';
import { settings, KEY_SETTINGS } from 'settings';
import { useHandleRequest } from 'shared';
import { useAzureCommonStorage } from 'shared';

const MARKERS_CONTAINER = 'markers';

const markersDummy = skeletonFactory.arMarkers();

export function useARMarkers() {
  const [markersData, setMarkersData] = useState([]);
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { uploadFile, getFiles, deleteFile } = useAzureCommonStorage(MARKERS_CONTAINER);

  useEffect(() => {
    async function getData() {
      try {
        setMarkersData(markersDummy);
        const prefix = settings.getSetting(KEY_SETTINGS.BRAND);
        const files = await getFiles({ prefix: prefix });
        setMarkersData(files);
      } catch (error) {
        setMarkersData([]);
      }
    }
    getData();
  }, [request, getFiles]);

  const createARMarkers = useCallback(
    async selectedFiles => {
      await request(async () => {
        const prefix = settings.getSetting(KEY_SETTINGS.BRAND);
        for (let i = 0; i < selectedFiles.length; i++) {
          const uploadedFileUrl = await uploadFile({
            ...selectedFiles[i],
            prefix
          });
          setMarkersData(prevMarkers => [...prevMarkers, { blobUrl: uploadedFileUrl }]);
        }
      });
    },
    [request, uploadFile]
  );

  const deleteARMarker = useCallback(
    async ({ blobUrl }) => {
      try {
        await request(async () => {
          const prefix = settings.getSetting(KEY_SETTINGS.BRAND);
          await deleteFile({
            blobUrl,
            prefix
          });
          setMarkersData(prevMarkers => prevMarkers.filter(marker => marker.blobUrl !== blobUrl));
        }, true);
      } catch (error) {}
    },
    [request, deleteFile]
  );

  return {
    markers: markersData,
    errorInfo: errorInfo,
    setErrorInfo,
    createARMarkers,
    deleteARMarker
  };
}
