import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { APIS } from 'app.apis.const';
import { http } from './http';

class AzureStorage {
  async uploadFile({ content, mimeType, containerIdentifier, sasToken, fileId }) {
    const date = moment().utc().format('ddd, MMM D YYYY hh:mm A');
    const uuid = fileId || uuidv4();

    const headers = {
      'x-ms-content-type': mimeType,
      'Content-Type': mimeType,
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-version': '2018-03-28',
      'x-ms-date': date,
      Accept: '*/*'
    };

    await http.put(
      `/${containerIdentifier}/${uuid}?${sasToken}&api-version=2018-03-28`,
      content,
      { headers },
      APIS.AZURE_STORAGE,
      false
    );

    return `${http.getBaseUrl(APIS.AZURE_STORAGE)}/${containerIdentifier}/${uuid}`;
  }

  getSasToken(id) {
    return http.post(`/blob-containers/${id}/write-access-token`);
  }

  getReadSasToken(id) {
    return http.post(`/blob-containers/${id}/read-access-token`);
  }
}

export const azureStorage = new AzureStorage();
