import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Grid } from '@material-ui/core';
import { useViewHistoryStyles } from './viewHistory.styles';
import { ViewHistoryStat } from './ViewHistoryStat';

export const ViewHistory = ({ experience }) => {
  const classes = useViewHistoryStyles();
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);

  return (
    <div className={classes.history}>
      <Grid container>
        <Grid item xs={6} md={4}>
          <ViewHistoryStat title={t('history.creation')} user={experience.createdUser} date={experience.createdDate} />
        </Grid>
        <Grid item xs={6} md={4}>
          <ViewHistoryStat title={t('history.updated')} user={experience.updatedUser} date={experience.updatedDate} />
        </Grid>
        <Grid item xs={6} md={4}>
          <ViewHistoryStat title={t('history.publish')} user={experience.publishUser} date={experience.publishDate} />
        </Grid>
      </Grid>
    </div>
  );
};

ViewHistory.propTypes = {
  experience: PropTypes.shape({
    createdUser: PropTypes.string,
    createdDate: PropTypes.string,
    updatedUser: PropTypes.string,
    updatedDate: PropTypes.string,
    publishUser: PropTypes.string,
    publishDate: PropTypes.string
  })
};
