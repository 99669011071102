import { useState, useMemo, useCallback, useEffect } from 'react';
import { rowFilterHelper } from 'shared';

export const useFieldBuildTable = (inputs, t) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: t('fields.properties.title')
      },
      {
        id: 'value',
        numeric: false,
        disablePadding: true,
        label: t('fields.properties.value')
      },
      {
        id: 'order',
        numeric: false,
        disablePadding: true,
        label: t('fields.properties.order')
      }
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs?.length
      ? []
      : inputs
      ? inputs.map(field => {
          return {
            id: field.id,
            title: field.title,
            value: field.value,
            order: field.order
          };
        })
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped);
  }, [inputs, inputs.length]);

  const getExperienceFields = useCallback(
    (paging, sorting, filters) => {
      setRows(rowFilterHelper.getFilteredRows(initialRows, filters));
    },
    [initialRows]
  );

  return { headCells, rows, getExperienceFields };
};
