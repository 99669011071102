import { makeStyles } from '@material-ui/core/styles';

export const useDashboardStyles = makeStyles(theme => ({
  suppliers: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    margin: '2rem 0 1.5rem 0'
  },
  root: {
    width: '100%',
    backgroundColor: 'white'
  },
  report: {
    paddingTop: 10,
    minHeight: 900,
    '& iframe': {
      border: 0,
      width: '100%',
      minHeight: 900
    }
  }
}));
