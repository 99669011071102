import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { NAME_SPACE } from 'i18n';
import { useTranslation } from 'react-i18next';
import { AsyncInputAutocomplete, Input } from '@engloba-tech/englobity';
import { experiences } from 'services';
import { isSkeletonLoading } from 'shared';
import { useViewStickerStyles } from './viewSticker.styles';
import { ViewContent } from './ViewContent';
import { multilanguageExperiences } from 'services';

export function ViewSticker({ createMode, sticker, onChange, onExperienceAutocomplete }) {
  const { t } = useTranslation(NAME_SPACE.STICKERS);
  const classes = useViewStickerStyles();

  async function getExperiences(queryString) {
    const singleData = await experiences.getUsingQueryFilter(queryString);
    const multilanguageData = await multilanguageExperiences.getUsingQueryFilter(queryString);
    return [...singleData, ...multilanguageData];
  }

  return (
    <div className={classes.sticker}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Input
            isLoading={isSkeletonLoading(sticker.name)}
            required
            name={t('properties.name')}
            label={t('properties.name')}
            variant="outlined"
            value={sticker.name || ''}
            onChange={onChange('name')}
            inputProps={{ 'aria-label': t('properties.name') }}
            validators={['required', 'maxStringLength:255']}
            errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
          />
        </Grid>
        <Grid item xs={6}>
          <AsyncInputAutocomplete
            isLoading={isSkeletonLoading({
              name: sticker.product,
              id: sticker.productId
            })}
            onChange={(e, element) => onExperienceAutocomplete(element)}
            label={t('properties.product')}
            required
            variant="outlined"
            icon={<SearchIcon />}
            value={createMode ? null : { name: sticker?.product?.name, id: sticker.productId }}
            defaultInputValue={sticker.product?.name}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            requestAction={getExperiences}
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        </Grid>
        <Grid item xs={12} className={classes.editor}>
          <ViewContent
            content={sticker.content}
            mediaFiles={sticker.mediaFiles}
            readToken={sticker?.readToken}
            qrCodes={sticker.product?.qrCodes}
            onChange={onChange('content')}
          />
        </Grid>
      </Grid>
    </div>
  );
}

ViewSticker.propTypes = {
  createMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onExperienceAutocomplete: PropTypes.func,
  sticker: PropTypes.object // TODO
};
