import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Grid, Typography } from '@material-ui/core';
import { Input } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';
import {
  ImageTagSelect,
  RatingCompletedResultSelect,
  RATING_COMPLETED_RESULT_TYPE,
  ContentPageSelect
} from '../../shared';
import { FontSelect } from 'pages/shared';
import { ColorPicker } from 'shared';
import { Skeleton } from '@material-ui/lab';

export const ViewPageRatings = ({
  classes,
  mediaFiles,
  title,
  fontFamily,
  visible,
  titleFontColor,
  subtitleFontColor,
  ratingFullImageTag,
  ratingHalfImageTag,
  ratingEmptyImageTag,
  ratingCompletedResultType,
  ratingGratutideMessage,
  ratingShareButtonTitle,
  ratingShareTargetTemplatePageReference,
  pages,
  onChange,
  onChangePage,
  itemSizeLg
}) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6} style={{ order: 2 }}>
        <div className={classes.colorSelector}>
          <ColorPicker name="titleFontColor" color={titleFontColor || ''} onChange={onChangePage('titleFontColor')} />
          {!isSkeletonLoading(titleFontColor) ? (
            <Typography variant="subtitle2" component={'span'}>
              {t('pages.properties.titleFontColor')}
            </Typography>
          ) : (
            <Skeleton />
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} style={{ order: 2 }}>
        <div className={classes.colorSelector}>
          <ColorPicker
            name="subtitleFontColor"
            color={subtitleFontColor || ''}
            onChange={onChangePage('subtitleFontColor')}
          />
          {!isSkeletonLoading(subtitleFontColor) ? (
            <Typography variant="subtitle2" component={'span'}>
              {t('pages.properties.subtitleFontColor')}
            </Typography>
          ) : (
            <Skeleton />
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 4 }}>
        <Input
          isLoading={isSkeletonLoading(title)}
          name={t('pages.properties.title')}
          label={t('pages.properties.title')}
          variant="outlined"
          value={title || ''}
          onChange={onChangePage('title')}
          inputProps={{ 'aria-label': t('pages.properties.title') }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 4 }}>
        <FontSelect
          className={classes.select}
          value={fontFamily}
          title={t('pages.properties.fontFamily')}
          name={t('pages.properties.fontFamily')}
          onChange={onChangePage('fontFamily')}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 5 }}>
        <ImageTagSelect
          variant="outlined"
          withAnyLabel
          title={t('properties.ratingEmptyImageTag')}
          value={ratingEmptyImageTag}
          onChange={onChange('ratingEmptyImageTag')}
          options={mediaFiles}
          fullWidth
          className={classes.select}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 5 }}>
        <ImageTagSelect
          variant="outlined"
          withAnyLabel
          title={t('properties.ratingHalfImageTag')}
          value={ratingHalfImageTag}
          onChange={onChange('ratingHalfImageTag')}
          options={mediaFiles}
          fullWidth
          className={classes.select}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 6 }}>
        <ImageTagSelect
          variant="outlined"
          withAnyLabel
          title={t('properties.ratingFullImageTag')}
          value={ratingFullImageTag}
          onChange={onChange('ratingFullImageTag')}
          options={mediaFiles}
          fullWidth
          className={classes.select}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 7 }}>
        <RatingCompletedResultSelect
          name={t('properties.ratingCompletedResultType')}
          label={t('properties.ratingCompletedResultType')}
          withAnyLabel
          required={visible}
          value={ratingCompletedResultType}
          onChange={onChange('ratingCompletedResultType')}
        />
      </Grid>
      {ratingCompletedResultType === RATING_COMPLETED_RESULT_TYPE.GRATITUDE_MESSAGE && (
        <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 8 }}>
          <Input
            isLoading={isSkeletonLoading(ratingGratutideMessage)}
            name={t('properties.ratingGratutideMessage')}
            label={t('properties.ratingGratutideMessage')}
            variant="outlined"
            value={ratingGratutideMessage || ''}
            required
            multiline
            rows={2}
            onChange={onChange('ratingGratutideMessage')}
            inputProps={{ 'aria-label': t('properties.ratingGratutideMessage') }}
            validators={['required', 'maxStringLength:256']}
            errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 256 })]}
            onKeyPress={e => {
              if (e.which === 13) {
                e.stopPropagation();
              }
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 9 }}>
        <ContentPageSelect
          variant="outlined"
          withAnyLabel
          title={t('properties.ratingShareTargetTemplatePageReference')}
          value={ratingShareTargetTemplatePageReference || ''}
          onChange={onChange('ratingShareTargetTemplatePageReference')}
          options={pages}
          fullWidth
          className={classes.select}
        />
      </Grid>
      {ratingShareTargetTemplatePageReference && (
        <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 10 }}>
          <Input
            isLoading={isSkeletonLoading(title)}
            name={t('properties.ratingShareButtonTitle')}
            label={t('properties.ratingShareButtonTitle')}
            variant="outlined"
            value={ratingShareButtonTitle || ''}
            onChange={onChange('ratingShareButtonTitle')}
            inputProps={{ 'aria-label': t('properties.ratingShareButtonTitle') }}
            validators={['maxStringLength:100']}
            errorMessages={[t('common:validations.maxStringLength', { length: 100 })]}
          />
        </Grid>
      )}
    </>
  );
};

ViewPageRatings.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  title: PropTypes.string,
  fontFamily: PropTypes.string,
  titleFontColor: PropTypes.string,
  subtitleFontColor: PropTypes.string,
  ratingFullImageTag: PropTypes.string,
  ratingHalfImageTag: PropTypes.string,
  ratingEmptyImageTag: PropTypes.string,
  ratingCompletedResultType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratingGratutideMessage: PropTypes.string,
  ratingShareButtonTitle: PropTypes.string,
  ratingShareTargetTemplatePageReference: PropTypes.string,
  onChange: PropTypes.func,
  onChangePage: PropTypes.func,
  itemSizeLg: PropTypes.number,
  mediaFiles: PropTypes.array,
  pages: PropTypes.array
};
