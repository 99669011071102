import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Typography, Grid, Toolbar, Tooltip, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import LinkIcon from '@material-ui/icons/Link';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { useViewMediaFilesStyles } from './viewMediaFiles.styles';
import { ImageGallery } from './ImageGallery/ImageGallery';
import { FormMediaFile } from './FormMediaFile';
import { FormSelectMediaFiles } from 'pages/shared/MediaFiles';
import { ButtonAdd } from 'shared';

export const ViewMediaFiles = ({
  experience,
  onAddMediaFiles,
  onEditMediaFile,
  onDeleteMediaFile,
  errorMessage,
  successMessage,
  resetErrorAndSuccessMessages
}) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);

  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);

  const {
    isOpen: isOpenEditFile,
    handleClose: handleCloseEditFile,
    handleOpen: handleOpenEditFile
  } = useHandleOpen(false);
  const [selectedMediaFile, setSelectedMediaFile] = useState();

  const $formFileUploadRef = useRef(null);
  const $formFileEditRef = useRef(null);

  const classes = useViewMediaFilesStyles();

  function handleMediaFileUploadOpen() {
    handleOpenAddFile();
  }

  async function handleMediaFileUploadSubmit(selectedFiles, event) {
    if (selectedFiles) {
      onAddMediaFiles(selectedFiles);
      handleCloseAddFile();
    }
  }

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  async function handleMediaFileEditSubmit(inputs, event) {
    if (inputs.file) {
      inputs.mediaFileId = inputs.file.id;
    }
    onEditMediaFile(inputs);
  }

  function handleSelectFile(file) {
    setSelectedMediaFile(file);
    handleOpenEditFile();
  }

  function handleModalAcceptEdit() {
    $formFileEditRef.current.submit();
  }

  function handleModalCloseEdit() {
    resetErrorAndSuccessMessages();
    setSelectedMediaFile();
    handleCloseEditFile();
  }

  useEffect(() => {
    if (isOpenEditFile && successMessage) {
      handleModalCloseEdit();
    }
  }, [isOpenEditFile, successMessage]);

  return (
    <div className={classes.mediaFiles}>
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="h6">
              {t('mediaFiles.name')}
            </Typography>
            <ButtonAdd onClick={handleMediaFileUploadOpen} />
          </Grid>
        </Grid>
      </Toolbar>
      <div className={classes.contentWrapper}>
        <ImageGallery
          images={experience.mediaFiles}
          templateImages={experience.template?.images}
          readToken={experience.readToken}
          cols={9}
          rowHeight={150}
          gap={3}
          tileMinWidth={150}
          onImageClick={handleSelectFile}
          onDeleteImageClick={onDeleteMediaFile}
          extraActions={image => {
            return (
              <>
                {image?.targetLink && (
                  <Tooltip title={t('actions.link')}>
                    <Link
                      onClick={evt => evt.stopPropagation()}
                      href={image.targetLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                    >
                      <LinkIcon color="inherit" />
                    </Link>
                  </Tooltip>
                )}
              </>
            );
          }}
        />
      </div>

      <DialogModal
        title={t('common:actions.addFile')}
        description=""
        maxWidth="lg"
        fullWidth
        className={classes.galleryModal}
        isOpen={isOpenAddFile}
        onClose={handleCloseAddFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseAddFile,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptUpload,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormSelectMediaFiles formRef={$formFileUploadRef} onSubmit={handleMediaFileUploadSubmit} />
      </DialogModal>
      <DialogModal
        title={t('common:actions.editFile')}
        description=""
        fullWidth
        isOpen={isOpenEditFile}
        onClose={handleModalCloseEdit}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalCloseEdit,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptEdit,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormMediaFile
          formRef={$formFileEditRef}
          mediaFile={selectedMediaFile}
          onSubmit={handleMediaFileEditSubmit}
          successMessage={successMessage}
          errorMessage={errorMessage}
          resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
          readToken={experience.readToken}
        />
      </DialogModal>
    </div>
  );
};

ViewMediaFiles.propTypes = {
  experience: PropTypes.object.isRequired,
  onAddMediaFiles: PropTypes.func.isRequired,
  onEditMediaFile: PropTypes.func.isRequired,
  onDeleteMediaFile: PropTypes.func.isRequired,
  errorInfo: PropTypes.object,
  setErrorInfo: PropTypes.func,
  isDeleteEnabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
