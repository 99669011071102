export const paperCommands = editor => {
  editor.Commands.add('set-device-a6', {
    run: editor => editor.setDevice('A6'),
    stop() {}
  });

  editor.Commands.add('set-device-a5', {
    run: editor => editor.setDevice('A5'),
    stop() {}
  });

  editor.Commands.add('set-device-a4', {
    run: editor => editor.setDevice('A4'),
    stop() {}
  });
};
