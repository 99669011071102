export const shareContainer = editor => {
  editor.BlockManager.add('body-share-container', {
    category: 'Experience Utils',
    label: 'Share container',
    media:
      '<svg viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg"><path d="M 11.978 10.259 C 12.156 10.259 12.305 10.111 12.305 9.932 L 12.305 4.044 L 10.671 4.044 C 10.306 4.044 10.014 3.754 10.014 3.392 L 10.014 1.754 L 6.741 1.754 C 6.565 1.754 6.415 1.902 6.415 2.082 L 6.415 9.932 C 6.415 10.111 6.565 10.259 6.741 10.259 L 11.978 10.259 Z M 5.436 2.082 C 5.436 1.361 6.022 0.774 6.741 0.774 L 10.126 0.774 C 10.475 0.774 10.809 0.909 11.053 1.157 L 12.904 3.006 C 13.147 3.251 13.285 3.585 13.285 3.931 L 13.285 9.932 C 13.285 10.651 12.698 11.237 11.978 11.237 L 6.741 11.237 C 6.022 11.237 5.436 10.651 5.436 9.932 L 5.436 2.082 Z" style=""></path><path d="M 10.508 6.878 C 10.99 6.878 11.384 6.482 11.384 5.999 C 11.384 5.512 10.99 5.12 10.508 5.12 C 10.022 5.12 9.629 5.512 9.629 5.999 C 9.629 6.032 9.631 6.072 9.635 6.106 L 8.775 6.537 C 8.617 6.384 8.402 6.291 8.166 6.291 C 7.681 6.291 7.287 6.684 7.287 7.17 C 7.287 7.654 7.681 8.049 8.166 8.049 C 8.402 8.049 8.617 7.953 8.775 7.801 L 9.635 8.231 C 9.631 8.267 9.629 8.302 9.629 8.338 C 9.629 8.825 10.022 9.217 10.508 9.217 C 10.99 9.217 11.384 8.825 11.384 8.338 C 11.384 7.855 10.99 7.463 10.508 7.463 C 10.27 7.463 10.054 7.555 9.899 7.707 L 9.037 7.278 C 9.042 7.242 9.043 7.206 9.043 7.17 C 9.043 7.132 9.042 7.095 9.037 7.059 L 9.899 6.631 C 10.054 6.782 10.27 6.878 10.508 6.878 Z" style=""></path></svg>',
    content: {
      tagName: 'main',
      style: { height: '450px', width: '300px' },
      classes: ['share-container']
    },
    type: 'cmp-share-container'
  });

  editor.DomComponents.addType('share-container', {
    isComponent: el => el.tagName === 'MAIN' && el.className === 'share-container',
    model: {
      defaults: {
        tagName: 'main',
        editable: false,
        droppable: true,
        style: {
          display: 'block',
          overflow: 'auto',
          'box-sizing': 'border-box'
        }
      }
    }
  });
};
