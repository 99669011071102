import React, { useContext } from 'react';
import { Toolbar } from '@material-ui/core';
import { Container as MaterialContainer } from '@material-ui/core';
import { Avatar } from '@engloba-tech/englobity';
import { DynamicMenu } from './DynamicMenu';
import { useHeaderStyles } from './header.styles';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'auth';
import { UserStateContext } from 'state/useUserStateContext';
import { PATH } from 'app.routes.const';
import { CustomAvatar } from 'shared';

export function Header() {
  const classes = useHeaderStyles();
  const { t } = useTranslation();
  const { user, logout } = useContext(UserContext);
  const { clearUserState } = useContext(UserStateContext);
  const name = user.alias;
  const email = user.name || user.userName || '';
  const letters = email[0]?.toUpperCase() + email[2]?.toUpperCase();

  const handleLogout = () => {
    logout();
    clearUserState();
  };

  return (
    <MaterialContainer maxWidth={false}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.grow} />
        <DynamicMenu
          avatar={
            <CustomAvatar
              id={'js__avatar'}
              onLogOut={handleLogout}
              userName={name}
              userThumbnail={`data:image/jpeg;base64,${user.avatar}`}
              letters={letters}
              // userEmail={email}
              userEmail={'Smart Packaging'}
              elevation={3}
              myAccountText={t('navigation.myAccount')}
              myAccountLink={PATH.USERS.PROFILEEDIT.replace(':id', user.id)}
              logOutText={t('navigation.logOut')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            />
          }
        />
      </Toolbar>
    </MaterialContainer>
  );
}
