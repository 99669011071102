import { useState, useEffect, useCallback } from 'react';
import { execHelper } from 'shared';

const ROWS_PER_PAGE = 25;
const ASCENDING_ORDER_KEY = 'asc';
const DESCENDING_ORDER_KEY = 'desc';
const DESCENDING_PROPERTY_NAME_PREFIX = '-';

export function usePagination(onFetchPaginatedData, defaultPageSize = ROWS_PER_PAGE, initialOrderBy) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const [order, setOrder] = useState(DESCENDING_ORDER_KEY);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const paging = { pageIndex: page, pageSize: rowsPerPage };

    const sorting = orderBy.property
      ? { orderBy: orderBy.property, orderDesc: orderBy.direction === DESCENDING_ORDER_KEY }
      : { orderBy: orderBy, orderDesc: order === DESCENDING_ORDER_KEY };

    onFetchPaginatedData(paging, sorting, filters);
  }, [page, rowsPerPage, orderBy, order, filters]);
  // WARN: dont add onFetchData method as depend

  function changePage(event, newPage) {
    setPage(newPage);
  }

  function changeRows(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  function changeSort(event, property) {
    const newOrder = property.startsWith(DESCENDING_PROPERTY_NAME_PREFIX) ? DESCENDING_ORDER_KEY : ASCENDING_ORDER_KEY;
    setOrder(newOrder);
    setOrderBy({
      property: property.replace(DESCENDING_PROPERTY_NAME_PREFIX, ''),
      direction: newOrder
    });
  }

  const changeFilter = useCallback((fieldName, value) => {
    execHelper.delayed(() => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [fieldName]: value
      }));

      setPage(0);
    }, 500);
  }, []);

  return { page, rowsPerPage, orderBy, filters, changePage, changeRows, changeSort, changeFilter };
}
