import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { usContainerStyles } from './container.styles';
import { Paper } from '@material-ui/core';

export function Container(pageComponent) {
  return function ContainerWrapper() {
    const classes = usContainerStyles();
    return (
      <>
        <AppBar className={classes.appBar} position="fixed" elevation={1}>
          {/* <Header /> */}
        </AppBar>

        <div className={classes.root}>
          <Navigation />
          <main className={classes.content} id="main-content">
            <Paper elevation={1} className={classes.topBar}>
              <Header />
            </Paper>
            <div className={classes.pageContainer}>{pageComponent}</div>
          </main>
        </div>
      </>
    );
  };
}
