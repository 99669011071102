import { lighten, makeStyles } from '@material-ui/core/styles';

export const useViewTableToolbarStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1, 3, 0)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold !important',
    alignSelf: 'end'
  },
  titleSelected: {
    color: theme.palette.primary.main
  },
  button: { marginLeft: '1rem' },
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem',
    whiteSpace: 'nowrap'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  advancedFiltersWrapper: {
    display: 'flex'
  },
  advancedFiltersChip: {
    cursor: 'pointer'
  },
  toolbarTitleRow: {
    background: 'transparent'
  }
}));
