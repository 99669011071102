import { http } from './http';
import { apiHelper } from 'shared';

class Experiences {
  async getUsingQueryFilter(queryFilter, isUsedInMultilanguage = null) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, isUsedInMultilanguage });

    const response = await http.get(`/products${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/products/${queryString}`);
  }

  getById(id) {
    return http.get(`/products/${id}`);
  }

  create(experience) {
    return http.post('/products', experience);
  }

  delete(id) {
    return http.delete(`/products/${id}`);
  }

  update(id, experience) {
    return http.put(`/products/${id}`, experience);
  }

  clone(id) {
    return http.post(`/products/${id}`);
  }
}

export const experiences = new Experiences();
