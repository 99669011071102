import { makeStyles } from '@material-ui/core/styles';

export const useColorInputStyles = makeStyles(theme => ({
  colorSelector: {
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: '8rem'
  },
  actionTitleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 15
  },
  actionIconContainer: {
    display: 'flex',
    height: '1.5rem',
    alignItems: 'baseline',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: theme.palette.primary.main
    },
    '& .MuiIconButton-root': {
      padding: 4,
      '& span': {
        marginLeft: 0,
        marginBottom: 1
      }
    },
    '& a': {
      alignSelf: 'center'
    },
    '& .Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: 'unset'
      }
    }
  }
}));
