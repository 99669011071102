import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { settings, KEY_SETTINGS } from 'settings';
import { editorConfiguration } from './editorConfiguration';

export function CustomHtmlEditor({
  id = 'gjs',
  onDestroy,
  selectedPageId,
  pages,
  disabled,
  assets = [],
  eventHandlers = [],
  customConfiguration
}) {
  const [editor, setEditor] = useState(null);
  const pagesRef = useRef([]);
  const traitPagesRef = useRef([]);
  const assetsRef = useRef([]);

  useEffect(() => {
    if (!editor) {
      const editor = editorConfiguration({ id, ...customConfiguration });

      editor.on('canvas:dragdata', function (dataTransfer, result) {
        const storageUrl = `${settings.getSetting(KEY_SETTINGS.STORAGE_URL)}`;
        if (dataTransfer && dataTransfer.types && dataTransfer.types.length > 0) {
          var tag = dataTransfer.getData('text/html');
          if (tag && typeof tag === 'string' && tag.indexOf('<img') >= 0 && !tag.indexOf(storageUrl) >= 0) {
            var storagePortIndex = storageUrl.indexOf(':', 10);
            if (storagePortIndex > 0) {
              result.content = tag.replace(storageUrl.substring(0, storagePortIndex), storageUrl);
            }
          }
        }
      });

      editor.on('component:selected', function (model) {
        if (model.attributes.type === 'page-reference') {
          var trait = model.getTrait('data-target');
          if (trait) {
            trait.set('options', [{ name: '', value: '' }, ...(traitPagesRef.current || [])]);
          }
        } else if (model.attributes.type === 'share-button') {
          var shareTrait = model.getTrait('data-target-share');
          if (shareTrait) {
            shareTrait.set('options', [...(traitPagesRef.current || [])]);
          }
        }
      });

      editor.on('page:add', function () {
        const um = editor.UndoManager;
        um.clear();
      });

      editor.on('page:remove', function () {
        const um = editor.UndoManager;
        um.clear();
      });

      editor.runCommand('reset-changes-counter');

      setEditor(editor);
    }
  }, [editor, id, disabled, eventHandlers, customConfiguration]);

  useEffect(() => {
    if (editor) {
      if (pages) {
        const pageManager = editor.Pages;
        const existingPages = (pagesRef && pagesRef.current) || [];

        if (!existingPages || existingPages.length === 0) {
          var editorPages = pageManager.getAll();

          for (let page of editorPages) {
            pageManager.remove(page);
          }
        }

        const newPages = pages.filter(p => existingPages.findIndex(ep => ep.id.toString() === p.id.toString()) < 0);

        const deletedPages = existingPages.filter(ep => pages.findIndex(p => p.id.toString() === ep.id.toString()) < 0);

        deletedPages.map(page => {
          const editorPage = pageManager.get(page.id);
          pageManager.remove(editorPage);
          return editorPage;
        });

        newPages.map(page => {
          let newPage = pageManager.add({
            id: page.id,
            component: page.content,
            name: page.reference
          });
          newPage.set({ id: page.id, name: page.reference });
          return newPage;
        });

        pagesRef.current = pages;

        traitPagesRef.current =
          pages?.map(page => {
            return {
              name: page.contentPageReference || page.reference,
              value: page.contentPageReference || ''
            };
          }) || [];
      }
    }
  }, [editor, pages]);

  useEffect(() => {
    if (editor && eventHandlers) {
      for (var i = 0; i < eventHandlers.length; i++) {
        if (eventHandlers[i]) {
          editor.off(eventHandlers[i].name);
          editor.on(eventHandlers[i].name, eventHandlers[i].action);
        }
      }
    }
  }, [editor, eventHandlers]);

  useEffect(() => {
    if (editor) {
      if (editor.getModel().get('changesCount')) {
        editor.runCommand('save-command');
      }

      const pageManager = editor.Pages;
      if (selectedPageId) {
        pageManager.select(selectedPageId);
      } else {
        pageManager.select(pageManager.getAll()[0]);
      }

      const um = editor.UndoManager;
      um.clear();

      // prevents styling options for body/wrapper element
      let wrapper = editor.getWrapper();
      if (wrapper) {
        wrapper.set('stylable', false);
      }
    }
  }, [editor, selectedPageId]);

  useEffect(() => {
    if (editor) {
      const assetManager = editor.AssetManager;

      // TODO review. Move to commands or events?
      const existingAssets = (assetsRef && assetsRef.current) || [];
      const newAssets = assets.filter(p => existingAssets.findIndex(ep => ep.src.toString() === p.src.toString()) < 0);
      const deletedAssets = existingAssets.filter(
        ep => assets.findIndex(p => p.src.toString() === ep.src.toString()) < 0
      );

      deletedAssets.map(asset => {
        const assetToRemove = assetManager.get(`${asset.src}`);
        assetManager.remove(assetToRemove);
        return true;
      });

      newAssets.map(asset => {
        assetManager.add(asset);
        return asset;
      });

      assetsRef.current = [...assets];
    }
  }, [editor, assets]);

  // TOOD reenable
  /* useEffect(() => {
		return function cleanup() {
			console.log('onDestroy');
			if (editor) {
				//localStorage.removeItem();
				if (typeof onDestroy === 'function') {
					onDestroy();
				}
				const index = grapesjs.editors.findIndex(
					(e) => e.Config.container === editor.Config.container
				);
				if (typeof index === 'number' && index >= 0) {
					grapesjs.editors.splice(index, 1);
				}
				editor.destroy();
			}
		};
	}, [editor, onDestroy]); */

  return (
    <>
      <div className="panel__top">
        <div className="panel__devices"></div>
        <div className="panel__basic-actions"></div>
        <div className="panel__switcher"></div>
      </div>
      <div className="editor-row">
        <div className="editor-canvas">
          <div id={id}></div>
        </div>
        <div className="panel__right">
          <div className="block-manager-container"></div>
          <div className="layer-manager-container"></div>
          <div className="trait-manager-container"></div>
          <div className="style-manager-container">
            <div className="selector-container"></div>
            <div className="styles-container"></div>
          </div>
        </div>
      </div>
    </>
  );
}

CustomHtmlEditor.propTypes = {
  id: PropTypes.string,
  onDestroy: PropTypes.func,
  selectedPageId: PropTypes.string,
  pages: PropTypes.array,
  // onChange: PropTypes.func,
  disabled: PropTypes.bool,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string
    })
  ),
  eventHandlers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      action: PropTypes.func
    })
  ),
  customConfiguration: PropTypes.object
};
