import { useState, useMemo, useCallback, useEffect } from 'react';
import { rowFilterHelper } from 'shared';

export const useBlockCountryBuildTable = (inputs, t) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: t('countries.properties.name')
      }
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs?.length
      ? []
      : inputs
      ? inputs.map(country => ({
          id: country.id,
          name: country.name
        }))
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped);
  }, [inputs, inputs?.length, t]);

  const getBlockCountries = useCallback(
    (paging, sorting, filters) => {
      setRows(rowFilterHelper.getFilteredRows(initialRows, filters));
    },
    [initialRows]
  );

  return { headCells, rows, getBlockCountries };
};
