import { objectHelper } from 'shared/helper';
import { skeletonUser } from './skeletonUser';
import { skeletonExperience } from './skeletonExperience';
import { skeletonARMarker } from './skeletonARMarkers';
import { skeletonQRCode } from './skeletonQRCodes';
import { skeletonTag } from './skeletonTag';
import { skeletonSticker } from './skeletonStickers';
import { skeletonConfiguration } from './skeletonConfiguration';
import { skeletonMediaFile } from './skeletonMediaFile';
import { skeletonHtmlTemplates } from './skeletonHtmlTemplates';
import { skeletonMultilanguageExperience } from './skeletonMultilanguageExperience';
import { skeletonInstallation } from './skeletonInstallation';

export const SKELETON_KEY = 'skeleton_loading';
export const SKELETON_KEY_NUMBER = 901901901901;
export const SKELETON_KEY_DATE = 'May 1,1890 11:20:00';
export const SKELETON_KEY_BOOL = false;
export const SKELETON_ZERO = 0;

export function isSkeletonLoading(value) {
  if (objectHelper.isAnObject(value)) {
    return Object.keys(value).some(function (k) {
      return value[k] === SKELETON_KEY || value[k] === SKELETON_KEY_NUMBER || value[k] === SKELETON_KEY_DATE;
    });
  }

  return value === SKELETON_KEY || value === SKELETON_KEY_NUMBER || value === SKELETON_KEY_DATE;
}

const pagination = { page: 0, pageSize: 2147483647, total: 3, totalPages: 1 };

export const skeletonFactory = {
  users: function users(num = 10) {
    const user = skeletonUser();
    return { ...pagination, items: Array(num).fill(user) };
  },
  user: function user() {
    return skeletonUser();
  },
  experiences: function experiences(num = 10) {
    const experience = skeletonExperience();
    return { ...pagination, items: Array(num).fill(experience) };
  },
  experience: function experience() {
    return skeletonExperience();
  },
  arMarkers: function arMarkers(num = 5) {
    const arMarker = skeletonARMarker();
    return Array(num).fill(arMarker);
  },
  qrCodes: function qrCodes(num = 10) {
    const qrCode = skeletonQRCode();
    return { ...pagination, items: Array(num).fill(qrCode) };
  },
  tags: function tags(num = 10) {
    const tag = skeletonTag();
    return { ...pagination, items: Array(num).fill(tag) };
  },
  stickers: function stickers(num = 10) {
    const tag = skeletonSticker();
    return { ...pagination, items: Array(num).fill(tag) };
  },
  sticker: function sticker() {
    return skeletonSticker();
  },
  configuration: function configuration(num = 10) {
    return skeletonConfiguration(num);
  },
  mediaFiles: function mediaFiles(num = 10) {
    const mediaFile = skeletonMediaFile();
    return { ...pagination, items: Array(num).fill(mediaFile) };
  },
  htmlTemplates: function htmlTemplates(num = 10) {
    const htmlTemplate = skeletonHtmlTemplates();
    return { ...pagination, items: Array(num).fill(htmlTemplate) };
  },
  multilanguageExperiences: function multilanguageExperiences(num = 10) {
    const multilanguageExperience = skeletonMultilanguageExperience();
    return { ...pagination, items: Array(num).fill(multilanguageExperience) };
  },
  multilanguageExperience: function multilanguageExperience() {
    return skeletonMultilanguageExperience();
  },
  installation: function installation() {
    return skeletonInstallation();
  }
};
