import React from 'react';
import { IconButton, ImageListItem, ImageListItemBar, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomImage from 'shared/CustomImage/CustomImage';
import { useImageTileStyles } from './imageTile.styles';
import { isSkeletonLoading } from 'shared/skeletonFactory';
import DeleteIcon from '@material-ui/icons/Delete';

export const ImageTile = props => {
  const {
    image,
    onClick,
    onDeleteImageClick,
    readToken,
    isValid = true,
    tileMinHeight,
    tileMinWidth,
    cols = 1,
    rows = 1,
    style,
    extraActions,
    canBeDeleted = true,
    component = 'li'
  } = props;
  const { t } = useTranslation('common');
  const classes = useImageTileStyles();

  const isLoading = isSkeletonLoading(image.blobUrl);
  return (
    <>
      {image && image.blobUrl && (
        <ImageListItem
          className={`${classes.tile} ${!isValid && classes.error}`}
          onClick={() => !isLoading && onClick && onClick(image)}
          cols={cols}
          rows={rows}
          component={component}
          style={{ ...style, minHeight: tileMinHeight, minWidth: tileMinWidth }}
        >
          {isLoading ? (
            <Skeleton height={tileMinHeight || style?.height} />
          ) : (
            <>
              <div className={classes.imageContainer}>
                <CustomImage src={`${image.blobUrl}?${readToken}`} alt="Image" className={classes.image} />
              </div>
              <ImageListItemBar
                position="top"
                title={image.tag}
                className={`${classes.imageTitle} ${classes.imageTitleTop}`}
                actionIcon={
                  <div className={classes.actionIconContainer}>
                    {extraActions && extraActions(image)}
                    {onDeleteImageClick && canBeDeleted && (
                      <Tooltip title={t('actions.remove')}>
                        <span>
                          <IconButton
                            className={classes.actionIconButton}
                            onClick={evt => {
                              evt.stopPropagation();
                              onDeleteImageClick(image);
                            }}
                            aria-label={t('actions.remove')}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                }
              />
            </>
          )}
        </ImageListItem>
      )}
    </>
  );
};

ImageTile.propTypes = {
  image: PropTypes.shape({
    blobUrl: PropTypes.string,
    tag: PropTypes.string,
    targetLink: PropTypes.string
  }),
  onClick: PropTypes.func,
  onDeleteImageClick: PropTypes.func,
  readToken: PropTypes.string,
  extraActions: PropTypes.func,
  isValid: PropTypes.bool,
  tileMinHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tileMinWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cols: PropTypes.number,
  rows: PropTypes.number,
  style: PropTypes.object,
  component: PropTypes.string,
  canBeDeleted: PropTypes.bool
};
