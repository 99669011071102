import { useState, useEffect, useCallback } from 'react';
import { multilanguageExperiences } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';

const experienceDummy = skeletonFactory.multilanguageExperience();

const experienceEmpty = { relatedProducts: [] };

export function useMultilanguageExperience(id) {
  const [experienceData, setExperienceData] = useState(id ? experienceDummy : { ...experienceEmpty });
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await multilanguageExperiences.getById(id);
        if (response) {
          setExperienceData(response.data || {});
        }
      }, false);
    }
    id && getData();
  }, [request, id]);

  const updateExperience = useCallback(
    async experience => {
      await request(async () => {
        setExperienceData(experience);
        await multilanguageExperiences.update(id, experience);
      });
    },
    [request, id]
  );

  const createExperience = useCallback(
    async experience => {
      await request(async () => {
        setExperienceData(experience);
        await multilanguageExperiences.create(experience);
      });
    },
    [request]
  );

  return {
    experience: experienceData,
    errorInfo,
    setErrorInfo,
    setExperienceData,
    createExperience,
    updateExperience
  };
}
