import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { NAME_SPACE } from 'i18n';
import { PATH } from 'app.routes.const';
import { ViewTable, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { useListMultilanguageExperiencesStyles } from './listMultilanguageExperiences.styles';
import { useMultilanguageExperiencesPaginated } from './useMultilanguageExperiencesPaginated';
import { useMultilanguageExperiencesBuildTable } from './useMultilanguageExperiencesBuildTable';

export function ListMultilanguageExperiences() {
  const classes = useListMultilanguageExperiencesStyles();
  const { experiences, getExperiences, deleteExperiences, errorInfo } = useMultilanguageExperiencesPaginated();
  const { t } = useTranslation(NAME_SPACE.MULTILANGUAGE_EXPERIENCES);
  const history = useHistory();

  const { rows, headCells } = useMultilanguageExperiencesBuildTable(experiences, t);

  function handleEdit([experienceId]) {
    history.push(PATH.MULTILANGUAGE_EXPERIENCES.EDIT.replace(':id', experienceId));
  }

  function handleAdd() {
    history.push(PATH.MULTILANGUAGE_EXPERIENCES.CREATE);
  }

  async function handleDelete(experiencesToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          experiencesToDelete.length > 1
            ? t('removeConfirm.message', { length: experiencesToDelete.length })
            : t('removeConfirm.singularMessage', {
                name: experiencesToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });
      await deleteExperiences(experiencesToDelete);
    } catch (err) {}
  }

  return (
    <div className={classes.experiences}>
      <ViewTable
        elevation
        spaced
        errors={errorInfo}
        rows={rows}
        cells={headCells}
        defaultOrderBy={{ property: 'name', direction: 'asc' }}
        mainKey={'name'}
        onFetchData={getExperiences}
        totalRows={experiences.total}
        selectRowWhen={element => element.canBeDeleted}
        serverSidePaging
        emptyText={t('empty')}
        actions={[
          {
            title: t('actions.edit'),
            name: 'edit',
            properties: ['id'],
            label: 'edit this row',
            cb: handleEdit,
            icon: <EditIcon />
          }
        ]}
        toolbar={{
          title: t('name'),
          actions: [
            {
              condition: isAnySelected => !isAnySelected,
              cb: handleAdd,
              component: ButtonAdd
            },
            {
              condition: isAnySelected => isAnySelected,
              resetOnCB: true,
              cb: handleDelete,
              component: ButtonDelete
            }
          ]
        }}
      />
    </div>
  );
}
