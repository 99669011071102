import { SKELETON_KEY } from './skeletonFactory';

export function skeletonMediaFile() {
  return {
    id: SKELETON_KEY,
    tag: SKELETON_KEY,
    targetLink: SKELETON_KEY,
    blobUrl: SKELETON_KEY
  };
}
