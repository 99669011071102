import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { settings, KEY_SETTINGS } from 'settings';
import { ACADEMY_PATH } from 'app.routes.const';

export function useAcademyLink() {
  const currentLocation = useLocation();

  const academyRoute = useMemo(() => {
    const values = Object.keys(ACADEMY_PATH)
      .filter(value => currentLocation?.pathname?.startsWith(value))
      .map(value => ACADEMY_PATH[value]);

    const academyPath = settings.getSetting(KEY_SETTINGS.ACADEMY_URL);
    return values[0] && `${academyPath}${values[0]}`;
  }, [currentLocation]);

  return {
    academyRoute
  };
}
