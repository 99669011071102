import { useViewTableData, skeletonFactory } from 'shared';
import { multilanguageExperiences } from 'services';

const experiencesDummy = skeletonFactory.multilanguageExperiences();

export function useMultilanguageExperiencesPaginated() {
  const {
    data: experiencesData,
    get: getExperiences,
    deleteElements: deleteExperiences,
    errorInfo: getDeleteErrorKey,
    setErrorInfo
  } = useViewTableData({
    service: multilanguageExperiences,
    paginatedSkeleton: experiencesDummy
  });

  return {
    experiences: experiencesData,
    errorInfo: getDeleteErrorKey,
    setErrorInfo,
    getExperiences,
    deleteExperiences
  };
}
