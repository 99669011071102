const deviceButtons = [
  {
    id: 'device-a4',
    command: 'set-device-a4',
    active: true,
    togglable: false,
    className: 'fa fa-sticky-note',
    label: 'A4',
    attributes: { title: 'A4' }
  },
  {
    id: 'device-a5',
    command: 'set-device-a5',
    togglable: false,
    className: 'fa fa-sticky-note',
    label: 'A5',
    attributes: { title: 'A5' }
  },
  {
    id: 'device-a6',
    command: 'set-device-a6',
    togglable: false,
    className: 'fa fa-sticky-note',
    label: 'A6',
    attributes: { title: 'A6' }
  }
];

export const paperPanels = editor => {
  const panelManager = editor.Panels;

  var devicesPanel = panelManager.getPanel('panel-devices');
  if (!devicesPanel) {
    panelManager.addPanel({
      id: 'panel-devices',
      el: '.panel__devices',
      buttons: deviceButtons
    });
  } else {
    devicesPanel.get('buttons').add(deviceButtons);
  }
};
