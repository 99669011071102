import { makeStyles } from '@material-ui/core/styles';

export const useViewStickerStyles = makeStyles(theme => ({
  sticker: {
    flexGrow: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`
  },
  editor: {
    height: '720px',
    maxHeight: '720px'
  }
}));
