import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { UserContext, useUserContext } from 'auth';
import { http } from 'services/http';
import { Routes } from './App.routes';
import { LoadingPage } from 'pages';
import { UserStateContext, useUserStateContext } from 'state/useUserStateContext';
import { BrandContext, useBrandContext } from 'brand';

function App() {
  const userContext = useUserContext();
  const userStateContext = useUserStateContext();
  const brandContext = useBrandContext();

  http.setOnUnauthorized(() => userContext.setIsLogged(false));

  return (
    <UserContext.Provider value={userContext}>
      <UserStateContext.Provider value={userStateContext}>
        <BrandContext.Provider value={brandContext}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {userContext.isLoading ? (
              <LoadingPage />
            ) : (
              <Routes isLogged={userContext.isLogged} user={userContext.user} />
            )}
          </MuiPickersUtilsProvider>
        </BrandContext.Provider>
      </UserStateContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
