function get(t, withAnyLabel) {
  let fontFamilies = [];

  if (withAnyLabel) {
    fontFamilies.push({ value: '', name: t('selectFirstOption') });
  }

  fontFamilies = [
    ...fontFamilies,
    ...[
      { value: '"Abril Fatface"', name: 'Abril Fatface' },
      { value: 'Cinzel', name: 'Cinzel' },
      { value: '"Playfair Display"', name: 'Playfair Display' },
      { value: 'Roboto', name: 'Roboto' },
      { value: '"Roboto Slab"', name: 'Roboto Slab' },
      { value: 'monospace', name: 'Generic monospace' },
      { value: 'sans-serif', name: 'Generic sans-serif' },
      { value: 'serif', name: 'Generic serif' }
    ]
  ];

  return fontFamilies;
}

export const fontFamilyHelper = {
  get
};
