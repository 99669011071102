import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useParams } from 'react-router-dom';
import { FormPage, useRequestLoading } from 'shared';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { FormConfiguration } from './FormConfiguration';
import { useConfiguration } from './useConfiguration';
import { useInstallation } from 'pages/shared';
import { ButtonSave } from 'shared';

export function EditConfiguration() {
  const { id } = useParams();
  const { configuration, updateConfiguration, errorInfo, setErrorInfo } = useConfiguration(id);
  const { installation } = useInstallation();
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const { requestLoading, callbackRequest } = useRequestLoading();
  const $formRef = useRef(null);

  async function handleSubmit(configuration) {
    await callbackRequest(async () => {
      setErrorInfo({ message: '', detail: '' });
      await updateConfiguration(configuration);
    });
  }

  function handleSaveClick() {
    $formRef.current.submit();
  }

  function composeActionButtons() {
    const actionButtons = [
      {
        icon: <SaveAltIcon />,
        text: t(`${NAME_SPACE.COMMON}:actions.save`),
        onClick: handleSaveClick,
        type: 'primary',
        enableOnDirty: true,
        component: ButtonSave
      }
    ];

    return actionButtons;
  }

  return (
    <FormPage
      title={t('singularName')}
      disabled={requestLoading}
      buttons={composeActionButtons()}
      onSubmit={handleSubmit}
    >
      <FormConfiguration
        errors={errorInfo}
        formRef={$formRef}
        configuration={{ ...configuration }}
        installation={{ ...installation }}
      />
    </FormPage>
  );
}
