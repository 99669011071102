import { SKELETON_KEY } from './skeletonFactory';

export function skeletonUser() {
  return {
    id: SKELETON_KEY,
    userName: SKELETON_KEY,
    password: SKELETON_KEY,
    role: SKELETON_KEY
  };
}
