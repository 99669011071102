import React from 'react';
import PropTypes from 'prop-types';
import { ViewPageAgeCheck } from './ViewPageAgeCheck';
import { ViewPagePromotionalVideo } from './ViewPagePromotionalVideo';
import { useViewPageStyles } from './viewPage.styles';
import { ViewPageExperienceInfo } from './ViewPageExperienceInfo';
import { useTranslation } from 'react-i18next';
import { Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { Grid, Typography } from '@material-ui/core';
import { ColorPicker } from 'shared';
import { ImageTagSelect, TEMPLATE_NAME, TEMPLATE_PAGE_REFERENCE_TYPE } from '../../shared';
import { isSkeletonLoading } from 'shared';
import { Skeleton } from '@material-ui/lab';
import { ViewPageRatings } from './ViewPageRatings';
import { ViewPageAuth } from './ViewPageAuth';

export const ViewTemplatePage = ({
  page,
  experience,
  onChange,
  onSwitchChange,
  templateUsesMediaFiles,
  pageColumns = 2
}) => {
  const classes = useViewPageStyles();
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);

  const handleChangePage = fieldName => event => {
    onChange(fieldName, { pageId: page.id })(event);
  };

  const handleSwitchChangePage = fieldName => event => {
    onSwitchChange(fieldName, { pageId: page.id })(event);
  };

  const containerSizeLg = pageColumns === 2 ? 6 : 4;
  const fieldSizeLg = pageColumns === 2 ? 6 : 12;

  return (
    <Grid item xs={12} sm={12} md={6} lg={containerSizeLg} className={classes.page}>
      {!isSkeletonLoading(page.fontColor) ? (
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12}>
            <Grid container spacing={4} style={{ height: 'unset' }}>
              <Grid item xs={12} className={classes.titleContainer}>
                {isSkeletonLoading(page.visible) ? (
                  <Skeleton height={'90%'} />
                ) : (
                  <Typography variant="h6" className={classes.title}>
                    {page.name}
                  </Typography>
                )}
                <Switch
                  isLoading={isSkeletonLoading(page.visible)}
                  checked={page.visible || false}
                  onChange={handleSwitchChangePage('visible')}
                  className={classes.pageSwitch}
                  label={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4} className={classes.fieldsContainer}>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ order: 1 }}>
                <div className={classes.colorSelector}>
                  <ColorPicker
                    name="backgroundColor"
                    color={page && page.backgroundColor ? page.backgroundColor : ''}
                    onChange={handleChangePage('backgroundColor')}
                  />
                  {!isSkeletonLoading(page.fontColor) ? (
                    <Typography variant="subtitle2" component={'span'}>
                      {t('pages.properties.backgroundColor')}
                    </Typography>
                  ) : (
                    <Skeleton />
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{ order: 1 }}>
                <div className={classes.colorSelector}>
                  <ColorPicker
                    name="fontColor"
                    color={page && page.fontColor ? page.fontColor : ''}
                    onChange={handleChangePage('fontColor')}
                  />
                  {!isSkeletonLoading(page.fontColor) ? (
                    <Typography variant="subtitle2" component={'span'}>
                      {t('pages.properties.fontColor')}
                    </Typography>
                  ) : (
                    <Skeleton />
                  )}
                </div>
              </Grid>

              {templateUsesMediaFiles && (
                <Grid item xs={12} sm={12} md={fieldSizeLg} lg={fieldSizeLg} style={{ order: 3 }}>
                  <ImageTagSelect
                    variant="outlined"
                    withAnyLabel
                    title={t('pages.properties.backgroundImageTag')}
                    value={page.backgroundImageTag}
                    onChange={handleChangePage('backgroundImageTag')}
                    options={experience.mediaFiles}
                    fullWidth
                    className={classes.select}
                  />
                </Grid>
              )}
              {page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION && (
                <ViewPageAuth
                  classes={classes}
                  successfulAuthenticationImageTag={experience.successfulAuthenticationImageTag}
                  unsuccessfulAuthenticationImageTag={experience.unsuccessfulAuthenticationImageTag}
                  successfulAuthenticationText={experience.successfulAuthenticationText}
                  unsuccessfulAuthenticationText={experience.unsuccessfulAuthenticationText}
                  isTamperType={experience.isTamperType}
                  closeTamperImageTag={experience.closeTamperImageTag}
                  closeTamperText={experience.closeTamperText}
                  openTamperImageTag={experience.openTamperImageTag}
                  openTamperText={experience.openTamperText}
                  unknownTamperImageTag={experience.unknownTamperImageTag}
                  unknownTamperText={experience.unknownTamperText}
                  onChange={onChange}
                  onSwitchChange={onSwitchChange}
                  onChangePage={handleChangePage}
                  mediaFiles={experience.mediaFiles}
                  itemSizeLg={fieldSizeLg}
                  templateUsesMediaFiles={templateUsesMediaFiles}
                  {...page}
                />
              )}
              {page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.LEGAL_TERMS && (
                <ViewPageAgeCheck
                  classes={classes}
                  visible={page.visible}
                  legalAge={experience.legalAge}
                  cookiesEnabled={experience.cookiesEnabled}
                  onChange={onChange}
                  onSwitchChange={onSwitchChange}
                  itemSizeLg={fieldSizeLg}
                />
              )}
              {page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.PROMOTIONAL_VIDEO && (
                <ViewPagePromotionalVideo
                  visible={page.visible}
                  youtubeUrl={experience.youtubeUrl}
                  onChange={onChange}
                  itemSizeLg={fieldSizeLg}
                />
              )}
              {page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.RATINGS && (
                <ViewPageRatings
                  classes={classes}
                  ratingEmptyImageTag={experience.ratingEmptyImageTag}
                  ratingHalfImageTag={experience.ratingHalfImageTag}
                  ratingFullImageTag={experience.ratingFullImageTag}
                  ratingCompletedResultType={experience.ratingCompletedResultType}
                  ratingGratutideMessage={experience.ratingGratutideMessage}
                  ratingShareButtonTitle={experience.ratingShareButtonTitle}
                  ratingShareTargetTemplatePageReference={experience.ratingShareTargetTemplatePageReference}
                  onChange={onChange}
                  onSwitchChange={onSwitchChange}
                  onChangePage={handleChangePage}
                  itemSizeLg={fieldSizeLg}
                  mediaFiles={experience.mediaFiles}
                  pages={experience.pages}
                  {...page}
                />
              )}

              {experience?.template?.name !== TEMPLATE_NAME.CIGAR_SHOP_ANDORRA &&
                (page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.VERTICAL ||
                  page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.HORIZONTAL ||
                  page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.IMAGES) && (
                  <ViewPageExperienceInfo
                    classes={classes}
                    visible={page.visible}
                    showTitle={experience.showTitle}
                    onSwitchChange={onSwitchChange}
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Skeleton height={260} />
      )}
    </Grid>
  );
};

ViewTemplatePage.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    reference: PropTypes.string,
    fontColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImageTag: PropTypes.string,
    visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }),
  onChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  experience: PropTypes.shape({
    mediaFiles: PropTypes.array,
    legalAge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cookiesEnabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    youtubeUrl: PropTypes.string,
    showTitle: PropTypes.bool,
    ratingEmptyImageTag: PropTypes.string,
    ratingHalfImageTag: PropTypes.string,
    ratingFullImageTag: PropTypes.string,
    ratingCompletedResultType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ratingGratutideMessage: PropTypes.string,
    ratingShareButtonTitle: PropTypes.string,
    ratingShareTargetTemplatePageReference: PropTypes.string,
    isTamperType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    closeTamperImageTag: PropTypes.string,
    closeTamperText: PropTypes.string,
    openTamperImageTag: PropTypes.string,
    openTamperText: PropTypes.string,
    unknownTamperImageTag: PropTypes.string,
    unknownTamperText: PropTypes.string,
    successfulAuthenticationImageTag: PropTypes.string,
    unsuccessfulAuthenticationImageTag: PropTypes.string,
    successfulAuthenticationText: PropTypes.string,
    unsuccessfulAuthenticationText: PropTypes.string,
    pages: PropTypes.array,
    template: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string
      })
    ])
  }),
  templateUsesMediaFiles: PropTypes.bool,
  pageColumns: PropTypes.number
};
