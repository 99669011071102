import { makeStyles } from '@material-ui/core';
const drawerWidthOpen = 260;
const drawerWidthClose = 52;
export const useNavigationStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDrawer-paper': {}
  },

  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    zIndex: 99,
    width: drawerWidthOpen,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    border: '0 !important',
    backgroundColor: theme.palette.basic.dark,
    color: theme.palette.basic.brightest
  },
  drawerOpen: {
    // top: '69px !important',
    borderRight: 0,
    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
    paddingTop: '1rem',
    overflowX: 'hidden',
    backgroundColor: theme.palette.basic.dark,
    color: theme.palette.secondary.light,
    width: drawerWidthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& .MuiPaper-root': {
      color: 'inherit',
      backgroundColor: 'inherit'
    }
  },
  drawerClose: {
    // top: '69px !important',
    backgroundColor: theme.palette.basic.dark,
    color: theme.palette.secondary.light,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: drawerWidthClose,
    '& h6': {
      marginLeft: '11px !important'
    },
    '& .MuiListItem-button': {
      marginLeft: '-5px'
    },
    '& .MuiPaper-root': {
      color: 'inherit',
      backgroundColor: 'inherit'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    height: '23px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  footer: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    flexDirection: 'column'
  },
  footerOpen: {
    width: drawerWidthOpen,
    background: 'inherit',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  footerClose: {
    width: drawerWidthClose,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  footerWrapperButton: {
    alignSelf: 'flex-end',
    height: '60px',
    '& .MuiIconButton-root': {
      color: 'white'
    }
  },
  appBar: {
    background: theme.palette.secondary.light,
    filter: 'drop-shadow(0px 1px 10px rgba(74, 86, 109, 0.05))'
  },
  hgTagLogo: {
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    textAlign: 'center',
    padding: '0rem 2rem',
    marginBottom: '2.5rem',
    paddingTop: '0.5rem'
  },
  hgTagLogoPlaceholder: {
    height: '50px'
  }
}));
