import { useMemo } from 'react';

export function useStickersBuildTable(inputs, t) {
  const headCells = useMemo(() => {
    return [
      { id: 'name', numeric: false, disablePadding: false, label: t('properties.name') },
      { id: 'product', numeric: false, disablePadding: false, label: t('properties.product') }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }
    return inputs.items.map(sticker => ({
      name: sticker.name,
      product: sticker.product,
      id: sticker.id
    }));
  }, [inputs]);

  return { headCells, rows };
}
