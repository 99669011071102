import { useState, useEffect, useCallback } from 'react';
import { htmlTemplates } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';

const htmlTemplateDummy = skeletonFactory.sticker();

export function useHtmlTemplate(id) {
  const [htmlTemplateData, setHtmlTemplateData] = useState(id ? htmlTemplateDummy : {});
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await htmlTemplates.getById(id);
        if (response) {
          setHtmlTemplateData(response.data || {});
        }
      }, false);
    }
    id && getData();
  }, [request, id]);

  const updateHtmlTemplate = useCallback(
    async htmlTemplate => {
      await request(async () => {
        setHtmlTemplateData(htmlTemplate);
        await htmlTemplates.update(id, htmlTemplate);
      });
    },
    [request, id]
  );

  const createHtmlTemplate = useCallback(
    async htmlTemplate => {
      await request(async () => {
        setHtmlTemplateData(htmlTemplate);
        await htmlTemplates.create(htmlTemplate);
      });
    },
    [request]
  );

  return {
    htmlTemplate: htmlTemplateData,
    errorInfo,
    setErrorInfo,
    setHtmlTemplateData,
    createHtmlTemplate,
    updateHtmlTemplate
  };
}
