import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Grid, Typography } from '@material-ui/core';
import { ViewIcon } from './ViewIcon';
import { experienceHelper, ICON_TARGET_TYPE } from '../shared';
import { useViewIconsStyles } from './viewIcons.styles';
import { ButtonAdd } from 'shared';

const MAX_TOP_ICONS = 2;
const MAX_BOTTOM_ICONS = 4;

export const ViewIcons = ({ icons = [], mediaFiles = [], onAddIcon, onChange, onDeleteIcon }) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewIconsStyles();

  const targetTypes = useMemo(() => {
    return Object.keys(ICON_TARGET_TYPE).map(key => {
      return { name: t(`icons.targetTypes.${key.toLowerCase()}`), value: ICON_TARGET_TYPE[key] };
    });
  }, [t]);

  const topIcons = useMemo(() => {
    return icons.filter(icon => icon.isTopIcon);
  }, [icons]);

  const bottomIcons = useMemo(() => {
    return icons.filter(icon => !icon.isTopIcon);
  }, [icons]);

  function handleNew(isTopIcon) {
    const order = experienceHelper.getNextOrder(icons.filter(icon => icon.isTopIcon === isTopIcon));
    onAddIcon({ isTopIcon: isTopIcon, order: order });
  }

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.titleContainer}>
          <div className={classes.subtitle}>
            <Typography variant="body1" className={classes.title}>
              {t('icons.topIcons')}
            </Typography>
            <ButtonAdd onClick={() => handleNew(true)} disabled={topIcons.length >= MAX_TOP_ICONS} />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.iconContainer}>
          {topIcons.map((icon, index) => (
            <ViewIcon
              key={index}
              icon={icon}
              mediaFiles={mediaFiles}
              onChange={onChange}
              onDelete={onDeleteIcon}
              targetTypes={targetTypes}
            />
          ))}
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <div className={classes.subtitle}>
            <Typography variant="body1" className={classes.title}>
              {t('icons.bottomIcons')}
            </Typography>
            <ButtonAdd onClick={() => handleNew(false)} disabled={bottomIcons.length >= MAX_BOTTOM_ICONS} />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.iconContainer}>
          {bottomIcons.map((icon, index) => (
            <ViewIcon
              key={index}
              icon={icon}
              mediaFiles={mediaFiles}
              onChange={onChange}
              onDelete={onDeleteIcon}
              targetTypes={targetTypes}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

ViewIcons.propTypes = {
  icons: PropTypes.array.isRequired,
  mediaFiles: PropTypes.array.isRequired,
  onAddIcon: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteIcon: PropTypes.func.isRequired
};
