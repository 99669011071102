export const templateCommands = (editor, config) => {
  editor.Commands.add('show-templates', {
    run(editor) {
      return editor;
    }
  });
  editor.Commands.add('select-template', {
    run(editor, sender, value) {
      editor.load(res => {
        editor.setComponents(value.content);
      });
    }
  });
};
