import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { templates } from 'services';

export function useExperienceTemplates(withAnyLabel) {
  const { t } = useTranslation();
  const [templatesData, setTemplatesData] = useState([]);

  useEffect(() => {
    async function getTemplates() {
      const templatesResult = await templates.getCachedTemplates();
      if (templatesResult.length) {
        setTemplatesData([
          withAnyLabel && {
            value: t('common:selectFirstOptionComboFilter'),
            name: t('common:selectFirstOptionComboFilter'),
            isAny: true
          },
          ...templatesResult.map(t => {
            return { value: t.id, name: t.name };
          })
        ]);
      }
    }
    getTemplates();
  }, [t, withAnyLabel]);

  return { templates: templatesData };
}
