import { makeStyles } from '@material-ui/core/styles';

export const useViewTableTreeStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      boxShadow: 'none'
    },
    '& .MuiTable-root': {
      position: 'relative',
      overflow: 'hidden'
    },
    '& .MuiTableRow-root': {
      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
      '&:before': {
        content: '""',
        fontWeight: theme.font.weight.black,
        fontSize: theme.font.size.xxl,
        position: 'absolute',
        left: `var(--left-before, ${0}px)`,
        width: '1px',
        height: '2.2rem',
        backgroundColor: theme.palette.basic.bright
      }
    }
  },
  summary: {
    marginBottom: '1rem',
    alignItems: 'center'
  }
}));
