import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useViewPageBlockingsStyles } from './viewPageBlockings.styles';
import { TEMPLATE_NAME } from '../../shared';

export function ViewPageBlockings({ experience, onSwitchChange }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewPageBlockingsStyles();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <div className={classes.titleContainer}>
          <Switch
            label={t('properties.ageCheckAllowed')}
            checked={experience.ageCheckAllowed || false}
            isLoading={isSkeletonLoading(experience.ageCheckAllowed)}
            onChange={onSwitchChange('ageCheckAllowed')}
            className={classes.switch}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className={classes.titleContainer}>
          <Switch
            label={t('properties.authenticationAllowed')}
            checked={experience.authenticationAllowed || false}
            isLoading={isSkeletonLoading(experience.authenticationAllowed)}
            onChange={onSwitchChange('authenticationAllowed')}
            disabled={experience.template?.name === TEMPLATE_NAME.CIGAR_SHOP_ANDORRA}
            className={classes.switch}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className={classes.titleContainer}>
          <Switch
            label={t('properties.promotionalVideoAllowed')}
            checked={experience.promotionalVideoAllowed || false}
            isLoading={isSkeletonLoading(experience.promotionalVideoAllowed)}
            onChange={onSwitchChange('promotionalVideoAllowed')}
            className={classes.switch}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className={classes.titleContainer}>
          <Switch
            label={t('properties.experienceInfoAllowed')}
            checked={experience.experienceInfoAllowed || false}
            isLoading={isSkeletonLoading(experience.experienceInfoAllowed)}
            onChange={onSwitchChange('experienceInfoAllowed')}
            disabled={experience.template?.name === TEMPLATE_NAME.REDIRECT}
            className={classes.switch}
          />
        </div>
      </Grid>
    </>
  );
}

ViewPageBlockings.propTypes = {
  experience: PropTypes.shape({
    ageCheckAllowed: PropTypes.bool,
    authenticationAllowed: PropTypes.bool,
    promotionalVideoAllowed: PropTypes.bool,
    experienceInfoAllowed: PropTypes.bool,
    template: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string
      })
    ])
  }),
  onSwitchChange: PropTypes.func.isRequired
};
