import moment from 'moment';

function validateBeforeExecute(string, cb) {
  if (typeof string === 'string') {
    return cb();
  } else {
    throw new Error(`string required, ${typeof string} found`);
  }
}

function toShortDate(string) {
  return validateBeforeExecute(string, () => moment(string).format('DD/MM/YYYY'));
}

function toLongDate(string, withSeconds) {
  return validateBeforeExecute(string, () =>
    moment(string).format(withSeconds ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm')
  );
}

// TODO toLongDate, toHour

export const dateHelper = {
  toShortDate,
  toLongDate
};
