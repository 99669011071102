export const ratingsShareableFields = editor => {
  editor.BlockManager.add('replaceable-text', {
    category: 'Experience Utils',
    label: 'Rating option name',
    media:
      '<svg viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg"><path d="M 9.401 0.787 C 9.357 0.695 9.263 0.638 9.161 0.638 C 9.059 0.638 8.967 0.695 8.922 0.787 L 8.388 1.885 L 7.194 2.062 C 7.095 2.077 7.011 2.146 6.981 2.242 C 6.95 2.338 6.975 2.444 7.047 2.514 L 7.912 3.371 L 7.708 4.581 C 7.691 4.681 7.733 4.783 7.815 4.841 C 7.897 4.9 8.007 4.908 8.096 4.86 L 9.162 4.291 L 10.228 4.86 C 10.318 4.908 10.426 4.901 10.509 4.841 C 10.592 4.782 10.633 4.681 10.616 4.581 L 10.41 3.371 L 11.277 2.514 C 11.347 2.444 11.373 2.338 11.342 2.242 C 11.311 2.146 11.228 2.077 11.128 2.062 L 9.935 1.885 L 9.401 0.787 Z" style=""></path><path d="M 16.224 6.166 C 16.224 5.737 15.867 5.452 15.295 5.452 L 2.934 5.452 C 2.291 5.452 1.934 5.737 1.934 6.166 L 1.934 10.453 C 1.934 10.882 2.291 11.167 2.863 11.167 L 15.295 11.167 C 15.867 11.167 16.224 10.882 16.224 10.453 L 16.224 6.166 Z M 15.509 10.453 L 2.649 10.453 L 2.649 6.166 L 15.509 6.166 L 15.509 10.453 Z" style=""></path><path d="M 3.363 6.881 L 4.078 6.881 L 4.078 9.739 L 3.363 9.739 L 3.363 6.881 Z" style=""></path></svg>',
    content: {
      type: 'replaceable-text',
      content: 'Rating option name',
      style: { padding: '10px' },
      classes: ['replaceable-text'],
      attributes: { 'data-replace': 'name' }
    }
  });

  const textExtendedTrait = {
    extend: 'text',
    isComponent: el => el.className === 'replaceable-text' && el.hasAttribute('data-replace'),
    model: {
      defaults: {
        name: 'Rating option name',
        traits() {
          const result = [];
          result.push('id');
          result.push('title');

          return result;
        }
      }
    }
  };
  editor.DomComponents.addType('replaceable-text', { ...textExtendedTrait });

  editor.BlockManager.add('replaceable-image', {
    category: 'Experience Utils',
    label: 'Rating option image',
    media:
      '<svg viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg"><path d="M 9.528 0.787 C 9.484 0.695 9.39 0.638 9.288 0.638 C 9.186 0.638 9.094 0.695 9.049 0.787 L 8.515 1.885 L 7.321 2.062 C 7.222 2.077 7.138 2.146 7.108 2.242 C 7.077 2.338 7.102 2.444 7.174 2.514 L 8.039 3.371 L 7.835 4.581 C 7.818 4.681 7.86 4.783 7.942 4.841 C 8.024 4.9 8.134 4.908 8.223 4.86 L 9.289 4.291 L 10.355 4.86 C 10.445 4.908 10.553 4.901 10.636 4.841 C 10.719 4.782 10.76 4.681 10.743 4.581 L 10.537 3.371 L 11.404 2.514 C 11.474 2.444 11.5 2.338 11.469 2.242 C 11.438 2.146 11.355 2.077 11.255 2.062 L 10.062 1.885 L 9.528 0.787 Z" style=""></path><path d="M 11.777 6.075 C 11.89 6.075 11.982 6.167 11.982 6.279 L 11.982 10.365 L 11.917 10.282 L 10.18 8.034 C 10.122 7.958 10.032 7.914 9.937 7.914 C 9.843 7.914 9.754 7.958 9.695 8.034 L 8.634 9.406 L 8.244 8.861 C 8.186 8.779 8.095 8.732 7.995 8.732 C 7.896 8.732 7.804 8.779 7.745 8.862 L 6.724 10.293 L 6.666 10.371 L 6.666 10.368 L 6.666 6.279 C 6.666 6.167 6.759 6.075 6.87 6.075 L 11.777 6.075 Z M 6.87 5.462 C 6.42 5.462 6.053 5.829 6.053 6.279 L 6.053 10.368 C 6.053 10.819 6.42 11.186 6.87 11.186 L 11.777 11.186 C 12.228 11.186 12.594 10.819 12.594 10.368 L 12.594 6.279 C 12.594 5.829 12.228 5.462 11.777 5.462 L 6.87 5.462 Z M 7.893 7.914 C 8.365 7.914 8.661 7.404 8.424 6.995 C 8.313 6.805 8.112 6.689 7.893 6.689 C 7.421 6.689 7.126 7.199 7.361 7.609 C 7.472 7.799 7.674 7.914 7.893 7.914 Z" style=""></path></svg>',
    content: {
      style: { color: 'black' },
      type: 'replaceable-image',
      classes: ['replaceable-image'],
      attributes: { 'data-replace': 'imageTag' }
    }
  });

  const imageExtendedTrait = {
    extend: 'image',
    isComponent: el => el.className === 'replaceable-image' && el.hasAttribute('data-replace') && el.tagName === 'IMG',
    model: {
      defaults: {
        name: 'Rating option image',
        traits() {
          const result = [];
          result.push('alt');

          return result;
        }
      }
    }
  };
  editor.DomComponents.addType('replaceable-image', { ...imageExtendedTrait });
};
