import { useState, useEffect, useCallback } from 'react';
import { users, auth } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';
import { authStorage } from 'auth';

const userDummy = skeletonFactory.user();

export function useUser(id, profile) {
  const [userData, setUserData] = useState(id ? userDummy : {});
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = profile ? await users.getMy() : id ? await users.getById(id) : setUserData({});
        if (response) {
          setUserData(response.data || []);
        }
      }, false);
    }
    id && getData();
  }, [request, id, profile]);

  const saveUser = useCallback(
    async user => {
      return await request(
        async () => {
          setUserData(userDummy);
          let response;
          if (id) {
            if (profile) {
              response = await users.updateMy(user);
            } else {
              response = await users.update(id, user);
            }
            setUserData(response.data || []);
          } else {
            response = await users.create(user);
          }

          return response.data.id;
        },
        true,
        () => setUserData(user)
      );
    },
    [id, request]
  );

  const changePassword = useCallback(
    async changePasswordRequest => {
      return await request(async () => {
        const response = await auth.changePassword(changePasswordRequest);
        if (response) {
          authStorage.setPassword(changePasswordRequest.newPassword);
        }
        return response;
      }, false);
    },
    [request]
  );

  return {
    user: userData,
    errorInfo,
    setErrorInfo,
    setUserData,
    saveUser,
    changePassword,
    changePasswordSuccess,
    setChangePasswordSuccess
  };
}
