import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useEditExperienceStyles = makeStyles(theme => ({
  formOverride: {
    '& form': {
      padding: 'unset',
      background: 'unset',
      boxShadow: 'unset',
      borderRadius: 'unset'
    }
  },
  buttonDownloadQRDemo: {
    ...buttonAddStyles
  },
  buttonCloseQRDemo: {
    ...buttonDeleteStyles
  }
}));
