import { http } from './http';
import { apiHelper } from 'shared';

class Tags {
  get(paging, sorting, filters) {
    let queryString = '';

    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });
    return http.get(`/tags${queryString}`);
  }

  delete(id) {
    return http.delete(`/tags/${id}`);
  }

  async createRange(tagRange) {
    const response = await http.post('/tags/range', tagRange);

    return response.data;
  }

  import(productId, file) {
    var form_data = new FormData();
    form_data.append('csvFile', file);

    return http.post(`/tags/${productId}/csv`, form_data);
  }

  async export(productId, requestDate, filters) {
    const headersT = await http.getBearerToken();
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...filters, requestDate });
    const response = await http.get(`/tags/${productId}/csv${queryString}`, options);

    return response.data;
  }
}

export const tags = new Tags();
