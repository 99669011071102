import React from 'react';
import PropTypes from 'prop-types';
import { useViewSetupStyles } from './viewSetup.styles';
import { ViewProfile } from './ViewProfile/ViewProfile';
import { ViewAppearance } from './ViewAppearance/ViewAppearance';

export function ViewSetup({ configuration, installation, onChange }) {
  const classes = useViewSetupStyles();
  return (
    <div className={classes.setup}>
      <div className={classes.leftContainer}>
        <ViewProfile configuration={configuration} installation={installation} />
      </div>
      <div className={classes.rightContainer}>
        <ViewAppearance configuration={configuration} onChange={onChange} />
      </div>
    </div>
  );
}

ViewSetup.propTypes = {
  configuration: PropTypes.shape({
    legalBlockCountries: PropTypes.array
  }),
  installation: PropTypes.object,
  onChange: PropTypes.func
};
