import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { ViewContent } from './ViewContent';
import { useHtmlTemplateForm } from './useHtmlTemplateForm';
import { useFormHtmlTemplateStyles } from './formHtmlTemplate.styles';

export function FormHtmlTemplate({ formRef, onSubmit, htmlTemplate, errors, onChangeInput }) {
  const { t } = useTranslation(NAME_SPACE.STICKERS);
  const classes = useFormHtmlTemplateStyles();
  const { htmlTemplateInputs, handleChange } = useHtmlTemplateForm(htmlTemplate, onChangeInput);

  function handleSubmit() {
    const regexSrc = new RegExp('src=[\'"](?:[^"\'/]*/)*([^\'"]+)[\'"]', 'g');
    const regexUrl = new RegExp('url\\([\'"]data(?:[^"\'/]*/)*([^\'"]+)[\'"]\\)', 'g');

    onSubmit({
      ...htmlTemplateInputs,
      content: htmlTemplateInputs.content
        ? htmlTemplateInputs.content.replaceAll(regexSrc, 'src=""').replaceAll(regexUrl, 'url("")')
        : htmlTemplateInputs.content
    });
  }

  return (
    <Form
      className={classes.formHtmlTemplate}
      errors={errors}
      elementRef={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      onKeyPress={e => {
        if (e.which === 13) {
          if (e?.target?.className?.indexOf('gjs-trait') < 0) {
            e.preventDefault();
          }
          e.stopPropagation();
        }
      }}
    >
      <Grid container spacing={3}>
        <Grid xs={6} item>
          <Input
            isLoading={isSkeletonLoading(htmlTemplateInputs.name)}
            required
            name={t('properties.name')}
            label={t('properties.name')}
            variant="outlined"
            value={htmlTemplateInputs.name || ''}
            onChange={handleChange('name')}
            inputProps={{ 'aria-label': t('properties.name') }}
            validators={['required', 'maxStringLength:255']}
            errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
          />
        </Grid>
        <Grid item xs={12} className={classes.editor}>
          <ViewContent content={htmlTemplateInputs.content} onChange={handleChange('content')} />
        </Grid>
      </Grid>
    </Form>
  );
}

FormHtmlTemplate.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  htmlTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string
  }),
  onChangeInput: PropTypes.func
};
