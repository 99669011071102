import { SKELETON_KEY } from './skeletonFactory';

export function skeletonRelatedExperiences() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    languageId: SKELETON_KEY,
    language: SKELETON_KEY
  };
}

export function skeletonMultilanguageExperience(relatedExperiences = 5) {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    defaultLanguageId: SKELETON_KEY,
    defaultLanguage: SKELETON_KEY,
    relatedProducts: Array(relatedExperiences).fill(skeletonRelatedExperiences())
  };
}
