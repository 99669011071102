import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { ViewTable, execConfirmModal, ButtonDelete, ButtonAdd } from 'shared';
import { NAME_SPACE } from 'i18n';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useRelatedExperiencesBuildTable } from './useRelatedExperiencesBuildTable';
import { useViewRelatedExperiencesStyles } from './viewRelatedExperiences.styles';
import { FormRelatedExperiences } from './FormRelatedExperiences';

export const ViewRelatedExperiences = ({ relatedExperiences, onAddExperiences, onDeleteExperiences }) => {
  const classes = useViewRelatedExperiencesStyles();
  const { t } = useTranslation(NAME_SPACE.MULTILANGUAGE_EXPERIENCES);
  const { rows, headCells, getRelatedExperiences } = useRelatedExperiencesBuildTable(relatedExperiences, t);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formExperienceRef = useRef(null);

  function handleOpenAddModal() {
    handleOpen();
  }

  function handleModalAccept() {
    $formExperienceRef.current.submit();
    handleClose();
  }

  function handleModalClose() {
    handleClose();
  }

  function handleSubmit(experiences) {
    onAddExperiences(experiences);
  }

  async function handleDelete(experiencesToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          experiencesToDelete.length > 1
            ? t('relatedExperiences.removeConfirm.message', { length: experiencesToDelete.length })
            : t('relatedExperiences.removeConfirm.singularMessage', {
                name: experiencesToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });

      onDeleteExperiences(experiencesToDelete);
    } catch (err) {}
  }

  return (
    <>
      <ViewTable
        rows={rows}
        cells={headCells}
        mainKey={'name'}
        defaultOrderBy={{ property: 'name', direction: 'asc' }}
        onFetchData={getRelatedExperiences}
        emptyText={t('relatedExperiences.empty')}
        toolbar={{
          title: ' ',
          actions: [
            {
              condition: isAnySelected => !isAnySelected,
              cb: handleOpenAddModal,
              component: ButtonAdd
            },
            {
              condition: isAnySelected => isAnySelected,
              resetOnCB: true,
              cb: handleDelete,
              component: ButtonDelete
            }
          ]
        }}
      />

      <DialogModal
        className={classes.modal}
        maxWidth="sm"
        title={t('relatedExperiences.dialogTitle')}
        description=""
        isOpen={isOpen}
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormRelatedExperiences formRef={$formExperienceRef} onSubmit={handleSubmit} />
      </DialogModal>
    </>
  );
};

ViewRelatedExperiences.propTypes = {
  relatedExperiences: PropTypes.array.isRequired,
  onAddExperiences: PropTypes.func,
  onDeleteExperiences: PropTypes.func
};
