import { makeStyles } from '@material-ui/core/styles';

export const useViewDefaultsStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  titleContainer: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    lineHeight: 2.2,
    fontSize: '1.1rem'
  },
  contentContainer: {
    padding: `${theme.spacing(1)}px  !important`,
    backgroundColor: theme.palette.primary.light,
    margin: 0
  },
  select: {
    minWidth: '8rem',
    fontSize: 'inherit'
  },
  textarea: {
    height: '100%',
    '& .MuiFormControl-root': {
      height: '100%'
    },
    '& .MuiOutlinedInput-multiline': {
      height: '100%',
      padding: '20px 14px'
    },
    '& .MuiOutlinedInput-multiline textarea': {
      lineHeight: '1.5rem',
      height: '100%'
    }
  }
}));
