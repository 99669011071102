import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useFormMediaFileStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1
  },
  container: {
    width: '35rem'
  },
  galleryModal: {
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      maxHeight: '65vh'
    }
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
