import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ImageList } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { AddImageTile, ImageTile } from 'pages/shared';
import { useImageGalleryStyles } from './imageGallery.styles';
import { isSkeletonLoading } from 'shared/skeletonFactory';
import { TemplateImageTile } from './TemplateImageTile';

export const ImageGallery = props => {
  const {
    images = [],
    templateImages = [],
    readToken,
    onAddImageClick,
    onImageClick,
    onDeleteImageClick,
    tileMinHeight,
    tileMinWidth,
    rowHeight = 200,
    cols = 10,
    gap = 3,
    extraActions
  } = props;
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useImageGalleryStyles();

  function isValid(image, images) {
    return image.blobUrl && images.findIndex(i => i.tag === image.tag && i.id !== image.id) < 0;
  }

  return (
    <>
      <ImageList cols={cols} rowHeight={rowHeight} gap={gap} className={classes.gallery}>
        {onAddImageClick && !isSkeletonLoading(images[0]?.blobUrl) && (
          <AddImageTile
            onClick={onAddImageClick}
            title={t('common:actions.addFile')}
            cols={1}
            rows={1}
            tileMinHeight={tileMinHeight}
            tileMinWidth={tileMinWidth}
          />
        )}
        {images
          .filter(image => image.imageTemplateId)
          .map((image, index) => (
            <TemplateImageTile
              image={image}
              templateImage={
                templateImages.filter(
                  templateImage => image.imageTemplateId !== null && templateImage.id === image.imageTemplateId
                )[0]
              }
              key={index}
              readToken={readToken}
              isValid={isValid(image, images)}
              onClick={onImageClick}
              cols={1}
              rows={1}
              tileMinHeight={tileMinHeight}
              tileMinWidth={tileMinWidth}
              extraActions={extraActions}
            />
          ))}
        {images
          .filter(image => !image.imageTemplateId)
          .map((image, index) => (
            <ImageTile
              image={image}
              key={index}
              readToken={readToken}
              isValid={isValid(image, images)}
              onClick={onImageClick}
              onDeleteImageClick={onDeleteImageClick}
              cols={1}
              rows={1}
              tileMinHeight={tileMinHeight}
              tileMinWidth={tileMinWidth}
              extraActions={extraActions}
              canBeDeleted
            />
          ))}
      </ImageList>
    </>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.array,
  templateImages: PropTypes.array,
  readToken: PropTypes.string,
  onOpenAddFileDialog: PropTypes.func,
  onAddImageClick: PropTypes.func,
  onImageClick: PropTypes.func,
  onDeleteImageClick: PropTypes.func,
  tileMinHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tileMinWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cols: PropTypes.number,
  rows: PropTypes.number,
  rowHeight: PropTypes.number,
  gap: PropTypes.number,
  extraActions: PropTypes.func
};
