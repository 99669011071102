import { makeStyles } from '@material-ui/core';

export const useAccountContainerStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  white: {
    backgroundColor: 'white'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    height: '23px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  appBar: {
    background: theme.palette.secondary.light,
    filter: 'drop-shadow(0px 1px 10px rgba(74, 86, 109, 0.05))'
  },
  contentCentered: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-webkit-autofill::first-line':
      {
        '-webkit-box-shadow': `0 0 0 30px ${theme.palette.secondary.main} inset !important`,
        fontFamily: 'Mulish'
      },
    '& .MuiInputLabel-outlined': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  background: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.secondary.main
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto'
    /* paddingRight: '5rem',
    paddingLeft: '5rem' */
  },
  '.MuiTypography-root': {
    fontFamily: 'Mulish'
  }
}));
