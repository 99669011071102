import { makeStyles } from '@material-ui/core/styles';

export const useColorPickerStyles = makeStyles(theme => ({
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px'
  },
  swatch: {
    padding: '5px',
    background: 'white',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  swatchDisabled: {
    cursor: 'default'
  },
  popover: {
    position: 'absolute',
    zIndex: '2000'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    width: '100%'
  }
}));
