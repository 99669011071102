import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Input, Select } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { ICON_TARGET_TYPE, ImageTagSelect } from '../shared';
import { useViewIconStyles } from './viewIcon.styles';

export function ViewIcon({ icon, mediaFiles, targetTypes, onChange, onDelete }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewIconStyles();

  const handleChangeIcon = fieldName => event => {
    onChange(fieldName, { iconId: icon.id })(event);
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <ImageTagSelect
          variant="outlined"
          withAnyLabel
          required
          name={t('icons.properties.iconImageTag')}
          title={t('icons.properties.iconImageTag')}
          value={icon.iconImageTag}
          onChange={handleChangeIcon('iconImageTag')}
          options={mediaFiles}
          validators={['required']}
          errorMessage={t('validations.required')}
          error={!icon.iconImageTag}
          className={classes.select}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Select
          isLoading={isSkeletonLoading(icon.targetType)}
          elements={targetTypes}
          value={icon.targetType?.toString() || ''}
          name={t('icons.properties.targetType')}
          required
          onChange={handleChangeIcon('targetType')}
          title={t('icons.properties.targetType')}
          validators={['required']}
          errorMessages={[t('validations.required')]}
          className={classes.select}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={4}>
        {icon && icon.targetType === ICON_TARGET_TYPE.IMAGE ? (
          <ImageTagSelect
            variant="outlined"
            withAnyLabel
            required
            name={t('icons.properties.targetImageTag')}
            title={t('icons.properties.targetImageTag')}
            value={icon.targetImageTag}
            onChange={handleChangeIcon('targetImageTag')}
            options={mediaFiles}
            validators={['required']}
            errorMessage={t('validations.required')}
            error={!icon.targetImageTag}
            className={classes.select}
          />
        ) : (
          <Input
            isLoading={isSkeletonLoading(icon.targetLink)}
            required
            name={t('icons.properties.targetLink')}
            label={t('icons.properties.targetLink')}
            variant="outlined"
            value={icon.targetLink || ''}
            onChange={handleChangeIcon('targetLink')}
            inputProps={{ 'aria-label': t('icons.properties.targetLink') }}
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
        )}
      </Grid>
      <Grid item xs={9} sm={10} md={2} lg={1}>
        <Input
          isLoading={isSkeletonLoading(icon.order)}
          required
          type="number"
          name={t('icons.properties.order')}
          label={t('icons.properties.order')}
          variant="outlined"
          value={icon.order ? icon.order.toString() : ''}
          onChange={handleChangeIcon('order')}
          inputProps={{ 'aria-label': t('icons.properties.order') }}
          validators={['required']}
          errorMessages={[t('validations.required')]}
        />
      </Grid>
      <Grid item xs={3} sm={2} md={1} lg={1} className={classes.actionsContainer}>
        <Tooltip title={t('actions.remove')}>
          <IconButton
            onClick={evt => {
              evt.stopPropagation();
              onDelete(icon);
            }}
            aria-label={t('actions.remove')}
            style={{ fontSize: '0.5rem' }}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

ViewIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  targetTypes: PropTypes.array.isRequired,
  mediaFiles: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func
};
