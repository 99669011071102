import { useState, useEffect } from 'react';
import { installations } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';

const installationDummy = skeletonFactory.installation();

export function useInstallation() {
  const [installationData, setConfigurationData] = useState(installationDummy);
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await installations.getMy();
        if (response) {
          setConfigurationData(response.data || {});
        }
      }, false);
    }
    getData();
  }, [request]);

  return {
    installation: installationData,
    errorInfo,
    setErrorInfo
  };
}
