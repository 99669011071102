import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources } from './assets/locales';

export const NAME_SPACE = {
  COMMON: 'common',
  ANALYTICS: 'analytics',
  AR_MARKERS: 'arMarkers',
  AUTHENTICATION: 'authentication',
  CONFIGURATION: 'configuration',
  EXPERIENCES: 'experiences',
  HTML_TEMPLATES: 'htmlTemplates',
  MEDIA_FILES: 'mediaFiles',
  MULTILANGUAGE_EXPERIENCES: 'multilanguageExperiences',
  QR_CODES: 'qrCodes',
  STICKERS: 'stickers',
  TAGS: 'tags',
  USERS: 'users'
};

export const LANGUAGE = {
  ENGLISH: 'en'
};

export const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: [LANGUAGE.ENGLISH],
    ns: [
      NAME_SPACE.COMMON,
      NAME_SPACE.AUTHENTICATION,
      NAME_SPACE.ANALYTICS,
      NAME_SPACE.AR_MARKERS,
      NAME_SPACE.CONFIGURATION,
      NAME_SPACE.EXPERIENCES,
      NAME_SPACE.HTML_TEMPLATES,
      NAME_SPACE.MEDIA_FILES,
      NAME_SPACE.MULTILANGUAGE_EXPERIENCES,
      NAME_SPACE.QR_CODES,
      NAME_SPACE.STICKERS,
      NAME_SPACE.TAGS,
      NAME_SPACE.USERS
    ],
    defaultNS: NAME_SPACE.COMMON,
    fallbackNS: NAME_SPACE.COMMON,
    react: {
      wait: true,
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
