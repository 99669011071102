import { useCallback } from 'react';
import { useViewTableData, skeletonFactory, useHandleRequest } from 'shared';
import { qrCodes as qrCodesService } from 'services';

const qrCodesDummy = skeletonFactory.qrCodes();

export function useQRCodesPaginated() {
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  const {
    data: qrCodes,
    get: getQRCodes,
    deleteElements: deleteQRCodes,
    errorInfo: errorInfoList,
    paging,
    sorting,
    filteredCells,
    undoData
  } = useViewTableData({
    service: qrCodesService,
    paginatedSkeleton: qrCodesDummy
  });

  const addQRCode = useCallback(
    async qrCode => {
      try {
        return await request(async () => {
          const createdQRCode = await qrCodesService.create(qrCode);
          if (createdQRCode && createdQRCode.data) {
            await getQRCodes(paging, sorting, filteredCells);
            return createdQRCode;
          }
        });
      } catch (error) {
        undoData();
      }
    },
    [request, undoData, getQRCodes, paging, sorting, filteredCells]
  );

  return {
    qrCodes,
    deleteQRCodes,
    addQRCode,
    errorInfoList,
    errorInfo,
    setErrorInfo,
    getQRCodes
  };
}
