import { useCallback } from 'react';
import FileSaver from 'file-saver';
import { useViewTableData, skeletonFactory, useHandleRequest } from 'shared';
import { tags as tagsService } from 'services';

const tagsDummy = skeletonFactory.tags();

export function useTagsPaginated() {
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  const {
    data: tags,
    get: getTags,
    deleteElements: deleteTags,
    errorInfo: errorInfoList,
    paging,
    sorting,
    filteredCells,
    setData,
    undoData
  } = useViewTableData({
    service: tagsService,
    paginatedSkeleton: tagsDummy
  });

  const addTagRange = useCallback(
    async tagRange => {
      try {
        return await request(async () => {
          setData({ ...tagsDummy });
          const createdRange = await tagsService.createRange(tagRange);
          if (createdRange && createdRange.file) {
            var blob = new Blob([atob(createdRange.file)], { type: 'text/csv' });
            FileSaver.saveAs(blob, `tags-${tagRange.productId}.csv`);
          }

          await getTags(paging, sorting, filteredCells);

          return createdRange;
        });
      } catch (error) {
        undoData();
      }
    },
    [request, setData, undoData, filteredCells, paging, sorting, getTags]
  );

  const importTags = useCallback(
    async ({ productId, selectedFile }) => {
      try {
        return await request(async () => {
          setData({ ...tagsDummy });

          const success = await tagsService.import(productId, selectedFile);
          await getTags(paging, sorting, filteredCells);
          return success;
        });
      } catch (error) {
        undoData();
      }
    },
    [request, setData, undoData, filteredCells, paging, sorting, getTags]
  );

  const exportTags = useCallback(
    async ({ productId, requestDate }) => {
      try {
        return await request(async () => {
          const file = await tagsService.export(productId, requestDate, filteredCells);
          if (file) {
            FileSaver.saveAs(file, `tags-${productId}.csv`);
            await getTags(paging, sorting, filteredCells);
            return true;
          }
        });
      } catch (error) {
        return false;
      }
    },
    [request, filteredCells, paging, sorting, getTags]
  );

  return {
    tags,
    deleteTags,
    addTagRange,
    importTags,
    exportTags,
    errorInfoList,
    errorInfo,
    setErrorInfo,
    getTags
  };
}
