import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Button, Chip, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzoneStyles } from './dropzone.styles';

export function Dropzone({
  onDrop,
  files,
  maxFiles,
  maxFileSize,
  accept,
  disabled,
  onDeleteFile,
  labelDrop,
  isLoading,
  skeletonHeight = 48
}) {
  const [filesReaded, setFilesReaded] = useState(files || []);
  const theme = useTheme();
  const classes = useDropzoneStyles();

  useEffect(() => {
    return setFilesReaded(files);
  }, [files]);

  function handleDeleteFile(file) {
    setFilesReaded(prev => prev.filter(prevFile => prevFile !== file));
    onDeleteFile(file);
  }

  const handleDragEnter = useCallback(
    event => {
      event.target.style.backgroundColor = theme.palette.secondary.main;
    },
    [theme]
  );

  const handleDragLeave = useCallback(event => {
    event.target.style.backgroundColor = 'unset';
  }, []);

  const handleOnDrop = useCallback(
    acceptedFiles => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    maxFiles: maxFiles,
    maxSize: maxFileSize,
    onDrop: handleOnDrop,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave
  });

  return (
    <div {...getRootProps()} className={`${classes.root} ${disabled ? 'Mui-disabled' : ''}`}>
      <div className={classes.wrapper}>
        <input {...getInputProps()} />
        {filesReaded && filesReaded.length > 0 ? (
          isLoading ? (
            <Skeleton width="215px" height={skeletonHeight} />
          ) : (
            filesReaded.map((file, index) => (
              <Chip
                key={index}
                disabled={disabled}
                icon={<FileCopy />}
                label={file.tag}
                onDelete={() => handleDeleteFile(file)}
                className={classes.chip}
              />
            ))
          )
        ) : (
          <div className={classes.message}>
            <Button disabled={disabled} variant="text" className={classes.button}>
              <CloudUploadIcon />
            </Button>
            <Typography color="textSecondary">{labelDrop}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

Dropzone.propTypes = {
  onDrop: PropTypes.func,
  onDeleteFile: PropTypes.func,
  files: PropTypes.array,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelDrop: PropTypes.string,
  isLoading: PropTypes.bool,
  skeletonHeight: PropTypes.number
};
