import { useMemo } from 'react';

export function useHtmlTemplatesBuildTable(inputs, t) {
  const headCells = useMemo(() => {
    return [{ id: 'name', numeric: false, disablePadding: false, label: t('properties.name') }];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }
    return inputs.items.map(htmlTemplate => ({
      name: htmlTemplate.name,
      id: htmlTemplate.id
    }));
  }, [inputs]);

  return { headCells, rows };
}
