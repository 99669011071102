export const genderSelect = (editor, showInBlockManager = true) => {
  if (showInBlockManager) {
    editor.BlockManager.add('gender-select', {
      category: 'Experience Utils',
      label: 'Gender select',
      attributes: { class: 'fa fa-venus-mars' },
      content: { type: 'gender-select', classes: ['gender-select'] },
      type: 'gender-select'
    });
  }

  editor.DomComponents.addType('gender-select', {
    isComponent: el => el.tagName === 'SELECT' && el.className === 'gender-select',

    model: {
      defaults: {
        tagName: 'select',
        className: 'gender-select',
        attributes: { name: 'gender' },
        droppable: false,
        highlightable: false,
        components: [
          { type: 'option', content: '', attributes: { value: '' } },
          { type: 'option', content: 'Male', attributes: { value: 'M' } },
          { type: 'option', content: 'Female', attributes: { value: 'F' } }
        ],
        traits: [
          {
            name: 'options',
            type: 'select-options-custom'
          },
          {
            type: 'checkbox',
            name: 'required'
          }
        ]
      }
    }
  });
};
