export const paperBlocks = (editor) => {
	editor.BlockManager.add('body-a6-container', {
		category: 'Paper Utils',
		label: 'A6 container',
		attributes: { class: 'fa fa-newspaper-o' },
		content: '<main class="a6-container" data-a6-paper></main>',
		type: 'cmp-a6-container',
	});

	editor.BlockManager.add('body-a5-container', {
		category: 'Paper Utils',
		label: 'A5 container',
		attributes: { class: 'fa fa-newspaper-o' },
		content: '<main class="a5-container" data-a5-paper></main>',
		type: 'cmp-a5-container',
	});

	editor.BlockManager.add('body-a4-container', {
		category: 'Paper Utils',
		label: 'A4 container',
		attributes: { class: 'fa fa-newspaper-o' },
		content: '<main class="a4-container" data-a4-paper></main>',
		type: 'cmp-a4-container',
	});
};
