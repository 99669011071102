import { makeStyles } from '@material-ui/core/styles';
export const useFormCsvFileUploadStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1
  },
  container: {
    width: '35rem'
  },
  tooltipWrapper: {
    width: '50%'
  }
}));
