import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useQRious } from 'shared';
import { useFormDownloadQRCodeStyles } from './formDownloadQRCode.styles';

export function FormDownloadQRCode({ formRef, qrCode, onSubmit }) {
  const { t } = useTranslation(NAME_SPACE.QR_CODES);
  const classes = useFormDownloadQRCodeStyles();
  const [inputs, setInputs] = useState({ ...qrCode, includeMargin: false });
  const { dataUrl, getQRCode } = useQRious();

  useEffect(() => {
    if (inputs?.url) {
      getQRCode({ value: inputs?.url, padding: inputs?.includeMargin ? 98 : 0 });
    }
  }, [inputs]);

  function handleSubmit() {
    onSubmit({ ...inputs, dataUrl: dataUrl });
  }

  const handleSwitchChange =
    fieldName =>
    ({ target }) => {
      setInputs(inputs => ({ ...inputs, [fieldName]: !inputs[fieldName] }));
    };

  return (
    <Form className={classes.form} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Switch
            label={t('download.properties.includeMargin')}
            checked={inputs.includeMargin || false}
            onChange={handleSwitchChange('includeMargin')}
          />
        </Grid>
        <Grid item xs={12} className={classes.centered}>
          <div style={{ height: 300, width: 300, border: '1px dashed silver' }}>
            <img src={dataUrl} style={{ height: '100%' }} alt="QR Code" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}

FormDownloadQRCode.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  qrCode: PropTypes.shape({ id: PropTypes.string, url: PropTypes.string }),
  onSubmit: PropTypes.func.isRequired
};
