import { SKELETON_KEY } from './skeletonFactory';

export function skeletonMediaFiles() {
  return {
    id: SKELETON_KEY,
    tag: SKELETON_KEY,
    targetLink: SKELETON_KEY,
    blobUrl: SKELETON_KEY
  };
}

export function skeletonSticker(mediaFilesNum = 4) {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    productId: SKELETON_KEY,
    product: SKELETON_KEY,
    content: SKELETON_KEY,
    readToken: SKELETON_KEY,
    mediaFiles: Array(mediaFilesNum).fill(skeletonMediaFiles())
  };
}
