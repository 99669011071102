import { http } from './http';
import { apiHelper } from 'shared';

class MediaFiles {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/media-files/${queryString}`);
  }

  getById(id) {
    return http.get(`/media-files/${id}`);
  }

  create(mediaFile) {
    return http.post('/media-files', mediaFile);
  }

  delete(id) {
    return http.delete(`/media-files/${id}`);
  }

  update(id, mediaFile) {
    return http.put(`/media-files/${id}`, mediaFile);
  }
}

export const mediaFiles = new MediaFiles();
