import { selectOptionsCustomTrait } from '../customTraits';
import { basicCommands } from './basicCommands';
import { basicExtendedBlocks } from './basicExtendedBlocks';
import { basicPanels } from './basicPanels';
import { basicStyles } from './basicStyles';

export const basicPlugin = (editor, config) => {
  basicCommands(editor, config);
  basicPanels(editor);
  basicStyles(editor);
  basicExtendedBlocks(editor);
  selectOptionsCustomTrait(editor);
};
