const deviceButtons = [
  {
    id: 'device-desktop',
    name: 'Desktop',
    command: 'set-device-desktop',
    active: true,
    togglable: false,
    className: 'fa fa-desktop',
    attributes: { title: 'Desktop view' }
  },
  {
    id: 'device-mobile',
    name: 'Mobile',
    command: 'set-device-mobile',
    togglable: false,
    className: 'fa fa-mobile',
    attributes: { title: 'Mobile view' }
  }
];

export const devicePanels = editor => {
  const panelManager = editor.Panels;

  var devicesPanel = panelManager.getPanel('panel-devices');
  if (!devicesPanel) {
    panelManager.addPanel({
      id: 'panel-devices',
      el: '.panel__devices',
      buttons: deviceButtons
    });
  } else {
    devicesPanel.get('buttons').add(deviceButtons);
  }
};
