import React, { useCallback, useEffect, useState } from 'react';
import defaultLogo from '../assets/images/logo_hgtag.png';
import defaultBackground from '../assets/images/backoffice_smart_packaging.jpg';
import { settings, KEY_SETTINGS } from 'settings';
import { useHandleRequest } from 'shared';
import { configurations } from 'services';

export const BrandContext = React.createContext({});

export function useBrandContext() {
  const { request } = useHandleRequest();
  const [brand, setBrand] = useState({});

  useEffect(() => {
    async function getData() {
      try {
        await request(async () => {
          const response = await configurations.getPublic();
          const brandConfiguration = response?.data;

          const navigationLogo = brandConfiguration?.navigationLogo || brandConfiguration?.mainLogo;
          const readToken = brandConfiguration?.readToken ? `?${brandConfiguration?.readToken}` : '';
          setBrand({
            name: settings.getSetting(KEY_SETTINGS.BRAND),
            logo: brandConfiguration?.mainLogo ? `${brandConfiguration?.mainLogo}${readToken}` : defaultLogo,
            layoutLogo: navigationLogo ? `${navigationLogo}${readToken}` : defaultLogo,
            welcomeImage: brandConfiguration?.loginBackground
              ? `${brandConfiguration?.loginBackground}${readToken}`
              : defaultBackground
          });
        });
      } catch (error) {
        setBrand({
          name: settings.getSetting(KEY_SETTINGS.BRAND),
          logo: defaultLogo,
          layoutLogo: defaultLogo,
          welcomeImage: defaultBackground
        });
      }
    }
    getData();
  }, [request]);

  const setBrandImages = useCallback(({ mainLogo, loginBackground, navigationLogo, readToken }) => {
    const newReadToken = readToken ? `?${readToken}` : '';

    setBrand(prevBrand => ({
      ...prevBrand,
      logo: mainLogo ? `${mainLogo}${newReadToken}` : defaultLogo,
      layoutLogo: navigationLogo || mainLogo ? `${navigationLogo || mainLogo}${newReadToken}` : defaultLogo,
      welcomeImage: loginBackground ? `${loginBackground}${newReadToken}` : defaultBackground
    }));
  }, []);

  return {
    brand,
    setBrandImages
  };
}
