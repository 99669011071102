export const color = {
  basic: {
    brightest: 'rgba(219, 222, 252, 0.55)',
    brighter: '#d2d2d2',
    bright: '#c2c5ca',
    normal: '#8A9AAF',
    semidark: '#4A566D',
    dark: '#333333',
    darker: '#000000'
  },
  primary: {
    dark: '#ad832b',
    light: '#fbf7ef', // '#f6e9cf',
    main: '#D6AA4F'
  },
  secondary: {
    dark: '#c2c5ca',
    light: '#ffffff',
    main: '#eeeeee'
  },
  action: {
    error: '#f44336',
    activeButton: 'rgba(173, 131, 43, 0.7)'
  },
  table: {
    header: '#eeeeee',
    summary: '#eeeeee'
  },
  status: {
    green: '#2ACC92',
    yellow: '#FFB935',
    red: '#EB3030',
    navyBlue: '#232977',
    blue: '#4d5df1',
    purple: '#5515A7'
  },
  toast: {
    error: 'rgb(254 112 98)'
  },
  boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
  buttons: {
    disabled: {
      background: 'rgba(168, 168, 169, 0.2)',
      text: '#838383'
    },
    add: {
      background: 'rgba(122, 173, 122, 0.2)',
      text: '#7AAD7A'
    },
    delete: {
      background: 'rgba(235, 108, 112, 0.2)',
      text: '#EB6C70'
    },
    primary: {
      background: 'rgba(214, 170, 79, 0.2)',
      text: '#D6AA4F'
    }
  }
};
