import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { ViewTable, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { NAME_SPACE } from 'i18n';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useFieldBuildTable } from './useFieldBuildTable';
import { FormField } from './FormField.js';
import { useViewFieldsStyles } from './viewFields.styles';
import { experienceHelper } from '../shared';

export const ViewFields = ({
  fields,
  onEditField,
  onDeleteFields,
  errorInfo,
  errorMessage,
  successMessage,
  resetErrorAndSuccessMessages
}) => {
  const classes = useViewFieldsStyles();
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const { rows, headCells, getExperienceFields } = useFieldBuildTable(fields, t);
  const [selectedField, setSelectedField] = useState(null);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formExperienceFieldRef = useRef(null);

  function handleGetNextFieldOrder() {
    return experienceHelper.getNextOrder(fields);
  }

  function handleDetail([experienceFieldId]) {
    setSelectedField(
      experienceFieldId ? fields.find(field => field.id === experienceFieldId) : { order: handleGetNextFieldOrder() }
    );
    handleOpen();
  }

  function handleModalAccept() {
    $formExperienceFieldRef.current.submit();
  }

  function handleModalClose() {
    resetErrorAndSuccessMessages();
    handleClose();
  }

  function handleFieldSubmit(field) {
    onEditField(field);
  }

  async function handleDelete(fieldsToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          fieldsToDelete.length > 1
            ? t('fields.removeConfirm.message', { length: fieldsToDelete.length })
            : t('fields.removeConfirm.singularMessage', {
                title: fieldsToDelete[0].title
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });

      onDeleteFields(fieldsToDelete);
    } catch (err) {}
  }

  return (
    <>
      <ViewTable
        rows={rows}
        errors={errorInfo}
        cells={headCells}
        mainKey={'name'}
        defaultOrderBy={{ property: 'order', direction: 'asc' }}
        onFetchData={getExperienceFields}
        emptyText={t('fields.empty')}
        actions={[
          {
            title: t('actions.view'),
            name: 'view',
            properties: ['id'],
            label: t('common:edit'),
            cb: handleDetail,
            icon: <EditIcon />
          }
        ]}
        toolbar={{
          title: t('fields.name'),
          actions: [
            {
              condition: isAnySelected => !isAnySelected,
              cb: handleDetail,
              component: ButtonAdd
            },
            {
              condition: isAnySelected => isAnySelected,
              resetOnCB: true,
              cb: handleDelete,
              component: ButtonDelete
            }
          ]
        }}
      />

      <DialogModal
        className={classes.modal}
        maxWidth="md"
        fullWidth
        title={t('fields.dialogTitle')}
        description=""
        isOpen={isOpen}
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormField
          formRef={$formExperienceFieldRef}
          onSubmit={handleFieldSubmit}
          field={{ ...selectedField }}
          successMessage={successMessage}
          errorMessage={errorMessage}
          resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
          onGetNextFieldOrder={handleGetNextFieldOrder}
        />
      </DialogModal>
    </>
  );
};

ViewFields.propTypes = {
  fields: PropTypes.array.isRequired,
  onEditField: PropTypes.func,
  onDeleteFields: PropTypes.func,
  errorInfo: PropTypes.object,
  setErrorInfo: PropTypes.func,
  isDeleteEnabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
