import React from 'react';
import { ImageListItem } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImageTileStyles } from './imageTile.styles';

export const AddImageTile = props => {
  const {
    onClick,
    title,
    disabled = false,
    tileMinHeight,
    tileMinWidth,
    style,
    className,
    cols = 1,
    rows = 1,
    component = 'li'
  } = props;
  const classes = useImageTileStyles();

  return (
    <ImageListItem
      id="addFile"
      className={`${classes.tile} ${className}`}
      cols={cols}
      rows={rows}
      style={{ ...style, minHeight: tileMinHeight, minWidth: tileMinWidth }}
      component={component}
    >
      <div className={classes.imageContainer} onClick={() => !disabled && onClick && onClick()}>
        <CloudUploadOutlined className={classes.addFileIcon} />
        <span>{title}</span>
      </div>
    </ImageListItem>
  );
};

AddImageTile.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  tileMinHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tileMinWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cols: PropTypes.number,
  rows: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.object,
  component: PropTypes.string
};

AddImageTile.defaultProps = {
  title: 'Add file'
};
