import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ViewBlockCountries } from './ViewBlockCountries';
import { ViewBlockPages } from './ViewBlockPages/ViewBlockPages';
import { useViewLegalBlockStyles } from './viewLegalBlock.styles';
import { ViewDefaultSection } from './ViewDefaultSection/ViewDefaultSection';

export function ViewLegalBlock({ configuration, onSwitchChange, onChange, onAddCountry, onDeleteCountry }) {
  const classes = useViewLegalBlockStyles();
  return (
    <div className={classes.legalBlock}>
      <div className={classes.leftContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              <ViewBlockPages configuration={configuration} onSwitchChange={onSwitchChange} />
            </div>
            <div>
              <ViewDefaultSection configuration={configuration} onChange={onChange} />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.rightContainer}>
        <ViewBlockCountries
          countries={configuration.legalBlockCountries}
          onAddCountry={onAddCountry}
          onDeleteCountry={onDeleteCountry}
        />
      </div>
    </div>
  );
}

ViewLegalBlock.propTypes = {
  configuration: PropTypes.shape({
    legalBlockCountries: PropTypes.array
  }),
  onSwitchChange: PropTypes.func,
  onChange: PropTypes.func,
  onAddCountry: PropTypes.func,
  onDeleteCountry: PropTypes.func
};
