import { lessProgress, moreProgress, stopProgress } from './progress';
import { execToaster, TOASTER_TYPES } from 'shared';
import i18n from 'i18n';
import { PATH } from 'app.routes.const';

export function configureRequestInterceptor(instance) {
  instance.interceptors.request.use(
    async config => {
      moreProgress();

      if (!config.headers.Accept) config.headers.Accept = 'application/json';
      if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';

      return config;
    },
    error => {
      execToaster({ name: i18n.t('request.error'), text: error.message, type: TOASTER_TYPES.ERROR });
      throw error;
    }
  );
}

export function configureResponseInterceptor(instance) {
  instance.interceptors.response.use(
    async response => {
      lessProgress();
      if (response.config.method !== 'get') {
        execToaster({
          name: i18n.t('request.succes'),
          text: i18n.t('request.expected'),
          type: TOASTER_TYPES.SUCCESS
        });
      }

      return response;
    },
    error => {
      if (!window.location.href.includes(PATH.AUTHENTICATION.LOGIN))
        execToaster({ name: i18n.t('request.error'), text: error.message, type: TOASTER_TYPES.ERROR });
      stopProgress();
      throw error;
    }
  );
}
