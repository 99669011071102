import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useViewArticleRatingItemStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(0)}px  !important`,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  select: {
    minWidth: '8rem'
  },
  actionsContainer: {
    textAlign: 'center'
  },
  chip: {
    '& .MuiChip-root': {
      backgroundColor: `${theme.palette.primary.light} !important`
    }
  },
  modal: {
    '& .MuiDialog-paperWidthMd': {
      minWidth: '20rem'
    }
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
