import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { NAME_SPACE } from 'i18n';
import { SortSelect } from './SortSelect';

export function MediaFileFilters({ onChangeSort, onChangeFilter }) {
  const { t } = useTranslation(NAME_SPACE.MEDIA_FILES);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <TextField
          name={t('properties.tag')}
          label={t('properties.tag')}
          variant="outlined"
          fullWidth
          onChange={onChangeFilter('tag')}
          inputProps={{ 'aria-label': t('properties.tag') }}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <SortSelect onChange={onChangeSort} title="Sort By" />
      </Grid>
    </Grid>
  );
}

MediaFileFilters.propTypes = {
  onChangeSort: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};
