import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

export function Loading({ className }) {
  return (
    <div className={`${className}`}>
      <CircularProgress className={className} />
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string
};
