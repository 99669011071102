import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NAME_SPACE } from 'i18n';
import { useParams, useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { DialogModal, Summary, useHandleOpen } from '@engloba-tech/englobity';
import { PATH } from 'app.routes.const';
import { FormPage, useRequestLoading, ButtonBack, Button, ButtonSave } from 'shared';
import { UserContext } from 'auth';
import { FormUser } from './FormUser';
import { useUser } from './useUser';
import { FormChangePassword } from './FormChangePassword';
import { Grid } from '@material-ui/core';
import { useEditUserStyles } from './editUser.styles';

export function EditUser({ profile }) {
  const { id } = useParams();
  const { user, saveUser, errorInfo, setErrorInfo, changePassword, changePasswordSuccess, setChangePasswordSuccess } =
    useUser(id, profile);
  const { t } = useTranslation(NAME_SPACE.USERS);
  const $formRef = useRef(null);
  const history = useHistory();
  const { user: userFromContext, setUser } = useContext(UserContext);
  const { requestLoading, callbackRequest } = useRequestLoading();
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formChangePassword = useRef(null);
  const classes = useEditUserStyles();

  const isProfile = profile === true;

  async function handleSubmitOnProfile(user) {
    await callbackRequest(async () => {
      await saveUser(user);
      setUser({
        ...userFromContext,
        ...user,
        alias: user.userName
      });
    });
  }

  async function handleSubmit(user) {
    await callbackRequest(async () => {
      await saveUser(user);
      history.push(PATH.USERS.LIST);
    });
  }

  function handleSaveClick() {
    $formRef.current.submit();
  }

  function handleGoBack() {
    history.push(PATH.USERS.LIST);
  }

  function composeActionButtons() {
    const actionButtons = [
      ...(!isProfile
        ? [
            {
              icon: <KeyboardBackspaceIcon />,
              text: t(`${NAME_SPACE.COMMON}:actions.back`),
              onClick: handleGoBack,
              component: ButtonBack
            }
          ]
        : []),
      ...(!isCreating && isProfile
        ? [
            {
              icon: <VpnKeyIcon />,
              text: t('actions.changePassword.title'),
              onClick: handleOpenChangePasswordModal,
              component: Button
            }
          ]
        : []),
      {
        icon: <SaveAltIcon />,
        text: t(`${NAME_SPACE.COMMON}:actions.save`),
        onClick: handleSaveClick,
        type: 'primary',
        enableOnDirty: true,
        component: ButtonSave
      }
    ];
    return actionButtons;
  }

  function handleOpenChangePasswordModal() {
    setChangePasswordSuccess(false);
    handleOpen();
  }

  function handleCloseChangePasswordModal() {
    setErrorInfo(null);
    handleClose();
  }

  async function onChangePassword(request) {
    var success = await changePassword(request);
    if (success) {
      setChangePasswordSuccess(true);
      handleClose();
    }
  }

  const isCreating = !id;

  return (
    <>
      <FormPage
        title={t('singularName')}
        disabled={requestLoading}
        buttons={composeActionButtons(isCreating, isProfile)}
        onSubmit={isProfile ? handleSubmitOnProfile : handleSubmit}
        className={classes.formOverride}
      >
        {changePasswordSuccess && (
          <Grid item xs={12}>
            <Summary text={t('actions.changePassword.success')} severity="success" />
          </Grid>
        )}

        <FormUser
          errors={!isOpen && errorInfo}
          formRef={$formRef}
          user={{ ...user }}
          createMode={isCreating}
          profile={profile}
        />
      </FormPage>

      <DialogModal
        title={t('actions.changePassword.title')}
        description={t('actions.changePassword.description')}
        fullWidth
        isOpen={isOpen}
        onClose={handleCloseChangePasswordModal}
        buttons={[
          {
            children: <KeyboardArrowLeft />,
            text: t('common:actions.back'),
            type: 'secondary',
            onClick: handleCloseChangePasswordModal,
            className: classes.buttonDelete
          },
          {
            children: <SaveAltIcon />,
            text: t('common:actions.save'),
            type: 'primary',
            onClick: () => $formChangePassword.current.submit(),
            className: classes.buttonAdd
          }
        ]}
      >
        <FormChangePassword formRef={$formChangePassword} onSubmit={onChangePassword} errorInfo={errorInfo} />
      </DialogModal>
    </>
  );
}

EditUser.propTypes = {
  profile: PropTypes.bool
};
