import React from 'react';
import PropTypes from 'prop-types';
import { FormPage } from 'shared';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { OpenInNewOutlined } from '@material-ui/icons';
import { useAnalyticsDashboardStyles } from './analyticsDashboard.styles';
import { settings, KEY_SETTINGS } from 'settings';
import { Button } from 'shared';

export function AnalyticsDashboard() {
  const classes = useAnalyticsDashboardStyles();
  const { t } = useTranslation(NAME_SPACE.ANALYTICS);

  const dashboardUrl = settings.getSetting(KEY_SETTINGS.DASHBOARD_URL);

  return (
    <FormPage
      buttons={[
        {
          icon: <OpenInNewOutlined />,
          text: t('powerBiLink'),
          onClick: () => window.open(dashboardUrl, '_blank'),
          component: Button
        }
      ]}
    >
      <div className={classes.root}>
        <div className={classes.report}>
          <iframe src={dashboardUrl} frameBorder={0} allowFullScreen={true} title={t('name')}></iframe>
        </div>
      </div>
    </FormPage>
  );
}

AnalyticsDashboard.propTypes = {
  pageTitle: PropTypes.string
};
