import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Summary, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useFormFieldStyles } from './formField.styles';

export function FormField({
  field,
  formRef,
  onSubmit,
  successMessage,
  errorMessage,
  resetErrorAndSuccessMessages,
  onGetNextFieldOrder
}) {
  const [inputs, setInputs] = useState({ ...field } || {});
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormFieldStyles();

  useEffect(() => {
    if (successMessage && !field?.id) {
      setInputs({ order: onGetNextFieldOrder() });
    }
  }, [field?.id, successMessage, onGetNextFieldOrder]);

  function handleSubmit() {
    resetErrorAndSuccessMessages();
    onSubmit(inputs);
  }

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));
    };

  return (
    <>
      {(successMessage || errorMessage) && (
        <Grid item xs={12}>
          <Summary
            text={successMessage ? t(successMessage) : t(errorMessage)}
            severity={successMessage ? 'success' : 'error'}
          />
        </Grid>
      )}
      <Form
        className={classes.formField}
        errors={errorMessage}
        elementRef={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item md={5} sm={12} xs={12}>
            <Input
              isLoading={isSkeletonLoading(inputs.title)}
              required
              name={t('fields.properties.title')}
              label={t('fields.properties.title')}
              variant="outlined"
              value={inputs.title || ''}
              onChange={handleChange('title')}
              inputProps={{ 'aria-label': t('fields.properties.title') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <Input
              isLoading={isSkeletonLoading(inputs.value)}
              required
              name={t('fields.properties.value')}
              label={t('fields.properties.value')}
              variant="outlined"
              value={inputs.value || ''}
              onChange={handleChange('value')}
              inputProps={{ 'aria-label': t('fields.properties.value') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.order)}
              required
              type="number"
              name={t('fields.properties.order')}
              label={t('fields.properties.order')}
              variant="outlined"
              value={inputs.order || ''}
              onChange={handleChange('order')}
              inputProps={{ 'aria-label': t('fields.properties.order') }}
              validators={['required']}
              errorMessages={[t('validations.required')]}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormField.propTypes = {
  field: PropTypes.object.isRequired,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func,
  onGetNextFieldOrder: PropTypes.func
};
