import grapesjs from 'grapesjs';

const defaultBaseCss = `
* {
  box-sizing: border-box;
}
html, body, [data-gjs-type=wrapper] {
  min-height: 100%;
}
body {
  margin: 0;
  height: 100%;
  background-color: white
}
[data-gjs-type=wrapper] {
  overflow: auto;
}

* ::-webkit-scrollbar-track {
  background: #3d3d3d
}

* ::-webkit-scrollbar-thumb {
  background: #646464
}

* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.gjs-plh-image {
  height: 100% !important;
  width: 100% !important;
  max-height: 100px;
  max-width: 100px;
  outline: none !important;
}
`;

const defaultAssetManager = {
  upload: false,
  embedAsBase64: false,
  custom: false,
  assets: [],
  showUrlInput: false,
  noAssets: 'No images available',
  stylePrefix: 'customAm-'
};

export const editorConfiguration = ({
  id,
  forceClass = false,
  height = '100%',
  width = '100%',
  fromElement = true,
  protectedCss = '',
  wrapper = 'div',
  clearStyles = false,
  storageManager = { type: 0 },
  assetManager = { ...defaultAssetManager },
  avoidInlineStyle = true,
  noticeOnUnload = 0, // 0 - prevents alert message 'unsaved changed changes'
  plugins = [],
  devices = [],
  mediaCondition = 'max-width',
  canvasStyles = [],
  baseCss = defaultBaseCss,
  pluginsOpts = {},
  canOpenAssetManager = true
}) => {
  const editor = grapesjs.init({
    container: `#${id}`, // TODO Put Id in selectors and classnames, etc
    forceClass: forceClass,
    height: height,
    width: width,
    fromElement: fromElement,
    protectedCss: protectedCss,
    clearStyles: clearStyles,
    storageManager: storageManager,
    avoidInlineStyle: avoidInlineStyle,
    layerManager: {
      appendTo: '.layer-manager-container'
    },
    panels: {
      defaults: [
        {
          id: 'panel-top',
          el: '.panel__top'
        }
      ]
    },
    plugins: [...plugins],
    noticeOnUnload: noticeOnUnload,
    blockManager: {
      appendTo: '.block-manager-container'
    },
    deviceManager: {
      default: 'Mobile',
      devices: [...devices]
    },
    // mediaCondition: mediaCondition,
    selectorManager: {
      componentFirst: true,
      appendTo: '.selector-container'
    },
    styleManager: {
      appendTo: '.styles-container',
      sectors: []
    },
    traitManager: {
      appendTo: '.trait-manager-container'
    },
    assetManager: assetManager,
    listenToEl: [document.querySelector('#main-content')], // Needed to fix the highlight offset on scroll
    colorPicker: {
      // TODO remove when issue is resolved
      appendTo: 'parent',
      offset: { top: 26, left: -160 }
    },
    canvas: {
      styles: [...canvasStyles]
    },
    baseCss: baseCss,
    pluginsOpts: pluginsOpts,
    parser: {
      optionsHtml: {
        allowUnsafeAttr: true
      }
    }
  });

  editor.Components.addType('wrapper', {
    model: {
      defaults: {
        tagName: wrapper // use wrapper instead of `body` in HTML export
      },

      // Skip wrapper in the HTML output
      toHTML(opts) {
        return this.getInnerHTML(opts);
      }
    }
  });

  if (!canOpenAssetManager) {
    editor.Commands.add('open-assets', () => {
      return true;
    });
  }

  return editor;
};
