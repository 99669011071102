import React from 'react';
import { useMemo } from 'react';
import { isSkeletonLoading } from 'shared';
import { Chip } from '@material-ui/core';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';
import { ROLES } from 'auth/roles.const';

export function useUsersBuildTable(inputs, t) {
  const headCells = useMemo(() => {
    return [
      {
        id: 'userName',
        numeric: false,
        disablePadding: true,
        label: t('properties.userName'),
        isSortable: false,
        filterType: FILTER_TYPE.NONE
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: t('properties.role'),
        isSortable: false,
        filterType: FILTER_TYPE.NONE
      }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }
    return inputs.items.map(user => ({
      userName: user.userName,
      role: isSkeletonLoading(user.role) ? user.role : <Chip label={user.role} style={{ marginRight: 5 }} />,
      id: user.id,
      canBeDeleted: user.role !== ROLES.TAG_MANAGER,
    }));
  }, [inputs]);

  return { headCells, rows };
}
