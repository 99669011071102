import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { FormHelperText } from '@material-ui/core';

export function ImageTagSelect({ options, value = '', withAnyLabel, error, errorMessage, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);

  const elements = useMemo(() => {
    let el = [];
    if (withAnyLabel) el.push({ value: '', name: t('selectFirstOption') });

    el = el.concat(
      options
        .filter(image => image.tag)
        .map(image => ({ value: image.tag, name: image.tag }))
        .sort((a, b) => (a.value > b.value ? 1 : -1))
    );

    return el;
  }, [t, withAnyLabel, options]);

  return (
    <>
      <Select isLoading={isSkeletonLoading(value)} {...props} fullWidth value={value || ''} elements={elements} />
      {error && errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
}

ImageTagSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  withAnyLabel: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};
