import { makeStyles } from '@material-ui/core/styles';

export const useFormStickerStyles = makeStyles(theme => ({
  formSticker: {
    flexGrow: 1,
    padding: '16px !important'
  },
  tabsMinHeight: {
    minHeight: '22.1875rem'
  }
}));
