import { makeStyles } from '@material-ui/core/styles';

export const useViewHistoryStatStyles = makeStyles(theme => ({
  historyStat: {
    padding: '12px 20px 6px 20px !important',
    minWidth: 250
  },
  container: {
    backgroundColor: theme.palette.primary.light,
    alignContent: 'flex-start',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  titleContainer: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    lineHeight: 2.2,
    fontSize: '1.1rem'
  },
  contentContainer: {
    margin: 0
  }
}));
