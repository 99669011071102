import HelpIcon from '@material-ui/icons/Help';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAcademyLink } from './useAcademyLink';
import { useAcademyLinkStyles } from './academyLink.styles';

export function AcademyLink({ component: Component, classes }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const defaultClasses = useAcademyLinkStyles();
  const { academyRoute } = useAcademyLink();

  if (!academyRoute) {
    return null;
  }

  return (
    <Component
      className={`${defaultClasses.root} ${classes?.root ? classes.root : ''}`}
      onClick={() => window.open(academyRoute)}
    >
      <HelpIcon className={`${defaultClasses.icon} ${classes?.icon ? classes.icon : ''}`} />
      <span className={`${defaultClasses.text} ${classes?.text ? classes.text : ''}`}>{t('actions.academy')}</span>
    </Component>
  );
}

AcademyLink.propTypes = {
  component: PropTypes.object,
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string
  })
};
