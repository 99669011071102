import { useState, useEffect, useCallback } from 'react';
import { execHelper } from 'shared/helper';
import { advancedFiltersHelper } from './AdvancedFilters';

const ASCENDING_ORDER_KEY = 'asc';
const DESCENDING_ORDER_KEY = 'desc';
const ROWS_PER_PAGE = 10;

// TODO :tests
export function usePagination(cells, onFetchPaginatedData, isPaginated, initialOrderBy, defaultPageSize) {
  const [order, setOrder] = useState(DESCENDING_ORDER_KEY);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize || ROWS_PER_PAGE);
  const [filters, setFilters] = useState(advancedFiltersHelper.mapCellsToFilteredCells(cells));
  const [lastFilters, setLastFilters] = useState({});

  useEffect(() => {
    const filtersToBack = advancedFiltersHelper.mapAsFilter(filters.filter(f => f.filterValue));
    setLastFilters(filtersToBack);
    const filtersHasChanged = JSON.stringify(lastFilters) !== JSON.stringify(filtersToBack);
    if (filtersHasChanged) {
      setPage(0);
    }
    const paging = { pageIndex: filtersHasChanged ? 0 : page, pageSize: rowsPerPage };
    const sorting = orderBy.property
      ? { orderBy: orderBy.property, orderDesc: orderBy.direction === DESCENDING_ORDER_KEY }
      : { orderBy: orderBy, orderDesc: order === DESCENDING_ORDER_KEY };

    onFetchPaginatedData(paging, sorting, filtersToBack);
  }, [page, rowsPerPage, orderBy, order, filters]);
  // WARN: dont add onFetchData method as depend

  function changeSort(event, property) {
    const newOrder = orderBy === property && order === ASCENDING_ORDER_KEY ? DESCENDING_ORDER_KEY : ASCENDING_ORDER_KEY;
    setOrder(newOrder);
    setOrderBy(property);
  }

  const changeFilter = useCallback((cellName, value) => {
    execHelper.delayed(
      () =>
        setFilters(prevFilters =>
          prevFilters.map(FCells => {
            if (FCells.id === cellName) {
              FCells.filterValue = value;
            }

            return FCells;
          })
        ),
      500
    );
  }, []);

  function changePage(event, newPage) {
    setPage(newPage);
  }

  function changeRows(event) {
    const newRowsPerPage = parseInt(event.target.value, ROWS_PER_PAGE);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  return { order, orderBy, page, rowsPerPage, changeSort, changePage, changeRows, changeFilter };
}
