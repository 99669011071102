import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import PaletteIconOutlined from '@material-ui/icons/PaletteOutlined';
import { Form, Tabs } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useFormConfigurationStyles } from './formConfiguration.styles';
import { ViewLegalBlock } from '../ViewLegalBlock/ViewLegalBlock';
import { useConfigurationForm } from './useConfigurationForm';
import { ViewSetup } from '../ViewSetup';
import { UserContext } from 'auth';
import { PLAN_ACCESS } from 'auth';
import { UnauthorizedSection } from 'pages/Unauthorized';

export function FormConfiguration({ formRef, onSubmit, configuration, installation, errors, onChangeInput }) {
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const classes = useFormConfigurationStyles();
  const { configurationInputs, handleChangeSwitch, handleChange, handleAddCountry, handleDeleteCountry } =
    useConfigurationForm(configuration || {}, formRef, onChangeInput, t);
  const { hasMinimumPlan } = useContext(UserContext);

  const renderTabs = useMemo(() => {
    const showLegalBlock = hasMinimumPlan(PLAN_ACCESS.ADVANCED);
    return [
      {
        name: t('setup'),
        icon: <PaletteIconOutlined />,
        children: <ViewSetup configuration={configurationInputs} installation={installation} onChange={handleChange} />
      },
      {
        name: t('legalBlock'),
        icon: <LanguageIcon />,
        children: showLegalBlock ? (
          <ViewLegalBlock
            configuration={configurationInputs}
            onSwitchChange={handleChangeSwitch}
            onChange={handleChange}
            onAddCountry={handleAddCountry}
            onDeleteCountry={handleDeleteCountry}
          />
        ) : (
          <UnauthorizedSection messageKey={'permissions.forbiddenByPlan'} />
        )
      }
    ];
  }, [
    t,
    configurationInputs,
    handleChangeSwitch,
    handleChange,
    handleAddCountry,
    handleDeleteCountry,
    installation,
    hasMinimumPlan
  ]);

  function handleSubmit() {
    onSubmit(configurationInputs);
  }

  return (
    <>
      <Form
        className={classes.formConfiguration}
        errors={errors}
        elementRef={formRef}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Grid container>
          <Grid item xs={12}>
            <Tabs classNameContent={classes.tabsMinHeight} tabs={renderTabs} />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormConfiguration.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  configuration: PropTypes.shape({
    id: PropTypes.string,
    ageCheckAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    authenticationAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    promotionalVideoAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    experienceInfoAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    legalBlockCountries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    )
  }),
  installation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    clientCode: PropTypes.string,
    finishDate: PropTypes.string,
    startDate: PropTypes.string,
    plan: PropTypes.string
  }),
  onChangeInput: PropTypes.func
};
