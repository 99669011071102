import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Grid, Paper, Tooltip, Typography, Link, Toolbar } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { MediaFiles } from 'pages/shared/MediaFiles';
import { useListMediaFilesStyles } from './listMediaFiles.styles';
import { FormFileUpload } from 'pages/shared';
import { DialogModal, Summary, useHandleOpen } from '@engloba-tech/englobity';
import { useRequestLoading, ButtonAdd } from 'shared';
import { useMediaFilesPaginated } from 'pages/shared/MediaFiles/useMediaFilesPaginated';

export function ListMediaFiles() {
  const classes = useListMediaFilesStyles();
  const { t } = useTranslation(NAME_SPACE.MEDIA_FILES);
  const $formFileUploadRef = useRef(null);
  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);
  const { callbackRequest } = useRequestLoading();
  const { mediaFiles, getMediaFiles, deleteMediaFiles, createMediaFiles, updateMediaFile, errorInfo } =
    useMediaFilesPaginated();

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  async function handleMediaFileUploadSubmit(selectedFiles, event) {
    if (selectedFiles) {
      await callbackRequest(async () => {
        await createMediaFiles(selectedFiles);
        handleCloseAddFile();
      });
    }
  }

  return (
    <div className={classes.mediaFiles}>
      <Summary severity={'error'} text={errorInfo?.message} detail={errorInfo?.detail} />
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {t('name')}
            </Typography>
            <ButtonAdd onClick={handleOpenAddFile} />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper elevation={0} className={classes.paper}>
        <div>
          <MediaFiles
            mediaFiles={mediaFiles}
            getMediaFiles={getMediaFiles}
            deleteMediaFiles={deleteMediaFiles}
            updateMediaFile={updateMediaFile}
            cols={10}
            rowHeight={150}
            gap={3}
            tileMinWidth={150}
            showAddImageTile={false}
            extraActions={image => {
              return (
                <>
                  {image?.targetLink && (
                    <Tooltip title={t('actions.link')}>
                      <Link
                        onClick={evt => evt.stopPropagation()}
                        href={image.targetLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                      >
                        <LinkIcon color="inherit" />
                      </Link>
                    </Tooltip>
                  )}
                </>
              );
            }}
            paginationProps={{ defaultPageSize: 50 }}
          />
        </div>
      </Paper>

      <DialogModal
        title={t('common:upload.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpenAddFile}
        onClose={handleCloseAddFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseAddFile,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptUpload,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormFileUpload
          formRef={$formFileUploadRef}
          onSubmit={handleMediaFileUploadSubmit}
          accept="image/*"
          // maxFileSize={maxFileSize}
        />
      </DialogModal>
    </div>
  );
}
