import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useParams, useHistory } from 'react-router-dom';
import { FormPage, useRequestLoading } from 'shared';
import { PATH } from 'app.routes.const';
import { useSticker } from './useSticker';
import { FormSticker } from './FormSticker';
import { ButtonSave } from 'shared';
import { ButtonBack } from 'shared';

export function EditSticker() {
  const { id } = useParams();
  const { sticker, createSticker, updateSticker, errorInfo, setErrorInfo } = useSticker(id);
  const { t } = useTranslation(NAME_SPACE.STICKERS);
  const $formRef = useRef(null);
  const history = useHistory();
  const { requestLoading, callbackRequest } = useRequestLoading();

  async function handleSubmit(sticker) {
    await callbackRequest(async () => {
      setErrorInfo({ message: '', detail: '' });
      id ? await updateSticker(sticker) : await createSticker(sticker);
      history.push(PATH.STICKERS.LIST);
    });
  }

  function handleSaveClick() {
    $formRef.current.submit();
  }

  function handleGoBack() {
    history.push(PATH.STICKERS.LIST);
  }

  function composeActionButtons() {
    const actionButtons = [
      { onClick: handleGoBack, component: ButtonBack },
      {
        onClick: handleSaveClick,
        enableOnDirty: true,
        component: ButtonSave
      }
    ];

    return actionButtons;
  }

  const isCreating = !id;

  return (
    <FormPage
      title={t('singularName')}
      disabled={requestLoading}
      buttons={composeActionButtons()}
      onSubmit={handleSubmit}
    >
      <FormSticker errors={errorInfo} formRef={$formRef} sticker={{ ...sticker }} createMode={isCreating} />
    </FormPage>
  );
}
