import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useListARMarkersStyles = makeStyles(theme => ({
  arMarkers: {
    width: '100%',
    padding: theme.spacing(3),
    '& .MuiTableRow-root': {
      '&:last-child': {
        '& .MuiTableCell-root': {
          borderBottom: 'none'
        }
      }
    }
  },
  toolbar: {
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  paper: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingTop: `${theme.spacing(3)}px !important`
  },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold !important',
    alignSelf: 'end'
  },
  button: { marginLeft: '1rem' },
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem',
    whiteSpace: 'nowrap'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
