import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useParams, useHistory } from 'react-router-dom';
import FileSaver from 'file-saver';
import { FormPage, useRequestLoading, ButtonBack, ButtonSave, ButtonQR, execConfirmFormModal } from 'shared';
import { PATH } from 'app.routes.const';
import { useExperience } from './useExperience';
import { FormExperience } from './FormExperience';
import { FormDownloadQRCode } from 'pages/QRCodes/List/FormDownloadQRCode';
import { useEditExperienceStyles } from './editExperience.styles';

export function EditExperience() {
  const { id } = useParams();
  const tab = new URLSearchParams(window.location.search).get('tab');
  const { experience, createExperience, updateExperience, errorInfo, setErrorInfo, experienceQRDemo } =
    useExperience(id);
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const $formRef = useRef(null);
  const history = useHistory();
  const { requestLoading, callbackRequest } = useRequestLoading();
  const classes = useEditExperienceStyles();

  async function handleSubmit(experience) {
    await callbackRequest(async () => {
      setErrorInfo({ message: '', detail: '' });
      id ? await updateExperience(experience) : await createExperience(experience);
      history.push(PATH.EXPERIENCES.LIST);
    });
  }

  function handleSaveClick() {
    $formRef.current.submit();
  }

  function handleGoBack() {
    history.push(PATH.EXPERIENCES.LIST);
  }

  async function handleDownloadQRDemo() {
    const qrCode = await execConfirmFormModal({
      title: t('downloadQRDemo.title'),
      confirmText: t('common:actions.download'),
      declineText: t('common:actions.close'),
      confirmClassName: classes.buttonDownloadQRDemo,
      declineClassName: classes.buttonCloseQRDemo,
      formComponent: <FormDownloadQRCode qrCode={experienceQRDemo} />
    });

    if (qrCode && qrCode.dataUrl) {
      FileSaver.saveAs(qrCode.dataUrl, `${experience?.id}.png`);
    }
  }

  function composeActionButtons() {
    const actionButtons = [
      { onClick: handleGoBack, component: ButtonBack },
      ...(id ? [{ onClick: handleDownloadQRDemo, component: ButtonQR }] : []),
      {
        onClick: handleSaveClick,
        enableOnDirty: true,
        component: ButtonSave
      }
    ];

    return actionButtons;
  }

  const isCreating = !id;

  return (
    <FormPage
      title={t('singularName')}
      disabled={requestLoading}
      buttons={composeActionButtons()}
      onSubmit={handleSubmit}
      className={classes.formOverride}
    >
      <FormExperience
        errors={errorInfo}
        formRef={$formRef}
        experience={{ ...experience }}
        createMode={isCreating}
        selectedTab={tab ? parseInt(tab, 10) : 0}
      />
    </FormPage>
  );
}
