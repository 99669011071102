import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useViewLegalBlockStyles } from './viewLegalBlock.styles';
import { ViewDefaults } from './ViewDefaults';
import { ViewPageBlockings } from './ViewPageBlockings';

export function ViewLegalBlock({ experience, mediaFiles, onSwitchChange, onChange }) {
  const classes = useViewLegalBlockStyles();

  return (
    <div className={classes.legalBlock}>
      <Grid container spacing={3}>
        <ViewDefaults experience={experience} mediaFiles={mediaFiles} onChange={onChange} />
        <ViewPageBlockings experience={experience} onSwitchChange={onSwitchChange} />
      </Grid>
    </div>
  );
}

ViewLegalBlock.propTypes = {
  experience: PropTypes.shape({
    ageCheckAllowed: PropTypes.bool,
    authenticationAllowed: PropTypes.bool,
    promotionalVideoAllowed: PropTypes.bool,
    experienceInfoAllowed: PropTypes.bool,
    template: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string
      })
    ])
  }),
  onSwitchChange: PropTypes.func.isRequired,
  mediaFiles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
