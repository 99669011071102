export const ageRangeSelect = (editor, showInBlockManager = true) => {
  if (showInBlockManager) {
    editor.BlockManager.add('age-range-select', {
      category: 'Experience Utils',
      label: 'Age range',
      attributes: { class: 'fa fa-arrows-h' },
      content: { type: 'age-range-select', classes: ['age-range-select'] },
      type: 'age-range-select'
    });
  }

  editor.DomComponents.addType('age-range-select', {
    isComponent: el => el.tagName === 'SELECT' && el.className === 'age-range-select',
    model: {
      defaults: {
        tagName: 'select',
        attributes: { name: 'ageRange' },
        droppable: false,
        highlightable: false,
        components: [
          { type: 'option', content: '', attributes: { value: '' } },
          { type: 'option', content: '&lt;21', attributes: { value: '' } },
          { type: 'option', content: '22-24', attributes: { value: '22' } },
          { type: 'option', content: '25-34', attributes: { value: '25' } },
          { type: 'option', content: '35-44', attributes: { value: '35' } },
          { type: 'option', content: '45-54', attributes: { value: '45' } },
          { type: 'option', content: '55-64', attributes: { value: '55' } },
          { type: 'option', content: '&gt;65', attributes: { value: '65' } }
        ],
        traits: [
          {
            name: 'options',
            type: 'select-options-custom'
          },
          {
            type: 'checkbox',
            name: 'required',
            value: true
          }
        ]
      }
    }
  });
};
