import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FileSaver from 'file-saver';
import { NAME_SPACE } from 'i18n';
import { ViewTable, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { useListQRCodesStyles } from './listQRCodes.styles';
import { useQRCodesBuildTable } from './useQRCodesBuildTable';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { FormQRCode } from './FormQRCode';
import { FormDownloadQRCode } from './FormDownloadQRCode';
import { useQRCodesPaginated } from './useQRCodesPaginated';

export function ListQRCodes() {
  const classes = useListQRCodesStyles();
  const { t } = useTranslation(NAME_SPACE.QR_CODES);
  const { qrCodes, addQRCode, deleteQRCodes, getQRCodes, errorInfo, setErrorInfo, errorInfoList } =
    useQRCodesPaginated();
  const { rows, headCells } = useQRCodesBuildTable(qrCodes, t);
  const [selectedQRCode, setSelectedQRCode] = useState({});

  const $formAddQRCode = useRef(null);

  const {
    isOpen: isOpenAddQRCode,
    handleClose: handleCloseAddQRCode,
    handleOpen: handleOpenAddQRCode
  } = useHandleOpen(false);

  const {
    isOpen: isOpenDownloadQRCode,
    handleClose: handleCloseDownloadQRCode,
    handleOpen: handleOpenDownloadQRCode
  } = useHandleOpen(false);

  function handleDownload([qrCodeId, customUrl]) {
    setSelectedQRCode({ id: qrCodeId, url: customUrl });
    handleOpenDownloadQRCode();
  }

  function handleAdd() {
    setErrorInfo();
    handleOpenAddQRCode();
  }

  async function handleDelete(qrCodesToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          qrCodesToDelete.length > 1
            ? t('removeConfirm.message', { length: qrCodesToDelete.length })
            : t('removeConfirm.singularMessage', {
                userName: qrCodesToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });
      await deleteQRCodes(qrCodesToDelete);
    } catch (err) {}
  }

  function handleModalAcceptAddQRCode() {
    $formAddQRCode.current.submit();
  }

  async function handleAddQRCodeSubmit(qrCode) {
    const success = await addQRCode(qrCode);
    if (success) {
      handleCloseAddQRCode();
    }
  }

  function handleModalAcceptDownloadQRCode() {
    $formAddQRCode.current.submit();
  }

  function handleDownloadQRCodeSubmit(qrCode) {
    FileSaver.saveAs(qrCode.dataUrl, `${qrCode.id}.png`);
  }

  return (
    <>
      <div className={classes.qrCodes}>
        <ViewTable
          elevation
          spaced
          errors={errorInfoList}
          rows={rows}
          emptyText={t('empty')}
          cells={headCells}
          defaultOrderBy={{ property: 'name', direction: 'asc' }}
          mainKey={'name'}
          onFetchData={getQRCodes}
          totalRows={qrCodes.total}
          serverSidePaging
          selectRowWhen={element => element.canBeDeleted}
          actions={[
            {
              title: t('actions.download'),
              name: 'download',
              properties: ['id', 'customUrl'],
              label: 'download this qr',
              cb: handleDownload,
              icon: <CloudDownloadOutlinedIcon />
            }
          ]}
          toolbar={{
            title: t('name'),
            actions: [
              {
                condition: isAnySelected => !isAnySelected,
                cb: handleAdd,
                component: ButtonAdd
              },
              {
                condition: isAnySelected => isAnySelected,
                resetOnCB: true,
                cb: handleDelete,
                component: ButtonDelete
              }
            ]
          }}
        />
      </div>

      <DialogModal
        maxWidth="xs"
        className={classes.modal}
        title={t('create.dialogTitle')}
        description=""
        isOpen={isOpenAddQRCode}
        onClose={handleCloseAddQRCode}
        buttons={[
          {
            children: <KeyboardArrowLeft />,
            text: t('common:actions.back'),
            type: 'secondary',
            onClick: handleCloseAddQRCode,
            className: classes.buttonDelete
          },
          {
            children: <SaveAltIcon />,
            text: t('common:actions.save'),
            type: 'primary',
            onClick: handleModalAcceptAddQRCode,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormQRCode formRef={$formAddQRCode} onSubmit={handleAddQRCodeSubmit} errors={errorInfo} />
      </DialogModal>

      <DialogModal
        maxWidth="xs"
        className={classes.modal}
        title={t('download.dialogTitle')}
        description=""
        isOpen={isOpenDownloadQRCode}
        onClose={handleCloseDownloadQRCode}
        buttons={[
          {
            children: <KeyboardArrowLeft />,
            text: t('common:actions.back'),
            type: 'secondary',
            onClick: handleCloseDownloadQRCode,
            className: classes.buttonDelete
          },
          {
            children: <SaveAltIcon />,
            text: t('common:actions.download'),
            type: 'primary',
            onClick: handleModalAcceptDownloadQRCode,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormDownloadQRCode
          formRef={$formAddQRCode}
          onSubmit={handleDownloadQRCodeSubmit}
          qrCode={selectedQRCode}
          errors={errorInfo}
        />
      </DialogModal>
    </>
  );
}
