import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useListCustomPagesStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.font.weight.bold
  },
  itemText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  itemPrimaryText: {
    display: 'inline'
  },
  itemHomePage: {
    paddingRight: 48,
    borderTop: `1px solid ${theme.palette.primary.main}`
  },
  itemPage: {
    paddingRight: 96
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  },
  locked: {
    right: 28,
    top: '54%',
    color: theme.palette.secondary.dark
  }
}));
