import openImportCommand from './openImportCommand';

export const basicCommands = (editor, config) => {
  editor.Commands.add('reset-changes-counter', {
    run(editor) {
      var model = editor.getModel();
      if (model) {
        model.set('changesCount', 0);
      }
      return true;
    }
  });

  editor.Commands.add('save-command', {
    run(editor) {
      const pageManager = editor.Pages;
      const page = pageManager.getSelected();

      editor.Commands.get('reset-changes-counter').run(editor);

      return {
        pageId: page.id,
        html: editor.getHtml(),
        css: editor.getCss({ avoidProtected: true })
      };
    }
  });

  editor.Commands.add('clear-html', () => editor.DomComponents.clear());

  editor.Commands.add('show-layers', {
    getRowEl(editor) {
      return editor.getContainer().closest('.editor-row');
    },
    getLayersEl(row) {
      return row.querySelector('.layer-manager-container');
    },

    run(editor) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = '';
    },
    stop(editor) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = 'none';
    }
  });

  editor.Commands.add('show-styles', {
    getRowEl(editor) {
      return editor.getContainer().closest('.editor-row');
    },
    getStyleEl(row) {
      return row.querySelector('.style-manager-container');
    },

    run(editor) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = '';
    },
    stop(editor) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = 'none';
    }
  });

  editor.Commands.add('show-blocks', {
    getRowEl(editor) {
      return editor.getContainer().closest('.editor-row');
    },
    getStyleEl(row) {
      return row.querySelector('.block-manager-container');
    },

    run(editor) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = '';
    },
    stop(editor) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = 'none';
    }
  });

  editor.Commands.add('show-traits', {
    getTraitsEl(editor) {
      const row = editor.getContainer().closest('.editor-row');
      return row.querySelector('.trait-manager-container');
    },
    run(editor) {
      this.getTraitsEl(editor).style.display = '';
    },
    stop(editor) {
      this.getTraitsEl(editor).style.display = 'none';
    }
  });

  editor.Commands.add('import', openImportCommand(editor, config));
};
