import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { CustomHtmlEditor } from 'shared';
import { settings, KEY_SETTINGS } from 'settings';
import { isSkeletonLoading } from 'shared/skeletonFactory';
import { viewContentConfiguration } from './viewContent.config';
import { execConfirmModal, execConfirmFormModal } from 'shared';
import { FormSelectHtmlTemplate } from 'pages/shared';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { UserContext, PLAN_ACCESS } from 'auth';
import { useViewContentStyles } from './viewContent.styles';

export function ViewContent({ onChange, content, mediaFiles = [], readToken, qrCodes = [] }) {
  const { t } = useTranslation(NAME_SPACE.HTML_TEMPLATES);
  const classes = useViewContentStyles();
  const { hasMinimumPlan } = useContext(UserContext);
  const isInPlan = hasMinimumPlan(PLAN_ACCESS.STANDARD);

  const saveCommandEventHandler = {
    name: 'run:save-command',
    action: model => {
      if (onChange) {
        const newEvent = {
          target: {
            name: 'content',
            value: model.html + `<style>${model.css}</style>`
          }
        };
        onChange(newEvent);
      }
    }
  };

  const selectComponentHandler = useMemo(() => {
    return {
      name: 'component:selected',
      action: model => {
        if (model && model.attributes && model.attributes.type === 'show-qr-code') {
          var trait = model.getTrait('data-qrcode');
          if (trait) {
            const experienceAppUrl = settings.getSetting(KEY_SETTINGS.EXPERIENCE_APP_URL);
            const traitOptions = (qrCodes || []).map(qrcode => {
              return {
                name: qrcode.name,
                value: `${experienceAppUrl || ''}?productId=${qrcode.id}`
              };
            });
            trait.set('options', [...traitOptions]);
          }
        }
      }
    };
  }, [qrCodes]);

  const openTemplatesCommandEventHandler = {
    name: 'run:show-templates',
    action: async editor => {
      let result;
      if (isInPlan) {
        result = await execConfirmFormModal({
          title: t('htmlTemplates:templateConfirm.title'),
          description: t('htmlTemplates:templateConfirm.description'),
          confirmText: t('common:actions.accept'),
          declineText: t('common:actions.close'),
          confirmClassName: classes.buttonAdd,
          declineClassName: classes.buttonDelete,
          formComponent: <FormSelectHtmlTemplate />
        });
      } else {
        await execConfirmModal({
          title: t('htmlTemplates:name'),
          description: t('common:permissions.forbiddenByPlan'),
          declineText: t('common:actions.close'),
          declineClassName: classes.buttonDelete,
          showConfirm: false
        });
      }

      if (result) {
        if (onChange) {
          const newEvent = {
            target: {
              name: 'content',
              value: result.content
            }
          };
          onChange(newEvent);
        }
        editor.Commands.run('select-template', { content: result.content });
      }
    }
  };

  const editorMediaFiles = useMemo(() => {
    return mediaFiles.map(f => ({ name: f.tag, src: `${f.blobUrl}?${readToken}` }));
  }, [mediaFiles, readToken]);

  return isSkeletonLoading(content) ? (
    <Skeleton height={600} />
  ) : (
    <CustomHtmlEditor
      pages={[{ id: 'first', content: content }]}
      selectedPageId={'first'}
      assets={editorMediaFiles}
      eventHandlers={[saveCommandEventHandler, selectComponentHandler, openTemplatesCommandEventHandler]}
      customConfiguration={viewContentConfiguration}
    />
  );
}

ViewContent.propTypes = {
  onChange: PropTypes.func,
  content: PropTypes.string,
  readToken: PropTypes.string,
  mediaFiles: PropTypes.array,
  qrCodes: PropTypes.array
};
