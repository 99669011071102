import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Summary, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useFormCustomPageStyles } from './formCustomPage.styles';
import { TEMPLATE_PAGE_REFERENCE_TYPE } from 'pages/Experiences/Edit/shared';

const emptyCustomPage = { reference: TEMPLATE_PAGE_REFERENCE_TYPE.DEFAULT_PAGE, contentPageReference: '' };

export function FormCustomPage({
  formRef,
  customPage,
  onSubmit,
  successMessage,
  errorMessage,
  resetErrorAndSuccessMessages
}) {
  const [inputs, setInputs] = useState({ ...customPage });
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormCustomPageStyles();

  useEffect(() => {
    if (successMessage && !customPage.id) {
      setInputs({ ...emptyCustomPage });
    }
  }, [successMessage]);

  function handleSubmit() {
    resetErrorAndSuccessMessages();
    onSubmit(inputs);
  }

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));
    };

  return (
    <>
      {(successMessage || errorMessage) && (
        <Grid item xs={12}>
          <Summary
            text={successMessage ? t(successMessage) : t(errorMessage)}
            severity={successMessage ? 'success' : 'error'}
          />
        </Grid>
      )}
      <Form
        className={classes.formField}
        errors={errorMessage}
        elementRef={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              required
              name={t('pages.properties.contentPageReference')}
              label={t('pages.properties.contentPageReference')}
              variant="outlined"
              value={inputs.contentPageReference || ''}
              onChange={handleChange('contentPageReference')}
              inputProps={{ 'aria-label': t('pages.properties.contentPageReference') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormCustomPage.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func,
  customPage: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
};
