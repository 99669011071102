import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ImageGallery } from 'pages/shared';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { Typography, Grid, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { FormSelectMediaFiles } from 'pages/shared/MediaFiles';
import { useViewMediaFilesStyles } from './viewMediaFiles.styles';
import { ButtonAdd } from 'shared';

export function ViewMediaFiles({ mediaFiles = [], readToken, onAddMediaFile, onDeleteMediaFile }) {
  const { t } = useTranslation(NAME_SPACE.STICKERS);
  const classes = useViewMediaFilesStyles();
  const {
    isOpen: isOpenAddFile,
    handleClose: handleCloseAddFile,
    handleOpen: handleOpenAddFile
  } = useHandleOpen(false);
  const $formFileUploadRef = useRef(null);

  function handleMediaFileUploadOpen() {
    handleOpenAddFile();
  }

  async function handleMediaFileUploadSubmit(selectedFiles, event) {
    if (selectedFiles) {
      onAddMediaFile(selectedFiles);
      handleCloseAddFile();
    }
  }

  function handleModalAcceptUpload() {
    $formFileUploadRef.current.submit();
  }

  return (
    <div className={classes.mediaFiles}>
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div"></Typography>
            <ButtonAdd onClick={handleMediaFileUploadOpen} />
          </Grid>
        </Grid>
      </Toolbar>
      <ImageGallery
        images={mediaFiles}
        readToken={readToken}
        cols={9}
        rowHeight={150}
        gap={3}
        tileMinWidth={150}
        onDeleteImageClick={onDeleteMediaFile}
      />

      <DialogModal
        title={t('common:upload.dialogTitle')}
        description=""
        maxWidth="lg"
        fullWidth
        className={classes.galleryModal}
        isOpen={isOpenAddFile}
        onClose={handleCloseAddFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            onClick: handleCloseAddFile,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            onClick: handleModalAcceptUpload,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormSelectMediaFiles formRef={$formFileUploadRef} onSubmit={handleMediaFileUploadSubmit} />
      </DialogModal>
    </div>
  );
}

ViewMediaFiles.propTypes = {
  readToken: PropTypes.string,
  mediaFiles: PropTypes.array,
  onAddMediaFile: PropTypes.func,
  onDeleteMediaFile: PropTypes.func
};
