import { makeStyles } from '@material-ui/core/styles';

export const useUnauthorizedSectionStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    padding: `${theme.spacing(5)}px ${theme.spacing(1)}px`,
    overflow: 'hidden'
  }
}));
