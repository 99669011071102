import { makeStyles } from '@material-ui/core/styles';

export const useFormArticleStyles = makeStyles(theme => ({
  formArticle: {
    flexGrow: 1
  },
  ratingsContainer: {
    paddingLeft: `${24}px !important`,
    paddingRight: `${24}px !important`,
    paddingTop: `${0}px !important`,
    paddingBottom: `${0}px !important`
  },
  select: {
    minWidth: '8rem'
  }
}));
