import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Form, AsyncInputAutocomplete } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { countries } from 'services';
import { useFormCountryStyles } from './formCountry.styles';

export function FormCountry({ formRef, onSubmit }) {
  const [inputs, setInputs] = useState([]);
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);
  const classes = useFormCountryStyles();

  function handleSubmit() {
    onSubmit([...inputs]);
  }

  function handleCountryAutocomplete(elements) {
    setInputs([...elements]);
  }

  return (
    <>
      <Form
        className={classes.formCountry}
        elementRef={formRef}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AsyncInputAutocomplete
              onChange={(e, element) => handleCountryAutocomplete(element)}
              label={t('countries.properties.name')}
              required
              multiple
              variant="outlined"
              icon={<SearchIcon />}
              value={inputs || []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              requestAction={countries.getUsingQueryFilter}
              validators={!inputs?.length ? ['required'] : []}
              errorMessages={[t('validations.required')]}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormCountry.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired
};
