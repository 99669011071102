import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Grid } from '@material-ui/core';
import { Input } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';

export const ViewPagePromotionalVideo = ({ visible, youtubeUrl, onChange, itemSizeLg }) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  return (
    <>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 4 }}>
        <Input
          isLoading={isSkeletonLoading(youtubeUrl)}
          required={visible}
          name={t('properties.youtubeUrl')}
          label={t('properties.youtubeUrl')}
          variant="outlined"
          value={youtubeUrl || ''}
          onChange={onChange('youtubeUrl')}
          inputProps={{ 'aria-label': t('properties.youtubeUrl') }}
          validators={visible ? ['required', 'maxStringLength:255'] : []}
          errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
        />
      </Grid>
    </>
  );
};

ViewPagePromotionalVideo.propTypes = {
  visible: PropTypes.bool,
  youtubeUrl: PropTypes.string,
  onChange: PropTypes.func,
  itemSizeLg: PropTypes.number
};
