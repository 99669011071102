const actionButtons = [
	{
		id: 'print-label-button',
		command: 'print-label',
		active: false,
		togglable: false,
		className: 'fa fa-print',
		attributes: { title: 'Print' },
	},
];

export const printPanels = (editor) => {
	const panelManager = editor.Panels;

	var actionsPanel = panelManager.getPanel('basic-actions');
	if (!actionsPanel) {
		panelManager.addPanel({
			id: 'basic-actions',
			el: '.panel__basic-actions',
			buttons: actionButtons,
		});
	} else {
		actionsPanel.get('buttons').add(actionButtons);
	}
};
