export const shareButton = editor => {
  editor.BlockManager.add('share-button', {
    category: 'Experience Utils',
    label: 'Share',
    attributes: { class: 'fa fa-share-alt' },
    content:
      '<a data-target-share="" class="share-button" onclick="event.preventDefault();" style="display:inline-block; min-height:50px;min-width:50px"></a>',
    type: 'cmp-share-button'
  });

  editor.DomComponents.addType('share-button', {
    isComponent: el =>
      typeof el.hasAttribute === 'function' && el.tagName === 'A' && el.hasAttribute('data-target-share'),
    model: {
      defaults: {
        tagName: 'a',
        attributes: { onclick: 'event.preventDefault();' },
        editable: false,
        droppable: true,
        traits: [
          {
            type: 'select',
            name: 'data-target-share',
            label: 'Content',
            options: []
          }
        ]
      }
    }
  });
};
