import { useCallback, useEffect, useState } from 'react';
import { azureCommonStorage } from 'services';
import { isSkeletonLoading } from 'shared';

export function useAzureCommonStorage(containerIdentifier) {
  const [sasToken, setSasToken] = useState('');

  useEffect(() => {
    async function getData() {
      const response = await azureCommonStorage.getSasToken(containerIdentifier);
      setSasToken(response.data.token);
    }
    containerIdentifier && !isSkeletonLoading(containerIdentifier) && getData();
  }, [containerIdentifier]);

  const getFiles = useCallback(
    async ({ prefix }) => {
      return await azureCommonStorage.getFiles({
        containerIdentifier,
        prefix
      });
    },
    [containerIdentifier]
  );

  const uploadFile = useCallback(
    async ({ content, mimeType, prefix }) => {
      return await azureCommonStorage.uploadFile({
        content,
        mimeType,
        containerIdentifier,
        sasToken,
        prefix
      });
    },
    [containerIdentifier, sasToken]
  );

  const deleteFile = useCallback(
    async ({ blobUrl, prefix }) => {
      await azureCommonStorage.deleteFile({
        blobUrl,
        sasToken,
        prefix
      });
    },
    [sasToken]
  );

  return { uploadFile, deleteFile, getFiles, sasToken };
}
