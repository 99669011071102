export const ACCESS = {
  ANALYTICS: 1 << 0,
  EXPERIENCES: 1 << 1,
  TAGS: 1 << 2,
  QR_CODES: 1 << 3,
  STICKERS: 1 << 4,
  MARKERS: 1 << 5,
  USERS: 1 << 6,
  CONFIGURATION: 1 << 7,
  MEDIA_FILES: 1 << 8,
  HTML_TEMPLATES: 1 << 9,
  MULTILANGUAGE_EXPERIENCES: 1 << 10
};
