import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { Form, Input, Summary, DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { useFormMediaFileStyles } from './formMediaFile.styles';
import { AddImageTile } from 'pages/shared';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { FormSelectMediaFiles } from 'pages/shared/MediaFiles';
import { ImageTile } from 'pages/shared';

export function FormMediaFile({
  formRef,
  mediaFile,
  onSubmit,
  successMessage,
  errorMessage,
  resetErrorAndSuccessMessages,
  readToken
}) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormMediaFileStyles();
  const [inputs, setInputs] = useState({ ...mediaFile });
  const {
    isOpen: isOpenSelectFile,
    handleClose: handleCloseSelectFile,
    handleOpen: handleOpenSelectFile
  } = useHandleOpen(false);

  const $formFileSelectRef = useRef(null);
  function handleSubmit() {
    if (!mediaFile) {
      return;
    }

    resetErrorAndSuccessMessages();
    onSubmit(inputs);
  }

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));
    };

  async function handleMediaFileUploadSubmit(selectedFiles) {
    if (selectedFiles) {
      setInputs(prevInputs => ({
        ...prevInputs,
        mediaFileId: selectedFiles[0].id,
        blobUrl: selectedFiles[0].blobUrl,
        mimeType: selectedFiles[0].mimeType,
        tag: !prevInputs.tag ? selectedFiles[0].tag : prevInputs.tag,
        targetLink: !prevInputs.targetLink ? selectedFiles[0].targetLink : prevInputs.targetLink
      }));
      handleCloseSelectFile();
    }
  }

  function handleModalAcceptUpload() {
    $formFileSelectRef.current.submit();
  }

  return (
    <>
      {(successMessage || errorMessage) && (
        <Grid item xs={12}>
          <Summary
            text={successMessage ? t(successMessage) : t(errorMessage)}
            severity={successMessage ? 'success' : 'error'}
          />
        </Grid>
      )}
      <Form
        className={`${classes.form} ${classes.container}`}
        elementRef={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          {inputs.imageTemplateId && (
            <Grid container alignItems="center" justifyContent="center" item xs={12}>
              {inputs.blobUrl ? (
                <ImageTile
                  image={inputs}
                  readToken={readToken}
                  cols={1}
                  rows={1}
                  tileMinHeight={150}
                  tileMinWidth={150}
                  onClick={() => handleOpenSelectFile()}
                  style={{ height: 150, width: 150 }}
                  component="div"
                />
              ) : (
                <AddImageTile
                  title={t('common:actions.addFile')}
                  cols={1}
                  rows={1}
                  tileMinHeight={150}
                  tileMinWidth={150}
                  onClick={() => handleOpenSelectFile()}
                  style={{ height: 150, width: 150 }}
                  component="div"
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Input
              required
              name={t('mediaFiles.properties.tag')}
              label={t('mediaFiles.properties.tag')}
              variant="outlined"
              value={inputs.tag || ''}
              onChange={handleChange('tag')}
              inputProps={{ 'aria-label': t('mediaFiles.properties.tag') }}
              validators={['required']}
              errorMessages={[t('validations.required')]}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name={t('mediaFiles.properties.targetLink')}
              label={t('mediaFiles.properties.targetLink')}
              variant="outlined"
              value={inputs.targetLink || ''}
              onChange={handleChange('targetLink')}
              inputProps={{ 'aria-label': t('mediaFiles.properties.targetLink') }}
            />
          </Grid>
        </Grid>
      </Form>

      <DialogModal
        title={t('common:actions.addFile')}
        description=""
        maxWidth="lg"
        fullWidth
        className={classes.galleryModal}
        isOpen={isOpenSelectFile}
        onClose={handleCloseSelectFile}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleCloseSelectFile,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.add'),
            type: 'primary',
            onClick: handleModalAcceptUpload,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormSelectMediaFiles
          formRef={$formFileSelectRef}
          onSubmit={handleMediaFileUploadSubmit}
          maxSelectedFiles={1}
        />
      </DialogModal>
    </>
  );
}

FormMediaFile.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  mediaFile: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    link: PropTypes.string,
    imageTemplateId: PropTypes.string,
    blobUrl: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  readToken: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
