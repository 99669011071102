import { BrandContext } from 'brand';
import { useState, useEffect, useCallback, useContext } from 'react';
import { configurations } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';

const configurationDummy = skeletonFactory.configuration();

export function useConfiguration() {
  const [configurationData, setConfigurationData] = useState(configurationDummy);
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { setBrandImages } = useContext(BrandContext);

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await configurations.get();
        if (response) {
          setConfigurationData(response.data || {});
        }
      }, false);
    }
    getData();
  }, [request]);

  const updateConfiguration = useCallback(
    async configuration => {
      await request(async () => {
        setConfigurationData(configuration);
        const mappedCountries = configuration.legalBlockCountries.map(c => c.id);
        const result = await configurations.update(configuration.id, {
          ...configuration,
          legalBlockCountries: mappedCountries
        });
        setBrandImages({ ...result.data });
      });
    },
    [request]
  );

  return {
    configuration: configurationData,
    errorInfo,
    setErrorInfo,
    setConfigurationData,
    updateConfiguration
  };
}
