import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useBrandImage = makeStyles(theme => ({
  tile: {
    // height: '240px',
    '& > div': {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  },
  imageContainer: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 8
  },
  imagePlaceholder: {
    height: '100%',
    width: '100%',
    backgroundColor: color.basic.dark
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  imageTitle: {
    background: theme.palette.secondary.light,
    flex: 1,
    '& > div': {
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '5px'
    },
    '& > div > div:first-child': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: '500'
    },
    '& > div > div': {
      color: theme.palette.primary.main,
      fontSize: '14px'
    }
  },
  imageTitleBottom: {
    background: theme.palette.secondary.light,
    height: 24
  },
  error: {
    '& > div': {
      border: '2px solid ' + color.action.error
    }
  },
  imageTitleTop: {
    '& > div': {
      marginLeft: '10px',
      marginRight: '5px',
      marginBottom: '0px'
    }
  },
  actionIconContainer: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      color: theme.palette.primary.main
    }
  },
  addFileIcon: {
    marginRight: 5
  }
}));
