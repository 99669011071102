import { makeStyles } from '@material-ui/core/styles';

export const useDynamicMenuStyles = makeStyles(theme => ({
  desktopMenu: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  mobileMenu: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  buttonIcon: {
    width: '3rem',
    height: '3rem'
  },
  label: {
    color: theme.palette.basic.semidark
  },
  icons: {
    color: theme.palette.basic.normal
  }
}));
