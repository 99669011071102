import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { isSkeletonLoading } from 'shared';
import { ColorPicker } from 'shared';
import { Skeleton } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { useColorInputStyles } from './colorInput.styles';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';

export function ColorInput({ label, name, value, onChange, disabled, skeletonHeight = 48, canBeCleared = false }) {
  const classes = useColorInputStyles();
  const { t } = useTranslation(NAME_SPACE.COMMON);

  return !isSkeletonLoading(value) ? (
    <div className={classes.colorSelector}>
      <ColorPicker name={name} color={value || ''} onChange={onChange} disabled={disabled} />
      <div className={classes.actionTitleContainer}>
        <Typography variant="subtitle2" component={'span'}>
          {label}
        </Typography>
        {canBeCleared && (
          <div className={classes.actionIconContainer}>
            <Tooltip title={t('actions.clear')}>
              <span>
                <IconButton
                  className={classes.actionIconButton}
                  onClick={onChange}
                  aria-label={t('actions.clear')}
                  disabled={!value}
                >
                  <DeleteIcon onClick={onChange} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Skeleton width={'100%'} height={skeletonHeight} />
  );
}

ColorInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  skeletonHeight: PropTypes.number,
  canBeCleared: PropTypes.bool
};
