import { useState } from 'react';

export function useFormState(formState, onChangeInput) {
  const [inputs, setInputs] = useState(formState);

  function setInputsChange(params, triggerChanges = true) {
    setInputs(params);

    if (triggerChanges) {
      onChangeInput && onChangeInput();
    }
  }

  return [inputs, setInputsChange];
}
