import { http } from './http';
import { apiHelper } from 'shared';

class Templates {
  cachedTemplates = [];

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/templates${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/templates/${queryString}`);
  }

  getById(id) {
    return http.get(`/templates/${id}`);
  }

  async getCachedTemplates() {
    if (!this?.cachedTemplates?.length) {
      const response = await http.get('/templates');
      const items = response.data && response.data.items;
      if (items) {
        this.cachedTemplates = [...items];
      }
    }
    return this.cachedTemplates;
  }
}

export const templates = new Templates();
