import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  ListItem,
  List,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useNavigationListStyles } from './navigationList.styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { execConfirmModal } from 'shared';
import { UserContext } from 'auth';

export function NavigationList({ navigations, isOpen }) {
  const classes = useNavigationListStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { hasMinimumPlan } = useContext(UserContext);
  const [expanded, setExpanded] = React.useState(navigations[findOpen()].name);

  function findOpen() {
    var i = 0;
    navigations.forEach((nav, index) => {
      if (nav.open) {
        i = index;
      }
    });
    return i;
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function handleNavigateTo(path, name, planAccess) {
    if (!hasMinimumPlan(planAccess)) {
      await execConfirmModal({
        title: name,
        description: t('common:permissions.forbiddenByPlan'),
        declineText: t('common:actions.close'),
        declineClassName: classes.buttonDelete,
        showConfirm: false
      });
    } else {
      history.push(path);
    }
  }

  function handleAction(action) {
    action();
  }

  return (
    <div className={classes.navigation} role="presentation" key={'navigation'}>
      {navigations.map((navigation, index) => (
        <React.Fragment key={index}>
          {navigation.show ? (
            !navigation.paths ? (
              <AccordionSummary
                key={index}
                onClick={() =>
                  navigation.action
                    ? handleAction(navigation.action(), navigation.name)
                    : handleNavigateTo(navigation.url, navigation.name, navigation.planAccess)
                }
                className={`${classes.summary} ${navigation.url && navigation.highlighted ? classes.highlighted : ''}
                `}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                  content: clsx({
                    [classes.navContentOpen]: isOpen,
                    [classes.navContentClose]: !isOpen
                  })
                }}
              >
                {navigation.icon}
                <Typography className={classes.heading}>{navigation.name}</Typography>
              </AccordionSummary>
            ) : (
              <Accordion
                expanded={expanded === navigation.name}
                onChange={handleChange(navigation.name)}
                key={index}
                square
              >
                <AccordionSummary
                  className={`${classes.summary}`}
                  IconButtonProps={{ edge: 'start' }}
                  expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                  aria-controls={navigation.name}
                  id={navigation.name}
                  classes={{
                    content: clsx({
                      [classes.navContentOpen]: isOpen,
                      [classes.navContentClose]: !isOpen
                    })
                  }}
                >
                  {navigation.icon}
                  <Typography className={classes.heading}>{navigation.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.root}>
                  <List className={classes.content}>
                    {navigation.paths.map(({ name, url, action, show, icon, highlighted, planAccess }) => (
                      <>
                        {show && (
                          <ListItem
                            style={{ transition: 'none' }}
                            className={`${classes.elementContent} ${clsx({
                              [classes.elementContentClose]: !isOpen
                            })} ${highlighted ? classes.highlighted : ''}`}
                            onClick={() =>
                              action ? handleAction(action, navigation.name) : handleNavigateTo(url, name, planAccess)
                            }
                            button
                            key={name}
                          >
                            <div
                              className={`${classes.wrapperIconText} ${clsx({
                                [classes.wrapperIconTextClose]: !isOpen
                              })}`}
                            >
                              <Tooltip className={classes.tooltip} title={name} placement="top">
                                {icon && icon}
                              </Tooltip>
                              <ListItemText className={classes.elementText} primary={name}></ListItemText>
                            </div>
                          </ListItem>
                        )}
                      </>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

NavigationList.propTypes = {
  onNavigate: PropTypes.func,
  isOpen: PropTypes.bool,
  navigations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.element,
      show: PropTypes.number,
      paths: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          action: PropTypes.func,
          show: PropTypes.number
        })
      )
    }).isRequired
  )
};
