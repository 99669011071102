import { makeStyles } from '@material-ui/core/styles';

export const useViewSetupStyles = makeStyles(theme => ({
  setup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    minWidth: 250,
    padding: '12px 8px',
    margin: '20px 12px 4px 6px',
    height: '100%',
    alignSelf: 'stretch'
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '2 1 0%',
    minWidth: 450,
    padding: '12px 8px',
    margin: '20px 6px 4px 12px',
    height: '100%',
    alignSelf: 'stretch'
  }
}));
