import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Summary, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useFormOptionStyles } from './formOption.styles';
import { useFormOption } from './useFormOption';
import { ImageTagSelect } from 'pages/Experiences/Edit/shared';

export function FormOption({ option, ratingItem, mediaFiles, formRef, onSubmit, disabled }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormOptionStyles();
  const { optionInputs, handleChange, handleSubmit, errorMessage, successMessage } = useFormOption(
    option,
    ratingItem,
    onSubmit
  );

  return (
    <>
      {(successMessage || errorMessage) && (
        <Grid item xs={12}>
          <Summary
            text={successMessage ? t(successMessage) : t(errorMessage)}
            severity={successMessage ? 'success' : 'error'}
          />
        </Grid>
      )}
      <Form
        className={classes.formOption}
        errors={errorMessage}
        elementRef={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <Input
              isLoading={isSkeletonLoading(optionInputs.name)}
              required
              name={t('articleRatingOptions.properties.name')}
              label={t('articleRatingOptions.properties.name')}
              variant="outlined"
              value={optionInputs.name || ''}
              onChange={handleChange('name')}
              inputProps={{ 'aria-label': t('articleRatingOptions.properties.name') }}
              validators={['required', 'maxStringLength:100']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 100 })]}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Input
              isLoading={isSkeletonLoading(optionInputs.reference)}
              required
              name={t('articleRatingOptions.properties.reference')}
              label={t('articleRatingOptions.properties.reference')}
              variant="outlined"
              value={optionInputs.reference || ''}
              onChange={handleChange('reference')}
              inputProps={{ 'aria-label': t('articleRatingOptions.properties.reference') }}
              validators={['required', 'maxStringLength:100']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 100 })]}
              disabled={disabled}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <ImageTagSelect
              variant="outlined"
              withAnyLabel
              title={t('articleRatingOptions.properties.imageTag')}
              value={optionInputs.imageTag}
              onChange={handleChange('imageTag')}
              options={mediaFiles}
              fullWidth
              className={classes.select}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Input
              isLoading={isSkeletonLoading(optionInputs.order)}
              required
              type="number"
              name={t('articleRatingOptions.properties.order')}
              label={t('articleRatingOptions.properties.order')}
              variant="outlined"
              value={optionInputs.order ?? ''}
              onChange={handleChange('order')}
              inputProps={{ 'aria-label': t('articleRatingOptions.properties.order') }}
              validators={['required', 'minNumber:0']}
              errorMessages={[t('validations.required'), t('validations.minNumber', { minimum: 0 })]}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormOption.propTypes = {
  option: PropTypes.object.isRequired,
  ratingItem: PropTypes.object.isRequired,
  mediaFiles: PropTypes.array,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
