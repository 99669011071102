import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useViewFieldsStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth': {
      minWidth: '20rem'
    }
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
