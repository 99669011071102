import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { Form, Input } from '@engloba-tech/englobity';
import { useFormMediaFileStyles } from './formMediaFile.styles';

export function FormMediaFile({ formRef, mediaFile, onSubmit, errors }) {
  const { t } = useTranslation(NAME_SPACE.MEDIA_FILES);
  const classes = useFormMediaFileStyles();
  const [inputs, setInputs] = useState({ ...mediaFile });

  function handleSubmit() {
    onSubmit(inputs);
  }

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));
    };

  return (
    <>
      <Form
        className={`${classes.form} ${classes.container}`}
        elementRef={formRef}
        onSubmit={handleSubmit}
        errors={errors}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              required
              name={t('properties.tag')}
              label={t('properties.tag')}
              variant="outlined"
              value={inputs.tag || ''}
              onChange={handleChange('tag')}
              inputProps={{ 'aria-label': t('properties.tag') }}
              validators={['required']}
              errorMessages={[t('validations.required')]}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name={t('properties.targetLink')}
              label={t('properties.targetLink')}
              variant="outlined"
              value={inputs.targetLink || ''}
              onChange={handleChange('targetLink')}
              inputProps={{ 'aria-label': t('properties.targetLink') }}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormMediaFile.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  mediaFile: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    link: PropTypes.string,
    imageTemplateId: PropTypes.string,
    blobUrl: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  })
};
