import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

export function CustomImage({ src, maxRetries, className, timeout, alt, readToken }) {
  const [tries, setTries] = useState(0);
  const [source, setSource] = useState(src);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSource(`${src}${readToken ? `?${readToken}` : ''}`);
  }, [src, readToken]);

  const onImageLoad = () => {
    setError(false);
    setTries(0);
  };
  const onImageLoadError = () => {
    setTries(tries + 1);
    if (tries < maxRetries) {
      setTimeout(() => {
        setSource(`${src}${readToken ? '&' : '?'}test=${Date.now()}`);
      }, timeout);
    }
    setError(true);
  };

  return (
    <>
      <img
        src={source}
        className={className}
        style={{ display: !error ? 'block' : 'none' }}
        alt={alt}
        onLoad={onImageLoad}
        onError={onImageLoadError}
      />
      {error ? <Skeleton height={'100%'} width={'100%'} /> : ''}
    </>
  );
}

CustomImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  maxRetries: PropTypes.number,
  timeout: PropTypes.number,
  alt: PropTypes.string,
  readToken: PropTypes.string
};

CustomImage.defaultProps = {
  maxRetries: 5,
  timeout: 2000,
  alt: 'Image'
};

export default CustomImage;
