import { useCallback, useEffect, useState } from 'react';
import { azureStorage } from 'services';
import { isSkeletonLoading } from 'shared';

export function useAzureStorage(containerIdentifier) {
  const [sasToken, setSasToken] = useState('');
  const [readSasToken, setReadSasToken] = useState('');

  useEffect(() => {
    async function getData() {
      const response = await azureStorage.getSasToken(containerIdentifier);
      setSasToken(response.data.token);
    }
    containerIdentifier && !isSkeletonLoading(containerIdentifier) && getData();
  }, [containerIdentifier]);

  const uploadFile = useCallback(
    async ({ content, mimeType, fileId }) => {
      return await azureStorage.uploadFile({
        content,
        mimeType,
        containerIdentifier,
        sasToken,
        fileId
      });
    },
    [containerIdentifier, sasToken]
  );

  const getReadSasToken = useCallback(async () => {
    const response = await azureStorage.getReadSasToken(containerIdentifier);
    setReadSasToken(response.data.token);
  }, [containerIdentifier]);

  return { uploadFile, getReadSasToken, sasToken, readSasToken };
}
