import { useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormState } from 'shared';
import { qrCodes } from 'services';

export function useStickerForm(sticker, createMode, onChangeInput) {
  const [stickerInputs, setStickerInputs] = useFormState(sticker, onChangeInput);

  useEffect(() => {
    setStickerInputs(
      {
        ...sticker,
        content: sticker.content ? sticker.content.replaceAll('#TOKEN#', `?${sticker.readToken}`) : null
      },
      false
    );
  }, [sticker]);

  const handleChange =
    fieldName =>
    ({ target }) => {
      setStickerInputs(inputs => ({ ...inputs, [fieldName]: target.value }));
    };

  async function handleExperienceAutocomplete(element) {
    if (element?.id) {
      const productQRCodes = await qrCodes.getByExperience(element.id);
      setStickerInputs(prevInputs => ({
        ...prevInputs,
        product: {
          ...element,
          qrCodes: productQRCodes
        },
        productId: element?.id
      }));
    } else {
      setStickerInputs(prevInputs => ({
        ...prevInputs,
        product: {},
        productId: ''
      }));
    }
  }

  const handleAddMediaFiles = useCallback(
    async mediaFiles => {
      setStickerInputs(prevInputs => ({
        ...prevInputs,
        mediaFiles: [
          ...prevInputs.mediaFiles,
          ...mediaFiles.map(mf => ({ ...mf, mediaFileId: mf.id, id: uuidv4(), canBeDeleted: true }))
        ]
      }));
    },
    [setStickerInputs]
  );

  const handleDeleteMediaFile = useCallback(
    mediaFileToDelete => {
      setStickerInputs(prevInputs => {
        return {
          ...prevInputs,
          mediaFiles: prevInputs.mediaFiles.filter(file => file.id !== mediaFileToDelete.id)
        };
      });
    },
    [setStickerInputs]
  );

  return {
    stickerInputs,
    handleChange,
    handleExperienceAutocomplete,
    handleAddMediaFiles,
    handleDeleteMediaFile
  };
}
