export const basicPanels = editor => {
  const panelManager = editor.Panels;
  panelManager.addPanel({
    id: 'layers',
    el: '.panel__right'
    // Make the panel resizable
    /* resizable: {
      maxDim: 350,
      minDim: 200,
      tc: 0, // Top handler
      cl: 1, // Left handler
      cr: 0, // Right handler
      bc: 0, // Bottom handler
      // Being a flex child we need to change `flex-basis` property
      // instead of the `width` (default)
      keyWidth: 'flex-basis'
    } */
  });
  panelManager.addPanel({
    id: 'panel-switcher',
    el: '.panel__switcher',
    buttons: [
      {
        id: 'show-layers',
        command: 'show-layers',
        active: true,
        togglable: false,
        className: 'fa fa-tasks',
        attributes: { title: 'Layers' }
      },
      {
        id: 'show-traits',
        command: 'show-traits',
        active: true,
        togglable: false,
        className: 'fa fa-cog',
        attributes: { title: 'Settings' }
      },
      {
        id: 'show-style',
        command: 'show-styles',
        active: true,
        togglable: false,
        className: 'fa fa-paint-brush',
        attributes: { title: 'Element styles' }
      },
      {
        id: 'show-blocks',
        active: true,
        command: 'show-blocks',
        togglable: false,
        className: 'fa fa-th-large',
        attributes: { title: 'Elements' }
      }
    ]
  });

  panelManager.addPanel({
    id: 'basic-actions',
    el: '.panel__basic-actions',
    buttons: [
      {
        id: 'visibility',
        active: true, // active by default
        className: 'fa fa-square-o',
        command: 'sw-visibility',
        attributes: { title: 'Border view' }
      },
      {
        id: 'export',
        className: 'fa fa-code',
        command: 'export-template',
        context: 'export-template',
        attributes: { title: 'View code' }
      },
      {
        id: 'undo-button',
        command: 'core:undo',
        active: false,
        togglable: false,
        className: 'fa fa-undo',
        attributes: { title: 'Undo' }
      },
      {
        id: 'redo-button',
        command: 'core:redo',
        active: false,
        togglable: false,
        className: 'fa fa-repeat',
        attributes: { title: 'Redo' }
      },
      {
        id: 'clear-button',
        command: 'clear-html',
        active: false,
        togglable: false,
        className: 'fa fa-trash',
        attributes: { title: 'Clear' }
      },
      {
        id: 'import-button',
        className: 'fa fa-download',
        command: e => e.runCommand('import'),
        attributes: { title: 'Import' }
      },
      {
        id: 'save-button',
        command: 'save-command',
        active: false,
        togglable: false,
        className: 'fa fa-save',
        attributes: { title: 'Save' }
      }
    ]
  });
};
