import { http } from './http';
import { apiHelper } from 'shared';

class Stickers {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/stickers${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/stickers/${queryString}`);
  }

  getById(id) {
    return http.get(`/stickers/${id}`);
  }

  create(sticker) {
    return http.post('/stickers', sticker);
  }

  delete(id) {
    return http.delete(`/stickers/${id}`);
  }

  update(id, sticker) {
    return http.put(`/stickers/${id}`, sticker);
  }

  clone(id) {
    return http.post(`/stickers/${id}`);
  }
}

export const stickers = new Stickers();
