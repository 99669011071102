import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { Input } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';
import { LocaleDatePicker } from 'shared';
import { ContentCard } from 'pages/shared';

export function ViewProfile({ installation }) {
  const { t } = useTranslation(NAME_SPACE.CONFIGURATION);

  return (
    <ContentCard title={t('profile.name')}>
      <>
        <Grid item xs={12}>
          <Input
            isLoading={isSkeletonLoading(installation.clientCode)}
            name={t('profile.properties.clientCode')}
            label={t('profile.properties.clientCode')}
            variant="outlined"
            value={installation.clientCode || ''}
            inputProps={{ 'aria-label': t('profile.properties.clientCode') }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            isLoading={isSkeletonLoading(installation.plan)}
            name={t('profile.properties.plan')}
            label={t('profile.properties.plan')}
            variant="outlined"
            value={installation.plan || ''}
            inputProps={{ 'aria-label': t('profile.properties.plan') }}
          />
        </Grid>
        <Grid item xs={12}>
          <LocaleDatePicker
            icon={null}
            isLoading={isSkeletonLoading(installation.startDate)}
            name={t('profile.properties.startDate')}
            label={t('profile.properties.startDate')}
            value={installation.startDate || ''}
            readOnly
            inputProps={{ 'aria-label': t('profile.properties.startDate') }}
          />
        </Grid>
        <Grid item xs={12}>
          <LocaleDatePicker
            icon={null}
            isLoading={isSkeletonLoading(installation.finishDate)}
            name={t('profile.properties.finishDate')}
            label={t('profile.properties.finishDate')}
            value={installation.finishDate || ''}
            readOnly
            inputProps={{ 'aria-label': t('profile.properties.finishDate') }}
          />
        </Grid>
      </>
    </ContentCard>
  );
}

ViewProfile.propTypes = {
  installation: PropTypes.shape({
    clientCode: PropTypes.string,
    plan: PropTypes.string,
    startDate: PropTypes.string,
    finishDate: PropTypes.string
  })
};
