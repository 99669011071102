export const ratingsModalButton = editor => {
  editor.BlockManager.add('show-ratings-popup-button', {
    category: 'Experience Utils',
    label: 'Rating Popup',
    attributes: { class: 'fa fa-star' },
    content:
      '<a class="ratings-popup-button" data-ratings-popup-button onclick="event.preventDefault();" style="display:inline-block; min-height:50px;min-width:50px"></a>',
    type: 'cmp-show-ratings-popup-button'
  });

  editor.DomComponents.addType('show-ratings-popup-button', {
    isComponent: el =>
      typeof el.hasAttribute === 'function' && el.tagName === 'A' && el.hasAttribute('data-ratings-popup-button'),
    model: {
      defaults: {
        editable: true,
        droppable: true
      }
    }
  });
};
