import { color } from './color.styles';

export const globals = {
  html: {
    fontSize: '16px', // 1rem = 16px,
    height: '100%'
  },
  body: {
    '& *': {
      fontFamily: 'Mulish'
    },
    fontFamily: 'Mulish',
    fontWeight: '300',
    backgroundColor: color.secondary.main,
    height: '100%',
    '& .MuiGrid-root.MuiGrid-container': {
      height: '100%'
    }
  },
  '.MuiButton-label': {
    fontFamily: 'Mulish'
  },
  '.MuiInputBase-root': {
    fontSize: 'inherit',
    background: 'white'
  },
  '.MuiInputLabel-outlined': {
    backgroundColor: 'white',
    padding: '0 0.5rem 0 0.2rem',
    fontSize: 'inherit'
  },
  '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -10px) scale(0.75)',
    width: 'auto',
    backgroundColor: 'white',
    overflow: 'visible',
    lineHeight: 1
  },
  '.MuiTypography-root': {
    fontFamily: 'Mulish'
  },
  '#alert_title': {
    fontWeight: 'bold'
  },
  '#nprogress': {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: '9999',
    bar: {
      zIndex: '9999',

      backgroundColor: color.primary.main
    },
    peg: {
      zIndex: '9999',

      boxShadow: 'none'
    }
  },
  '.MuiAlert-root': {
    padding: '0px 15px !important',
    lineHeight: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row !important',
    marginBottom: '20px'
  },
  '.MuiAlert-standardInfo': {
    padding: '0px 15px !important',
    border: '1px solid #b0ddff'
  },
  '.MuiAlert-standardWarning': {
    padding: '0px 15px !important',
    border: '1px solid #ffcf88'
  },
  '.MuiCardContent-root': {
    '& .MuiCollapse-entered': {
      marginBottom: '20px'
    }
  },
  '.MuiAppBar-colorDefault': {
    borderColor: color.basic.brightest
  },

  '.MuiAlert-standardError': {
    alignItems: 'center',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'white',
    backgroundColor: color.toast.error,
    '& .MuiAlert-icon': {
      color: 'white'
    }
  },
  '.MuiToolbar-root': {
    minHeight: 'auto'
  },
  '.MuiPaper-elevation1.MuiPaper-rounded': {
    boxShadow: color.boxShadow,
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  '.MuiTypography-h6': {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    fontFamily: 'Mulish'
  },
  '.MuiTableHead-root': {
    backgroundColor: color.table.header
  },
  '.MuiTableFooter-root': {
    backgroundColor: color.table.summary
  },
  '.MuiTableCell-root': {
    '& .MuiCollapse-root': {
      marginTop: '5px'
    },
    padding: '5px 15px !important',
    fontSize: '14px !important',
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiSwitch-thumb': {
      marginTop: '9px'
    },
    '& .MuiSwitch-colorPrimary': {
      '&.Mui-checked': {
        marginLeft: '15px'
      }
    }
  },

  '.MuiDialog-paperScrollPaper': {
    display: 'block',
    maxHeight: 'none',
    padding: '0',
    '& .MuiGrid-root.MuiGrid-container': {
      height: 'auto'
    },
    '& .MuiAlert-root': {
      marginBottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& h6': {
        fontSize: '14px'
      }
    },
    /*
    'paperWidthXs'
  | 'paperWidthSm'
  | 'paperWidthMd'
  | 'paperWidthLg'
  | 'paperWidthXl'
    */
    '&.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth': {
      width: 'auto',
      '& .MuiDialogActions-root': {
        marginTop: '1.5rem'
      },
      '& h2': {
        textAlign: 'center',
        fontWeight: 'bold'
      }
    },
    '&.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth': {
      '& .MuiDialogActions-root': {
        marginTop: '1.5rem'
      },
      '& h2': {
        textAlign: 'center',
        fontWeight: 'bold'
      }
    },
    '& .MuiPaper-root': {
      boxShadow: 'none'
    },
    '& .MuiCollapse-root': {
      marginBottom: '20px',
      '&.MuiCollapse-hidden': {
        marginBottom: '0px'
      }
    }
  },
  '.MuiAppBar-root': {
    padding: '0 !important'
  },
  '.MuiTab-root': {
    height: 'auto !important',
    maxWidth: '180px !important',
    textTransform: 'capitalize',
    '& .MuiSvgIcon-root': {
      color: color.primary.main
    },
    '& .MuiTabs-indicator': {
      width: '180px'
    }
  },
  '.MuiTab-textColorPrimary': {
    opacity: 0.65,
    color: 'unset',
    fontWeight: 'bold'
  },
  '.MuiTab-textColorPrimary.Mui-selected': {
    opacity: 1,
    color: 'unset',
    fontWeight: 'bold'
  },
  '.MuiBox-root': {
    '& .MuiPaper-root': {
      padding: '0 !important',
      '& .MuiAlert-standardInfo': {
        '& .MuiAlert-icon': {
          marginLeft: '10px'
        },
        '& .MuiAlert-action': {
          marginRight: '6px'
        }
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: color.primary.main,
    '&:active': { borderColor: color.primary.main, backgroundColor: color.primary.main, color: color.primary.main }
  },
  '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: color.primary.main,
    '&:active': { borderColor: color.primary.main, backgroundColor: color.primary.main, color: color.primary.main }
  },
  'label.MuiInputLabel-outlined': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '75%',
    lineHeight: '1rem'
  },

  '.MuiSelect-outlined': {
    display: 'block !important',
    height: '1.1876em !important'
  },
  '.MuiDialogContent-root': {
    '& .viewtable': {
      padding: 0
    }
  },
  '.MuiDialogContentText-root': {
    fontSize: '14px',
    marginBottom: '24px !important',
    textAlign: 'center',
    paddingBottom: '15px'
  },
  '.react-bpmn-diagram-container *': {
    fontFamily: 'inherit'
  },

  '.MuiLink-root': {
    cursor: 'pointer'
  },
  '.djs-label': {
    '& tspan': {
      fontFamily: 'PT Sans Caption, sans-serif, arial, Times New Roman'
    }
  }
};
