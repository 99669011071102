import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useViewTableData, useHandleRequest, skeletonFactory } from 'shared';
import { mediaFiles } from 'services';
import { useAzureStorage } from 'shared';
import { useTranslation } from 'react-i18next';
import { CONTAINERS } from 'pages/shared';

const mediaFilesDummy = skeletonFactory.mediaFiles(26);

export function useMediaFilesPaginated() {
  const { t } = useTranslation();
  const {
    data: mediaFilesData,
    get: getMediaFiles,
    deleteElements: deleteMediaFiles,
    errorInfo: getViewTableErrorKey,
    setErrorInfo: setViewTableErrorKey,
    setData,
    paging,
    sorting,
    filteredCells,
    undoData
  } = useViewTableData({
    service: mediaFiles,
    paginatedSkeleton: mediaFilesDummy
  });
  const { uploadFile } = useAzureStorage(CONTAINERS.MEDIA);
  const { request } = useHandleRequest();

  const createMediaFiles = useCallback(
    async selectedFiles => {
      await request(async () => {
        await Promise.allSettled(
          selectedFiles.map(async element => {
            setData(prevElement => {
              prevElement.items = prevElement.items.map(w => {
                if (!selectedFiles.find(o => o.id === w.id)) {
                  return w;
                }
                return mediaFilesDummy.items[0];
              });
              return { ...prevElement };
            });
            const fileId = uuidv4();
            const urlFile = await uploadFile({ ...element, fileId: fileId });
            element.blobUrl = urlFile;
            element.id = fileId;
            const result = await mediaFiles.create(element);
            return result;
          })
        ).then(responses => {
          if (responses.filter(resp => resp.status === 'rejected').length === responses.length) {
            undoData();
            const errorResponse = JSON.parse(responses[0].reason.response.data);
            setViewTableErrorKey({
              message: errorResponse.message ? t(JSON.parse(responses[0].reason.response.data).message) : errorResponse
            });
          } else if (
            responses.some(resp => resp.status === 'rejected') &&
            responses.some(resp => resp.status === 'fulfilled')
          ) {
            getMediaFiles(paging, sorting, filteredCells);
            const errorResponse = responses.find(resp => resp.status === 'rejected').reason.response.data;
            setViewTableErrorKey({
              message: `${t('addedWithErrors')} ${errorResponse.message ? t(JSON.parse().message) : errorResponse}`,
              severity: 'warning'
            });
          } else {
            getMediaFiles(paging, sorting, filteredCells);
          }
        });
      });
    },
    [request, uploadFile, getMediaFiles, paging, sorting, filteredCells, setData, setViewTableErrorKey, t, undoData]
  );

  const updateMediaFile = useCallback(
    async mediaFile => {
      try {
        return await request(async () => {
          setData(prevElements => ({
            ...prevElements,
            items: prevElements.items.map(prevMediaFile =>
              prevMediaFile.id === mediaFile.id ? { ...prevMediaFile, ...mediaFile } : prevMediaFile
            )
          }));
          const result = await mediaFiles.update(mediaFile.id, mediaFile);
          getMediaFiles(paging, sorting, filteredCells);
          return result;
        });
      } catch (error) {
        undoData();
      }
    },
    [request, setData, undoData, paging, sorting, filteredCells, getMediaFiles]
  );

  return {
    mediaFiles: mediaFilesData,
    errorInfo: getViewTableErrorKey,
    setErrorInfo: setViewTableErrorKey,
    createMediaFiles,
    updateMediaFile,
    getMediaFiles,
    deleteMediaFiles
  };
}
