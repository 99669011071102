import { objectHelper } from 'shared';
import { TEMPLATE_NAME, TEMPLATE_PAGE_REFERENCE_TYPE } from './experienceTypes.const';
import { PLAN_ACCESS } from 'auth';

export const experienceHelper = {
  getNextOrder: function (items) {
    const order =
      items && items.length > 0
        ? Math.max(
            ...items.map(v =>
              v.order !== null && v.order !== undefined && v.order.toString().length > 0 ? v.order : 0
            )
          ) + 1
        : 1;

    return order;
  },
  syncTemplateMediaFiles: function (experienceMediaFiles, template) {
    if (template?.name === TEMPLATE_NAME.CIGAR_SHOP_ANDORRA) {
      return [];
    }

    let mediaFiles = [
      ...experienceMediaFiles.filter(mf => !mf.imageTemplateId),
      ...(template
        ? template.images.map(templateMediaFile => ({
            imageTemplateId: templateMediaFile.id
          }))
        : [])
    ];

    return mediaFiles;
  },
  templateSectionsUsed: function (template) {
    let templateUsesMediaFiles = false;
    let templateUsesFields = false;
    let templateUsesIcons = false;
    let templateUsesCustomPages = false;

    if (!objectHelper.isEmpty(template)) {
      const isAndorraCigarShopTemplate = template.id && template?.name === TEMPLATE_NAME.CIGAR_SHOP_ANDORRA;
      const isRedirectTemplate = template.id && template?.name === TEMPLATE_NAME.REDIRECT;
      templateUsesMediaFiles = !isAndorraCigarShopTemplate && !isRedirectTemplate;
      templateUsesCustomPages = template.name === TEMPLATE_NAME.CUSTOM_PAGE;
      templateUsesIcons = templateUsesMediaFiles && !templateUsesCustomPages;
      templateUsesFields = template.name === TEMPLATE_NAME.VERTICAL || template.name === TEMPLATE_NAME.HORIZONTAL;
    }

    return {
      mediaFiles: templateUsesMediaFiles,
      fields: templateUsesFields,
      icons: templateUsesIcons,
      editor: templateUsesCustomPages,
      productRatings: templateUsesCustomPages
    };
  },
  isPageActive: function (experience, pageReference) {
    return experience && experience.pages?.filter(p => p.reference === pageReference)[0]?.visible;
  },
  getPageMinimumPlanVisible: function (templatePageReference) {
    switch (templatePageReference) {
      case TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION:
        return PLAN_ACCESS.STANDARD;
      case TEMPLATE_PAGE_REFERENCE_TYPE.RATINGS:
        return PLAN_ACCESS.ADVANCED;
      default:
        return PLAN_ACCESS.BASIC;
    }
  }
};
