import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { useDynamicMenuStyles } from './dynamicMenu.styles';
import { useTranslation } from 'react-i18next';
import { AcademyLink } from 'shared';

const MOBILE_MENU_ID = 'primary-search-account-menu-mobile';

export function DynamicMenu({ avatar }) {
  const classes = useDynamicMenuStyles();
  const { t } = useTranslation();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  return (
    <>
      <div className={classes.desktopMenu}>
        <AcademyLink component={MenuItem} />
        {avatar && avatar}
      </div>
      <div className={classes.mobileMenu}>
        <IconButton
          aria-label="show more"
          aria-controls={MOBILE_MENU_ID}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
        >
          <MoreIcon />
        </IconButton>
      </div>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={MOBILE_MENU_ID}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="show 4 new mails" className={classes.buttonIcon} onClick={handleMobileMenuClose}>
            <SearchIcon className={classes.icons} />
          </IconButton>
          <p className={classes.label}>{t('navigation.search')}</p>
        </MenuItem>
        <MenuItem>
          <IconButton
            aria-label="show 11 new notifications"
            className={classes.buttonIcon}
            onClick={handleMobileMenuClose}
          >
            <NotificationsIcon className={classes.icons} />
          </IconButton>
          <p className={classes.label}>{t('navigation.notifications')}</p>
        </MenuItem>
        {avatar && avatar}
      </Menu>
    </>
  );
}

DynamicMenu.propTypes = {
  avatar: PropTypes.element
};
