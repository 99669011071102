import { makeStyles } from '@material-ui/core/styles';
import { buttonAddStyles, buttonPrimaryStyles, buttonDeleteStyles } from 'shared/Buttons/button.styles';

export const useViewBlockCountryStyles = makeStyles(theme => ({
  legalBlockCountry: {},
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '35rem'
    }
  },
  container: {
    backgroundColor: theme.palette.primary.light,
    alignContent: 'flex-start',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  contentContainer: {
    margin: 0,
    paddingBottom: `${theme.spacing(2)}px !important`
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonBack: {
    ...buttonPrimaryStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  }
}));
