import React, { useContext, useCallback } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, Input, Button } from '@engloba-tech/englobity';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestLoading } from 'shared';
import { NAME_SPACE } from 'i18n';
import { UserContext } from 'auth';
import { useHistory } from 'react-router-dom';
import { PATH } from 'app.routes.const';
import { BrandContext } from 'brand';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  imgFluid: {
    height: 'auto',
    maxWidth: '100%',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    marginBottom: '4rem'
  },
  imgPlaceholder: {
    height: '150px'
  }
}));

export function Login() {
  const classes = useStyles();
  const { t } = useTranslation(NAME_SPACE.AUTHENTICATION);
  const [inputs, setInputs] = useState({});

  const { login, errorInfo } = useContext(UserContext);
  const { callbackRequest } = useRequestLoading();
  const history = useHistory();

  const { brand } = useContext(BrandContext);

  const enterLogin = useCallback(() => {
    const returnUrl = new URLSearchParams(window.location.search).get('returnUrl');
    history.push(returnUrl || PATH.HOME);
  }, [history]);

  async function handleLogin() {
    await callbackRequest(async () => {
      await login(inputs);
      enterLogin();
    });
  }

  const handleField =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {brand.logo ? (
          <img src={brand.logo} className={classes.imgFluid} alt="logo HFGroup" />
        ) : (
          <div className={`${classes.imgFluid} ${classes.imgPlaceholder}`}></div>
        )}
        <Typography
          gutterBottom
          component="h1"
          variant="h5"
          style={{ fontWeight: 'bold', fontFamily: 'Mulish', fontSize: '30px', textAlign: 'center' }}
        >
          {t('login.welcomeTitle')}
        </Typography>

        <Typography gutterBottom component="div" variant="body1" style={{ fontFamily: 'Mulish' }}>
          {t('login.welcomeDescription')}
        </Typography>

        <Form onSubmit={handleLogin} errors={errorInfo} className={classes.form} noValidate>
          <Input
            onChange={handleField('user')}
            value={inputs.user}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder={`${t('email')}*`}
            name="email"
            autoComplete="email"
            autoFocus
            inputProps={{ 'aria-label': 'user' }}
            validators={['required', 'isEmail']}
            errorMessages={[t('validations.required'), t('validations.invalidEmail')]}
          />
          <Input
            onChange={handleField('password')}
            value={inputs.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            placeholder={`${t('password')}*`}
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />

          <Button isSubmit fullWidth type="primary" className={classes.submit}>
            {t('login.signIn')}
          </Button>
        </Form>
      </div>
    </Container>
  );
}
