import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { useColorPickerStyles } from './colorPicker.styles';
import { Skeleton } from '@material-ui/lab';
import { isSkeletonLoading } from 'shared/skeletonFactory';

export function ColorPicker({ name, color, onChange, disabled }) {
  const classes = useColorPickerStyles();
  const [display, setDisplay] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color || '#000000');

  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  function handleClick() {
    setDisplay(!display);
  }

  function handleClose() {
    setDisplay(false);
  }

  function handleChange(color) {
    setSelectedColor(color.hex);
  }

  function handleChangeComplete(color) {
    setSelectedColor(color.hex);
    if (onChange) {
      const evt = {
        target: {
          name: name,
          value: color.hex
        }
      };
      onChange(evt);
    }
  }

  return (
    <div>
      {isSkeletonLoading(selectedColor) ? (
        <Skeleton />
      ) : (
        <div
          className={!disabled ? classes.swatch : `${classes.swatch} ${classes.swatchDisabled}`}
          onClick={!disabled ? handleClick : undefined}
        >
          <div className={classes.color} style={{ backgroundColor: selectedColor }} />
        </div>
      )}
      {display ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <SketchPicker
            disableAlpha
            presetColors={[]}
            color={selectedColor}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
}

ColorPicker.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
