import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Select } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';

export const SortSelect = ({ className, value, title, onChange }) => {
  const { t } = useTranslation(NAME_SPACE.MEDIA_FILES);

  const values = useMemo(() => {
    return [
      {
        value: '-uploadDate',
        name: `${t('properties.uploadDate')} (${t('common:sorting.sortNewestFirst')})`
      },
      {
        value: 'uploadDate',
        name: `${t('properties.uploadDate')} (${t('common:sorting.sortOldestFirst')})`
      },
      {
        value: 'tag',
        name: `${t('properties.tag')} (${t('common:sorting.sortAtoZ')})`
      },
      {
        value: '-tag',
        name: `${t('properties.tag')} (${t('common:sorting.sortZtoA')})`
      }
    ];
  }, [t]);

  return (
    <Select
      isLoading={isSkeletonLoading(value)}
      defaultValue={'-uploadDate'}
      value={value}
      elements={values}
      name={title}
      onChange={onChange}
      title={title}
      className={className}
      fullWidth
    />
  );
};

SortSelect.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};
