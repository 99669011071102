import React, { useMemo, useContext } from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Drawer } from '@material-ui/core';
import { NavigationList } from './NavigationList';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'auth';
import { navigationHelper } from './navigation.helper';
import { useNavigationStyle } from './navigation.styles';
import { useNavigationStorage } from './useNavigationStorage';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PATH } from 'app.routes.const';
import { BrandContext } from 'brand';

export function Navigation() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { brand } = useContext(BrandContext);
  const classes = useNavigationStyle();
  let location = useLocation();
  const history = useHistory();
  const { isNavOpenisOpen, toggleNavOpen } = useNavigationStorage();
  const navigation = useMemo(() => navigationHelper.generate(t), [t]);
  const navigationItems = useMemo(() => navigationHelper.byAccess(navigation, user.access), [navigation, user.access]);
  const highlightedNavigations = useMemo(
    () => navigationHelper.highlighted(navigationItems, location.pathname),
    [navigationItems, location.pathname]
  );

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isNavOpenisOpen,
        [classes.drawerClose]: !isNavOpenisOpen
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isNavOpenisOpen,
          [classes.drawerClose]: !isNavOpenisOpen
        })
      }}
    >
      {brand.layoutLogo ? (
        <img
          src={brand.layoutLogo}
          className={classes.hgTagLogo}
          alt="logo HFGroup"
          onClick={() => history.push(PATH.HOME)}
        />
      ) : (
        <div className={`${classes.hgTagLogo} ${classes.imgPlaceholder}`}></div>
      )}

      <NavigationList navigations={highlightedNavigations} isOpen={isNavOpenisOpen} />
      <div
        className={clsx(classes.footer, {
          [classes.footerOpen]: isNavOpenisOpen,
          [classes.footerClose]: !isNavOpenisOpen
        })}
      >
        <Divider />
        <div className={`${classes.toolbar} ${classes.footerWrapperButton}`}>
          <IconButton onClick={toggleNavOpen}>
            {!isNavOpenisOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
      </div>
    </Drawer>
  );
}
