import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { Grid } from '@material-ui/core';
import { isSkeletonLoading } from 'shared';
import { FontSelect } from 'pages/shared';
import { ImageTagSelect } from '../../shared';

export const ViewPageAuth = ({
  classes,
  mediaFiles,
  visible,
  isTamperType,
  closeTamperImageTag,
  closeTamperText,
  openTamperImageTag,
  openTamperText,
  unknownTamperImageTag,
  unknownTamperText,
  fontFamily,
  continueButtonText,
  successfulAuthenticationImageTag,
  unsuccessfulAuthenticationImageTag,
  successfulAuthenticationText,
  unsuccessfulAuthenticationText,
  onChange,
  onSwitchChange,
  onChangePage,
  itemSizeLg,
  templateUsesMediaFiles
}) => {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  return (
    <>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 4 }}>
        <FontSelect
          className={classes.select}
          value={fontFamily}
          title={t('pages.properties.fontFamily')}
          name={t('pages.properties.fontFamily')}
          onChange={onChangePage('fontFamily')}
        />
      </Grid>
      {templateUsesMediaFiles && (
        <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 5 }}>
          <ImageTagSelect
            variant="outlined"
            withAnyLabel
            title={t('properties.successfulAuthenticationImageTag')}
            value={successfulAuthenticationImageTag}
            onChange={onChange('successfulAuthenticationImageTag')}
            options={mediaFiles}
            fullWidth
            className={classes.select}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 6 }}>
        <Input
          isLoading={isSkeletonLoading(successfulAuthenticationText)}
          name={t('properties.successfulAuthenticationText')}
          label={t('properties.successfulAuthenticationText')}
          variant="outlined"
          value={successfulAuthenticationText || ''}
          onChange={onChange('successfulAuthenticationText')}
          inputProps={{ 'aria-label': t('properties.successfulAuthenticationText') }}
          validators={['maxStringLength:256']}
          errorMessages={[t('common:validations.maxStringLength', { length: 256 })]}
        />
      </Grid>
      {templateUsesMediaFiles && (
        <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 7 }}>
          <ImageTagSelect
            variant="outlined"
            withAnyLabel
            title={t('properties.unsuccessfulAuthenticationImageTag')}
            value={unsuccessfulAuthenticationImageTag}
            onChange={onChange('unsuccessfulAuthenticationImageTag')}
            options={mediaFiles}
            fullWidth
            className={classes.select}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 8 }}>
        <Input
          isLoading={isSkeletonLoading(unsuccessfulAuthenticationText)}
          name={t('properties.unsuccessfulAuthenticationText')}
          label={t('properties.unsuccessfulAuthenticationText')}
          variant="outlined"
          value={unsuccessfulAuthenticationText || ''}
          onChange={onChange('unsuccessfulAuthenticationText')}
          inputProps={{ 'aria-label': t('properties.unsuccessfulAuthenticationText') }}
          validators={['maxStringLength:256']}
          errorMessages={[t('common:validations.maxStringLength', { length: 256 })]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 9 }}>
        <Input
          isLoading={isSkeletonLoading(continueButtonText)}
          name={t('pages.properties.continueButtonText')}
          label={t('pages.properties.continueButtonText')}
          variant="outlined"
          value={continueButtonText || ''}
          onChange={onChangePage('continueButtonText')}
          inputProps={{ 'aria-label': t('pages.properties.continueButtonText') }}
          validators={['maxStringLength:100']}
          errorMessages={[t('common:validations.maxStringLength', { length: 100 })]}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.checkInput} style={{ order: 10 }}>
        <Switch
          label={t('properties.isTamperType')}
          checked={isTamperType || false}
          onChange={onSwitchChange('isTamperType')}
          disabled={!visible}
          className={classes.switch}
        />
      </Grid>

      {isTamperType && (
        <>
          {templateUsesMediaFiles && (
            <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 13 }}>
              <ImageTagSelect
                variant="outlined"
                withAnyLabel
                title={t('properties.closeTamperImageTag')}
                value={closeTamperImageTag}
                onChange={onChange('closeTamperImageTag')}
                options={mediaFiles}
                fullWidth
                className={classes.select}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 14 }}>
            <Input
              isLoading={isSkeletonLoading(closeTamperText)}
              name={t('properties.closeTamperText')}
              label={t('properties.closeTamperText')}
              variant="outlined"
              value={closeTamperText || ''}
              onChange={onChange('closeTamperText')}
              inputProps={{ 'aria-label': t('properties.closeTamperText') }}
              required={isTamperType}
              validators={isTamperType ? ['required', 'maxStringLength:256'] : []}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 256 })]}
            />
          </Grid>

          {templateUsesMediaFiles && (
            <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 17 }}>
              <ImageTagSelect
                variant="outlined"
                withAnyLabel
                title={t('properties.openTamperImageTag')}
                value={openTamperImageTag}
                onChange={onChange('openTamperImageTag')}
                options={mediaFiles}
                fullWidth
                className={classes.select}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 18 }}>
            <Input
              isLoading={isSkeletonLoading(openTamperText)}
              name={t('properties.openTamperText')}
              label={t('properties.openTamperText')}
              variant="outlined"
              value={openTamperText || ''}
              onChange={onChange('openTamperText')}
              inputProps={{ 'aria-label': t('properties.openTamperText') }}
              required={isTamperType}
              validators={isTamperType ? ['required', 'maxStringLength:256'] : []}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 256 })]}
            />
          </Grid>
          {templateUsesMediaFiles && (
            <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 19 }}>
              <ImageTagSelect
                variant="outlined"
                withAnyLabel
                title={t('properties.unknownTamperImageTag')}
                value={unknownTamperImageTag}
                onChange={onChange('unknownTamperImageTag')}
                options={mediaFiles}
                fullWidth
                className={classes.select}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={itemSizeLg} lg={itemSizeLg} style={{ order: 20 }}>
            <Input
              isLoading={isSkeletonLoading(unknownTamperText)}
              name={t('properties.unknownTamperText')}
              label={t('properties.unknownTamperText')}
              variant="outlined"
              value={unknownTamperText || ''}
              onChange={onChange('unknownTamperText')}
              inputProps={{ 'aria-label': t('properties.unknownTamperText') }}
              required={isTamperType}
              validators={isTamperType ? ['required', 'maxStringLength:256'] : []}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 256 })]}
            />
          </Grid>
        </>
      )}
    </>
  );
};

ViewPageAuth.propTypes = {
  classes: PropTypes.object.isRequired,
  mediaFiles: PropTypes.array,
  visible: PropTypes.bool,
  legalAge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isTamperType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  closeTamperImageTag: PropTypes.string,
  closeTamperText: PropTypes.string,
  openTamperImageTag: PropTypes.string,
  openTamperText: PropTypes.string,
  unknownTamperImageTag: PropTypes.string,
  unknownTamperText: PropTypes.string,
  fontFamily: PropTypes.string,
  continueButtonText: PropTypes.string,
  successfulAuthenticationImageTag: PropTypes.string,
  unsuccessfulAuthenticationImageTag: PropTypes.string,
  successfulAuthenticationText: PropTypes.string,
  unsuccessfulAuthenticationText: PropTypes.string,
  onChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  onChangePage: PropTypes.func,
  itemSizeLg: PropTypes.number,
  templateUsesMediaFiles: PropTypes.bool
};
