const AUTH_STORAGE = {
  PATH_TO_GO: 'path_to_go',
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD'
};

class AuthStorage {
  setToken(token) {
    localStorage.setItem(AUTH_STORAGE.TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(AUTH_STORAGE.TOKEN);
  }

  setUserName(userName) {
    localStorage.setItem(AUTH_STORAGE.USERNAME, userName);
  }

  getUserName() {
    return localStorage.getItem(AUTH_STORAGE.USERNAME);
  }

  setPassword(password) {
    return localStorage.setItem(AUTH_STORAGE.PASSWORD, password);
  }

  getPassword() {
    return localStorage.getItem(AUTH_STORAGE.PASSWORD);
  }
}

export const authStorage = new AuthStorage();
