import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Avatar } from '@material-ui/core';
import { Form } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { useFormAvatarStyles } from './formAvatar.styles';
import { fileHelper, Dropzone, ButtonDelete } from 'shared';

export function FormAvatar({ avatar, formRef, onSubmit }) {
  const { t } = useTranslation(NAME_SPACE.RATES);
  const classes = useFormAvatarStyles();
  const [selectedFile, setSelectedFile] = useState(avatar);

  function handleSubmit() {
    onSubmit(selectedFile);
  }

  async function handleSelectFile(acceptedFile) {
    const file = acceptedFile[0];
    if (file) {
      const readFile = await fileHelper.asyncImgReader(file);
      setSelectedFile(readFile.content);
    }
  }

  function handleDeleteFile() {
    setSelectedFile();
  }

  return (
    <Form
      className={`${classes.form} ${classes.container}`}
      elementRef={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid container alignItems="center" item xs={12}>
          <Avatar src={`data:image/jpeg;base64,${selectedFile || ''}`} className={classes.picture} />

          <Dropzone
            accept="image/*"
            onDrop={handleSelectFile}
            onDeleteFile={handleDeleteFile}
            labelDrop={t('common:import.drop')}
          />

          <ButtonDelete onClick={handleDeleteFile} />
        </Grid>
      </Grid>
    </Form>
  );
}

FormAvatar.propTypes = {
  avatar: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired
};
