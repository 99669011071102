export const mainContainer = (editor) => {
	editor.BlockManager.add('body-main-container', {
		category: 'Experience Utils',
		label: 'Main container',
		attributes: { class: 'fa fa-newspaper-o' },
		content: '<main class="main-container"></main>',
		type: 'cmp-main-container',
	});

	editor.DomComponents.addType('main-container', {
		isComponent: (el) =>
			el.tagName === 'MAIN' && el.className === 'main-container',
		model: {
			defaults: {
				editable: false,
				droppable: true,
				style: {
					display: 'block',
					height: '100%',
					width: '100%',
					'min-height': '100vh',
					overflow: 'auto',
					'box-sizing': 'border-box',
				},
			},
		},
	});
};
