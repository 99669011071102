import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

const buttonCommonStyles = {
  height: '2.7rem',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  fontWeight: 'bold',
  border: 'none',
  marginLeft: '0.8rem',
  '&:hover': {
    border: 'none',
    boxShadow: 'none'
  }
};

export const buttonDeleteStyles = {
  ...buttonCommonStyles,
  backgroundColor: color.buttons.delete.background,
  color: color.buttons.delete.text,
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: color.buttons.delete.text,
    color: '#fff',
    boxShadow: 'none'
  }
};

export const buttonAddStyles = {
  ...buttonCommonStyles,
  backgroundColor: color.buttons.add.background,
  color: color.buttons.add.text,
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: color.buttons.add.text,
    color: '#fff',
    boxShadow: 'none'
  }
};

export const buttonPrimaryStyles = {
  ...buttonCommonStyles,
  backgroundColor: color.buttons.primary.background,
  color: color.buttons.primary.text,
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: color.buttons.primary.text,
    color: '#fff',
    boxShadow: 'none'
  }
};

export const useButtonStyles = makeStyles(theme => ({
  buttonCommonStyles,
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonAdd: {
    ...buttonAddStyles
  },
  buttonDelete: {
    ...buttonDeleteStyles
  },
  buttonPrimary: {
    ...buttonPrimaryStyles
  }
}));
