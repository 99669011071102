import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewTemplatePages } from '../ViewTemplatePages';
import { useViewExperienceStyles } from './viewExperience.styles';
import { ViewIcons } from '../ViewIcons';
import { experienceHelper, TEMPLATE_PAGE_REFERENCE_TYPE } from '../shared';
import { UserContext } from 'auth';
import { PLAN_ACCESS } from 'auth';

export function ViewExperience({ experience, onChange, onSwitchChange, onAddIcon, onDeleteIcon }) {
  const classes = useViewExperienceStyles();
  const { hasMinimumPlan } = useContext(UserContext);

  const templatePages = useMemo(() => {
    let pageReferenceFilters = [TEMPLATE_PAGE_REFERENCE_TYPE.DEFAULT_PAGE];

    if (!hasMinimumPlan(PLAN_ACCESS.STANDARD)) {
      pageReferenceFilters.push(TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION);
    }

    if (!hasMinimumPlan(PLAN_ACCESS.ADVANCED)) {
      pageReferenceFilters.push(TEMPLATE_PAGE_REFERENCE_TYPE.RATINGS);
    }

    return experience.pages.filter(
      page => pageReferenceFilters.findIndex(reference => reference === page.reference) < 0
    );
  }, [experience.pages, hasMinimumPlan]);

  const templateSectionsUsed = experienceHelper.templateSectionsUsed(experience?.template);

  return (
    <div className={classes.experience}>
      {experience.template && (
        <>
          <ViewTemplatePages
            experience={experience}
            pages={templatePages}
            onChange={onChange}
            onSwitchChange={onSwitchChange}
            templateUsesMediaFiles={templateSectionsUsed.mediaFiles}
          />
          {templateSectionsUsed.icons && (
            <ViewIcons
              mediaFiles={experience.mediaFiles}
              icons={experience.icons}
              onAddIcon={onAddIcon}
              onChange={onChange}
              onDeleteIcon={onDeleteIcon}
            />
          )}
        </>
      )}
    </div>
  );
}

ViewExperience.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    templateId: PropTypes.string,
    template: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isPublished: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    mediaFiles: PropTypes.array,
    pages: PropTypes.array,
    icons: PropTypes.array,
    readToken: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  onAddIcon: PropTypes.func.isRequired,
  onDeleteIcon: PropTypes.func.isRequired
};
