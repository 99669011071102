import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { ButtonAdd } from 'shared';
import { useViewArticleRatingItemsStyles } from './viewArticleRatingItems.styles';
import { ViewArticleRatingItem } from './ViewArticleRatingItem';
import { ARTICLE_RATING_TYPE } from 'pages/Experiences/Edit/shared';

export function ViewArticleRatingItems({
  ratingItems = [],
  mediaFiles,
  onAdd,
  onChange,
  onDelete,
  onAddOption,
  onDeleteOption,
  onEditOption
}) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewArticleRatingItemsStyles();

  const ratingTypes = useMemo(() => {
    return Object.keys(ARTICLE_RATING_TYPE).map(key => {
      return { name: t(`articleRatings.ratingTypes.${key.toLowerCase()}`), value: ARTICLE_RATING_TYPE[key] };
    });
  }, [t]);

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.titleContainer}>
          <div className={classes.subtitle}>
            <Typography variant="body1" className={classes.title}>
              {t('articleRatings.name')}
            </Typography>
            <ButtonAdd onClick={onAdd} />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.ratingContainer}>
          {ratingItems.map((ratingItem, index) => (
            <>
              <ViewArticleRatingItem
                key={index}
                ratingItem={ratingItem}
                disabled={ratingItem.hasRatedScans}
                onChange={onChange}
                onDelete={onDelete}
                onAddOption={onAddOption}
                onDeleteOption={onDeleteOption}
                onEditOption={onEditOption}
                ratingTypes={ratingTypes}
                mediaFiles={mediaFiles}
              />
              {index < ratingItems.length - 1 && <Divider variant="middle" className={classes.divider} />}
            </>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

ViewArticleRatingItems.propTypes = {
  ratingItems: PropTypes.array.isRequired,
  mediaFiles: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired,
  onDeleteOption: PropTypes.func.isRequired,
  onEditOption: PropTypes.func.isRequired
};
