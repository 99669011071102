import { makeStyles } from '@material-ui/core/styles';

export const useViewTableToolbarStyles = makeStyles(theme => ({
  toolbarTitleRow: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 20px !important'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    lineHeight: 2.2,
    fontSize: '1.1rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));
